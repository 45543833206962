<template>
  <div>
    <input
      class="searchBar"
      type="text"
      v-model="search"
      placeholder="Gruppe suchen..."
    />

    <div class="groupList">
      <div class="groupListItem" v-for="group in filteredGroups" :key="group">
        <div class="box content" @click="openGroup(group)">
          <h1 class="groupTitle cpTextMedium">
            {{ group.title }}
          </h1>
          <p class="groupDescription cpTextSmall">
            {{ group.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupList",
  props: [],
  mounted: function () {
    this.getGroups();
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      groups: [],
      search: "",
    };
  },
  methods: {
    getGroups() {
      return;
      this.HTTPRequest()
        .getAllGroups()
        .then(
          function (response) {
            this.groups = response.data.groups;
          }.bind(this)
        );
    },
    openGroup(group) {
      this.$emit("selectGroup", group);
    },
  },
  computed: {
    filteredGroups: function () {
      return this.groups.filter((group) => {
        return group.title.toLowerCase().match(this.search.toLowerCase());
      });
    },
  },
};
</script>

<style scoped>
.groupList {
  width: 100%;
}

.groupListItem {
  width: 90%;
  margin: 10px;
  display: inline-block;
  cursor: pointer;
}

.groupTitle {
  color: #e9a12d;
}

.box {
  background-color: #1c63af;
  color: white;
  border-radius: 40px;
  box-shadow: -10px 10px 15px #777777;
}
</style>