import overview from './overview.vue';
import detail from './detail.vue';

export default {
    install(Vue, options) {
        Vue.component('qf-beispielservice', overview);
        Vue.component('qf-beispielservice-detail', detail);

        options.store.commit('addService', { "overview": "qf-beispielservice", "page": "qf-beispielservice-detail", "name": "Bürgerservice", "id": "1234sss3" })
    },

    testexport() {
        console.log("test export")
    }
}