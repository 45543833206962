<template>
  <div class="component">
    <div class="columns is-mobile is-multiline is-gapless">
      <div
        class="
          surveyItem
          column
          is-6-desktop is-3-fullhd is-6-mobile is-6-tablet
        "
        v-for="(survey, index) in surveys"
        :key="survey.id"
        @click="chooseSurvey(survey.id, index)"
        v-if="survey.isOpen && (isAdmin || survey.questions.length > 0)"
      >
        <div class="surveyContainer" :style="getSurveyItemClass(survey, index)">
          <div class="surveyRow" v-if="!isAdmin">
            <span class="surveyTitle">{{ survey.title }}</span>
            <span class="surveyBottomButton">{{
              $t("enaq_translation_surveys_vote")
            }}</span>
          </div>
          <!-- Admin-Bereich -->
          <div v-else>
            <span v-if="isAdmin" class="surveyTitle surveyRow">{{
              survey.title
            }}</span>
          </div>
        </div>
      </div>

      <div
        class="surveyItem column is-3"
        v-for="survey in surveys"
        :key="survey"
        v-if="!survey.isOpen && (isAdmin || survey.questions.length > 0)"
        @click="chooseSurvey(survey.id)"
      >
        <div class="surveyContainer" :style="getSurveyItemClass(survey)">
          <div class="surveyRow" v-if="!isAdmin">
            <span class="surveyTitle">{{ survey.title }}</span>
            <span class="surveyBottomButton">{{
              $t("enaq_translation_surveys_closed")
            }}</span>
          </div>
          <div v-else>
            <span v-if="isAdmin" class="surveyTitle surveyRow">{{
              survey.title
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "surveyList",
  props: ["isAdmin"],
  mounted: function () {
    this.getSurveys();
    this.callApi("/survey/all");
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      surveys: [],
    };
  },
  methods: {
    getSurveys() {
      this.callApi("/survey/all", "get").then(
        function (response) {
          console.log(JSON.stringify(response) + "x");
          this.surveys = response.data.surveys;
        }.bind(this)
      );
      return;
    },

    chooseSurvey(id) {
      this.$emit("choose", id);
    },
    getSurveyItemClass(survey, index = 0) {
      let backgroundColors = ["#FFC400", "#00EC94", "#F80084", "#4C39FF"];
      let bgColor = "#9792A8";
      if (survey.isOpen == 1) {
        //   bgColor = backgroundColors[Math.floor(Math.random() * backgroundColors.length)]
        bgColor = backgroundColors[index % 4];
      }

      let styleData = {
        background: bgColor,
      };
      return styleData;
    },
  },
  computed: {},
};
</script>

<style scoped>
.surveyRow {
  min-height: calc(3em + 1.5rem);
  align-items: center;
  cursor: default;
}

.surveyTitle {
  font-size: 2.2rem;
  display: block;
  font-weight: bolder;
  min-height: 9vw;
}

.surveyBottomButton {
  display: inline-block;
  background: black;
  width: 60%;
  font-size: 1.3rem;
  margin: auto;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

.surveyContainer {
  color: white;
  position: relative;
  min-height: 10rem;
  height: 16vw;
  margin-bottom: 20px;
  border-radius: 40px;
  margin: 1vw 0.5vw;
  padding: 2rem 0.5rem 0 0.5rem;
}

.surveyItem {
}
</style>
