<template>
<div class="component">
    <div class="adminNavigationContainer">
        <div class="adminNavigation">
            <h1 class="adminNavTitle">Administration</h1>
            <div class="adminNavLink" @click="activeLink = 'groups'" :class="{ activeAdminLink: activeLink == 'groups' }">
                <router-link class="" :to="'/administration/groups'">Gruppen</router-link>
            </div>
            <div class="adminNavLink" @click="activeLink = 'surveys'" :class="{ activeAdminLink: activeLink == 'surveys' }">
                <router-link class="" :to="'/administration/surveys'">Umfragen</router-link>
            </div>
            <div class="adminNavLink" @click="activeLink = 'createuser'" :class="{ activeAdminLink: activeLink == 'createuser' }">
                <router-link class="" :to="'/administration/user'">Benutzer</router-link>
            </div>

            <div class="adminNavLink" @click="activeLink = 'news'" :class="{ activeAdminLink: activeLink == 'news' }">
                <router-link class="" :to="'/administration/announcements'">Aktuelles</router-link>
            </div>
         <div class="adminNavLink" @click="activeLink = 'categories'" :class="{ activeAdminLink: activeLink == 'categories' }">
                <router-link class="" :to="'/administration/categories'">Kategorien</router-link>
            </div>
            <div class="adminNavLink" @click="activeLink = 'services'" :class="{ activeAdminLink: activeLink == 'services' }">
                <router-link class="" :to="'/administration/services'">Services</router-link>
            </div>

            <div class="adminNavLink" @click="activeLink = 'ressources'" :class="{ activeAdminLink: activeLink == 'ressources' }">
                <router-link class="" :to="'/administration/createressources'">Resourcen und Geräte</router-link>
            </div>
            <div class="adminNavLink" @click="activeLink = 'rooms'" :class="{ activeAdminLink: activeLink == 'rooms' }">
                <router-link class="" :to="'/administration/rooms'">Räume</router-link>
            </div>
        </div>
    </div>

    <div class="adminContent">
        <router-view />
    </div>
</div>
</template>

<script>
export default {
    name: "AdminBoard",
    props: [],
    mounted: function () {},
    data() {
        return {
            msg: "Welcome to Your Vue.js App",
            data: [],
            activeLink: "groups",
        };
    },
    methods: {},
    computed: {},
};
</script>

<style scoped>
.adminNavigationContainer {
    display: inline-block;
    width: 20%;
    position: fixed;
    left: 0;
    top: 124px;
    bottom: 0;
    overflow-x: hidden;
}

/*
    min-height: 61vh;
    width: 100vw;
    padding: 0 5vw 20px 5vw;
    margin: auto;
    margin-top: 170px;
    padding-bottom: 20px;
*/
.adminNavigation {
    display: inline-block;
    width: 100%;
    height: 100%;

    background: #004684;
}

.adminNavTitle {
    font-size: 2em;
    font-weight: bolder;
    color: white;
    border-bottom: 1px solid white;
    margin-bottom: 10%;
}

.adminNavLink {
    text-decoration: none;
}

.adminNavLink a {
    text-decoration: none !important;
    color: white !important;
    font-size: 1.5em;
    border-bottom: 1px solid grey;
    width: 100%;
    display: block;
}

.adminContent {
    display: inline-block;
    width: 80%;
    margin-left: 20%;
}

.activeAdminLink a {
    color: green !important;
    font-size: 1.7rem;
    font-weight: bolder;
}
</style>
