<template>
<div class="component">
  <h1 class="title ">Ressourcen</h1>
    <div class="button" @click="newRessourceClick">Neue Ressource</div>

    <div class="ressourceList">
        <table class="table is-fullwidth ">
            <tr>
                <th>Name</th>
                <th class="has-text-centered ">Löschen</th>
            </tr>
            
            <tr v-for="ressource in ressources" >
                <td>{{ressource.title}}</td>
                <td class="has-text-centered " @click="deleteRessource(ressource.id)"><i class=" fas fa-trash-alt"></i></td>
            </tr>
        </table>
    </div>

    <div class="modal is-active" v-if="newRessourceName!=null">
        <div class="modal-background" @click="newRessourceName=null"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Eine neue Ressource erstellen</p>
                <button class="delete" aria-label="close" @click="newRessourceName=null"></button>
            </header>
            <section class="modal-card-body">
                <label class="label">Ressourcenname</label>
                <input :class="{'inputError':formErrors.includes('ressourcename')}" type="text" class="input" v-model="newRessourceName" />
            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="createRessource">Erstellen</button>
                <button class="button" @click="newRessourceName=null">Abbrechen</button>
            </footer>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'createRessource',
    props: [],
    mounted: function () {
        this.getRessources();
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            ressources: [],
            newRessourceName: null,
            formErrors: []
        }
    },
    methods: {
        getRessources() {

            this.ressources = [];

            this.callApi("/ressource/all").then(function (response) {
                this.ressources = response.data.ressources

            }.bind(this))

        },
        deleteRessource(id) {

            this.callApi("/ressource/" + id + "/delete", 'post').then(function (response) {
                this.getRessources();

            }.bind(this))

        },
        newRessourceClick() {
            this.newRessourceName = "";
        },
        createRessource() {
            this.validateInput();
            if (this.formErrors.length > 0) {
                return
            }
     
            let postData = {
                "ressource": {
                    "title": this.newRessourceName
                }
            }
   

            
            this.callApi("/ressource/create", 'post',postData).then(function (response) {
                this.newRessourceName = null;
                this.getRessources();

            }.bind(this))


        },
        validateInput() {
            this.formErrors = [];
            if (this.newRessourceName == "") {
                this.formErrors.push("ressourcename")
            }
        }

    },
    computed: {},
}
</script>

<style scoped>
.inputError {
    border: 1px solid red;
}

.fa-trash-alt {
    cursor: pointer;
    color: red;
}
</style>
