<template>
  <div>
Test Test Test
  </div>
</template>

<script>
export default {
  name: "detail4",
};
</script>

<style scoped>

</style>
