<template>
<div class="component">

    <div class="mainpageAreal">
        <div class="mainpageContainer">
            <div class="mainpageContainerIcon">
                <div class="iconBackground">
                    icon
                </div>
            </div>
            <div class="mainpageContainerHeader"><span>Ankündigungen</span></div>
            <div class="mainpageContainerContent">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. </div>
            <div class="mainpageContainerContent">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. </div>
            <div class="mainpageContainerButtonContainer">
                <div class="mainpageContainerButton">Mehr</div>
            </div>
        </div>

    </div>

    <div class="mainpageAreal">

        <div class="consumptionGraph">
            <apexchart width="100%" type="donut" :options="consumptionOptions" :series="getConsumptionPercentage()"></apexchart>
        </div>

        <div class="mainpageContainer">
            <div class="mainpageContainerIcon">
                <div class="iconBackground">
                    icon
                </div>
            </div>
            <div class="mainpageContainerHeader"><span>Meine Geräte</span></div>
            <div class="mainpageContainerContent minHeightContent">Gerät 1 - Teschke, Timo</div>
            <div class="mainpageContainerButtonContainer">
                <div class="mainpageContainerButton">Mehr</div>
            </div>
        </div>

    </div>




        <div class="mainpageAreal">

        <div class="consumptionGraph">
            <apexchart width="100%" type="donut" :options="consumptionOptions" :series="getConsumptionPercentage()"></apexchart>
        </div>

        <div class="mainpageContainer">
            <div class="mainpageContainerIcon">
                <div class="iconBackground">
                    icon
                </div>
            </div>
            <div class="mainpageContainerHeader"><span>Was ist wo?</span></div>
            <div class="mainpageContainerContent minHeightContent">Karte des Quartiers</div>
            <div class="mainpageContainerButtonContainer">
                <div class="mainpageContainerButton">Mehr</div>
            </div>
        </div>

    </div>

    <div class="mainpageAreal">
        <div class="mainpageContainer">
            <div class="mainpageContainerIcon">
                <div class="iconBackground">
                    icon
                </div>
            </div>
            <div class="mainpageContainerHeader"><span>Neuste Gruppennachrichten</span></div>
            <div class="mainpageContainerContent">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</div>
            <div class="mainpageContainerContent">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</div>
            <div class="mainpageContainerContent">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</div>
            <div class="mainpageContainerContent">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</div>
            <div class="mainpageContainerButtonContainer">
                <div class="mainpageContainerButton">Mehr</div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'MainPage',
    props: [],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],

        }
    },
    methods: {
        getConsumptionPercentage() {
            return [20, 80];
        }
    },
    computed: {
        consumptionOptions() {
            let currentConsumption = this.getConsumptionPercentage()[0]
            let options = {
                chart: {
                    id: 'vuechart-example'
                },

                labels: ['Bisher', "Gesamt"],
                colors: ['#FFC476', '#E9A12D'],
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    show: false
                },
                plotOptions: {
                    pie: {
                        donut: {

                            labels: {
                                show: true,
                                name: {
                                    show: false
                                },
                                value: {
                                    show: true,
                                    color: "#E9A12D",
                                    fontSize: '2em',
                                    fontWeight: 900,

                                },

                                total: {
                                    show: true,
                                    color: "blue",
                                    showAlways: true,
                                    fontWeight: 900,

                                    formatter: function (val) {
                                        return currentConsumption + " %"
                                    }
                                },

                            }
                        }
                    }
                },

            }
            return options;

        }

    },
}
</script>

<style scoped>
.consumptionGraph{
    padding: 0 10%    ;
}
.mainpageAreal {
    width: 24%;
    margin: 0.5%;
    display: inline-block;

}

.mainpageContainer {

    background: #1D65B3;
    border-radius: 25px;
    padding-bottom: 1%;
}

.mainpageContainerHeader span {
    color: white;
    font-size:1.5em;
    font-weight: normal;
    border-bottom: 1px solid white;
    padding: 0 30px;

}
.minHeightContent{
    min-height:20vh;
}
.iconBackground {
    width: 5em;
    ;
    height: 5em;
    display: inline-block;
    margin: 1em auto;
    border-radius: 100px;
    background: #4590CE;

}

.mainpageContainerContent {
    background: #4590CE;
    margin: 4% 2%;
    border-radius: 15px;
    color: white;
}

.mainpageContainerButtonContainer {
    display: inline-block;
    margin: 5% 0;
    width: 100%;
}

.mainpageContainerButton {
    width: 33%;
    display: inline-block;
    background: #E9A12D;
    color: white;
    border-radius: 8px;
}
</style>
