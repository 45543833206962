<template>
<div class="component">
    <div class="modal is-active">
        <div class="modal-background" @click="closeModal()"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{question.question}}</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body">
                <label class="label">Antworttext</label>
                <input type="text" class="input" v-model="answer" />
                <br />
                <label class="label">Antwortbeschreibung</label>
                <textarea class="textarea" v-model="answerDescription"></textarea>

            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="sendAnswer">Senden</button>
                <button class="button" @click="closeModal()">Abbrechen</button>
            </footer>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'addPossibleAnswer',
    props: ["question"],
    mounted: function () {},
    data() {
        return {
            data: [],
            answer: "",
            answerDescription: "",
        }
    },
    methods: {
        closeModal() {
            this.$emit("close")
        },

        sendAnswer() {
            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }

            let postData = {
                survey_question_id: this.question.id,
                answerText: this.answer,
                answerDescription: this.answerDescription
            }

            this.$axios.post(this.$backendURL + "/survey/question/addanswer", postData, {
                headers: headerData
            }).then(function (result) {
                this.$emit("onAnswerAdd");
                this.closeModal();
            }.bind(this))
        }
    },
    computed: {},
}
</script>

<style scoped>

</style>
