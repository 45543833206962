<template>
<div class="header">
    <div class="enaqHeader cpPrimaryBackground" v-if="!isMobile">
        <div class="enaqHeaderLogo" @click="onLogoClick()">
            <!--   <img class="enaq_logo" src="../assets/enaq_logo.png">-->
            <div class="headertitle">Helleheide</div>
        </div>
        <div class="enaqHeaderMiddle cpTextMedium" @click="onLinkClick">
            <div class="enaqHeaderLinks">
                <div class="headerNavigationLink" @click="selectSite('dashboard')">
                    <span @click="mobileExpanded = false" :class="{ activeDropdown: activeDropdownItem == 'dashboard' }" class="" ><i class="fas fa-home"></i>
                        {{ $t("enaq_translation_header_dashboard") }}</span>
                </div>

                <div class="headerNavigationLink" @click="selectSite('devices')">
                    <span @click="mobileExpanded = false" :class="{ activeDropdown: activeDropdownItem == 'devices' }" class="" ><i class="fas fa-tablet-alt"></i>{{ $t("enaq_translation_header_devices") }}</span>
                </div>
                <div class="headerNavigationLink" @click="selectSite('market')">
                    <span @click="mobileExpanded = false" :class="{ activeDropdown: activeDropdownItem == 'meetingpoint' }" class="" ><i class="fas fa-street-view"></i>{{ $t("enaq_translation_header_meetingpoint") }}</span>
                </div>
                <div class="headerNavigationLink" @click="selectSite('PluginOverview')">
                    <span @click="mobileExpanded = false" :class="{ activeDropdown: activeDropdownItem == 'plugins' }" class="" ><i class="fas fa-box-open"></i>
                        {{ $t("enaq_translation_header_services") }}</span>
                </div>
            </div>
        </div>
        <div class="enaqHeaderEnd">
            <div v-if="userName == ''" class="enaqHeaderButtons">
                <div class="buttons">
                    <router-link v-if="useLocalAuthorisation" class="button is-primary" :to="'/login'">{{ $t("enaq_translation_header_login") }}</router-link>
                    <router-link v-else class="button is-primary" :to="'/authcallback'">{{ $t("enaq_translation_header_login") }}</router-link>
                    <router-link class="button is-light" :to="'/register'">{{
              $t("enaq_translation_register_register")
            }}</router-link>
                </div>
            </div>

            <div v-else class="headerUser" @click="profileDropdownOpen = !profileDropdownOpen">
                <div class="headerUserText">
                    <div v-if="newMessages > 0" class="newMessageAlert" @click.stop="openMessage">
                        {{ newMessages }}
                    </div>
                    <div class="thumbnailContainer" v-if="avatar">
                        <img class="profileThumbnail" :src="this.$storageURL + this.avatar" />
                    </div>
                    <div class="helloMessage">
                        <span>{{ $t("enaq_translation_header_hello") }},
                            {{ userName }}!</span>
                    </div>
                </div>
            </div>

            <transition name="enaqMobileTransition">
                <div class="headerUserDropdownContainer" v-if="profileDropdownOpen" @click="profileDropdownOpen = false">
                    <div class="headerUserDropdown">
                        <div class="enaqHeaderDropdownItem">
                            <router-link class="" :to="'/user/' + $store.getters.getUser.id">{{ $t("enaq_translation_header_profile") }}</router-link>
                        </div>
                        <div class="enaqHeaderDropdownItem">
                            <router-link class="" :to="'/message/inbox'">{{
                  $t("enaq_translation_header_inbox")
                }}</router-link>
                        </div>
                        <div class="enaqHeaderDropdownItem">
                            <a href="#" class="" @click="logout()">
                                {{ $t("enaq_translation_header_logout") }}
                            </a>
                        </div>
                        <div v-if="isAdmin" class="enaqHeaderDropdownItem">
                            <router-link class="" :to="'/administration'">{{
                  $t("enaq_translation_header_admin")
                }}</router-link>
                        </div>

                        <div class="enaqHeaderDropdownItem countryFlags">
                            <span v-for="country in this.$supportedLanguages" :key="country" @click="changeLocalisation(country)">
                                <country-flag :country="country" size="normal" /></span>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>

    <!--Mobile menu-->

    <div class="enaqHeaderMobile cpPrimaryBackground" v-else>
        <div class="enaqHeaderMobileLogo">
            <img class="enaq_logo" src="../../assets/enaq_logo.png" />
        </div>

        <div class="enaqHeaderMobileBurgerMenu" @click="openMobileDropdown = !openMobileDropdown">
            <i class="fas fa-bars"></i>
        </div>
        <transition name="enaqMobileTransition">
            <div class="enaqMoobileDropdown" v-if="openMobileDropdown" @click="onLinkClick">
                <div class="enaqHeaderDropdownItem countryFlags">
                    <span v-for="country in this.$supportedLanguages" :key="country" @click="changeLocalisation(country)">
                        <country-flag :country="country" size="large" /></span>
                </div>
                <div class="headerMobileDropdownLink">
                    <router-link @click="mobileExpanded = false" class="" :to="'/dashboard'">Dashboard</router-link>
                </div>

                <div class="headerMobileDropdownLink">
                    <router-link @click="mobileExpanded = false" class="" :to="'/devices'"><i class="fas fa-tablet-alt"></i>Geräte</router-link>
                </div>
                <div class="headerMobileDropdownLink">
                    <router-link @click="mobileExpanded = false" class="" :to="'/quartier'"><i class="fas fa-home"></i>Quartier</router-link>
                </div>

                <div class="headerMobileDropdownLink">
                    <router-link class="" :to="'/user/' + $store.getters.getUser.id">Profil</router-link>
                </div>

                <div class="headerMobileDropdownLink">
                    <router-link class="" :to="'/message/inbox'">Inbox</router-link>
                </div>

                <div class="headerMobileDropdownLink">
                    <a href="#" class="" @click="logout()"> Logout </a>
                </div>
            </div>
        </transition>
    </div>
</div>
</template>

<script>
export default {
    name: "enaqHeader",
    props: [],
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        console.log("env: " + process.env.VUE_APP_useLocalAuth);
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted: function () {
        this.HTTPRequest()
            .getDeviceList()
            .then(function () {
                console.log("testtest");
            });

        this.$root.test = "roottest";

        this.$root.$on("pageclick", (data) => {
            console.log("pageclick event in header")
            if (this.profileDropdownOpen) {
                this.profileDropdownOpen = false;
            }
        });

        this.$root.$on("navigationChange", (data) => {
            console.log("navigationChange event in header: " + data)
            var siteName = data.replace('/', '');


            if (siteName == this.activeDropdownItem) {
              console.log("newsitename: "+newsitename+" - active: "+this.activeDropdownItem)
               window.location.reload();
            } else {
              this.activeDropdownItem = siteName;

            }
        });

        this.checkLocalisation();
    },
    data() {
        return {
            msg: "Welcome to Your Vue.js App",
            data: [],
            profileDropdownOpen: false,

            mobileExpanded: false,

            useLocalAuthorisation: process.env.VUE_APP_useLocalAuth == "true" ? true : false,
            window: {
                width: 0,
                height: 0,
            },
            openMobileDropdown: false,
            activeDropdownItem: "dashboard",
        };
    },
    methods: {
        selectSite(newsitename) {
            if (newsitename == this.activeDropdownItem) {
              console.log("newsitename: "+newsitename+" - active: "+this.activeDropdownItem)
          //      window.location.reload();
            } else {
           //     this.activeDropdownItem = newsitename;

                this.$router.push({
                    name: newsitename,
                });
            }
        },

        logout() {
            this.$store.commit("setUsername", "");
            this.$store.commit("setUser", "");

            localStorage.bearertoken = "";
            localStorage.clear();

            this.$router.push("/authcallback");
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        onLinkClick() {
            this.openMobileDropdown = false;
            this.profileDropdownOpen = false;
        },
        openMessage() {
            this.openMobileDropdown = false;
            this.profileDropdownOpen = false;
            this.$router.push({
                name: "inbox",
                params: {},
            });
        },
        onLogoClick() {
            this.openMobileDropdown = false;
            this.profileDropdownOpen = false;
            this.$router.push({
                name: "dashboard",
                params: {},
            });
        },
        checkLocalisation() {
            if (localStorage.language == "" || localStorage.language == undefined) {
                localStorage.language = "de";
            }
            this.$i18n.locale = localStorage.language;
            this.$root.$emit("onChangeLanguage", this.$i18n.locale);
        },
        changeLocalisation(countryCode) {
            localStorage.language = countryCode;
            this.$i18n.locale = countryCode;
            this.$root.$emit("onChangeLanguage", countryCode);
        },
    },
    computed: {
        userName() {
            let userName = this.$store.getters.getUsername;
            return userName;
        },

        avatar() {
            if (this.$store.getters.getUser.avatar != undefined) {
                return this.$store.getters.getUser.avatar;
            } else return false;
        },

        isAdmin() {
            return this.$store.getters.getUser.role >= 2;
        },

        isMobile() {
            if (this.window.width > 1400) {
                return false;
            } else {
                return true;
            }
        },
        newMessages() {
            return this.$store.getters.getNewMessageCount;
        },
    },
};
</script>

<style scoped>
.header {
    overflow: hidden;
}

.enaqMobileTransition-enter-active,
.enaqMobileTransition-leave-active {
    transition: all 0.4s;

    overflow: hidden;
    max-height: 100vh;
}

.enaqMobileTransition-enter,
.enaqMobileTransition-leave-to {
    opacity: 0;
    max-height: 0px;
}

.enaqMobileTransition-enter-to {}

.enaqHeader,
.enaqHeaderMobile {
    width: 100%;
    height: 124px;
    max-height: 124px;
    /* overflow: hidden;*/
}

.enaqMoobileDropdown {
    background: #004684;
    background: #ffc400;
    position: absolute;
    top: 110px;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(100vh - 96px);
    overflow: scroll;
}

.headerMobileDropdownLink {
    border-top: 1px solid #0046a4;
    padding: 10px;
    padding-left: 0%;
    text-align: left;
}

#nav .enaqMoobileDropdown .headerMobileDropdownLink a {
    color: white;
    font-size: 2em;
    padding-left: 40%;
    display: block;
}

.enaqHeaderMobile {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
}

.enaqHeaderLogo {
    display: inline-block;
    width: 20%;
    height: 124px;
    overflow: hidden;

    /*
    background: #004684;
    background-image: linear-gradient(to right, #004684, #004684, #004684, #004684, #004684, #004684, #004684, #004684, #FFC400);
*/
}

.enaqHeaderMobileLogo {
    width: 85%;
    height: 124px;
    text-align: left;
    display: inline-block;
    overflow: hidden;
}

.enaqHeaderMobileBurgerMenu {
    font-size: 3.5rem;
    color: white;
}

.enaqHeaderMobileLogo img {
    width: 250px;
    height: 90px;
    margin: 17px 0 17px 15px;
}

.enaqHeaderLogo img {
    width: 250px;
    height: 90px;
    margin: 17px 0 17px 15px;
    cursor: pointer;
}

.enaqHeaderMiddle {
    display: inline-block;
    width: 60%;
    max-height: 124px;
    overflow: hidden;
    vertical-align: middle;
}

.enaqHeaderLinks,
.enaqHeaderButtons {
    display: flex;
    align-items: baseline;
    height: 124px;
    overflow: hidden;
}

.headertitle {
    line-height: 124px;
    color: white;
    white-space: nowrap;
    font-size: 48px;
    cursor: pointer;
    font-weight: 300;
    padding: 0 50px 0 0;
}

.activeDropdown {
    border-bottom: 2px solid #f9c300;
    padding-bottom: 3px;
}

.enaqHeaderEnd {
    display: inline-block;
    width: 20%;
    max-height: 124px;
    vertical-align: middle;
}

.headerNavigationLink {
    display: inline-block;
    width: 16.6%;
    color: white;
    white-space: nowrap;
}

#nav .headerNavigationLink span:hover {
    color: #ffc403;cursor: pointer;
}
headerNavigationLink
#nav .headerNavigationLink a {
    color: white;
    line-height: 1.4em;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    transition: 0.3s;
}

.headerMobileDropdownLink i {
    margin-right: 20px;
}

.headerNavigationLink i {
    margin-right: 5px;
}

.headerUser {
    height: 124px;
    position: relative;
    display: inline-block;
    width: 60%;
}

.headerFlags {
    height: 124px;
    position: relative;
    display: inline-block;
    width: 40%;
}

.headerUserText {
    display: flex;
    cursor: pointer;
}

.profileThumbnail {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    vertical-align: text-bottom;
}

.countryFlags {
    z-index: 999999;
}

.thumbnailContainer {
    vertical-align: text-bottom;
    margin-right: 0px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: 45%;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 70, 132, 1);
    -moz-box-shadow: 0px 0px 4px 0px rgb(7, 7, 7);
    box-shadow: 0px 0px 4px 0px rgb(12, 12, 12);
    color: white;
}

.newMessageAlert {
    position: absolute;
    font-size: 1em;
    z-index: 2;
    top: -10px;
    left: -10px;
    padding: 1px calc(1px + 0.5em);
    background: white;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.8);
    color: rgba(255, 0, 0, 1);
    font-weight: bolder;
    cursor: pointer;
}

#nav .enaqHeaderEnd .helloMessage {
    color: white;
    display: inline-block;
    width: 85%;
    text-align: left;
    margin-left: 5%;
    font-size: 1.5em;
    font-weight: normal;
    font-family: "Montserrat", sans-serif;
}

.headerUserDropdown {
    position: absolute;
    width: 100%;
    overflow: visible;
    padding: 10px 0;
    background: #004684;
    top: 124px;
    width: 12vw;
    border-radius: 0 0 15px 15px;
}

.headerUserDropdownContainer {
    background: #004684;
    overflow: visible;
}

.enaqHeaderDropdownItem {
    padding: 10px 0;
}

#nav .headerUserDropdown .enaqHeaderDropdownItem a {
    color: white;
    margin: 10px 0;
    font-size: 1.5em;
}

.enaqHeaderDropdownItem {
    display: block;
}

.headerUserDropdown {
    width: 18%;
    right: 4vw;
}

@media screen and (max-width: 1700px) {
    .headerUserDropdown {
        width: 20%;
        right: 2vw;
    }
}

@media screen and (max-width: 600px) {
    #nav .enaqMoobileDropdown .headerMobileDropdownLink a {
        color: white;
        font-size: 2em;
        padding-left: 31%;
        display: block;
    }
}

@media screen and (max-width: 400px) {
    #nav .enaqMoobileDropdown .headerMobileDropdownLink a {
        color: white;
        font-size: 2em;
        padding: 0;
        display: block;
        text-align: center;
    }
}

/*

@media screen and (min-width: 1024px) {}

*/
</style>
