<template>
    <div class="component">


    </div>
</template>

<script>
    export default {
        name: 'component',
        props: [],
        mounted: function () {
        },
        data() {
            return {
                msg: 'Welcome to Your Vue.js App',
                data: []
            }
        },
        methods: {},
        computed: {},
    }
</script>

<style scoped>

</style>
