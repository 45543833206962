<template>
  <div class="component">
    <div class="surveyHeader columns is-mobile is-multiline">
      <div
        v-if="choosenSurvey < 0"
        class="column"
        :class="{ 'is-8': choosenSurvey >= 1, 'is-12': choosenSurvey <= -1 }"
      >
        <h1 class="title surveyTitle">
         {{ $t("enaq_translation_surveys_title") }}
        </h1>
      </div>
    </div>

    <div class="surveyList" v-if="choosenSurvey <= 0">
      <surveyList @choose="openSurvey"></surveyList>
    </div>
    <div v-else>
      <showSurvey
        :surveyId="choosenSurvey"
        @close="choosenSurvey = -1"
      ></showSurvey>
    </div>
  </div>
</template>

<script>
import surveyList from "@/components/Meetingpoint/Surveys/surveyList.vue";
import showSurvey from "@/components/Meetingpoint/Surveys/showSurvey";
export default {
  name: "Umfragen",
  props: [],
  components: {
    surveyList,
    showSurvey,
  },
  mounted: function () {},
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      choosenSurvey: -1,
    };
  },
  methods: {
    openSurvey(id) {
      this.choosenSurvey = id;
    },
    closeSurvey() {
      this.choosenSurvey = -1;
    },
  },
  computed: {},
};
</script>

<style scoped>
.surveyHeader {
  margin-top: 20px;
}

.surveyTitle {
  color: #004684;
  font-weight: 900;
  font-size: 3rem;
}
</style>
