<template>
  <div class="component">
    <div class="highscoreData">
      <div class="highscoreTitle">Highscore</div>
      <table class="table is-fullwidth highscoreList">
        <div
          v-for="(userData, place) in highscoreData"
          :key="userData.id"
          :class="{
            ownHighscoreListRow: userData.id == $store.getters.getUser.id,
          }"
          class="columns is-mobile is-multiline highscoreListRow is-gapless"
        >
          <div class="column is-3-desktop is-12-tablet is-12-mobile">
            <div class="highscoreListPlace has-text-left" v-if="place == 0">
              <span class="firstPlace">1.<i class="fas fa-trophy"></i></span>
            </div>

            <div
              class="highscoreListPlace has-text-left"
              v-else-if="place == 1"
            >
              <span class="secondPlace">2.<i class="fas fa-trophy"></i></span>
            </div>

            <div
              class="highscoreListPlace has-text-left"
              v-else-if="place == 2"
            >
              <span class="thirdPlace">3.<i class="fas fa-trophy"></i></span>
            </div>

            <div v-else class="highscoreListPlace has-text-left">
              {{ 1 + place }}.
            </div>
          </div>
          <div class="column is-3-desktop is-12-tablet is-12-mobile">
            <div class="highscoreListProfileImage column is-2">
              <img
                class="profileThumbnail"
                :src="$storageURL + userData.avatar"
              />
            </div>
          </div>
          <div class="column is-3-desktop is-12-tablet is-12-mobile">
            <div class="highscoreListUsername">
              {{ userData.nickname }}
            </div>
          </div>
          <div class="column is-3-desktop is-12-tablet is-12-mobile">
            <div class="highscoreListPoints">
              {{ userData.score }} <i class="fas fa-leaf"></i>
            </div>
          </div>
        </div>
      </table>

      <div class="highscoreList">
        <div v-if="highscoreData == []">Keine Daten</div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "highscore",
  props: [],
  mixins: [],
  mounted: function () {
    this.getHighscoreList();
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      highscoreData: [],
    };
  },
  methods: {
    testMixin() {
      this.callApi("/user/highscore").then(
        function (result) {
          this.highscoreData = result.data.user;
        }.bind(this)
      );
    },
    getHighscoreList() {
      this.HTTPRequest()
        .getAllHighscoreData()
        .then(
          function (result) {
            this.highscoreData = result.data.user;
          }.bind(this)
        );
    },
    getHighscoreRank(place) {
      place += 1;

      if (place == 1) {
        return '<span class="firstPlace">1 <i class="fas fa-trophy"></i></span>';
      } else {
        return "<span>" + place + "</span>";
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.highscoreTitle {
  font-size: 2.4rem;
  font-weight: bolder;
  color: #e9a12d;
  margin: 15px;
  text-shadow: 0 0 2px black;
}

.firstPlace i {
  color: gold;
  margin-left: 15px;
  text-shadow: 0 0 2px black;
}

.secondPlace i {
  color: #c86c18;
  margin-left: 15px;
  text-shadow: 0 0 2px black;
}
.thirdPlace i {
  color: silver;
  margin-left: 15px;
  text-shadow: 0 0 2px black;
}

.profileThumbnail {
  max-height: 5em;
  border-radius: 20px;
  margin-top: 0.5em;
}

.highscoreListPlace,
.highscoreListUsername,
.highscoreListPoints {
  line-height: 4em;
  font-weight: bolder;
}
.component .highscoreList .highscoreListPlace {
  padding-left: 50px !important;
}

.highscoreListRow {
  background-color: #1c63af;
  border-radius: 50px;
  box-shadow: -10px 10px 15px #777777;
  color: #e9a12d;
  font-size: 1.2rem;
}

.ownHighscoreListRow {
  background-color: #e9a12d;
  color: #1c63af;
}
</style>