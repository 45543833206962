<template>
<div class="component" v-if="consumptionData!=undefined">

    <div :class="{ 'bigCircle':isActive, 'smallCircle':!isActive}" class="Circle" :style="getCircleStyle()">
        <div class="circleTopIcon"> <img alt="Icon" :src="require(`@/assets/${consumptionData.Icon}`)">
        </div>
        <div class="innerCircle">

            <div class="circleHeader">{{consumptionData.Title}}</div>

            <div class="circleRating">
                <div v-if="isActive" class="circleSpeedo"><img alt="Icon" src="@/assets/icons/Speedo.png"></div>
                <div v-if="isActive" class="ratingScala" :style="getScalaStyle()"></div>

                <div class="circleSmiley">
                    <img alt="Icon" :src="require(`@/assets/icons/smiley_${getSmileyNumber()}.png`)"> </div>
            </div>

            <div class="columns is-mobile is-multiline">
                <div class="column is-6 circleLeft">
                    <div class="circleItem">
                        <div class="circleDescription" :style="getHeaderStyle()">{{$t('enaq_translation_devices_kwhconsumption')}}</div>
                        <div class="circleValue">{{consumptionData.Consumption}} kW/h</div>
                    </div>

                    <div class="circleItem" v-if="isActive">
                        <div class="circleDescription" :style="getHeaderStyle()">{{$t('enaq_translation_devices_costs')}}</div>
                        <div class="circleValue">{{consumptionData.Costs}} €</div>
                    </div>

                    <div class="circleItem" v-if="isActive">
                        <div class="circleDescription" :style="getHeaderStyle()">{{$t('enaq_translation_devices_costs_avg')}}</div>
                        <div class="circleValue">{{consumptionData.CostAverage}} €</div>
                    </div>
                </div>

                <div class="column is-6 circleRight">
                    <div class="circleItem" v-if="isActive">
                        <div class="circleDescription" :style="getHeaderStyle()">{{$t('enaq_translation_devices_costs_saving')}}</div>
                        <div class="circleValue">{{consumptionData.CostSaving}} €</div>
                    </div>

                    <div class="circleItem" v-if="isActive">
                        <div class="circleDescription" :style="getHeaderStyle()">{{$t('enaq_translation_devices_kwhconsumption_avg')}}</div>
                        <div class="circleValue">{{consumptionData.ConsumptionAverage}} kW/h</div>
                    </div>

                    <div class="circleItem" v-if="isActive">
                        <div class="circleDescription" :style="getHeaderStyle()">{{$t('enaq_translation_devices_co2emission')}}</div>
                        <div class="circleValue">{{consumptionData.CO2}} KG</div>
                    </div>

                    <div class="circleItem" v-if="!isActive">
                        <div class="circleDescription" :style="getHeaderStyle()">{{$t('enaq_translation_devices_costs')}}</div>
                        <div class="circleValue">{{consumptionData.Costs}} €</div>
                    </div>
                </div>
            </div>
            <div :style="getHeaderStyle()" v-if="!isActive" @click="$emit('moredetailsClicked')" class="moreDetails">{{$t('enaq_translation_devices_moredetails')}}</div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: 'consumptionCarouselItem',
    props: ["isActive", "consumptionData"],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: []
        }
    },
    methods: {
        getCircleStyle() {
            return {
                background: "linear-gradient(" + this.consumptionData.CircleColorStart + "," + this.consumptionData.CircleColorEnd + ")"
            }
        },
        getScalaStyle() {
            return {
                transform: "rotate(" + (-90 + (this.consumptionData.RatingPercentage * 1.8)) + "deg)"
            }
        },
        getHeaderStyle() {
            return {
                "color": this.consumptionData.HeaderColor,
                "font-weight": "bolder"
            }
        },
        getSmileyNumber() {
            let smileyNumber = 0;
            switch (true) {
                case (this.consumptionData.RatingPercentage < 18): {
                    smileyNumber = 0;
                    break;
                }
                case (this.consumptionData.RatingPercentage < 39): {
                    smileyNumber = 1;
                    break;
                }
                case (this.consumptionData.RatingPercentage < 59): {
                    smileyNumber = 2;
                    break;
                }
                case (this.consumptionData.RatingPercentage < 79): {
                    smileyNumber = 3;
                    break;
                }
                case (this.consumptionData.RatingPercentage >= 79): {
                    smileyNumber = 4;
                    break;
                }
            }

            return smileyNumber;
        }
    },
    computed: {},
}
</script>

<style scoped>
.Circle {
    display: inline-block;
    width: 33vw;
    height: 33vw;
    border-radius: 100%;
    position: relative;
    margin-top: 2vh;

    -webkit-box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.75);

}

.smallCircle {
  /*
    margin-top: -50vw;
    transform: translateY(100px);
    margin: 0 0vw;

*/

}

.Circle .circleHeader {
    color: white;
    font-size: 1.6em;
    font-weight: bolder;
}

.smallCircle .circleHeader {
    color: white;
    font-size: 2.2em;
    font-weight: bolder;
        margin-top:1vw;

}

.circleDescription {
    color: black;
    font-weight: bolder;
}

.circleValue {
    color: white;
    font-weight: bolder;
}

.Circle .innerCircle {
    display: inline-block;
    width: 31vw;
    height: 31vw;
    margin-top: 1vw;
    border-radius: 100%;
    border: 5px solid white;
    padding: 2.5vw 2vw;
}


.Circle .circleTopIcon {
    position: absolute;
    width: 6vw;
    height: 6vw;
    left: 50%;
    transform: translateX(-50%);
    top: -4vh;
    background: white;
    border-radius: 100px;
}


.bigCircle .circleLeft,
.bigCircle .circleRight {
    margin-top: 3vw;
}

.smallCircle .circleLeft,
.smallCircle .circleRight {
    margin-top: calc(4.8vw * 2);
}

.circleLeft {
    border-right: 1px dashed white;
    text-align: right;
}

.circleRight {
    text-align: left;
}

.Circle .circleItem {
    height: 4vw;
}

.smallCircle .circleItem {
    height: 6vw;
    font-size: 1.8em;
}

.Circle .circleRating {
    position: relative;
}

.Circle .circleSpeedo img {
    max-width: 14vw;
}

.Circle .circleSmiley {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);

}

.Circle .ratingScala {
    border-right: 4px solid black;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;

    height: 5vw;
    width: 2px;
    position: absolute;
    top: 1vw;

    left: calc(50% - 2px);
    transform-origin: 100% 100%;

}

.Circle .circleSmiley img {
    max-width: 7vw;
    z-index: 10;

}

.smallCircle .circleSmiley img {
    max-width: 10vw;
    z-index: 10;


}

.moreDetails {
    cursor: pointer;
    text-decoration: underline;
    font-size: 2em;
}

.component {
    display: inline-block
}
</style>
