<template>
<div class="component">

    <apexchart v-if="graphData!=undefined" width="90%" :height="'90%'" type="line" :options="graphOptions" :series="graphData"></apexchart>
</div>
</template>

<script>
export default {
    name: 'consumptionProductionGraph',
    props: ["graphData", "graphLabels"],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            chartData: []
        }
    },
    methods: {

    },
    computed: {

        graphOptions() {

            let graphOptions = {
                stroke: {
                    curve: 'smooth',
                },
                chart: {

                    id: this.title || "Graph " + Math.floor(Math.random() * 1000),
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                xaxis: {
                    categories: this.graphData[0].dates,

                },
                yaxis: {
                    type: "numeric",
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(2)
                        }
                    },
                    title: {
                        text: "Euro",
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            cssClass: 'apexcharts-xaxis-title',
                        },
                    },
                }

            };

            return graphOptions;
        },

    },
    watch: {
        graphData: function (newData, oldData) {
            this.chartData = this.graphData
        }
    }
}
</script>

<style scoped>
.component {
    height: 100%;
}
</style>
