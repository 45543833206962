<template>
  <div class="component">
    <br />
    <div class="column is-12">
      <table class="table is-fullwidth">
        <div class="columns is-mobile is-multiline">
          <div class="column is-4-desktop is-12-tablet is-12-mobile">
            <div class="">
              <!-- Platzhalter -->
            </div>
          </div>
          <div class="column is-4-desktop is-12-tablet is-12-mobile">
            <div>
              <input
                class="searchBar"
                type="text"
                v-model="search"
                placeholder="Nachbarschaftshilfe suchen..."
              />
            </div>
          </div>
          <div class="column is-4-desktop is-12-tablet is-12-mobile">
            <div class="has-text-right">
              <enaqbutton
                :width="'180px'"
                :fontsize="'20px'"
                :padding="'10px'"
                @click="openNewNBHModal = true"
              >
                + Neu
              </enaqbutton>
            </div>
          </div>
        </div>
      </table>
    </div>

    <div
      v-for="nbh in filteredNBH"
      class="nbhItem columns is-mobile is-multiline"
      :key="nbh.id"
    >
      <div class="column is-12 nbhTitle">
        <table class="table ressourceList ressourceItem is-fullwidth">
          <div class="columns is-mobile is-multiline">
            <div class="column is-10-desktop is-12-tablet is-12-mobile">
              <div class="nbhTitle">
                {{ nbh.title }}
              </div>
            </div>
            <div class="column is-2-desktop is-12-tablet is-12-mobile">
              <div class="nbhReward">
                <p>{{ nbh.reward }} <i class="fas fa-leaf"></i></p>
              </div>
            </div>
          </div>
        </table>
      </div>

      <div v-if="selectedNbh != nbh.id" class="column is-12 nbhDescPreview">
        {{ nbh.description.slice(0, 200) }}<span v-if="nbh.description.length>200">...</span>
      </div>

      <transition name="nbhListTransition">
        <div
          class="columns is-mobile is-multiline"
          v-if="selectedNbh == nbh.id"
        >
          <div class="nbhDesc column is-12">{{ nbh.description }}</div>

          <div
            class="column is-12"
            v-if="nbh.author.id == $store.getters.getUser.id"
          >
            <enaqbutton @click="nbhToResolve = nbh"> Erledigen </enaqbutton>
          </div>

          <div class="column is-12" v-else>
            <router-link
              class=""
              :to="{
                name: 'messageCreateWithTitle',
                params: {
                  messagetitle: 'Nachbarschaftshilfe: ' + nbh.title,
                  messagereciever: nbh.author.id,
                },
              }"
            >
              <enaqbutton :width="'300px'" class="contactButton">
                {{ nbh.author.firstname }} {{ nbh.author.name }} (Kontakt)
              </enaqbutton>
            </router-link>
          </div>
        </div>
        <!--Pfeil nach unten -->
        <div v-if="selectedNbh != nbh.id" class="column is-12">
          <i
            class="fas fa-chevron-down openNbhArrow"
            @click="selectNbh(nbh.id)"
          ></i>
        </div>
      </transition>
    </div>

    <div
      v-if="nbhToResolve != null"
      class="modal"
      :class="{ 'is-active': nbhToResolve != null }"
    >
      <div class="modal-background" @click="nbhToResolve = null"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Wähle den entsprechenden Benutzer</p>
          <button
            class="delete"
            aria-label="close"
            @click="nbhToResolve = null"
          ></button>
        </header>
        <section class="modal-card-body">
          <h1 class="title">{{ nbhToResolve.title }}</h1>
          <userlist @selected="selectUserForResolve($event, nbhToResolve.id)" />
        </section>
        <footer class="modal-card-foot">
          <enaqbutton :backgroundcolor="'#808080'" @click="nbhToResolve = null">
            {{ $t("enaq_translation_cancel") }}
          </enaqbutton>
        </footer>
      </div>
    </div>

    <div
      v-if="openNewNBHModal != false"
      class="modal"
      :class="{ 'is-active': openNewNBHModal != false }"
    >
      <div class="modal-background" @click="closeNewNBHModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t("enaq_translation_new") }}</p>
          <button
            class="delete"
            aria-label="close"
            @click="closeNewNBHModal()"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-mobile is-multiline">
            <div class="column is-4">
              <label class="label">{{ $t("enaq_translation_title") }}</label>
            </div>
            <div class="column is-8">
              <input
                :class="{ formError: formErrors.includes('title') }"
                type="text"
                class="input"
                v-model="createNBHObject.title"
              />
            </div>
            <div class="column is-4">
              <label class="label">{{
                $t("enaq_translation_description")
              }}</label>
            </div>
            <div class="column is-8">
              <textarea
                :class="{ formError: formErrors.includes('description') }"
                class="textarea"
                rows="5"
                v-model="createNBHObject.description"
              ></textarea>
            </div>

            <div class="column is-4">
              <label class="label"
                >{{ $t("enaq_translation_nbh_reward") }}
              </label>
            </div>
            <div class="column is-8">
              <input
                type="number"
                class="input"
                v-model="createNBHObject.reward"
              />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <enaqbutton :margin="'5px'" @click="createNBH">
            {{ $t("enaq_translation_save") }}
          </enaqbutton>
          <enaqbutton
            :margin="'5px'"
            :backgroundcolor="'#808080'"
            @click="closeNewNBHModal()"
          >
            {{ $t("enaq_translation_cancel") }}
          </enaqbutton>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import userlist from "@/components/User/userList.vue";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "nachbarschaftshilfe",
  props: [],
  components: {
    userlist,
    enaqbutton,
  },
  mounted: function () {
    this.getNBHS();
  },
  data() {
    return {
      nbhs: [],
      search: "",
      nbhToResolve: null,
      openNewNBHModal: false,
      selectedNbh: -1,
      createNBHObject: {
        title: "",
        description: "",
        reward: 10,
      },
      formErrors: [],
    };
  },
  methods: {
    getNBHS() {
      this.HTTPRequest()
        .getAllNBHs()
        .then(
          function (result) {
            this.nbhs = result.data.nbhs;
          }.bind(this)
        );
    },
    createNBH() {
      this.validateForm();
      if (this.formErrors.length > 0) {
        return;
      }
      this.HTTPRequest()
        .createNBH(
          this.createNBHObject.title,
          this.createNBHObject.description,
          this.createNBHObject.reward
        )
        .then(
          function (result) {
            this.createNBHObject = {
              title: "",
              description: "",
              reward: 10,
              type: "nbh",
            };
            this.openNewNBHModal = false;
            this.getNBHS();
          }.bind(this)
        );
    },
    closeNewNBHModal() {
      this.createNBHObject = {
        title: "",
        description: "",
        reward: 10,
      };
      this.openNewNBHModal = false;
    },
    validateForm() {
      this.formErrors = [];
      if (this.createNBHObject.title == "") {
        this.formErrors.push("title");
      }
      if (this.createNBHObject.description == "") {
        this.formErrors.push("description");
      }
    },
    deleteNBH(nbh) {
      this.HTTPRequest()
        .deleteNBH(nbh.id)
        .then(
          function (result) {
            this.getNBHS();
          }.bind(this)
        );
    },
    selectUserForResolve(userId, nbhId) {
      this.HTTPRequest()
        .resolveNBH(userId, nbhId)
        .then(
          function (result) {
            this.nbhToResolve = null;
            this.getNBHS();
          }.bind(this)
        );
    },
    selectNbh(id) {
      if (this.selectedNbh == id) {
        this.selectedNbh = -1;
      } else {
        this.selectedNbh = id;
      }
    },
  },
  computed: {
    filteredNBH: function () {
      return this.nbhs.filter((nbh) => {
        return nbh.title.toLowerCase().match(this.search.toLowerCase());
      });
    },
  },
};
</script>

<style scoped>
.formError {
  border: 1px solid red;
}

.searchBar {
  width: 300px;
  border: 2px solid #1c63af;
  color: #1c63af;
  outline: none;
  font-size: 1.2rem;
}

.ressourceList {
  background-color: #1c63af;
}

.nbhTitle,
.nbhReward {
  font-size: 1.3em;
  font-weight: bold;
  color: #e9a12d;
}

.nbhTitle {
  text-align: center;
  padding-left: 10%;
}

.nbhReward {
  text-align: right;
  color: #e9a12d;
}

.nbhDesc,
.nbhDescPreview {
  color: white;
  text-align: start;
}

.nbhItem {
  background-color: #1c63af;
  border-radius: 50px;
  margin: 2% 0;
  padding: 1em 2em;
  box-shadow: -10px 10px 15px #777777;
}

.openNbhArrow {
  cursor: pointer;
  font-size: 2em;
  color: white;
}

.nbhListTransition-enter-active {
  transition: all 0.5s;
}

.nbhListTransition-leave-active {
  transition: all 0.5s;
}

.nbhListTransition-enter,
.nbhListTransition-leave-to {
  opacity: 0;
}

.nbhListTransition-enter-to,
.nbhListTransition-leave {
  opacity: 1;
}
</style>
