<<template>
  <div class="newSaleModal">
    <div class="modal is-active">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ getSaleType(sale) }}: {{ sale.title }}
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal"
          ></button>
        </header>
        <section class="modal-card-body">
           <div
              v-if="saleItems.length > 0"
              class="has-text-centered deleteImage"
            >
             <slideshow :items="saleItems"></slideshow>
              <br>
            </div>
          <div class="columns is-mobile is-multiline">
            <div class="label column is-3">Preis:</div>
            <div class="column is-9">{{ getSalePrice(sale) }}</div>

            <div class="label column is-3">Beschreibung:</div>
            <div class="column is-9">{{ sale.description }}</div>

            <div class="label column is-3">Von:</div>
            <div class="column is-9">
             {{ sale.author.firstname }} {{ sale.author.name }}
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
         <router-link
            :to="'/message/create/' + sale.author.id"
          >
            <enaqbutton :width="'180px'">Nachricht schreiben</enaqbutton>
          </router-link>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import slideshow from "@/components/Slideshow/slideshow.vue";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "showSale",
  props: ["sale"],
  components: {
    slideshow,
    enaqbutton,
  },
  mounted: function () {},
  data() {
    return {
      data: [],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    getSaleType(sale) {
      switch (sale.type) {
        case 0:
          return this.$t("enaq_translation_market_new_offer_type_sell");
        case 1:
          return this.$t("enaq_translation_market_new_offer_type_buy");
        case 2:
          return this.$t("enaq_translation_market_new_offer_type_trade");
        case 3:
          return this.$t("enaq_translation_market_new_offer_type_gift");
        case 4:
        default:
          return this.$t("enaq_translation_market_new_offer_type_other");
      }
    },
    getSalePrice(sale) {
      if (sale.price != 0 && sale.price != undefined && sale.price != null) {
        return sale.price + "€";
      } else if (
        sale.individual_reward != "" &&
        sale.individual_reward != 0 &&
        sale.individual_reward != undefined &&
        sale.individual_reward != null
      ) {
        return sale.individual_reward;
      } else {
        return "Kein Preis angegeben";
      }
    },
  },
  computed: {
    saleItems() {
      let items = [];
      for (var x = 0; x < this.sale.images.length; x++) {
        let imageData = this.sale.images[x];
        items.push({
          id: imageData.id,
          title: "",
          path: this.$storageURL + "" + imageData.imagepath,
        });
      }

      return items;
    },
  },
};
</script>

<style scoped>
.modal-card-body {
  overflow: auto;
}
</style>
