<template>
  <div class="component">
    <div class="switchButtonBox" :style="buttonBoxDynamic" @click="toggle">
      <div class="switchButton" :style="buttonDynamic"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "slideButton",
  props: ["height", "default"],
  mounted: function () {
    if (this.height != undefined && this.height != "") {
      this.switchHeight = this.height;
    }

    if (this.default != undefined && this.default != "") {
      this.enabled = this.default;
    }
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      border: "2px",
      borderRadius: "20px",
      borderColor: "#5D5D5D",
      insetPadding: "2px",
      enabled: false,
      backgroundDisabled: "white",
      backgroundEnabled: "#4D4D4D",
      buttonColorEnabled: "white",
      buttonColorDisabled: "#4D4D4D",

      switchHeight: "2rem",
    };
  },
  methods: {
    toggle() {
      this.enabled = !this.enabled;
      this.$emit("toggle", this.enabled);
    },
  },
  computed: {
    buttonBoxDynamic: function () {
      let styleData = {
        height: this.switchHeight,
        width: "calc(" + this.switchHeight + " * 2)",
        border: this.border + " solid " + this.borderColor,
        background: this.backgroundDisabled,
        "border-radius": this.borderRadius,
      };

      if (this.enabled) {
        styleData.background = this.backgroundEnabled;
      }

      return styleData;
    },
    buttonDynamic: function () {
      let styleData = {
        width:
          "calc( " +
          this.switchHeight +
          " - ( " +
          this.border +
          " *2 ) - ( " +
          this.insetPadding +
          " *2 ))",
        height:
          "calc( " +
          this.switchHeight +
          " - (" +
          this.border +
          " *2 + 2 * " +
          this.insetPadding +
          ")",
        "margin-top": this.insetPadding,
        "margin-left": this.insetPadding,
        background: this.buttonColorDisabled,
        "border-radius": this.borderRadius,
      };

      if (this.enabled) {
        styleData.transform =
          "TranslateX(calc(" +
          this.switchHeight +
          " - (" +
          this.insetPadding +
          " * 2 )))";
        styleData.background = this.buttonColorEnabled;
      }

      return styleData;
    },
  },
};

//transform: translateX(calc(2.6em - 8px));
//TranslateX(calc(2.6em+2px+200px))
</script>

<style scoped>
.switchButtonBox {
  transition: all 0.5s ease-in-out;
  border-radius: 5%;

  box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.33);
}

.switchButton {
  transition: 0.5s;
}
</style>
