<template>
<div class="login">
    <div class="inputbox">

        <input class="input" type="text" :placeholder="$t('enaq_translation_register_mail')" v-model="email">
        <input class="input" type="password" :placeholder="$t('enaq_translation_register_password')" v-model="password">
        <input class="input" type="password" :placeholder="$t('enaq_translation_register_password_confirm')" v-model="password_confirm">
        <input class="input" type="text" :placeholder="$t('enaq_translation_register_voucher')" v-model="voucher">
    </div>

    <button class="button is-info is-hidden-mobile" @click="onClickRegister">{{$t('enaq_translation_register_register')}}</button>
    <button class="button is-large is-info is-hidden-tablet " @click="onClickRegister">{{$t('enaq_translation_register_register')}}</button>

    <infoModal :message="infoMessage" :openModal="infoMessage!=''" :title="infoTitle" @closed="infoMessage=''"></infoModal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import infoModal from '@/components/UI/infoModal.vue';

export default {
    name: 'register',
    components: {
        Datepicker,
        infoModal
    },
    props: [],
    mounted: function () {
        this.infoTitle = this.$t('enaq_translation_register_error')
    },
    data() {
        return {
            name: "",
            firstName: "",
            salutation: 0,
            birthdate: "22 Jun 1990",
            email: "",
            password: "",
            password_confirm: "",
            voucher: "",
            errorMessage: "",
            infoMessage: "",
            infoColor: "",
            infoTitle: "Registrierung fehlgeschlagen.",
        }
    },
    methods: {
        onClickRegister() {
            this.validateInput();
            if (this.infoMessage == "") {
                this.trySignup();
            }
        },

        validateInput() {
            this.infoMessage = "";
            if (this.email == "") {
                this.errorMessage = this.$t('enaq_translation_register_error_mail')
            }
            if (this.password == "") {
                this.infoMessage = this.$t('enaq_translation_register_error_pass')
            }
            if (this.password_confirm != this.password) {
                this.infoMessage = this.$t('enaq_translation_register_error_password_confirm')
            }
        },

        trySignup() {
            let signupData = {
                email: this.email,
                name: this.username,
                password: this.password,
                password_confirmation: this.password_confirm,
                voucher: this.voucher
            }
            this.callApi("/auth/signup", 'post', signupData).then(function (response) {
                this.infoTitle = "Information"
                this.infoMessage = this.$t('enaq_translation_register_created');
            });
            return;

            this.$axios.post(this.$backendURL + "/auth/signup", signupData).then(function (result) {
                    if (result.data.data != undefined && (result.data.data.code == 32 || result.data.data.code == 33)) {
                        this.infoMessage = this.$t('enaq_translation_register_error_wrong_voucher');
                    }
                    this.infoTitle = "Information"
                    this.infoMessage = this.$t('enaq_translation_register_created');
                }.bind(this))
                .catch(function (error) {
                    if (error.response == undefined) {
                        return;
                    }

                    if (error.response.data.errors && error.response.data.errors.email) {
                        if (error.response.data.errors.email[0].includes("already")) {
                            this.infoMessage += this.$t('enaq_translation_register_error_mail_used');

                        } else {
                            this.errorMessage = this.$t('enaq_translation_register_error_mail')
                        }

                    } else if (error.response.data.code == 32) {
                        this.infoMessage = this.$t('enaq_translation_register_error_wrong_voucher');

                    }

                }.bind(this))
        }
    },
    computed: {},
}
</script>

<style scoped>
.inputbox {
    width: 60%;
    margin: auto;
    margin-bottom: 15px;
}

.input {
    margin-top: 30px
}

.salutationDropdown {
    margin-top: 30px
}

.datePicker input {
    width: 100%;
    margin-top: 30px;
}

.alignedLabel {
    font-size: 1rem;
    height: 2.25em;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    text-align: left;
}

.birthFields {
    margin-top: 20px;
}

@media screen and (max-width: 900px) {
    .inputbox {
        width: 90%;
    }
}
</style>
