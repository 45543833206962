<template>
<div class="component">
    <div class="tabs is-fullwidth is-boxed">
        <ul>
            <li class="tpTab" :class="{'is-active':activeTab=='ownCompare'}" @click="activeTab='ownCompare'"><a>{{$t('enaq_translation_devices_tab_ownCompare')}}</a></li>
            <li class="tpTab" :class="{'is-active':activeTab=='quartieroverview'}" @click="activeTab='quartieroverview'"><a>Quartier</a></li>
            <li class="tpTab" :class="{'is-active':activeTab=='ownCompare3'}" @click="activeTab='ownCompare'"><a>{{$t('enaq_translation_devices_tab_otherCompare')}}</a></li>

        </ul>
    </div>

    <div class=" columns is-mobile is-multiline">

        <div class="column is-2">
            <div class="selectPeriod button is-fullwidth is-medium has-text-weight-bold is-info" :class="{'is-success':activePeriode=='periode'}" @click="activePeriode='periode'">{{$t('enaq_translation_devices_select_monthly')}}</div>
        </div>

        <div class="column is-2">
            <div class="selectPeriod button is-fullwidth is-medium has-text-weight-bold is-info" :class="{'is-success':activePeriode=='daily'}" @click="activePeriode='daily'">{{$t('enaq_translation_devices_select_daily')}}</div>
        </div>

        <div class="column is-2">
            <div class="selectPeriod button is-fullwidth is-medium has-text-weight-bold is-info" :class="{'is-success':activePeriode=='hourly'}" @click="activePeriode='hourly'">{{$t('enaq_translation_devices_select_hourly')}}</div>
        </div>

        <div class="column is-2">
            <div class="selectPeriod button is-fullwidth is-medium has-text-weight-bold is-info" :class="{'is-success':activePeriode=='quarter'}" @click="activePeriode='quarter'">{{$t('enaq_translation_devices_select_quarter_hour')}}</div>
        </div>

        <div class="column is-2">
            <div class="selectPeriod button is-fullwidth is-medium has-text-weight-bold is-info" :class="{'is-success':activePeriode=='yearly'}" @click="activePeriode='yearly'">{{$t('enaq_translation_devices_select_yearly')}}</div>
        </div>

        <div class="column is-2">
            <div class="selectPeriod button is-fullwidth is-medium has-text-weight-bold is-info" :class="{'is-success':activePeriode=='all'}" @click="activePeriode='all'">{{$t('enaq_translation_devices_select_all')}}</div>
        </div>

    </div>

    <div class="columns is-mobile is-multiline consumptionData">

        <div class="column is-12">
            <div v-if="activeTab=='ownCompare'">
                <compareconsumption :selectedPeriode="activePeriode"></compareconsumption>

            </div>

            <div v-if="activeTab=='quartieroverview'">
                <comparequarter :selectedPeriode="activePeriode"></comparequarter>
            </div>

        </div>
    </div>

</div>
</template>

<script>
import compareconsumption from '@/components/Devices/compareconsumption.vue'
import comparequarter from '@/components/Devices/comparequarter.vue'

export default {
    name: 'Devices',
    props: [],
    components: {
        compareconsumption,
        comparequarter
    },
    mounted: function () {
        this.getConsumptionData();
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            consumptionData: [],
            activeTab: "ownCompare",
            activePeriode: "periode",
            showSelectPeriode: false,

        }
    },
    methods: {
        getConsumptionData() {
            this.consumptionData = this.getMockConsumptionData(30, 5, 9);
        },
        getMockConsumptionData(amount = 30, min = 0, max = 10) {
            let consumptionData = []
            for (var i = 0; i < amount; i++) {
                var consumption = min + Math.floor(Math.random() * (max - min))

                consumptionData.push(consumption)
            }
            return consumptionData;
        }

    },
    computed: {},
}
</script>

<style scoped>
.tabs .is-active a {
    color: #F0AD12 !important;
    font-weight: bolder;
}

.periodeSelection {
    position: absolute;
    background: white;
    z-index: 12;
    height: 2.5em;
    cursor: default;
}

.consumptionData {
    margin-top: 3em;
}
</style>
