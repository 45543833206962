import en from './en.json'
import de from './de.json'

export const defaultLocale = 'de'
export const fallbackLocale = 'en'
export const languageAssigned = {
    us: "en",
    de: "de",
    uk: "en"
}
export const languages = {
    us: en,
    de: de,
}