<template>
  <div class="component">
    <div class="groupHeader">
      <div class="groupBackButton cpTextMedium" @click="$emit('closegroup')">
        <button class="button is-medium is-info">Zurück</button>
      </div>
      <h1 class="title groupTitle cpTextLarge">{{ group.title }}</h1>
      <div
        class="groupNewMessageButton cpTextMedium"
        @click="newMessageModal = true"
      >
        <button class="button is-medium is-info">Neue Nachricht</button>
      </div>
    </div>
    <div class="groupDescription cpTextSmall">{{ group.description }}</div>

    <div class="messages">
      <div class="messageOptions">
        <div class="pagination" v-if="lastMessagePage > 1">
          <nav class="pagination" role="navigation" aria-label="pagination">
            <a
              class="pagination-previous"
              v-if="currentMessagePage != 1"
              @click="getMessages(currentMessagePage - 1)"
              >Previous</a
            >
            <a
              class="pagination-next"
              v-if="currentMessagePage < lastMessagePage"
              @click="getMessages(currentMessagePage + 1)"
              >Next page</a
            >

            <ul class="pagination-list" v-if="lastMessagePage > 7">
              <li v-for="page in pagesToShow" :key="page">
                <span v-if="page == '&hellip;'" class="pagination-ellipsis"
                  >&hellip;</span
                >
                <a
                  v-else
                  @click="selectPage(page)"
                  class="pagination-link"
                  aria-label="Goto page 1"
                  :class="{ 'is-current': currentMessagePage == page }"
                  >{{ page }}</a
                >
              </li>
            </ul>

            <ul class="pagination-list" v-else>
              <li v-for="page in lastMessagePage" :key="page">
                <a
                  @click="selectPage(page)"
                  class="pagination-link"
                  aria-label="Goto page 1"
                  :class="{ 'is-current': currentMessagePage == page }"
                  >{{ page }}</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div
        v-for="(message, index) in messages"
        class="groupMessageContainer"
        :key="index"
      >
        <div class="groupMessage" v-if="index % 2 == 0">
          <div class="userInformarion">
            <div class="userAvatar">
              <img
                class="profileThumbnail"
                :src="$storageURL + message.user.avatar"
              />
            </div>
            <div class="userName">
              {{ message.user.firstname }} {{ message.user.name }}
            </div>
          </div>
          <div class="messagecontent">
            {{ message.message }}
          </div>
          <div class="messageFooter">
            <div class="messageTime">
              {{ message.created_at }}
            </div>
            <div
              class="messageDelete"
              v-if="canDeleteMessage(message)"
              @click="deleteMessage(message)"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </div>
          </div>
        </div>

        <div class="groupMessage" v-else>
          <div class="messagecontent">
            {{ message.message }}
          </div>
          <div class="userInformarion">
            <div class="userAvatar">
              <img
                class="profileThumbnail"
                :src="$storageURL +  message.user.avatar"
              />
            </div>
            <div class="userName">
              {{ message.user.firstname }} {{ message.user.name }}
            </div>
          </div>
          <div class="messageFooter">
            <div class="messageTime">
              {{ message.created_at }}
            </div>
            <div
              class="messageDelete"
              v-if="canDeleteMessage(message)"
              @click="deleteMessage(message)"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="newMessageModal" class="modal is-active enaqColorModal">
      <div class="modal-background" @click="closeMessageModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Neue Nachricht</p>
          <button
            class="delete"
            aria-label="close"
            @click="closeMessageModal"
          ></button>
        </header>
        <section class="modal-card-body">
          <textarea
            class="textarea"
            rows="5"
            v-model="newMessageContent"
          ></textarea>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="sendMessage">
            Save changes
          </button>
          <button class="button" @click="closeMessageModal">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Group",
  props: ["group"],
  mounted: function () {
    this.getMessages(1);
  },
  data() {
    return {
      data: [],
      messages: [],
      currentMessagePage: 1,
      lastMessagePage: 1,
      perPage: 20,
      messageCache: {},
      newMessageModal: false,
      newMessageContent: "",
      groupMessageCount: -1,
    };
  },
  methods: {
    getMessages(page) {
      this.currentMessagePage = page;
      //alert("get message from page "+page)
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };

      this.callApi("/group/" + this.group.id + "/messages/count").then(
        function (response) {
          if (this.groupMessageCount != response.data.messages) {
            this.groupMessageCount = response.data.messages;
            this.messageCache = {};
          }

          if (this.messageCache[page] != undefined) {
            this.messages = this.messageCache[page];
            return;
          } else {
            this.fetchMessages(page);
          }
        }.bind(this)
      );

      // this.fetchMessages(page);
    },

    fetchMessages(page) {
      let postData = {
        page: page,
        per_page: this.perPage,
      };
      this.sendInfoMessage({
        loading: true,
      });

      this.callApi(
        "/group/" + this.group.id + "/messages",
        "post",
        postData
      ).then(
        function (response) {
          this.messages = response.data.messages.data;
          this.lastMessagePage = response.data.messages.last_page;
          this.messageCache[page] = response.data.messages.data;
          this.sendInfoMessage({
            type: "close",
          });
        }.bind(this)
      );
    },
    selectPage(page) {
      this.getMessages(page);
    },

    closeMessageModal() {
      this.newMessageContent = "";
      this.newMessageModal = false;
    },

    sendMessage() {
      let postData = {
        message: this.newMessageContent,
      };
      this.sendInfoMessage({
        loading: true,
      });

      this.callApi(
        "/group/" + this.group.id + "/message/create",
        "post",
        postData
      ).then(
        function (response) {
          this.closeMessageModal();
          this.getMessages(1);
          this.sendInfoMessage({
            type: "close",
          });
        }.bind(this)
      );
    },

    canDeleteMessage(message) {
      if (this.$store.getters.getUser.role >= 2) {
        return true;
      }
      if (this.$store.getters.getUser.id == message.user_id) {
        return true;
      }

      return false;
    },
    deleteMessage(message) {
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };

      let postData = {
        messageid: message.id + "",
      };
      this.sendInfoMessage({
        loading: true,
      });

      this.callApi(
        "/group/" + this.group.id + "/message/delete",
        "post",
        postData
      ).then(
        function (response) {
          this.groupMessageCount = -1;
          this.getMessages(1);
        }.bind(this)
      );
    },
  },
  computed: {
    pagesToShow() {
      if (this.currentMessagePage < 4) {
        /*   return[
                '<a class="pagination-link" aria-label="Goto page 1">1</a>',
                            '<a class="pagination-link" aria-label="Goto page 1">2</a>',
                            '<a class="pagination-link" aria-label="Goto page 1">3</a>',
                           ' <span class="pagination-ellipsis">&hellip;</span>',
                            '<a class="pagination-link" aria-label="Goto page 1">'+this.lastMessagePage-2+'</a>',
                            '<a class="pagination-link" aria-label="Goto page 1">'+this.lastMessagePage-1+'</a>',
                            '<a class="pagination-link" aria-label="Goto page 1">'+this.lastMessagePage+'</a>',

                ];
*/

        return [
          1,
          2,
          3,
          "&hellip;",
          this.lastMessagePage - 2,
          this.lastMessagePage - 1,
          this.lastMessagePage,
        ];
      } else if (this.currentMessagePage > this.lastMessagePage - 2) {
        return [
          1,
          2,
          "&hellip;",
          this.lastMessagePage - 2,
          this.lastMessagePage - 1,
          this.lastMessagePage,
        ];
      } else {
        return [
          1,
          "&hellip;",
          this.currentMessagePage - 1,
          this.currentMessagePage,
          this.currentMessagePage + 1,
          "&hellip;",
          this.lastMessagePage,
        ];
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  text-align: right;
}

.groupMessageContainer:nth-child(even) .groupMessage {
  border: 1px solid grey;
  margin: 10px 0;
  background: #2a2;
  padding: 15px 10%;
  border-radius: 20px;
}

.groupMessageContainer:nth-child(odd) .groupMessage {
  border: 1px solid grey;
  margin: 10px 0;
  background: #ddd;
  padding: 15px 10%;
  border-radius: 20px;
}

.userInformarion {
  display: inline-block;
  width: 10%;
}

.messagecontent {
  display: inline-block;
  width: 90%;
}

.userAvatar img {
  max-height: 90px;
  overflow: hidden;
  width: auto;
  height: 90px;
}

.messageFooter {
  text-align: right;
  font-size: 0.8rem;
  color: #555;
}

.messageTime {
  display: inline-block;
  width: 20%;
}

.messageDelete {
  display: inline-block;
  width: 5%;
}

.groupBackButton,
.groupNewMessageButton {
  display: inline-block;
  width: 10%;
}

.groupTitle {
  display: inline-block;
  width: 80%;
}
</style>
