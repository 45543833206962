<template>
<div class="component">
    <div class="modal is-active" v-if="loaded">
        <div class="modal-background" @click="closeModal"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ message.title }}</p>
                <button class="delete" aria-label="close" @click="closeModal"></button>
            </header>
            <section class="modal-card-body">
                {{ message.message }}
            </section>
            <footer class="modal-card-foot">
                <enaqbutton :backgroundcolor="'#808080'" :margin="'5px'" @click="closeModal">Schließen</enaqbutton>

                <enaqbutton :margin="'5px'">
                    <router-link :to="{
                name: 'messageCreateWithTitle',
                params: {
                  messagetitle: 'AW: ' + this.message.title,
                  messagereciever: this.message.author_id,
                },
              }">
                        Antworten
                    </router-link>
                </enaqbutton>

                <div class="authorInfo columns is-mobile is-multiline" @click="
              $router.push({ name: 'user', params: { id: message.author.id } })
            ">
                    <span class="
                column
                is-2-desktop is-3-mobile is-offset-3-desktop is-offset-1-mobile
                has-text-left-mobile
                authorTitle
              ">Von:
                    </span>
                    <span class="column is-2-desktop is-hidden-mobile authorImageContainer">
                        <img class="authorImage" :src="$storageURL + message.author.avatar" />
                    </span>
                    <span class="
                authorName
                column
                is-5-desktop is-8-mobile
                has-text-right-mobile
              ">{{ message.author.name }}, {{ message.author.firstname }}</span>
                </div>
            </footer>
        </div>
    </div>
</div>
</template>

<script>
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
    name: "message",
    props: ["messageId"],
    components: {
        enaqbutton,
    },
    mounted: function () {
        this.getMessage();
    },
    data() {
        return {
            msg: "Welcome to Your Vue.js App",
            data: [],
            message: "",
            loaded: false,
        };
    },
    methods: {
        getMessage() {
            this.callApi("/message/" + this.messageId).then(
                function (response) {
                    this.message = response.data.message;
                    this.loaded = true;
                }.bind(this)
            );
        },
        closeModal() {
            this.$emit("close");
        },
    },
    computed: {},
};
</script>

<style scoped>
.component {
    display: absolute;
    width: 90vw;
    height: 90vh;
    z-index: 102;
    top: 0;
}

.authorImage {
    max-height: 30px;
    border-radius: 20px;
}

.authorImageContainer {
    text-align: right;
}

.authorName {
    padding-left: 10px;
    line-height: 30px;
    text-align: left;
}

.authorTitle {
    padding-left: 10px;
    line-height: 30px;
    text-align: right;
}

.authorInfo {
    width: 100%;
}
</style>
