<template>
  <div class="component">
    <br />
    <div class="has-text-right">
      <enaqbutton
        :width="'200px'"
        :fontsize="'20px'"
        :padding="'10px'"
        @click="openModal = true"
      >
        + {{ $t("enaq_translation_market_new_offer_button") }}
      </enaqbutton>
    </div>
    <br />

    <header
      class="saleListHeader modal-card-head"
      @click="openTabAllSales('Alle Angebote')"
    >
      <p class="modal-card-title">Alle Angebote</p>
      <i class="fas fa-chevron-down"></i>
    </header>

    <transition name="allSalesTransition">
      <div v-if="tabAllSales == 'Alle Angebote'">
        <br />
        <table class="table is-fullwidth">
          <saleslist :areOwnSales="false"></saleslist>
        </table>
      </div>
    </transition>

    <br />

    <header
      class="saleListHeader modal-card-head"
      @click="openTabOwnSales('Own Sales')"
    >
      <p class="modal-card-title">
        {{ $t("enaq_translation_market_your_offers") }}
      </p>
      <i class="fas fa-chevron-down"></i>
    </header>

    <transition name="ownSalesTransition">
      <div v-if="tabOwnSales == 'Own Sales'">
        <br />
        <table class="table is-fullwidth">
          <saleslist :areOwnSales="true"></saleslist>
        </table>
      </div>
    </transition>

    <div class="newSaleModal" v-if="openModal">
      <newsale @close="closeNewSale"></newsale>
    </div>
  </div>
</template>

<script>
import saleslist from "@/components/Meetingpoint/Market/salesList.vue";
import newsale from "@/components/Meetingpoint/Market/newSale.vue";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "Sales",
  props: [],
  components: {
    saleslist,
    newsale,
    enaqbutton,
  },
  mounted: function () {},
  data() {
    return {
      data: [],
      openModal: false,
      openSale: false,
      tabAllSales: "",
      tabOwnSales: "",
    };
  },
  methods: {
    closeNewSale() {
      this.openModal = false;
    },
    openTabAllSales(name) {
      if (this.tabAllSales == name) {
        this.tabAllSales = "";
      } else {
        this.tabAllSales = name;
      }
    },
    openTabOwnSales(name) {
      if (this.tabOwnSales == name) {
        this.tabOwnSales = "";
      } else {
        this.tabOwnSales = name;
      }
    },
  },
  computed: {},
};
</script>

<style>
.saleListHeader {
  cursor: pointer;
}

/* allSalesTransition */
.allSalesTransition-enter-active {
  transition: all 0.7s;
  overflow: hidden;
  max-height: 100vh;
}
.allSalesTransition-leave-active {
  transition: all 0.3s;
  overflow: hidden;
  max-height: 100vh;
}
.allSalesTransition-enter,
.allSalesTransition-leave-to {
  opacity: 0;
  max-height: 0px;
}

/* ownSalesTransition */
.ownSalesTransition-enter-active {
  transition: all 0.7s;
  overflow: hidden;
  max-height: 100vh;
}
.ownSalesTransition-leave-active {
  transition: all 0.3s;
  overflow: hidden;
  max-height: 100vh;
}
.ownSalesTransition-enter,
.ownSalesTransition-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
