<template>
  <div class="component">
    <div class="calendarEnaq">
      <div class="columns is-mobile is-multiline navigation">
        <div class="column is-3">
          <span class="navButton" @click="prevMonth"
            ><i class="fas fa-chevron-left"></i
          ></span>
        </div>
        <div class="column is-6">
          <span class="currentmonth"
            >{{ monthNames[currentmonth] }} {{ currentyear }}</span
          >
        </div>
        <div class="column is-3">
          <span class="navButton" @click="nextMonth"
            ><i class="fas fa-chevron-right"></i
          ></span>
        </div>
      </div>

      <div class="columns is-gapless is-multiline is-mobile">
        <div
          class="column is-one-fifth-fullhd is-12-mobile is-12-tablet"
          v-for="day in daysInMonth"
          :key="day.id"
        >
          <calendargrid
            @eventclicked="onEventClick"
            :highlight="isToday(day)"
            :day="day"
            :width="'100%'"
            :height="getRowMax(day) * 35 + 'px'"
            :events="getDayEvents(day)"
          ></calendargrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import calendargrid from "@/components/Meetingpoint/Calendar/calendarGrid";

export default {
  name: "calendarMain",
  components: {
    calendargrid,
  },
  props: ["events", "currentyear", "currentmonth"],
  mounted: function () {
    if (this.year != undefined && this.year != "") {
      this.currentyear = this.year;
    }
    if (this.month != undefined && this.month != "") {
      this.currentmonth = this.month;
    }
    this.prepareCalendar();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      data: [],
      currentyears: 2019,
      currentmonths: 9,
      window: {
        width: 0,
        height: 0,
      },
      cachedEventsDay: [],
      monthNames: {
        1: "Januar",
        2: "Februar",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
      },
    };
  },
  methods: {
    prepareCalendar() {
      //this.countRowMax();
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    getRowMax(day) {
      day -= 1; //Check for rowindex, not monthday
      
      let row = (day - (day % this.rowCount)) / this.rowCount + 1;
      for (var index = 0; index < this.rowMaxData.length; index++) {
        if (this.rowMaxData[index].row == row) {
          return this.rowMaxData[index].count;
        }
      }

      return 0;
    },
    resetCache() {
      this.cachedEventsDay = [];
    },
    isToday(day) {
      var today = new Date();
      if (
        today.getFullYear() == this.currentyear &&
        today.getMonth() + 1 == this.currentmonth &&
        today.getDate() == day
      ) {
        return true;
      }
      return false;
    },
    nextMonth() {
      this.currentmonth += 1;
      if (this.currentmonth > 12) {
        this.currentmonth = 1;
        this.currentyear++;
      }
      this.prepareCalendar();
      this.$emit(
        "onChangeDate",
        this.currentyear + "-" + this.currentmonth + "-01 00:00:00"
      );
    },
    prevMonth() {
      this.currentmonth -= 1;
      if (this.currentmonth < 1) {
        this.currentmonth = 12;
        this.currentyear--;
      }

      this.prepareCalendar();
      this.$emit(
        "onChangeDate",
        this.currentyear + "-" + this.currentmonth + "-01 00:00:00"
      );
    },
    getDayEvents(day) {
      for (var index = 0; index < this.dayEvents.length; index++) {
        if (this.dayEvents[index].day == day) {
          return this.dayEvents[index].events;
        }
      }
      return null;
    },
    onEventClick(event) {
      this.$emit("onEventClick", event);
    },
  },
  computed: {
    daysInMonth() {
      let smallMonths = [4, 6, 9, 11];
      let longMonths = [1, 3, 5, 7, 8, 10, 12];
      if (smallMonths.includes(this.currentmonth)) {
        return 30;
      }
      if (longMonths.includes(this.currentmonth)) {
        return 31;
      }
      if (this.currentmonth == 2) {
        if (
          this.currentyear % 4 == 0 &&
          (this.currentyear % 100 != 0 || this.currentyear % 400 == 0)
        ) {
          return 29;
        } else {
          return 28;
        }
      }
    },
    rowMaxData() {
      let rowMax = [];
      if (this.rowCount == 1) {
        for (var index = 0; index < this.dayEvents.length; index++) {
          let event = this.dayEvents[index];

          rowMax.push({
            row: index + 1,
            count: event.events.length,
          });
        }
      } else {
        for (var row = 1; row <= 7; row++) {
          var maxCount = 0;
          for (var index = 0; index < this.dayEvents.length; index++) {
            let event = this.dayEvents[index];
            if (event.day > (row - 1) * 5 && event.day <= row * 5) {
              if (event.events.length > maxCount) {
                maxCount = event.events.length;
              }
            }
          }
          rowMax.push({
            row: row,
            count: maxCount,
          });
        }
      }

      return rowMax;
    },
    dayEvents() {
      let cachedEventsDay = [];

      for (var day = 1; day <= this.daysInMonth; day++) {
        var eventsThisDay = [];
        for (var index = 0; index < this.events.length; index++) {
          var event = this.events[index];
          var eventDate = new Date(event.date);
          if (
            eventDate.getMonth() + 1 == this.currentmonth &&
            eventDate.getFullYear() == this.currentyear &&
            eventDate.getDate() == day
          ) {
            eventsThisDay.push(event);
          }
        }

        cachedEventsDay.push({
          day: day,
          events: eventsThisDay,
        });
      }

      return cachedEventsDay;
    },
    rowCount() {
      if (this.window.width > 1215) {
        return 5;
      } else return 1;
    },
  },
};
</script>

<style scoped>
.calendarEnaq {
  margin-bottom: 30px;
  padding-top: 20px;
}

.navButton {
  color: #f0ad12;
  font-size: 70px;
  line-height: 70px;
  font-weight: bolder;
}

.currentmonth {
  font-size: 50px;
  font-weight: bolder;
}

@media (max-width: 1215px) {
  .navButton {
    color:  #f0ad12;
    font-size: 40px;
    font-weight: bolder;
  }

  .currentmonth {
    font-size: 10px;
    font-weight: bolder;
  }
}
</style>
