import overview from './overview.vue';
import detail from './detail.vue';

export default {
    install(Vue, options) {
        Vue.component('service3', overview);
        Vue.component('detail3', detail);

        options.store.commit('addService', { "overview": "service3", "page": "detail3", "name": "Beispielplugin", "id": "1234sdfsd567890" })
    }
}