<template>
  <div class="component">
    <div class="announcementHeader columns is-mobile is-multiline">
      <h1 class="title column is-8 is-offset-2">Ankündigungen</h1>
      <div class="column is-2">
        <div class="button is-info is-large" @click="openNewAnnouncement">
          Neue Ankündigung
        </div>
      </div>
    </div>
    <announcement
      :adminview="true"
      @selectAnnouncement="selectAnnouncement"
      v-for="announcement in announcements"
      :announcement="announcement"
      :key="announcement.id"
    ></announcement>

    <div class="modal is-active" v-if="editAnnouncement != null">
      <div class="modal-background" @click="editAnnouncement = null"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Bearbeiten</p>
          <button
            class="delete"
            aria-label="close"
            @click="editAnnouncement = null"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-mobile is-multiline">
            <label class="label column is-4">Titel</label>
            <input
              type="text"
              class="column is-8 input"
              v-model="editAnnouncement.title"
            />

            <label class="label column is-3">Priorität</label>

            <div class="column is-3">
              <div
                class="button"
                @click="editAnnouncement.priority = 50"
                :class="{ 'is-primary': editAnnouncement.priority == 50 }"
              >
                Normal
              </div>
            </div>
            <div class="column is-3">
              <div
                class="button"
                @click="editAnnouncement.priority = 60"
                :class="{ 'is-primary': editAnnouncement.priority == 60 }"
              >
                Hoch
              </div>
            </div>
            <div class="column is-3">
              <div
                class="button"
                @click="editAnnouncement.priority = 70"
                :class="{ 'is-primary': editAnnouncement.priority == 70 }"
              >
                Sehr hoch
              </div>
            </div>

            <label class="label column is-4">Angepinnt</label>
            <div class="column is-8 is-paddingless">
              <slidebutton
                :height="'30px'"
                :default="editAnnouncement.sticky"
                @toggle="editAnnouncement.sticky = !editAnnouncement.sticky"
              ></slidebutton>
            </div>

            <label class="label column is-4">Kurzbeschreibung</label>
            <input
              type="text"
              class="column is-8 input"
              v-model="editAnnouncement.description"
            />

            <label class="label column is-4">Inhalt</label>
            <div class="column is-8">
              <textarea
                rows="4"
                class="textarea"
                v-model="editAnnouncement.content"
              ></textarea>
            </div>
            <div>
              <button class="button" @click="deleteAnnouncement()">
                Löschen
              </button>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="updateAnnouncement">
            Speichern
          </button>
          <button class="button is-error" @click="editAnnouncement = null">
            Abbrechen
          </button>
        </footer>
      </div>
    </div>

    <div class="modal is-active" v-if="newAnnouncement != null">
      <div class="modal-background" @click="newAnnouncement = null"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Neue Ankündigung</p>
          <button
            class="delete"
            aria-label="close"
            @click="newAnnouncement = null"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-mobile is-multiline">
            <label class="label column is-4">Titel</label>
            <input
              type="text"
              class="column is-8 input"
              v-model="newAnnouncement.title"
            />

            <label class="label column is-3">Priorität</label>

            <div class="column is-3">
              <div
                class="button"
                @click="newAnnouncement.priority = 50"
                :class="{ 'is-primary': newAnnouncement.priority == 50 }"
              >
                Normal
              </div>
            </div>
            <div class="column is-3">
              <div
                class="button"
                @click="newAnnouncement.priority = 60"
                :class="{ 'is-primary': newAnnouncement.priority == 60 }"
              >
                Hoch
              </div>
            </div>
            <div class="column is-3">
              <div
                class="button"
                @click="newAnnouncement.priority = 70"
                :class="{ 'is-primary': newAnnouncement.priority == 70 }"
              >
                Sehr hoch
              </div>
            </div>

            <label class="label column is-4">Angepinnt</label>
            <div class="column is-8 is-paddingless">
              <slidebutton
                :height="'30px'"
                :default="newAnnouncement.sticky"
                @toggle="newAnnouncement.sticky = !newAnnouncement.sticky"
              ></slidebutton>
            </div>

            <label class="label column is-4">Kurzbeschreibung</label>
            <input
              type="text"
              class="column is-8 input"
              v-model="newAnnouncement.description"
            />

            <label class="label column is-4">Inhalt</label>
            <div class="column is-8">
              <textarea
                rows="4"
                class="textarea"
                v-model="newAnnouncement.content"
              ></textarea>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="createAnnouncement">
            Save changes
          </button>
          <button class="button" @click="newAnnouncement = null">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import announcement from "@/components/Announcements/announcement.vue";
import slidebutton from "@/components/helper/slidebutton.vue";

export default {
  name: "adminannouncements",
  props: [],
  mounted: function () {
    this.getAnnouncements();
  },
  components: {
    announcement,
    slidebutton,
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      announcements: null,
      editAnnouncement: null,
      newAnnouncement: null,
    };
  },
  methods: {
    getAnnouncements() {
      this.HTTPRequest()
        .getAllAnnouncements(this.announcementCount)
        .then(
          function (response) {
            this.announcements = response.data.announcements;
          }.bind(this)
        );
      /*
                  let postData = {
                      amount: this.announcementCount
                  }
                  this.callApi("/announcement/all", 'post', postData).then(function (response) {
                      this.announcements = response.data.announcements.data

                  }.bind(this))
                  */
    },
    selectAnnouncement(announcement) {
      this.editAnnouncement = announcement;
    },
    updateAnnouncement() {
      let postData = {
        announcement: this.editAnnouncement,
      };

      this.callApi(
        "/announcement/" + this.editAnnouncement.id + "/update",
        "post",
        postData
      ).then(
        function (response) {
          this.editAnnouncement = null;
          this.getAnnouncements();
        }.bind(this)
      );
    },
    openNewAnnouncement() {
      this.newAnnouncement = {
        priority: 50,
        sticky: 0,
        title: "",
        description: "",
        content: "",
      };
    },
    createAnnouncement() {
      let postData = {
        announcement: this.newAnnouncement,
      };

      this.callApi("/announcement/create", "post", postData).then(
        function (response) {
          this.newAnnouncement = null;
          this.getAnnouncements();
        }.bind(this)
      );
    },
    deleteAnnouncement() {
      let postData = {
        announcement: this.editAnnouncement,
      };

      this.callApi(
        "/announcement/" + this.editAnnouncement.id + "/delete",
        "post",
        postData
      ).then(
        function (response) {
          this.editAnnouncement = null;
          this.getAnnouncements();
        }.bind(this)
      );
    },
  },
  computed: {},
};
</script>

<style scoped>
</style>
