<template>
<div class="component">
  <h1 class="title ">Räume</h1>

    <h1 class="title viewTitle">Raum erstellen</h1>

    <div class="roomForm columns is-mobile is-multiline">
        <label class="label inputBox column is-2">Raumname</label>
        <input type="text" v-model="roomName" class="input column is-10" />

        <label class="label inputBox column is-2">Raumbeschreibung</label>
        <textarea v-model="roomDescription" class="textarea textareaPartial column is-10"></textarea>

        <br />
        <label class="label inputBox column is-2">Maximale Besucher</label>
        <input type="number" v-model="roomMaxMember" class="input column is-1"/>

        <span class="column is-12"></span>

        <label class="label inputBox  column is-2">Raum ist buchbar</label>
        <div class="column is-1 is-paddingless">
            <slidebutton :height="'30px'" :default="roomIsPublic" @toggle="roomIsPublic = !roomIsPublic"></slidebutton>
        </div>

        <span class="column is-12"></span>

        <label class="label inputBox  column is-2">Raum ist draußen</label>
        <div class="column is-1 is-paddingless">
            <slidebutton :height="'30px'" :default="roomIsOutdoor" @toggle="roomIsOutdoor = !roomIsOutdoor"></slidebutton>
        </div>

    </div>

    <button @click="createRoom" class="button is-info is-large">Raum erstellen</button>
    <infoModal :message="infoMessage" :openModal="infoMessage!=''" :title="'Information'" @closed="infoMessage=''"></infoModal>

</div>
</template>

<script>
import slidebutton from '@/components/helper/slidebutton.vue'
import infoModal from '@/components/UI/infoModal.vue';

export default {
    name: 'createRoom',
    components: {
        slidebutton,
        infoModal
    },
    props: [],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            roomName: "",
            roomDescription: "",
            roomMaxMember: 0,
            roomIsPublic: true,
            roomIsOutdoor: false,
            infoMessage: ""

        }
    },
    methods: {
        createRoom() {

            if (this.roomName == "") {
                this.infoMessage = "Bitte einen Namen für den Raum angeben.";
                return;
            }
   

            let postData = {
                'name': this.roomName,
                'description': this.roomDescription,
                'maxPersons': parseInt(this.roomMaxMember),
                'public': this.roomIsPublic,
                'outdoor': this.roomIsOutdoor,
            }
         

            this.callApi("/rooms/create", 'post', postData).then(function (response) {
                this.infoMessage = "Raum erstellt.";
                this.roomName = ""
                this.roomDescription = ""
                this.roomMaxMember = 0
                this.roomIsPublic = true
                this.roomIsOutdoor = false
            }.bind(this)).catch(function (error) {
                this.infoMessage = "Raum konnte nicht erstellt werden.";

            }.bind(this))

        },

    },
    computed: {},
}
</script>

<style scoped>
.viewTitle {
    margin-top: 15px;
    font-weight: bolder;
    font-size: 1.4em
}

.inputBox {
    height: 2.25em;
    vertical-align: super;
    line-height: 1.5;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0;
    font-size: 1.2em;
}

.textareaPartial {
    min-width: 0px;
    margin-bottom: 0.5em
}
</style>
