<template>
  <div class="component">
    <div>
      <table class="containerBox column is-fullwidth">
        <div class="is-mobile is-multiline">
          <div class="is-12-desktop is-12-tablet is-12-mobile">
            <div class="questionItemHeader">
              <div class="questionTitle">
                <span
                  ><i
                    class="pointer fas fa-info-circle"
                    @click="showDescription(question.description)"
                  ></i>
                  {{ question.question }}</span
                >
              </div>
            </div>
          </div>
          <div class="is-12-desktop is-12-tablet is-12-mobile">
            <div class="questionItemBody">
              <div v-if="question.questionType == 0">
                <table class="table is-fullwidth">
                  <div class="answerHeader columns is-mobile is-multiline">
                    <div class="column is-4-desktop is-12-tablet is-12-mobile">
                      <span class="answerText"> Antwort </span>
                    </div>
                    <div class="column is-4-desktop is-12-tablet is-12-mobile">
                      Prozent
                    </div>
                    <div class="column is-4-desktop is-12-tablet is-12-mobile">
                      Stimmenanzahl
                    </div>
                  </div>
                  <div
                    v-for="answer in question.answers"
                    :key="answer.id"
                    :class="{ choosenAnswer: questionAnswer == answer.id }"
                    class="multipleChoiceAnswers columns is-mobile is-multiline"
                  >
                    <div class="column is-4-desktop is-12-tablet is-12-mobile">
                      <div>
                        <span class="answerText">
                          <i
                            class="pointer fas fa-info-circle"
                            @click="showDescription(answer.answerDescription)"
                          ></i>
                          {{ answer.answerText }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-4-desktop is-12-tablet is-12-mobile">
                      <div>{{ answer.percentage }} %</div>
                    </div>
                    <div class="column is-4-desktop is-12-tablet is-12-mobile">
                      <span v-if="getTotal(answer.id, question) == null">
                        0
                      </span>
                      <span></span>
                      {{ getTotal(answer.id, question) }}
                    </div>
                  </div>
                </table>
              </div>

              <div v-if="question.questionType == 1">
                <table class="table is-fullwidth">
                  <div class="answerHeader columns is-mobile is-multiline">
                    <div class="column is-12-desktop is-12-tablet is-12-mobile">
                      <span class="answerText"> Antworten der Bewohner: </span>
                    </div>
                  </div>
                  <div
                    class="customAnswerCarousel columns is-mobile is-multiline"
                  >
                    <div
                      class="
                        column
                        is-3-desktop is-12-tablet is-12-mobile
                        has-text-right
                      "
                    >
                      <i
                        class="fas fa-chevron-left customAnswerCarouselArrow"
                        @click="customAnswerIndex--"
                      ></i>
                    </div>
                    <div class="column is-6-desktop is-12-tablet is-12-mobile">
                      <div>{{ customCarouselAnswer }}</div>
                    </div>
                    <div
                      class="
                        column
                        is-3-desktop is-12-tablet is-12-mobile
                        has-text-left
                      "
                    >
                      <i
                        class="fas fa-chevron-right customAnswerCarouselArrow"
                        @click="customAnswerIndex++"
                      ></i>
                    </div>
                  </div>
                </table>
              </div>

              <div v-if="question.questionType == 2">
                <div class="resultNumbers column is-mobile is-multiline">
                  <span class="">{{
                    $t("enaq_translation_surveys_average_answer")
                  }}</span
                  ><span class="resultNumber"> {{ question.result }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </table>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  name: "questionResult",
  props: ["question"],
  mounted: function () {
    this.parseAnswers();
  },
  data() {
    return {
      data: [],
      openModal: false,
      answers: [],
      customAnswers: [],
      customAnswerIndex: 0,
    };
  },
  methods: {
    showDescription(text) {
      if (text != "" && text != null && text != undefined) {
        this.sendInfoMessage({
          text: text,
          duration: "8",
        });
      } else {
        this.sendInfoMessage({
          text: "Keine Beschreibung vorhanden.",
          duration: "8",
        });
      }
    },
    getTotal(ID, q) {
      for (var r in q.result) {
        var total = q.result[r].total;
        var surveyID = q.result[r].survey_answer_id;
        if (ID == surveyID) {
          console.log("true");
          return total;
        }
      }
    },
    parseAnswers() {
      this.answers = this.question.answers;

      for (var answerID in this.answers) {
        for (var resultId in this.question.result) {
          if (
            this.question.result[resultId].survey_answer_id ==
            this.answers[answerID].id
          ) {
            this.answers[answerID].percentage =
              Math.round(this.question.result[resultId].percentage * 1000) / 10;
          }
        }
        if (this.answers[answerID].percentage == undefined) {
          this.answers[answerID].percentage = 0;
        }
      }

      this.answers.sort(function (a, b) {
        return b.percentage - a.percentage;
      });
    },
  },
  computed: {
    customCarouselAnswer() {
      if (this.customAnswerIndex < 0) {
        this.customAnswerIndex = this.question.result.length - 1;
      }

      if (this.customAnswerIndex >= this.question.result.length) {
        this.customAnswerIndex = 0;
      }

      return this.question.result[this.customAnswerIndex].customAnswerText;
    },
  },
};
</script>

<style scoped>
.component {
  cursor: default;
}

.choosenAnswer .answerMark {
  color: #1c63af;
}

.containerBox {
  padding: 15px;
  border-radius: 30px;
  border: 1px solid #1c63af;
  margin: 10px 0px 0px 0px;
  box-shadow: -10px 10px 15px #777777;
  background: #ededed;
  color: #1c63af;
}

.questionItemHeader:after {
  content: "";
  border-bottom: 1px solid #1c63af;
  width: 95%;
  display: inline-block;
}

.questionTitle {
  text-align: start;
  font-weight: bolder;
  font-size: 1.3rem;
  padding-left: 3.5%;
  padding-right: 3.5%;
}

.questionItemBody {
  text-align: center;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

.multipleChoiceAnswers {
  background: #ededed;
  color: #1c63af;
  font-size: 1.2rem;
}

.answerHeader {
  background: #ededed;
  color: #1c63af;
  font-size: 1.2rem;
  font-weight: bolder;
  padding-top: 5px;
}

.multipleChoiceAnswers:before {
  content: "";
  border-bottom: 1px solid #afafaf;
  width: 100%;
  margin: 0 3%;
}

.customAnswerCarousel {
  padding: 5px;
  font-size: 1.2em;
  background: #ededed;
  color: #1c63af;
}

.customAnswerCarouselArrow {
  cursor: pointer;
}

.resultNumbers {
  font-size: 1.2em;
}

.resultNumber {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}
</style>
