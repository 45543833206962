<template>
<div class="vouchers">

    <table class="table is-fullwidth">

        <tr>
            <th>Name</th>
            <th>Vorname</th>
            <th>Voucher</th>
            <th>Löschen</th>

        </tr>
        <tr class="voucherRow" v-for="voucher in vouchers">
            <td @click="copyToClipboard(voucher.voucher)">{{voucher.name}}</td>
            <td @click="copyToClipboard(voucher.voucher)">{{voucher.firstname}}</td>
            <td @click="copyToClipboard(voucher.voucher)">{{voucher.voucher}}</td>
                <td class="has-text-center " @click="deleteVoucher(voucher.id)"><i class=" fas fa-trash-alt"></i></td>

        </tr>

    </table>
</div>
</template>

<script>
export default {
    name: 'vouchers',
    props: [],
    mounted: function () {
        this.getVouchers();
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            vouchers: [],
        }
    },
    methods: {
        getVouchers() {
            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }

            this.$axios.get(this.$backendURL + "/auth/admin/user/vouchers", {
                headers: headerData
            }).then(function (result) {
                this.vouchers = result.data.voucher;
            }.bind(this))
        },
        copyToClipboard(voucher) {
            this.$clipboard(voucher)
        },
        deleteVoucher(id){

        }
    },
    computed: {},
}
</script>

<style scoped>
.listHeader {
    font-weight: 900;
    font-size: 1.4em
}

.voucherRow {
    cursor: pointer;
}
</style>
