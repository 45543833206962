import axios from 'axios'


export default class RouterHelper {

    constructor(Vueinstance, store) {
        this.allowedRoutes = 323;
        this.Vue = Vueinstance
        this.store = store;
    }
    setRoutesWithoutAuth(allowedRoutes) {
        this.allowedRoutesWithoutAuth = allowedRoutes
    }
    setAdminRoutes(adminRoutes) {
        this.adminRoutes = adminRoutes
    }
    needsRouteAuthentication(routeName) {
        return !this.allowedRoutesWithoutAuth.includes(routeName)
    }
    test() {
        console.log("testrouterhelper" + this.allowedRoutes)
    }

    userCanAccessRoute(routeName) {
        let checkLogin = new Promise((resolve, reject) => {
            if (localStorage.bearertoken == "") {
                reject("no token")
            }
            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }
            axios.get(this.Vue.prototype.$backendURL + "/user", { headers: headerData }).then(
                function(response) {
                    console.log("Vue.router.beforeEach - Ok: Got userdata from /user")

                    resolve(response)
                }
            ).catch(function(error) {
                console.log("Vue.router.beforeEach - Error: " + JSON.stringify(error))
                reject(error);
            });
        });
        let responseData = { routeToAccess: routeName, allowedToAccess: false, loginSuccessfull: false, errorMessage: "" }

        return new Promise((Resolve, reject) => checkLogin.then(function(response) {
            //Store userdata from backend
            responseData.loginSuccessfull = true;
            this.store.commit('setUsername', response.data.firstname)
            this.store.commit('setUser', response.data)
            this.store.commit('setNewMessage', response.data.newmessages)

            //Check if user is allowed to access this route. If not, redirect to login
            if ((this.adminRoutes.includes(routeName) && store.getters.getUser.role >= 2) ||
                !this.adminRoutes.includes(routeName)) {
                responseData.allowedToAccess = true;
                console.log("user can access the route")
                Resolve(responseData)
            } else {
                console.log("Router: User is not allowed to access this route. Reason: Not admin")
                this.store.commit('setUsername', '')
                this.store.commit('setUser', '')
                Resolve(responseData)

            }
        }.bind(this)).catch(function(error) {
            console.log("Router: User is not allowed to access this route. Reason: Failed login/auth")
            responseData.errorMessage = error
            Resolve(responseData)
        }.bind(this)))
    }



}