<template>
<div class="component">
    <div class="groupHeader columns is-mobile is-multiline">
        <div class="column is-8 is-offset-2">
            <h1 class="title">Gruppen des Quartiers</h1>
        </div>
        <div class="column is-2"><span class="button is-info is-large" @click="newGroupModal = true">Neue Gruppe</span></div>

        <div class="groupList">
            <div class="groupListItem" v-for="group in groups" :key="group.id">

                <div class="box">
                    <article class="media">

                        <div class="media-content">
                            <div class="content">
                                    <h1 class="groupTitle">{{group.title}}</h1>
                                    <div class="groupTitleButtons">
                                        <a class="">
                                            <span class="icon is-small" @click="deleteGroup(group.id)">
                                                <i class="fas fa-trash-alt"></i> </span>
                                        </a>
                                        <a class="" aria-label="">
                                            <span class="icon is-small" @click="groupToEdit = group">
                                                <i class="fas fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                    <br>
                                    <p class="groupDescription">
                                        {{group.description}}
                                    </p>
                            </div>

                        </div>
                    </article>
                </div>

            </div>
        </div>

        <div class="modal enaqColorModal" :class="{'is-active':newGroupModal}">
            <div class="modal-background" @click="newGroupModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Neue Gruppe</p>
                    <button class="delete" aria-label="close" @click="newGroupModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <div class="columns is-mobile is-multiline">
                        <div class="column is-4"><label class="label">Gruppenname</label></div>
                        <div class="column is-8"><input v-model="newGroupTitle" class="input" type="text" /></div>

                        <div class="column is-4"><label class="label">Beschreibung</label></div>
                        <div class="column is-8"><textarea v-model="newGroupDescription" rows="4" class="input"></textarea></div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="createGroup">Gruppe erstellen</button>
                    <button class="button" @click="newGroupModal = false">Abbrechen</button>
                </footer>
            </div>
        </div>

        <div class="modal enaqColorModal is-active" v-if="groupToEdit!=null">
            <div class="modal-background" @click="groupToEdit = null"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Neue Gruppe</p>
                    <button class="delete" aria-label="close" @click="groupToEdit = null"></button>
                </header>
                <section class="modal-card-body">
                    <div class="columns is-mobile is-multiline">
                        <div class="column is-4"><label class="label">Gruppenname</label></div>
                        <div class="column is-8"><input v-model="groupToEdit.title" class="input" type="text" /></div>

                        <div class="column is-4"><label class="label">Beschreibung</label></div>
                        <div class="column is-8"><textarea v-model="groupToEdit.description" rows="4" class="input"></textarea></div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="editGroup">Speichern</button>
                    <button class="button" @click="groupToEdit = null">Abbrechen</button>
                </footer>
            </div>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'AdminGroups',
    props: [],
    mounted: function () {
      //  this.getGroups();
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            newGroupTitle: "",
            newGroupDescription: "",
            newGroupModal: false,
            groups: [],
            groupToEdit: null,
        }
    },
    methods: {
        getGroups() {
          

            this.callApi("/group/all").then(function (response) {
                if (response.data.code == 1) {
                    this.groups = response.data.groups;
                }
            }.bind(this))

        },
        createGroup() {
            if (this.newGroupTitle == "") {
                return;
            }

            this.newGroupModal = false;

            let postData = {
                title: this.newGroupTitle,
                description: this.newGroupDescription
            }

            this.callApi("/group/create", 'post', postData).then(function (response) {
                this.newGroupTitle = ""
                this.newGroupDescription = ""
                this.getGroups();
            }.bind(this)).catch(function (error) {
                this.newGroupTitle = ""
                this.newGroupDescription = ""
                this.getGroups();
            }.bind(this))

        },
        openGroup() {

        },
        editGroup() {
            if (this.groupToEdit == null) {
                return;
            }

            let postData = {
                title: this.groupToEdit.title,
                description: this.groupToEdit.description
            }

            this.callApi("/group/" + this.groupToEdit.id + "/update", 'post', postData).then(function (response) {
                this.groupToEdit = null;
                this.getGroups();
            }.bind(this))

        },

        deleteGroup(id) {
          

            let postData = {
                id: id + ''
            }

            this.callApi("/group/" + id + "/delete", 'post', postData).then(function (response) {
                this.getGroups();
            }.bind(this))

        }

    },
    computed: {},
}
</script>

<style scoped>
.groupList {
    width: 100%;
}

.groupListItem {
    width: 90%;
    margin: 0.5% 5%;
    display: inline-block;
    cursor: pointer;
}

.groupTitle {
    text-align: center;
    width: 60%;
    margin-left: 20%;
    display: inline-block;
    margin-top: 0 !important;

}

.groupTitleButtons {
    width: 20%;
    display: inline-block;
    white-space: nowrap;
}

.groupTitleButtons a {
    width: 50%;
    display: inline-block;
    text-align: center;
}

.groupDescription {
    text-align: center;
}
</style>
