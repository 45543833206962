<template>
  <div class="component paginatorController" v-if="maxPages > 0">
    <nav
      class="pagination is-large is-centered"
      role="navigation"
      aria-label="pagination"
    >
      <a class="pagination-previous" @click="changePage(currentPage - 1)"
        >Previous</a
      >
      <a class="pagination-next" @click="changePage(currentPage + 1)"
        >Next page</a
      >
      <ul class="pagination-list">
        <li v-for="pageNumber in numbersToShow" :key="pageNumber">
          <a
            class="pagination-link"
            v-if="pageNumber > 0"
            @click="changePage(pageNumber)"
            aria-label="Goto page "
            :class="{ 'is-current': pageNumber == currentPage }"
            >{{ pageNumber }}</a
          >
          <span class="pagination-ellipsis" v-else>&hellip;</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Paginator",
  props: ["initialSite", "pages", "edgePageAmount", "middlePageAmount"],
  components: {},
  mounted: function () {
    if (!isNaN(this.initialSite)) {
      this.currentPage = this.initialSite;
    }

    if (!isNaN(this.pages)) {
      this.maxPages = this.pages;
    }

    if (!isNaN(this.pages)) {
      this.maxPages = this.pages;
    }

    if (!isNaN(this.edgePageAmount)) {
      this.numberAmountOnEdge = this.edgePageAmount;
    }

    if (!isNaN(this.middlePageAmount)) {
      this.numberAmountInMiddle = this.middlePageAmount;
    }
  },
  data() {
    return {
      announcements: null,
      currentPage: 1,
      amountPerPage: 5,
      maxPages: 0,
      numberAmountOnEdge: 3,
      numberAmountInMiddle: 5,
    };
  },
  methods: {
    getAnnouncements() {
      this.HTTPRequest()
        .getAnnouncementPage(this.amountPerPage, this.currentPage)
        .then(
          function (response) {
            this.announcements = response.data.announcements.data;
            this.maxPages = response.data.announcements.last_page;
          }.bind(this)
        );
    },

    changePage(pageNumber) {
      if (pageNumber < 1) {
        pageNumber = 1;
      }
      if (pageNumber > this.maxPages) {
        pageNumber = this.maxPages;
      }
      if (this.currentPage > pageNumber) {
        this.$emit("pageback", pageNumber);
      } else if (this.currentPage < pageNumber) {
        this.$emit("pageforward", pageNumber);
      }
      this.currentPage = pageNumber;
      this.$emit("changepage", pageNumber);
    },
    firstNumbers(amount) {
      var firstNumbers = [...Array(amount).keys()].map((x) => x + 1);

      return firstNumbers;
    },
    lastNumbers(amount) {
      var lastNumbers = [...Array(amount).keys()].map(
        (x) => x + this.maxPages - amount + 1
      );
      return lastNumbers;
    },
    middleNumbers(center, amount) {
      var leftMost = 0;
      if (amount % 2 == 0) {
        leftMost = center - amount / 2;
      } else {
        leftMost = center - (amount - 1) / 2;
      }
      var middleNumbers = [...Array(amount).keys()].map((x) => x + leftMost);
      return middleNumbers;
    },
  },
  computed: {
    middlePageNumber() {
      return Math.round(this.maxPages / 2);
    },
    showAllNumbers() {
      var maxAmountToShow =
        this.numberAmountInMiddle + this.numberAmountOnEdge * 2 + 2;
      return maxAmountToShow >= this.maxPages;
    },
    pageIsOnSideposition() {
      return (
        this.currentPage < this.numberAmountOnEdge ||
        this.currentPage > this.maxPages - (this.numberAmountOnEdge - 1)
      );
    },
    pageIsEdgenear() {
      return (
        this.currentPage - this.middleNumberAmountEachSite <=
          this.numberAmountOnEdge ||
        this.currentPage + this.middleNumberAmountEachSite >
          this.maxPages - this.numberAmountOnEdge
      );
    },
    middleNumberAmountEachSite() {
      var range = Math.round((this.numberAmountInMiddle - 1) / 2);
      return range;
    },
    numbersToShow() {
      let allNumbersToShow = [];
      let leftNumbers = [];
      let middleNumbers = [];
      let rightNumbers = [];
      if (this.showAllNumbers) {
        allNumbersToShow = [...Array(this.maxPages).keys()];
      } else {
        if (this.pageIsOnSideposition) {
          leftNumbers = this.firstNumbers(this.numberAmountOnEdge);

          middleNumbers = this.middleNumbers(
            this.middlePageNumber,
            this.numberAmountInMiddle
          );

          rightNumbers = this.lastNumbers(this.numberAmountOnEdge);
        } else if (this.pageIsEdgenear) {
          //
          /***
           * Site is not on edge, but near enought to show only edgenumbers.
           * Example: if we are on site 8 it would display 1,2,3,... 7,8,9,... 17,18,19.  ( 3 Numbers on each edge)
           * If we are on site 4 it would show 1,2,3, ... 3,4,5, ... 17,18,19. ( 3 Numbers on each edge)
           * Instead we want something like 1,2,3,4,... 16,17,18,19 ( 4 Numbers on each edge, but only in this case)
           *
           * */
          var numbersEachSite =
            this.middleNumberAmountEachSite + this.numberAmountOnEdge + 1;

          leftNumbers = this.firstNumbers(numbersEachSite);
          rightNumbers = this.lastNumbers(numbersEachSite);
        } else {
          leftNumbers = this.firstNumbers(this.numberAmountOnEdge);

          middleNumbers = this.middleNumbers(
            this.currentPage,
            this.numberAmountInMiddle
          );

          rightNumbers = this.lastNumbers(this.numberAmountOnEdge);
        }

        if (this.pageIsEdgenear && !this.pageIsOnSideposition) {
          allNumbersToShow = allNumbersToShow.concat(
            leftNumbers,
            [-1],
            rightNumbers
          );
        } else {
          allNumbersToShow = allNumbersToShow.concat(
            leftNumbers,
            [-1],
            middleNumbers,
            [-2],
            rightNumbers
          );
        }
      }
      return allNumbersToShow;
    },
  },
  watch: {
    initialSite: function (newVal, oldVal) {
      if (this.currentPage != newVal && !isNaN(newVal)) {
        this.currentPage = newVal;
      }
    },
    pages: function (newVal, oldVal) {
      if (this.maxPages != newVal && !isNaN(newVal)) {
        this.maxPages = newVal;
      }
    },
  },
};
</script>

<style>
.paginatorController {
  padding-top: 3rem;
}
</style>
