<template>
<div class="component permissionModal">

    <div class="modal  " :class="{'is-active':pluginID!=null}">
        <div class="modal-background" @click="closeModal"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Berechtigungen verwalten</p>
                <button class="delete" aria-label="close" @click="closeModal"></button>
            </header>
            <section class="modal-card-body">
                <div class="title">{{appDetails.name}}</div>
                <div class="subtitle">Die App {{appDetails.name}} benötigt Zugriff auf ihren ENaQ-Account. Folgende Berechtigungen werden zur Nutzung benötigt:</div>

                <div class="permissionlist">
                    <div class="permissionEntry" v-for="permissionEntry in appDetails.claims" :key="permissionEntry.id">{{permissionEntry.name}}
                        <hoverText :forceHover="false" :maxBoxWidthCH="40" :tooltip="permissionEntry.desc"> <i class="fas fa-info-circle"></i></hoverText>
                    </div>
                </div>

            </section>

            <footer class="modal-card-foot">

                <button class="button" v-if="!appDetails.consent_given" @click="consentAllPluginPermissions">Zustimmen</button>
                <button class="button"  v-if="!appDetails.consent_given"  @click="closeModal">Verweigern</button>
                <button class="button"  v-else  @click="revokeAllPluginPermissions">Berechtigungen entziehen</button>
            </footer>
        </div>
    </div>

</div>
</template>

<script>
import hoverText from '../../components/UI/hoverText.vue';

export default {

    name: 'pluginPermissionPopup',
    props: ["pluginID"],
    components: {
        hoverText
    },

    mounted: function () {
        this.getAllApps();
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            appDetails: {
                "privacy_statement_url": "privacy_statement_url",
                "logo_url": "logo_url",
                "name": "Berechtigungsappname",
                "claims": [{
                    "name": "Berechtigung 1",
                    "id": "12345",
                    "desc": "1234",
                    //  "desc": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
                }, {
                    "name": "Berechtigung 2",
                    "id": "id",
                    "desc": "desc 2"
                }],
                "publisher": "publisher",
                "consent_given": true,
                "id": "id",
                "category": "category",
                "desc": "desc"
            }
        }
    },
    methods: {
        getAllApps() {
            this.SPRequest().getAllApps().then(function (result) {

            }.bind(this))

        },

        getNeededPermissions() {

        },
        getPluginDetails() {
            this.SPRequest().getAppDetails().then(function (result) {
                this.appDetails = result
            }.bind(this))
        },
        consentAllPluginPermissions() {
            this.SPRequest().consentToApp(this.appDetails.id).then(function (result) {
                this.$emit("close")
            }.bind(this))
        },
        revokeAllPluginPermissions() {
            this.SPRequest().revokeAppConsent(this.appDetails.id).then(function (result) {
                this.$emit("close")
            }.bind(this))
        },
        closeModal() {
            this.$emit("close")
        },

    },
    computed: {},
}
</script>

<style scoped>

</style>
