import overview from './overview.vue';
import detail from './detail.vue';

export default {
    install(Vue, options) {
        Vue.component('service4', overview);
        Vue.component('detail4', detail);

        options.store.commit('addService', { "overview": "service4", "page": "detail4", "name": "Grünstromindex", "id": "123456fdsf7890" })
    }
}