<template>
<div class="component ">
<div class="enaqPiediagramm">
    <apexchart width="70%"  type="pie" :options="graphOptions" :series="graphData"></apexchart>
</div></div>
</template>

<script>
export default {
    name: 'PieDiagramm',
    props: ["graphData", "graphLabels", "width"],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            chartData: []
        }
    },
    methods: {

    },
    computed: {

        graphOptions() {

            let graphOptions = {

                dataLabels: {
                    offsetX: 10,
                    enabled: true,
                    style: {
                        fontSize: '16px',
                        fontWeight: 'light',
                    },
                },

                chart: {
                    id: this.title || "Graph " + Math.floor(Math.random() * 1000),
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },

                },

                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'left',
                    floating: false,
                    fontSize: '14',
                    fontFamily: 'Helvetica, Arial',
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: undefined,
                        useSeriesColors: false,
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },
                    itemMargin: {
                        horizontal: 0,
                        vertical: 'auto'
                    },
                },

                labels: this.graphLabels,

                plotOptions: {
                    pie: {
                        customScale: 1,
                        offsetX: 0,
                        offsetY: 0,
                        expandOnClick: true,
                        dataLabels: {
                            offset: -10,
                            minAngleToShowLabel: 10
                        },
                    },
                },
            };

            if (this.graphData[0] != undefined && this.graphData[0].dates != undefined) {
                graphOptions.xaxis.categories = this.graphData[0].dates
            }
            return graphOptions;
        },

    },
    watch: {
        graphData: function (newData, oldData) {
            this.chartData = this.graphData
        }
    }
}
</script>

<style scoped>
.component {
    height: 100%;
}
</style>
<style>
.enaqPiediagramm .apexcharts-legend-series {
    width: 50%;
    display: block;
    text-align: left;
    margin: 0 25%;
}

.enaqPiediagramm {
    position:relative;
}
</style>
