<template>
<div id="app">
   
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css">
    <link href="https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,500,500i,600i,700,800i,900,900i&display=swap" rel="stylesheet">
    <div id="nav">
        <enaqHeader></enaqHeader>
    </div>
    <div class="mainContentArea" @click="onPageClick">
        <transition name="fadex" mode="out-in" @beforeLeave="beforeLeave" @enter="enter" @afterEnter="afterEnter">

            <router-view />
        </transition>
    </div>

    <div class="enaqFooter">
        <enaqFooter></enaqFooter>
    </div>
    <infoMessage @close="infoMessageData=null" :infoMessageData="infoMessageData"></infoMessage>

</div>
</template>



<script>
import enaqHeader from '@/components/UI/header.vue'
import enaqFooter from '@/components/UI/footer.vue'
import infoMessage from '@/components/UI/infoMessage.vue'

import {
    languageAssigned
} from './lang/localisation.js'

export default {
    name: 'Communityportal',
    components: {
        enaqHeader,
        enaqFooter,
        infoMessage
    },
    mounted: function () {
        this.$root.$on('onChangeLanguage', function (data) {
            let currentLanguage = languageAssigned[data];
            if (currentLanguage == undefined) {
                currentLanguage = "de"
            }
            this.$store.commit('setCurrentLanguage', currentLanguage);

        }.bind(this));
    },
    data() {
        return {
            prevHeight: 0,
            infoMessageData: {},

        }
    },
    methods: {
        initInfoMessage(data) {
            this.infoMessageData = data;
        },
        onPageClick(data) {
            this.$root.$emit('pageclick', data);

        },
        beforeLeave(element) {
            this.prevHeight = getComputedStyle(element).height;
        },
        enter(element) {
            const {
                height
            } = getComputedStyle(element);

            element.style.height = this.prevHeight;

            setTimeout(() => {
                element.style.height = height;
            });
        },
        afterEnter(element) {
            element.style.height = 'auto';
        },

    },
}
</script>

<style>
.enaqSiteBackground {
    margin-left: -5vw;
    margin-right: -5vw;
    padding: 0 2vw;
    margin-top: -46px;
    margin-bottom: -20px;
    background: #3EAA0D;
    background: rgba(13, 0, 57, 1);
}

.enaqSiteBackground h1 {
    color: white;
}

.modal {
    z-index: 999 !important
}

#app {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    position: fixed;
    z-index: 60;
    width: 100vw;
    top: 0;
    max-height: 5.25rem;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

body {
    margin: 0;
}

.enaqColorModal .modal-card {
    border: 5px solid #F80084;
    border-radius: 20px;
}

.enaqColorModal .modal-card-head {
    background: #FFC400;
    font-weight: bolder;
}

.enaqGreen {
    background: #2a2 !important;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/latothin.ttf") format("ttf");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/latolight.ttf") format("ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/lato.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/latobold.ttf") format("ttf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/latoblack.ttf") format("ttf");
    font-weight: bold;
    font-style: 900;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/latothinitalic.ttf") format("ttf");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/latolightitalic.ttf") format("ttf");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/latoitalic.ttf") format("ttf");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/latobolditalic.ttf") format("ttf");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/latoblackitalic.ttf") format("ttf");
    font-weight: 900;
    font-style: italic;
}

.mainContentArea {
    min-height: 61vh;
    width: 100vw;
    padding: 0 5vw 20px 5vw;
    margin: auto;
    margin-top: 124px;
    padding-bottom: 20px
}

.enaqFooter {
    width: 100vw;
}


.cpPrimaryBackground {
    background: #164786;
}

.cpTitle {
    font-size: 3em
}

.cpTextLarge {
    font-size: 2em !important
}

.cpTextMedium {
    font-size: 1.3em!important;
    line-height: 1.5*1.3em!important
}

.cpTextSmall {
    font-size: 1.0em!important
}

html {
    font-size: 90%
}

@media (max-width: 560px) {
    .cpTitle {
        font-size: 3em;
    }
}
@media screen and (max-width: 1224px) {

    .mainContentArea {
        min-height: 61vh;
        width: 100vw;
        padding: 0 1vw 20px 1vw;
        margin: auto;
        margin-top: 135px;
        padding-bottom: 20px
    }
}

@media screen and (max-width: 600px) {
    .enaqColorModal .modal-card .modal-card-head .modal-card-title {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 400px) {

    html {
        font-size: 14px !important;
    }
}
</style>