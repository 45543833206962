<template>
  <div>
    <div :class="farbe">
      <header class="card-header has-text-centered">
        <p class="card-header-title has-text-centered">{{title}}</p>
      </header>
      <div class="card-content" :serviceCardTooltip="text" flow="down">
        <div class="content">
          {{text}}
        </div>
      </div>
      <footer class="card-footer">
        <a href="#" class="card-footer-item" @click="$emit('open')">Öffnen</a>
        <a href="#" class="card-footer-item" @click="$emit('edit')">Bearbeiten</a>
        <a href="#" class="card-footer-item" @click="$emit('privacy')">Datenschutz</a>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "service5",
  data() {
    return {
      farbe: "orangecard",
      title: "Supermarkt",
      text: "Sortiment und Angebote aus nahegelegenen Supermärkten",
    };
  },
};
</script>