<template>
<div class="component">
<h1 class="title ">Umfragen</h1>
       <div class="has-text-right" v-if="choosenSurvey==undefined || choosenSurvey<0"> 
       <span class="button is-info addSurvey" @click="addSurveyModal=true">Neue Umfrage</span>

        </div>
    <div v-if="(choosenSurvey==undefined || choosenSurvey<0 ) && !addSurveyModal">
        <surveyList :isAdmin="true" @choose="chooseSurvey"></surveyList>
    </div>

    <div v-if="choosenSurvey>=0">
        <showSurvey :surveyId="choosenSurvey" @close="choosenSurvey=-1"></showSurvey>
    </div>

    <div v-if="addSurveyModal"><addSurvey @close="addSurveyModal=false"></addSurvey></div>

</div>
</template>

<script>
import surveyList from '@/components/Admin/Surveys/surveyList.vue'
import showSurvey from '@/components/Admin/Surveys/showSurvey.vue'
import addSurvey from '@/components/Admin/Surveys/addSurvey.vue'

export default {
    name: 'create_survey',
    components: {
        surveyList,
        showSurvey,
        addSurvey
    },
    props: [],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            choosenSurvey: -1,
            addSurveyModal:false,
        }
    },
    methods: {
        chooseSurvey(id) {
            this.choosenSurvey = id;
        }
    },
    computed: {},
}
</script>

<style scoped>
.addSurvey {
    font-size: 22px !important;
    margin: 20px 0%;
    font-weight: bolder;
}

.viewTitle{
    margin-top:15px;
    font-weight:bolder;
    font-size:3em
}
</style>


