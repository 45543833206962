<template>
  <div>
    <div class="filterHeader columns is-mobile is-multiline">
      <table class="table is-fullwidth">
        <div class="columns is-mobile is-multiline container">
          <div class="column is-7-desktop is-12-tablet is-12-mobile">
            <div class="filter">
              <input
                class="searchBar"
                type="text"
                v-model="search"
                placeholder="Angebote durchsuchen..."
              />
              <label>Kategorie:</label>
              <select v-model="salesType">
                <option value="-1">Alles</option>
                <option value="0">Verkaufe</option>
                <option value="1">Kaufe/Suche</option>
                <option value="2">Tausche</option>
                <option value="3">Verschenke</option>
                <option value="4">Anderes</option>
              </select>
              <label>Preis:</label>
              <input
                class="textField"
                type="number"
                min="0"
                v-model="priceFrom"
              />
              <span> - </span>
              <input
                class="textField"
                type="number"
                min="1"
                v-model="priceTo"
              />
              <span>€</span>
            </div>
          </div>
          <div
            class="column is-5-desktop is-12-tablet is-12-mobile has-text-left"
          >
            <enaqbutton
              :width="'200px'"
              :backgroundcolor="'#e9a12d'"
              @click="resetFilter()"
              >Filter zurücksetzen</enaqbutton
            >
          </div>
        </div>
      </table>
    </div>

    <span v-if="sales.length == 0">
      <div>Keine Angebote vorhanden.</div>
    </span>
    <span v-else>
      <div class="columns is-mobile is-multiline">
        <div
          class="column is-3-desktop is-12-tablet is-12-mobile"
          v-for="sale in filteredSales"
          :key="sale.id"
        >
          <div class="saleItem">
            <div v-if="sale.images.length > 0">
              <div class="saleImage">
                <img class="salePreviewImage" :src="getSaleImage(sale)" />
                <div class="salePrice">
                  {{ getSalePrice(sale) }}
                </div>
              </div>
            </div>
            <div v-else>
              <div class="saleImage">
                <img
                  class="salePreviewImage"
                  alt="Icon"
                  :src="require(`@/assets/icons/image-regular-2.svg`)"
                  width="150px"
                />
                <div class="salePrice">
                  {{ getSalePrice(sale) }}
                </div>
              </div>
            </div>

            <div class="saleContent">
              <div class="saleHeader">
                <div class="saleTitle">
                  {{ getSaleType(sale) }}: {{ sale.title }}
                </div>
              </div>
              <div class="saleDescription">
                {{ sale.description }}
              </div>
              <div v-if="areOwnSales == true">
                <enaqbutton :margin="'15px'" @click="editSale(sale)">
                  Bearbeiten
                </enaqbutton>
              </div>
              <div v-else>
                <enaqbutton :margin="'15px'" @click="selectSale(sale)">
                  Mehr Infos
                </enaqbutton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>

    <div v-if="saleToShow != undefined">
      <showsale @close="saleToShow = null" :sale="saleToShow"></showsale>
    </div>

    <div v-if="saleToEdit != undefined">
      <editSale
        @close="resetSaleList"
        :ownSale="saleToEdit"
        @refreshSale="refreshSale"
      ></editSale>
    </div>
  </div>
</template>

<script>
import showsale from "@/components/Meetingpoint/Market/showSale.vue";
import editSale from "@/components/Meetingpoint/Market/editSale.vue";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "salesList",
  props: ["areOwnSales"],
  components: {
    showsale,
    editSale,
    enaqbutton,
  },
  mounted: function () {
    this.getSales();
    if (this.areOwnSales == true) {
      this.getOwnSales();
    } else {
      this.getSales();
    }
  },
  data() {
    return {
      data: [],
      sales: [],
      selectedSale: "",
      openModal: false,
      sale: null,
      priceFrom: "",
      priceTo: "",
      salesType: -1,
      search: "",
      saleToShow: null,
      saleToEdit: null,
    };
  },
  methods: {
    resetSaleList() {
      this.saleToEdit = null;
      this.getOwnSales();
    },
    resetFilter() {
      this.priceFrom = "";
      this.priceTo = "";
      this.salesType = "-1";
      this.search = "";
    },
    refreshSale() {
      var sale = this.saleToEdit;
      this.HTTPRequest()
        .getSale(this.saleToEdit.id)
        .then(
          function (result) {
            var index = this.sales
              .map(function (e) {
                return e.id;
              })
              .indexOf(sale.id);
            this.sales.splice(index, 1);
            this.sales.splice(index, 0, result);
          }.bind(this, sale)
        );
    },
    refreshImages() {
      this.HTTPRequest()
        .getOwnSales("/sales/own")
        .then(
          function (result) {
            var currentSale = result.data.sales.filter((obj) => {
              return obj.id == this.saleToEdit.id;
            });
            this.selectedOwnSale = currentSale[0];
          }.bind(this)
        );
    },
    getSales() {
      this.HTTPRequest()
        .getSales()
        .then(
          function (result) {
            this.sales = result.data.sales;
          }.bind(this)
        );
    },
    getOwnSales() {
      this.HTTPRequest()
        .getOwnSales()
        .then(
          function (result) {
            this.sales = result.data.sales;
          }.bind(this)
        );
    },
    getSalePrice(sale) {
      if (sale.price != 0 && sale.price != undefined && sale.price != null) {
        return sale.price + ",00 €";
      } else if (
        sale.individual_reward != "" &&
        sale.individual_reward != 0 &&
        sale.individual_reward != undefined &&
        sale.individual_reward != null
      ) {
        return this.$t("enaq_translation_market_individual_price");
      } else if (
        sale.price == 0 &&
        sale.price != undefined &&
        sale.price != null
      ) {
        return this.$t("enaq_translation_market_free");
      }
      {
        return this.$t("enaq_translation_market_no_price");
      }
    },
    selectSale(sale) {
      this.saleToShow = sale;
      this.saleToEdit = null;
    },
    editSale(sale) {
      this.saleToShow = null;
      this.saleToEdit = JSON.parse(JSON.stringify(sale));
    },
    getSaleImage(sale) {
      var imagePath = "";
      if (sale.images != null && sale.images.length > 0) {
        imagePath += sale.images[0].imagepath;
      } else {
        imagePath += "/sales/default.png";
      }
      return this.$storageURL + imagePath;
    },
    getSaleType(sale) {
      var saleType = parseInt(sale.type);
      switch (saleType) {
        case 0:
          return this.$t("enaq_translation_market_new_offer_type_sell");
        case 1:
          return this.$t("enaq_translation_market_new_offer_type_buy");
        case 2:
          return this.$t("enaq_translation_market_new_offer_type_trade");
        case 3:
          return this.$t("enaq_translation_market_new_offer_type_gift");
        case 4:
          return this.$t("enaq_translation_market_new_offer_type_other");
        default:
      }
    },
  },
  computed: {
    filteredSales: function () {
      var vm = this;
      var type = vm.salesType;
      var priceFrom = vm.priceFrom;
      var priceTo = vm.priceTo;
      var searchText = vm.search;

      if (searchText != "" && searchText != undefined && searchText != null) {
        if (
          priceFrom != "" &&
          priceFrom >= 0 &&
          priceFrom != undefined &&
          priceFrom != null &&
          priceTo != "" &&
          priceTo >= 0 &&
          priceTo != undefined &&
          priceTo != null
        ) {
          switch (type) {
            case "0":
              return vm.sales.filter(function (sale) {
                return (
                  sale.title.toLowerCase().match(searchText.toLowerCase()) &&
                  sale.type === 0 &&
                  sale.price >= priceFrom &&
                  sale.price <= priceTo
                );
              });
            case "1":
              return vm.sales.filter(function (sale) {
                return (
                  sale.title.toLowerCase().match(searchText.toLowerCase()) &&
                  sale.type === 1 &&
                  sale.price >= priceFrom &&
                  sale.price <= priceTo
                );
              });
            case "2":
              return vm.sales.filter(function (sale) {
                return (
                  sale.title.toLowerCase().match(searchText.toLowerCase()) &&
                  sale.type === 2 &&
                  sale.price >= priceFrom &&
                  sale.price <= priceTo
                );
              });
            case "3":
              return vm.sales.filter(function (sale) {
                return (
                  sale.title.toLowerCase().match(searchText.toLowerCase()) &&
                  sale.type === 3 &&
                  sale.price >= priceFrom &&
                  sale.price <= priceTo
                );
              });
            case "4":
              return vm.sales.filter(function (sale) {
                return (
                  sale.title.toLowerCase().match(searchText.toLowerCase()) &&
                  sale.type === 4 &&
                  sale.price >= priceFrom &&
                  sale.price <= priceTo
                );
              });
            default:
              return vm.sales.filter(function (sale) {
                return (
                  sale.title.toLowerCase().match(searchText.toLowerCase()) &&
                  sale.price >= priceFrom &&
                  sale.price <= priceTo
                );
              });
          }
        } else {
          switch (type) {
            case "0":
              return vm.sales.filter(function (sale) {
                return (
                  sale.title.toLowerCase().match(searchText.toLowerCase()) &&
                  sale.type === 0
                );
              });
            case "1":
              return vm.sales.filter(function (sale) {
                return (
                  sale.title.toLowerCase().match(searchText.toLowerCase()) &&
                  sale.type === 1
                );
              });
            case "2":
              return vm.sales.filter(function (sale) {
                return (
                  sale.title.toLowerCase().match(searchText.toLowerCase()) &&
                  sale.type === 2
                );
              });
            case "3":
              return vm.sales.filter(function (sale) {
                return (
                  sale.title.toLowerCase().match(searchText.toLowerCase()) &&
                  sale.type === 3
                );
              });
            case "4":
              return vm.sales.filter(function (sale) {
                return (
                  sale.title.toLowerCase().match(searchText.toLowerCase()) &&
                  sale.type === 4
                );
              });
            default:
              return vm.sales.filter(function (sale) {
                return sale.title.toLowerCase().match(searchText.toLowerCase());
              });
          }
        }
      } else {
        if (
          priceFrom != "" &&
          priceFrom >= 0 &&
          priceFrom != undefined &&
          priceFrom != null &&
          priceTo != "" &&
          priceTo >= 0 &&
          priceTo != undefined &&
          priceTo != null
        ) {
          switch (type) {
            case "0":
              return vm.sales.filter(function (sale) {
                return (
                  sale.type === 0 &&
                  sale.price >= priceFrom &&
                  sale.price <= priceTo
                );
              });
            case "1":
              return vm.sales.filter(function (sale) {
                return (
                  sale.type === 1 &&
                  sale.price >= priceFrom &&
                  sale.price <= priceTo
                );
              });
            case "2":
              return vm.sales.filter(function (sale) {
                return (
                  sale.type === 2 &&
                  sale.price >= priceFrom &&
                  sale.price <= priceTo
                );
              });
            case "3":
              return vm.sales.filter(function (sale) {
                return (
                  sale.type === 3 &&
                  sale.price >= priceFrom &&
                  sale.price <= priceTo
                );
              });
            case "4":
              return vm.sales.filter(function (sale) {
                return (
                  sale.type === 4 &&
                  sale.price >= priceFrom &&
                  sale.price <= priceTo
                );
              });
            default:
              return vm.sales.filter(function (sale) {
                return sale.price >= priceFrom && sale.price <= priceTo;
              });
          }
        } else {
          switch (type) {
            case "0":
              return vm.sales.filter(function (sale) {
                return sale.type === 0;
              });
            case "1":
              return vm.sales.filter(function (sale) {
                return sale.type === 1;
              });
            case "2":
              return vm.sales.filter(function (sale) {
                return sale.type === 2;
              });
            case "3":
              return vm.sales.filter(function (sale) {
                return sale.type === 3;
              });
            case "4":
              return vm.sales.filter(function (sale) {
                return sale.type === 4;
              });
            default:
              return vm.sales;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
/* Filter Style*/
.textField {
  width: 75px;
}
.container {
  padding: 15px;
  font-size: 1.2rem;
}
label {
  margin: 0px 5px 0px 15px;
}
.resetButton {
  cursor: pointer;
  background-color: #e9a12d;
  width: auto;
  padding: 5px 10px 5px 10px;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  margin-left: 10px;
}
.searchBar {
  outline: none;
}

/* saleList Style */
.saleItem {
  cursor: default;
  box-shadow: -10px 10px 15px #777777;
  border-radius: 30px;
  overflow: hidden;
}
.saleImage {
  background: #ffffff;
  padding-top: 10px;
  position: relative;
}
.salePreviewImage {
  background: #ffffff;
  height: 20vh;
}
.saleHeader:after {
  content: "";
  border-bottom: 1px solid #ffffff;
  width: 90%;
  display: inline-block;
}
.saleTitle {
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  height: 5rem;
  overflow: hidden;
}
.salePrice {
  color: #ffffff;
  width: 40%;
  background: #e9a12d;
  border-radius: 20px;
  line-height: 2em;
  font-weight: bolder;
  position: absolute;
  left: 65%;
  top: 75%;
}
.saleContent {
  background: #1c63af;
}
.saleDescription {
  color: #ffffff;
  text-align: start;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
  height: 5rem;
  overflow: hidden;
}
.saleInfoButton {
  cursor: pointer;
  background-color: #e9a12d;
  padding: 5px 10px 5px 10px;
  color: #ffffff;
  border-color: #1c63af;
  border-radius: 50px;
  margin: 20px;
}
</style>
