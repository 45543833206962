<template>
  <div class="newSaleModal">
    <div class="modal is-active">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Füge ein Bild hinzu</p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal"
          ></button>
        </header>
        <section class="modal-card-body">
          <div
            class=""
            v-if="
              this.imageData != null &&
              this.imageData != undefined &&
              this.imageData != ''
            "
          >
            <img :src="this.imageData" />
          </div>
          <input type="file" @change="onFileChanged" />
        </section>
        <footer class="modal-card-foot">
          <enaqbutton :margin="'5px'" @click="onUpload">Speichern</enaqbutton>
          <enaqbutton :margin="'5px'" @click="closeModal">Abbrechen</enaqbutton>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "newsale",
  props: ["saleId"],
  components: { enaqbutton },
  mounted: function () {},
  data() {
    return {
      data: [],
      selectedFile: null,
      imageData: null,
    };
  },
  methods: {
    onFileChanged(event) {
      this.previewImage(event);
      this.selectedFile = event.target.files[0];
    },

    onUpload() {
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };
      if (this.saleId == undefined || this.saleId == "") {
        return;
      }
      const formData = new FormData();
      formData.append("image", this.selectedFile, this.selectedFile.name);

      this.$axios
        .post(
          this.$backendURL + "/sales/" + this.saleId + "/image/add",
          formData,
          {
            headers: headerData,
          }
        )
        .then(
          function (response) {
            this.$emit("refreshAddedImages");
            this.closeModal();
          }.bind(this)
        );
    },
    updateDescription() {
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };

      let postData = {
        description: this.profile.description,
      };
      this.$axios
        .post(this.$backendURL + "/user/edit/description", postData, {
          headers: headerData,
        })
        .then(
          function (response) {
            this.$router.go();
          }.bind(this)
        );
    },
    previewImage: function (event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
  computed: {
    salesGroup() {
      let group = [];
      var counter = 0;
      if (this.sales.length < 4) {
        return [this.sales];
      }
      while (counter < this.sales.length - 2) {
        let triple = [];
        triple.push(this.sales[counter]);
        counter++;
        triple.push(this.sales[counter]);
        counter++;
        triple.push(this.sales[counter]);
        counter++;
        group.push(triple);
      }

      return group;
    },
  },
};
</script>

<style scoped>
.textareaPartial {
  min-width: 0px;
  margin-bottom: 0.5em;
}

.errorFormField {
  border: 1px solid red;
}
</style>
