export default {
    data() {
        return {

        }

    },
    created: function() {},
    methods: {
        /**
         * type: post/get
         * endpoint: url
         * header: json
         * payload: data
         * 
         * Calls an api. Used for communication with backend, so that we dont need to add the authorization-header manually on
         * every request.
         */
        async callApi(endpoint, type = 'get', payload = {}, header = null, showError = true, customEndpoint = false) {
            //    alert("callapimixin")
            var promise = new Promise(function(resolve, reject) {
                let headerData = header;

                //Only add authorization-header when backend is called
                if (header == null && !customEndpoint) {
                    headerData = {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.bearertoken
                    }
                }
                /*console.log("headerdata: " + JSON.stringify(headerData))*/
                let apiUrl = this.$backendURL + endpoint;
                if (customEndpoint) {
                    apiUrl = endpoint
                }
                if (type == 'post') {
                    this.$axios.post(apiUrl, payload, {
                        headers: headerData
                    }).then(function(result) {
                        this.checkForError(result)
                        resolve(result);

                    }.bind(this)).catch(function(error) {

                        this.checkForError(error, 'server')

                        reject(error);
                    }.bind(this))
                } else {
                    this.$axios.get(apiUrl, {
                        headers: headerData,
                        data: payload
                    }).then(function(result) {
                        this.checkForError(result)

                        resolve(result);

                    }.bind(this)).catch(function(error) {
                        reject(error);
                        this.checkForError(error, 'server')

                    }.bind(this))
                }
            }.bind(this));
            return promise;
        },
        SPRequest() {
            let callApi = this.callApi
            let customHeader = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': "940b4f1deb6946ee99ab971bf84d28c2",
                'Authorization': 'Bearer ' + localStorage.spAuthToken
            }
            let btcRootURL = "https://apim-enaq-serivces-dev.azure-api.net/broker"
            return {
                async getAllApps() {
                    return callApi(btcRootURL + "/user/apps/all", "get", {}, customHeader, true, true);
                },
                async getAppDetails(appID) {
                    return callApi(btcRootURL + "/user/app/" + appID, "get", {}, customHeader, true, true);
                },

                async consentToApp(appID) {
                    let postData = {
                        app_Id: appID,
                    }
                    return callApi(btcRootURL + "/user/app/consent", "post", postData, customHeader, true, true);
                },
                async revokeAppConsent(appID) {
                    let postData = {
                        app_Id: appID,
                    }
                    return callApi(btcRootURL + "/user/app/revoke", "post", postData, customHeader, true, true);
                },


            }
        },
        HTTPRequest() {
            let callApi = this.callApi
            return {
                async getDeviceList() {
                    var promise = new Promise(function(resolve, reject) {
                        setTimeout(function() {
                            resolve();

                        }, 5000);
                    });
                    return promise;
                },

                //--- Groups --//

                async getAllGroups() {
                    return callApi("/group/all")
                },
                async getNewGroupMessages() {
                    var result = { data: { messages: [] } };
                    return result;
                    return callApi("/group/messages/new");
                },

                //--- User --//

                async getAllHighscoreData() {
                    return callApi("/user/highscore");
                },
                async getUserMessages() {
                    return callApi("/message/own");
                },

                //--- Profile --//

                async getProfile(userID) {
                    return callApi("/user/" + userID, "get");
                },

                async onUpload(selectedFile) {
                    let postData = {
                        avatar: selectedFile,
                    }

                    return callApi("/user/edit/avatar", postData);
                },

                async delete_own_avatar() {
                    return callApi("/user/delete/avatar", "post");
                },

                //--- Announcements --//

                async getAnnouncements(amount = 5) {
                    let postData = {
                        amount: this.announcementCount
                    }
                    return callApi("/announcement/get", "post", postData)
                },
                async getAllAnnouncements(amount = 5) {
                    let postData = {
                        amount: this.announcementCount
                    }
                    return callApi("/announcement/all", "post", postData)
                },
                async getAnnouncementPage(amount, page) {

                    return callApi("/announcement/paginate?page=" + page + "&amount=" + amount)
                },

                //--- Service --//

                async getVisibleServices() {

                    let postData = {
                        visible: true
                    }
                    return callApi("/service/settings/visibility/get", "post", postData)
                },

                async getHiddenServices() {

                    let postData = {
                        visible: false
                    }
                    return callApi("/service/settings/visibility/get", "post", postData)
                },
                async setServiceVisibility(serviceID, visibility) {

                    let postData = {
                        serviceid: serviceID,
                        visible: visibility
                    }
                    return callApi("/service/settings/visibility/edit", "post", postData)
                },

                //--- Sales --//

                async getSale(id) {

                    return callApi("/sales/get/" + id);
                },
                async getSales() {
                    return callApi("/sales/get");
                },
                async getOwnSales() {
                    return callApi("/sales/own");
                },
                async deleteSale(id) {
                    return callApi("/sales/" + id + "/delete", "post");
                },
                async closeSale(id) {
                    return callApi("/sales/" + id + "/close", "post");
                },
                async removeSaleImage(sale, image) {
                    let postData = {
                        imageID: image
                    }
                    return callApi("/sales/" + sale + "/image/remove", "post", postData);
                },
                async updateSale(sale) {
                    let postData = {
                        title: sale.title,
                        description: sale.description,
                        type: sale.type,
                        price: sale.price,
                    };
                    console.log("PostData:" + JSON.stringify(postData));
                    return callApi("/sales/" + sale.id + "/update", "post", postData);
                },
                //--- Categories --//

                async getAllCategories() {


                    return callApi("/events/categories/", "post");
                },
                async deleteCategory(id) {
                    let postData = {
                        categorie_id: id
                    }

                    return callApi("/events/categories/delete/", "post", postData);
                },

                async createCategory(name, description) {
                    let postData = {
                        category_name: name,
                        category_description: description
                    }

                    return callApi("/events/categories/create", "post", postData);
                },
                //--- Surveys --//

                async deleteSurvey(id) {
                    let postData = {
                        survey_id: id
                    }

                    return callApi("/survey/delete/", "post", postData);
                },
                async closeSurvey(id) {
                    let postData = {
                        survey_id: id
                    }

                    return callApi("/survey/close/", "post", postData);
                },
                async openSurvey(id) {
                    let postData = {
                        survey_id: id
                    }

                    return callApi("/survey/open/", "post", postData);
                },
                async deleteQuestion(id) {
                    let postData = {
                        question_id: id
                    }

                    return callApi("/survey/question/delete/", "post", postData);
                },
                async deleteAnswer(id) {
                    let postData = {
                        answer_id: id
                    }

                    return callApi("/survey/question/answer/delete", "post", postData);
                },

                //--- Calendar || Events ---//

                async getEvents(eventYear, eventMonth) {
                    let postData = {
                        year: eventYear + "",
                        month: eventMonth + ""
                    }
                    return callApi("/events/get", "post", postData)
                },

                async getOwnEvents() {
                    return callApi("/events/own", "get")
                },

                async getParticipatedEvents() {
                    return callApi("/events/participated")
                },

                async joinEvent(id) {
                    return callApi("/events/" + id + "/join", "post")
                },

                async leaveEvent(id) {
                    return callApi("/events/" + id + "/leave", "post")
                },

                async deleteEvent(id) {
                    return callApi("/events/" + id + "/delete", "post")
                },

                async getEventTypes() {
                    return callApi("/events/types");
                },

                async createEventType(newEventType) {
                    let postData = {
                        name: newEventType,
                    }
                    return callApi("/events/types/create", postData);
                },

                async getAcceptedMembers(id) {
                    return callApi("/events/" + id + "/member", "post")
                },

                async getMembershipRequests(id) {
                    return callApi("/events/" + id + "/requests", "post")
                },

                async acceptMember(eventID, userID) {
                    let postData = {
                        member: userID
                    }
                    return callApi("/events/" + eventID + "/accept", "post", postData)
                },

                async removeMember(eventID, userID) {
                    let postData = {
                        member: userID
                    }
                    return callApi("/events/" + eventID + "/remove", "post", postData)
                },

                async createEvent(eventName, eventDescription, maxMember, price, needsManualParticipateConfirmation, begin, end, selectedEventTypes, room, place) {
                    let postData = {
                        title: eventName,
                        description: eventDescription,
                        maxMember: maxMember,
                        price: price,
                        needsManualParticipateConfirmation: needsManualParticipateConfirmation,
                        begin: begin,
                        end: end,
                        eventTypes: selectedEventTypes,
                        room: room,
                        place: place,
                    }
                    return callApi("/events/create", "post", postData)
                },

                async updateEvent(eventID, eventName, eventDescription, maxMember, price, needsManualParticipateConfirmation, begin, end, selectedEventTypes, room, place) {
                    let postData = {
                        title: eventName,
                        description: eventDescription,
                        maxMember: maxMember,
                        price: price,
                        needsManualParticipateConfirmation: needsManualParticipateConfirmation,
                        begin: begin,
                        end: end,
                        eventTypes: selectedEventTypes,
                        room: room,
                        place: place,
                    }
                    return callApi("/events/" + eventID + "/update", "post", postData)
                },

                //--- editEvent ---//

                async getRooms() {
                    return callApi("/rooms/all", "get")
                },

                async checkRoom(beginTime, endTime, room, eventtoupdate) {
                    let postData = {
                        begin: beginTime,
                        end: endTime,
                        eventtoupdate: eventtoupdate,
                    }
                    return callApi("/rooms/available/" + room, "post", postData)
                },

                //--- Nachbarschaftshilfe / NBH ---//

                async getAllNBHs() {
                    return callApi("/nbh/get")
                },
                async createNBH(title, description, reward, type = "nbh") {
                    var nbhData = {
                        "title": title,
                        'description': description,
                        'reward': reward,
                        'type': type
                    }
                    var postdata = {
                        "nbh": nbhData
                    }
                    return callApi("/nbh/create", "post", postdata)
                },
                async deleteNBH(id) {
                    return callApi("/nbh/" + id + "/delete", "post")
                },
                async resolveNBH(userId, nbhId) {
                    var postData = {
                        'helpedBy': userId
                    }
                    return callApi("/nbh/" + nbhId + "/resolve", "post", postData)
                },

                // ------------------------//
                //--- Serviceplattform ---//
                // ------------------------//


                //--- Idealer Vebrauch ---//

                async getIdealCOnsumption(dayTimestamp, token) {
                    var date = new Date(dayTimestamp);


                    var url = "https://incentivierungfunction.azurewebsites.net/api/Incentivierung_Trigger?von=1556017200&bis=1556018100&token=" + token
                }

            }
        }
    }
};