<template>
<div class="component enaqFeedbackForm">
    <!--
    <div class="feedbackForm">
        <div class="field">
            <label class="label">{{$t('enaq_translation_feedback_form_label_title')}}</label>
            <div class="control">
                <input class="input" :class="{'inputError':formErrors.includes('feedbackTitle')}" type="text" placeholder="" v-model="feedback.title">
            </div>
        </div>

        <div class="field">
            <label class="label">{{$t('enaq_translation_feedback_form_label_mail')}}</label>
            <div class="control">
                <input class="input" :class="{'inputError':formErrors.includes('feedbackMail')}" type="mail" placeholder="" v-model="feedback.email">
            </div>
        </div>

        <div class="field">
            <label class="label">{{$t('enaq_translation_feedback_form_label_description')}}</label>
            <div class="control">
                <textarea v-model="feedback.message" :class="{'inputError':formErrors.includes('feedbackContent')}" class="textarea" placeholder=""></textarea>
            </div>
        </div>

        <div class="button is-success" @click="sendFeedback()">{{$t('enaq_translation_feedback_send')}}</div>
    </div>
    -->
    <div class=" has-text-left enaqFeedbackTitle">Kontakt</div>

    <div class="columns is-mobile is-multinine">

        <div class="column is-5 is-offset-1-tablet is-12-mobile  ">

            <div class="field">
                <label class="label">{{$t('enaq_translation_feedback_form_label_mail')}}</label>
                <div class="control">
                    <input class="input" :class="{'inputError':formErrors.includes('feedbackMail')}" type="mail" placeholder="" v-model="feedback.email">
                </div>
            </div>

            <div class="field">
                <label class="label">{{$t('enaq_translation_feedback_form_label_title')}}</label>
                <div class="control">
                    <input class="input" :class="{'inputError':formErrors.includes('feedbackTitle')}" type="text" placeholder="" v-model="feedback.title">
                </div>
            </div>

            <div class="field">
                <label class="label">{{$t('enaq_translation_feedback_form_label_description')}}</label>
                <div class="control">
                    <textarea v-model="feedback.message" :class="{'inputError':formErrors.includes('feedbackContent')}" class="textarea" placeholder="" rows="10"></textarea>
                </div>
            </div>
            <div class="enaqFeedbackSendButton">
                <div class="button is-success" @click="sendFeedback()">{{$t('enaq_translation_feedback_send')}}</div>
            </div>
        </div>

        <div class="column is-5 is-12-mobile">
            <div class="enaqGMap">
                <iframe width="100%" height="100%" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA5GjrZCUkiOJYF76A3yp86Qgg68yeL96c&q=Fliegerhorst+Oldenburg++Alexanderstraße++Oldenburg&zoom=14" allowfullscreen></iframe>
            </div>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'feedback',
    props: [],
    mounted: function () {

    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            feedback: {
                title: '',
                message: '',
                email: ''
            },
            formErrors: '',
            center: {
                lat: 45.508,
                lng: -73.587
            },
            markers: [],
            places: [],
            currentPlace: null,
            mapOpen: true,
            markers: []
        }
    },
    methods: {
        sendFeedback() {
            this.validateInput();
            if (this.formErrors.length > 0) {
                return;
            }
            this.sendInfoMessage({
                'loading': true
            })
            this.callApi("/feedback/send", 'post', {
                feedback: this.feedback
            }).then(function (response) {
                this.sendInfoMessage({
                    'text': this.$t('enaq_translation_feedback_send_ok')
                })

                this.feedback = {
                    title: '',
                    message: '',
                    email: ''
                }
            }.bind(this))
        },
        validateInput() {
            this.formErrors = [];
            if (this.feedback.title == '') {
                this.formErrors.push('feedbackTitle')
            }

            if (this.feedback.email == '') {
                this.formErrors.push('feedbackMail')
            }

            if (this.feedback.message == '') {
                this.formErrors.push('feedbackContent')
            }
        },

        geolocate() {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
            });
        }
    },
    computed: {},
}
</script>

<style scoped>
.feedbackForm {
    width: 90%;
    margin: auto;
}

.inputError {
    border: 1px solid red;
}

.enaqGMap {
    height: 600px;
    width: 80%
}

.enaqFeedbackForm {
    text-align: left
}

.enaqFeedbackTitle {
    width: 83.3%;
    margin: auto;
    font-size: 2.5em;

    font-size: 50px;
    line-height: 60px;
    font-weight: 700;
    letter-spacing: 0px;
    margin-bottom: 1em;
}

.enaqGMap iframe {
    height: 100%
}

.enaqFeedbackSendButton .button {
    background-color: #f6ae2d;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 50px;
    border-width: 0;
    box-shadow: unset;
    -webkit-appearance: none;
    cursor: pointer;
    padding: 20px 35px;
}

.enaqFeedbackSendButton .button:hover{
        background-color: #f6ae2d;

}

.enaqFeedbackForm label{margin-bottom:0;}
.enaqFeedbackTitle:before{
    content:"";
    position:absolute;
    border-bottom: 2px solid #F8AD00;
    width:50px;
    height:2px;
    margin-top:-35px;
}
</style>
