<template>
  <div class="newSaleModal">
    <div class="modal is-active">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Neuen Markteintrag erstellen</p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-mobile is-multiline">
            <label class="label inputBox column is-3">Titel</label>
            <div class="column is-9">
              <input
                class="input"
                :class="{ errorFormField: inputHasError('title') }"
                v-model="saleTitle"
              />
            </div>

            <label class="label inputBox column is-3">Art</label>

            <div class="column is-9">
              <div class="select">
                <select v-model="salesType">
                  <option value="0">Verkaufe</option>
                  <option value="1">Kaufe/Suche</option>
                  <option value="2">Tausche</option>
                  <option value="3">Verschenke</option>
                  <option value="4">Anderes</option>
                </select>
              </div>
            </div>

            <label class="label inputBox column is-3">Beschreibung</label>
            <textarea
              v-model="saleDescription"
              class="textarea textareaPartial column is-9"
              :class="{ errorFormField: inputHasError('description') }"
            ></textarea>

            <div class="is-12 column">
              <div class="columns is-mobile is-multiline is-gapless">
                <label class="label inputBox column is-3"
                  >Individueller Preis</label
                >
                <div class="column is-9">
                  <slidebutton
                    :default="hasIndividualPrice"
                    @toggle="hasIndividualPrice = !hasIndividualPrice"
                  ></slidebutton>
                </div>

                <div class="column is-12"><br /></div>

                <label class="label inputBox column is-3"
                  >Preis <span v-if="!hasIndividualPrice">( € )</span></label
                >
                <div class="column is-9">
                  <input
                    :class="{ errorFormField: inputHasError('price') }"
                    v-if="hasIndividualPrice"
                    v-model="saleCustomPrice"
                    class="input"
                  />
                  <input
                    :class="{ errorFormField: inputHasError('price') }"
                    type="number"
                    v-if="!hasIndividualPrice"
                    v-model="salePrice"
                    class="input"
                  />
                </div>
              </div>
            </div>

            <label class="label inputBox column is-3">Zeitraum</label>
            <div class="column is-9">
              <div class="select">
                <select v-model="saleOpenUntil">
                  <option v-for="day in 14" :value="day" :key="day.id">
                    {{ day }} Tag<span v-if="day != 1">e</span>
                  </option>
                </select>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <enaqbutton :margin="'5px'" @click="createSale">
            Erstellen
          </enaqbutton>
          <enaqbutton
            :margin="'5px'"
            :backgroundcolor="'#808080'"
            @click="closeModal"
            >Abbrechen</enaqbutton
          >
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import slidebutton from "@/components/helper/slidebutton.vue";
import moment from "moment";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "newsale",
  props: [],
  components: {
    slidebutton,
    enaqbutton,
  },
  mounted: function () {},
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      saleTitle: "",
      saleDescription: "",
      salesType: 0,
      hasIndividualPrice: false,
      salePrice: "0",
      saleCustomPrice: "",
      saleOpenUntil: 5,
      formErrors: [],
    };
  },
  methods: {
    getSales() {
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };

      let postData = {};
      this.$axios
        .get(this.$backendURL + "/sales/get", {
          headers: headerData,
        })
        .then(
          function (result) {
            this.sales = result.data.sales;
          }.bind(this)
        );
    },
    createSale() {
      this.validateInput();
      if (this.formErrors.length > 0) {
        return;
      }

      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };

      let postData = {
        title: this.saleTitle,
        description: this.saleDescription,
        open_until: this.parseDate(),
        type: this.salesType,
      };
      if (this.hasIndividualPrice) {
        postData.individual_reward = this.saleCustomPrice;
      } else {
        postData.price = this.salePrice;
      }

      this.$axios
        .post(this.$backendURL + "/sales/create", postData, {
          headers: headerData,
        })
        .then(
          function (result) {
            this.closeModal();
          }.bind(this)
        );
    },
    validateInput() {
      this.formErrors = [];
      if (this.saleTitle == "") {
        this.formErrors.push("title");
      }
      if (this.saleDescription == "") {
        this.formErrors.push("description");
      }
      if (this.hasIndividualPrice && this.saleCustomPrice == "") {
        this.formErrors.push("price");
      }

      if (
        !this.hasIndividualPrice &&
        (this.salePrice == "" || this.salePrice < 0)
      ) {
        this.formErrors.push("price");
      }
    },
    inputHasError(name) {
      if (this.formErrors.includes(name)) {
        return true;
      }
      return false;
    },
    parseDate() {
      let dateString = moment()
        .add(this.saleOpenUntil, "days")
        .format("YYYY-MM-DD");

      dateString += " 00:00:00";
      return dateString;
    },
    closeModal() {
      this.$emit("close");
    },
  },
  computed: {
    salesGroup() {
      let group = [];
      var counter = 0;
      if (this.sales.length < 4) {
        return [this.sales];
      }
      while (counter < this.sales.length - 2) {
        let triple = [];
        triple.push(this.sales[counter]);
        counter++;
        triple.push(this.sales[counter]);
        counter++;
        triple.push(this.sales[counter]);
        counter++;
        group.push(triple);
      }

      return group;
    },
  },
};
</script>

<style scoped>
.textareaPartial {
  min-width: 0px;
  margin-bottom: 0.5em;
}

.errorFormField {
  border: 1px solid red;
}
</style>
