<template>
  <div id="app">
    <div>
      <br />
      <label>
        <input
          type="search"
          list="Buildings"
          v-model="searchValue"
          placeholder="Suche..."
          id="searchbar"
        />
        <datalist id="Buildings">
          <option v-for="building in filteredBuildings" :key="building.title">
            {{ building.title }}
          </option>
        </datalist>
      </label>
    </div>

    <br />
    <figure>
      <svg
        version="1.1"
        width="1536"
        height="1024"
        viewBox="0 0 1536 1024"
        sodipodi:docname="TestSVG_7.svg"
        inkscape:version="1.1 (c68e22c387, 2021-05-23)"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
      >
        <image
          width="1536"
          height="1024"
          :href="require(`@/assets/plan.png`)"
        ></image>

        <g transform="matrix(0.48227725,0,0,0.48227725,224.01371,-495.92253)">
          <path
            name="path"
            id="Gemeinschaftliches Wohnen"
            d="M 924.23242 314.4668 L 924.23242 487.76758 L 1105.373 486.94141 L 1105.373 448.56836 L 965.08203 447.33008 L 965.49414 354.49023 L 1105.373 354.49023 L 1106.1992 314.87891 L 924.23242 314.4668 z M 1029.4746 583.68945 L 923.78125 583.83594 L 923.78125 748.13672 L 1029.4004 748.17383 L 1029.291 708.23828 L 963.35156 708.01953 L 963.24219 623.88086 L 1029.1816 623.73438 L 1029.4746 583.68945 z M 1066.6387 583.78711 L 1066.6387 746.13086 L 1106.3184 746.13086 L 1106.166 583.82227 L 1066.6387 583.78711 z "
            transform="matrix(2.0734961,0,0,2.0734961,-464.49156,1028.2934)"
          />

          <rect
            x="910"
            y="513"
            rx="20"
            ry="20"
            width="215"
            height="40"
            transform="matrix(2.0734961,0,0,2.0734961,-464.49156,1028.2934)"
          />
          <text
            x="913.30859"
            y="537.96587"
            transform="matrix(2.0734961,0,0,2.0734961,-464.49156,1028.2934)"
          >
            Gemeinschaftliches Wohnen
          </text>
        </g>

        <g>
          <path
            name="path"
            d="m 1952.4083,1778.8227 0.2506,167.2512 136.5456,-0.1039 0.1986,-167.3979 -33.9202,0.8645 -0.8037,-12.488 -67.8153,0.9593 0.07,9.9555 z"
            transform="matrix(0.48227725,0,0,0.48227725,224.01371,-495.92253)"
          />
        </g>

        <g>
          <path
            name="path"
            d="m 1951.661,2038.7779 0.037,134.7698 137.5073,-0.7456 -0.4949,-134.6292 z"
            transform="matrix(0.48227725,0,0,0.48227725,224.01371,-495.92253)"
          />
        </g>

        <g>
          <path
            name="path"
            id="Studentisches Wohnen"
            d="m 1951.5528,2238.1684 0.9853,118.7772 8.9056,-0.2031 -0.2506,163.6124 10.9192,0.2506 -0.026,70.485 109.8977,-0.324 -0.3589,-32.3818 10.6946,0.1468 -0.1512,-205.2113 -10.5694,0.1512 0.099,-28.8736 -21.0563,0.2381 0.6523,-86.4948 z"
            transform="matrix(0.48227725,0,0,0.48227725,224.01371,-495.92253)"
          />
          <rect x="1140" y="658" rx="20" ry="20" width="115" height="60" />
          <text transform="translate(60.353867,29.361341)">
            <tspan x="1085.30859" y="649.96587">Studentisches</tspan>
            <tspan x="1105.30859" y="679.96587">Wohnen</tspan>
          </text>
        </g>

        <g>
          <path
            name="path"
            id="Halle 145"
            d="m 1110.2256,2013.3945 3.7369,190.9656 58.3652,-0.7885 9.5265,481.8502 115.0928,-2.9546 -9.3924,-485.4129 -16.3983,0.1803 -2.0257,-109.9321 -57.3357,0.7612 -1.0358,-76.4266 z"
            transform="matrix(0.48227725,0,0,0.48227725,224.01371,-495.92253)"
          />
          <rect x="770" y="605" rx="20" ry="20" width="95" height="40" />
          <text x="785.30859" y="629.96587">Halle 145</text>
        </g>
        <g>
          <path
            name="path"
            id="Quartiersgarage"
            d="m 308.6172,121.17603 2.7658,120.01149 161.3339,-1.96831 -2.40505,-121.41024 z"
          />
          <rect x="325" y="195" rx="20" ry="20" width="135" height="40" />
          <text x="335.30859" y="219.96587">Quartiersgarage</text>
        </g>
        <g>
          <path
            name="path"
            id="Kita"
            d="m 777.61142,43.01349 -56.39819,77.56253 56.99945,49.90458 77.80303,-0.36075 0.2405,-97.163613 z"
          />
          <rect x="790" y="125" rx="20" ry="20" width="55" height="40" />
          <text x="805.30859" y="149.96587">Kita</text>
        </g>
      </svg>
    </figure>
  </div>
</template>

<script>
export default {
  mounted: function () {},
  data() {
    return {
      searchValue: "",
      tempBuildings: "",
      buildings: [
        {
          title: "Gemeinschaftliches Wohnen",
        },
        {
          title: "Studentisches Wohnen",
        },
        {
          title: "Halle 145",
        },
        {
          title: "Quartiersgarage",
        },
        {
          title: "Kita",
        },
      ],
    };
  },
  methods: {},
  computed: {
    filteredBuildings() {
      this.tempBuildings = this.buildings;

      if (
        this.searchValue != "" &&
        this.searchValue != undefined &&
        this.searchValue != null
      ) {
        this.tempBuildings = this.tempBuildings.filter((item) => {
          return item.title
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
        this.tempBuildings.forEach(function (building) {
          document.getElementById(building.title).style.fill = "#64ff27";
        });
      }
      return this.tempBuildings;
    },
  },
  watch: {
    searchValue: function (newVal, oldVal) {
      if (newVal != oldVal) {
        var elements = document.getElementsByName("path");
        elements.forEach(function (element) {
          element.style.fill = "#50618f";
        });
      }
    },
  },
};
</script>
<style scoped>
#searchbar {
  border: 2px solid;
  border-radius: 0.5em;
  font-size: 1.4em;
  width: 10em;
  transition: width 0.5s ease-in-out;
}

#searchbar:focus {
  width: 20%;
}

path {
  stroke: #50618f;
  stroke-width: 5px;
  fill: #50618f;
  opacity: 0.5;
}

rect {
  fill: white;
  opacity: 0;
}

text {
  font-weight: bold;
  font-family: sans-serif;
  opacity: 0;
  font-size: 15px;
  fill: black;
  pointer-events: none;
}

g:hover > path {
  stroke: #000000;
  stroke-width: 5px;
}

g:hover > rect {
  opacity: 0.5;
}

g:hover > text {
  opacity: 1;
}
</style>