<template>
  <div class="component">
    <br />
    <div class="filterHeader columns is-mobile is-multiline">
      <table class="table is-fullwidth">
        <div class="columns is-mobile is-multiline container">
          <div
            class="column is-7-desktop is-12-tablet is-12-mobile has-text-right"
          >
            <label class="filterText">Status:</label>
            <select v-model="ressourceStatus">
              <option value="-1">Alle</option>
              <option value="0">Verfügbar</option>
              <option value="1">Belegt</option>
            </select>
            <input
              class="searchBar"
              type="text"
              v-model="search"
              placeholder="Ressource suchen..."
            />
          </div>
          <div
            class="column is-5-desktop is-12-tablet is-12-mobile has-text-left"
          >
            <enaqbutton
              :width="'200px'"
              :backgroundcolor="'#e9a12d'"
              @click="resetFilter()"
              >Filter zurücksetzen</enaqbutton
            >
          </div>
        </div>
      </table>
    </div>
    <div>
      <table
        class="table ressourceItem is-fullwidth"
        v-for="ressource in filteredRessources"
        :key="ressource.id"
      >
        <div class="columns rows is-mobile is-multiline">
          <div class="column is-12-desktop is-12-tablet is-12-mobile">
            <div class="ressourceHeader">
              <div class="ressourceTitle">
                {{ ressource.title }}
              </div>
            </div>
          </div>

          <div class="column is-4-desktop is-12-tablet is-12-mobile">
            <div class="cpTextSmall">
              <p v-if="ressource.available">
                {{ $t("enaq_translation_ressources_state") }} :
                <span class="free has-text-weight-bold">{{
                  $t("enaq_translation_ressources_free")
                }}</span>
              </p>
              <p v-else>
                {{ $t("enaq_translation_ressources_state") }} :
                <span class="occupied has-text-weight-bold">{{
                  $t("enaq_translation_ressources_not_free")
                }}</span>
              </p>
            </div>
          </div>
          <div class="column is-4-desktop is-12-tablet is-12-mobile">
            <div class="cpTextSmall">
              <p v-if="ressource.available">
                {{ $t("enaq_translation_ressources_free_when") }} :
                <span class="free has-text-weight-bold">{{
                  $t("enaq_translation_ressources_free_now")
                }}</span>
              </p>
              <p v-else>
                {{ $t("enaq_translation_ressources_free_when") }} :
                <span class="occupied has-text-weight-bold"
                  >{{ formatDate(ressource.openTimespots[1].begin)[0] }},
                  {{ formatDate(ressource.openTimespots[1].begin)[1] }}</span
                >
              </p>
            </div>
          </div>
          <div class="column is-4-desktop is-12-tablet is-12-mobile">
            <div class="cpTextSmall">
              <p
                class="has-text-weight-bold"
                v-if="getBookedTimeframeForUser(ressource) == null"
              >
                <enaqbutton
                  :backgroundcolor="'#30b830'"
                  :width="'150px'"
                  @click="openBookRessourceModal(ressource)"
                >
                  <i class="fas fa-edit"></i
                  >{{ $t("enaq_translation_ressources_book") }}
                </enaqbutton>
              </p>
              <p v-else class="has-text-weight-bold">
                <enaqbutton
                  :backgroundcolor="'#f06969'"
                  :width="'150px'"
                  @click="openEditRessourceModal(ressource)"
                >
                  <i class="fas fa-edit"></i>
                  {{ $t("enaq_translation_ressources_is_booked") }}
                </enaqbutton>
              </p>
            </div>
          </div>
          <div class="column is-12-desktop is-12-tablet is-12-mobile">
            <div class="ressourceDescription">
              <div class="">{{ ressource.description }}</div>
            </div>
          </div>
        </div>
      </table>
    </div>

    <div class="modal is-active" v-if="ressourceToBook != null">
      <div class="modal-background" @click="closeModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ $t("enaq_translation_ressources_book_ressource") }}
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal()"
          ></button>
        </header>
        <section class="modal-card-body bookRessourceModal">
          <div class="columns is-mobile is-multiline">
            <label class="label column is-3">{{
              $t("enaq_translation_ressources_book_ressource_date")
            }}</label>
            <datepicker
              :disabledDates="disabledDates"
              class="column is-9-desktop is-12-mobile"
              input-class="input"
              v-model="eventDate"
            ></datepicker>
          </div>
          <div
            class="columns is-mobile is-multiline"
            v-if="openTimeSpots != null"
          >
            <label class="label column is-3">{{
              $t("enaq_translation_ressources_book_ressource_from")
            }}</label>

            <div class="select column is-3" v-if="openTimeSpots != null">
              <select v-model="beginHourToBook">
                <option
                  v-for="(hour, index) in openTimeSpots.hours"
                  :key="hour.id"
                  :value="hour"
                  v-if="
                    openTimeSpots.hours[index + 1] ==
                    openTimeSpots.hours[index] + 1
                  "
                >
                  {{ hour }}
                </option>
              </select>
            </div>

            <label
              class="label column is-3"
              v-if="openTimeSpots != null && beginHourToBook != null"
              >{{
                $t("enaq_translation_ressources_book_ressource_until")
              }}</label
            >

            <div
              class="select column is-3 is-arrowless"
              v-if="openTimeSpots != null && beginHourToBook != null"
            >
              <select v-model="endHourToBook">
                <option v-for="hour in endHours" :value="hour" :key="hour.id">
                  {{ hour }}
                </option>
              </select>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <enaqbutton :margin="'5px'" @click="bookRessource()">
            {{ $t("enaq_translation_ressources_book_ressource_submit") }}
          </enaqbutton>
          <enaqbutton
            :margin="'5px'"
            :backgroundcolor="'#808080'"
            @click="closeModal()"
          >
            {{ $t("enaq_translation_ressources_book_ressource_cancel") }}
          </enaqbutton>
        </footer>
      </div>
    </div>
    <div class="modal is-active" v-if="bookedRessourceToEdit != null">
      <div class="modal-background" @click="closeModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ $t("enaq_translation_ressources_cancel_booking_title") }}
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal()"
          ></button>
        </header>
        <section class="modal-card-body">
          <table class="table">
            <tr>
              <th>
                {{ $t("enaq_translation_ressources_cancel_booking_date") }}
              </th>
              <th>
                {{ $t("enaq_translation_ressources_cancel_booking_from") }}
              </th>
              <th>
                {{ $t("enaq_translation_ressources_cancel_booking_until") }}
              </th>
              <th></th>
            </tr>
            <tr>
              <td>{{ bookedTimeframe.date }}</td>
              <td>{{ bookedTimeframe.begin }}</td>
              <td>{{ bookedTimeframe.end }}</td>
              <td>
                <enaqbutton
                  :backgroundcolor="'#f06969'"
                  :width="'150px'"
                  @click="deleteBooking(bookedTimeframe.calendar_id)"
                >
                  {{ $t("enaq_translation_ressources_cancel_booking_submit") }}
                </enaqbutton>
              </td>
            </tr>
          </table>
        </section>
        <footer class="modal-card-foot">
          <enaqbutton :backgroundcolor="'#808080'" @click="closeModal()">
            {{ $t("enaq_translation_ressources_cancel_booking_cancel") }}
          </enaqbutton>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import enaqbutton from "@/components/UI/enaqbutton.vue";
import Enaqbutton from "../../components/UI/enaqbutton.vue";

export default {
  name: "Ressources",
  props: [],
  mounted: function () {
    this.getRessources();
  },
  components: {
    Datepicker,
    moment,
    enaqbutton,
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      ressources: [],
      search: "",
      ressourceStatus: "-1",
      ressourceToBook: null,
      bookedRessourceToEdit: null,
      bookedTimeframe: null,
      eventDate: null,
      beginHourToBook: null,
      endHourToBook: null,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
    };
  },
  methods: {
    resetFilter() {
      this.search = "";
      this.ressourceStatus = "-1";
    },
    getRessources() {
      this.ressources = [];
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };
      let postData = {};
      this.$axios
        .get(this.$backendURL + "/ressource/all", {
          headers: headerData,
        })
        .then(
          function (result) {
            this.ressources = result.data.ressources;
          }.bind(this)
        );
    },

    openBookRessourceModal(ressource) {
      this.ressourceToBook = ressource;
      // alert(ressource)
    },
    openEditRessourceModal(ressource) {
      let bookedFrame = this.getBookedTimeframeForUser(ressource);
      let bookedDate = moment(bookedFrame.begin).format("YYYY-MM-DD");
      let bookedHourBegin = moment(bookedFrame.begin).format("H");
      let bookedHourEnd = moment(bookedFrame.end).format("H");

      this.bookedTimeframe = {
        date: bookedDate,
        begin: bookedHourBegin,
        end: bookedHourEnd,
        calendar_id: bookedFrame.id,
      };
      this.bookedRessourceToEdit = ressource;
    },
    getBookedTimeframeForUser(ressource) {
      if (ressource.timeslot.length == 0 || ressource.timeslot == null) {
        return null;
      }
      for (var i = 0; i < ressource.timeslot.length; i++) {
        if (
          ressource.timeslot[i].pivot.user_id == this.$store.getters.getUser.id
        ) {
          return ressource.timeslot[i];
        }
      }
      return null;
    },
    deleteBooking(timeslot_id) {
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };
      let postData = {
        timeslot_id: timeslot_id + "",
      };
      this.$axios
        .post(this.$backendURL + "/ressource/booking/cancel", postData, {
          headers: headerData,
        })
        .then(
          function (result) {
            this.bookedRessourceToEdit = null;
            this.getRessources();
          }.bind(this)
        );
    },
    closeModal() {
      this.ressourceToBook = null;
      this.beginHourToBook = null;
      this.endHourToBook = null;
      this.eventDate = null;
      this.ressourceToBook = null;
      this.bookedRessourceToEdit = null;
    },
    bookRessource() {
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };
      var bookDate = moment(this.eventDate).format("YYYY-MM-DD");

      let postData = {
        begin: bookDate + " " + this.parseHour(this.beginHourToBook) + ":00:00",
        end: bookDate + " " + this.parseHour(this.endHourToBook) + ":00:00",
      };
      this.$axios
        .post(
          this.$backendURL + "/ressource/" + this.ressourceToBook.id + "/book",
          postData,
          {
            headers: headerData,
          }
        )
        .then(
          function (result) {
            this.ressourceToBook = null;
            this.beginHourToBook = null;
            this.endHourToBook = null;
            this.eventDate = null;
            this.getRessources();
          }.bind(this)
        );
    },
    parseHour(hour) {
      hour = hour + "";
      console.log("hourlength for '" + hour + "' : " + hour.length);
      if (hour.length == 1) {
        return "0" + hour;
      } else {
        return hour;
      }
    },
    formatDate(date) {
      var formattedDate = new Date(date);
      var dateValues = [];
      var options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      dateValues.push(formattedDate.toLocaleDateString("de-DE", options));
      var optionsH = {
        hour: "numeric",
      };
      dateValues.push(
        formattedDate.getHours() + " " + this.$t("enaq_translation_events_hour")
      );

      return dateValues;
    },
  },
  computed: {
    filteredRessources: function () {
      var vm = this;
      var status = vm.ressourceStatus;
      var searchText = vm.search;

      if (searchText != "" && searchText != undefined && searchText != null) {
        switch (status) {
          case "0":
            return vm.ressources.filter(function (ressource) {
              return (
                ressource.available === true &&
                ressource.title.toLowerCase().match(searchText.toLowerCase())
              );
            });
          case "1":
            return vm.ressources.filter(function (ressource) {
              return (
                ressource.available === false &&
                ressource.title.toLowerCase().match(searchText.toLowerCase())
              );
            });
          default:
            return vm.ressources.filter(function (ressource) {
              return ressource.title
                .toLowerCase()
                .match(searchText.toLowerCase());
            });
        }
      } else {
        switch (status) {
          case "0":
            return vm.ressources.filter(function (ressource) {
              return ressource.available === true;
            });
          case "1":
            return vm.ressources.filter(function (ressource) {
              return ressource.available === false;
            });
          default:
            return vm.ressources;
        }
      }
    },
    openTimeSpots() {
      let openTimeSlots = {
        hours: [],
        possibleTimeSlots: [],
      };
      let possibleTimeSlots = [];

      //Die vom Server übergebenen Timeslots zeigen die Daten, an denen Ressourcen gebucht werden können (Zwischen Start und end)
      //Der letzte zeigt nur an ab wann die ressource frei ist und hat kein Ende(Da danach noch keine Buchung ist)
      if (this.ressourceToBook == null || this.eventDate == null) {
        return null;
      }

      if (this.ressourceToBook.openTimespots.length <= 0) {
        for (var hour = 0; hour < 24; hour++) {
          openTimeSlots.hours.indexOf(hour) === -1
            ? openTimeSlots.hours.push(hour)
            : console.log("This item already exists +hour");
        }
        return openTimeSlots;
      }

      //Speichere den gewünschten Tag
      let dateString = moment(this.eventDate).format("YYYY-MM-DD");
      let foundTimeslotForToday = false;
      //Gehe jeden möglichen freien Timespot für die Ressource durch
      for (
        var index = 0;
        index < this.ressourceToBook.openTimespots.length;
        index++
      ) {
        var timespot = this.ressourceToBook.openTimespots[index];

        var timespotBegin = moment(timespot.begin).format("YYYY-MM-DD");
        var timespotEnd = moment(timespot.end).format("YYYY-MM-DD");

        //Prüfe, ob der Timespot für den heutigen Tag gilt
        if (
          timespotBegin <= dateString &&
          (timespotEnd >= dateString || timespot.end == null)
        ) {
          foundTimeslotForToday = true;
          possibleTimeSlots.push(timespot);
          var beginHour = parseInt(moment(timespot.begin).format("H"));
          //Ist der Timeslot der letzte(end == null)?
          if (timespot.end == null) {
            //Beginnt der Timeslot heute? Wenn nicht ist er bspw seit tagen frei
            if (timespotBegin == dateString) {
              for (var hour = beginHour; hour < 24; hour++) {
                openTimeSlots.hours.indexOf(hour) === -1
                  ? openTimeSlots.hours.push(hour)
                  : console.log("This item already exists +hour");
              }
            } else {
              for (var hour = 0; hour < 24; hour++) {
                openTimeSlots.hours.indexOf(hour) === -1
                  ? openTimeSlots.hours.push(hour)
                  : console.log("This item already exists +hour");
              }
            }
          } else {
            var endHour = parseInt(moment(timespot.end).format("H"));
            console.log(endHour);
            for (var hour = beginHour; hour <= endHour; hour++) {
              openTimeSlots.hours.indexOf(hour) === -1
                ? openTimeSlots.hours.push(hour)
                : console.log("This item already exists +hour");
            }
          }
        }
      }

      if (
        this.ressourceToBook.openTimespots.length <= 0 ||
        !foundTimeslotForToday
      ) {
        for (var hour = 0; hour < 24; hour++) {
          openTimeSlots.hours.indexOf(hour) === -1
            ? openTimeSlots.hours.push(hour)
            : console.log("This item already exists +hour");
        }
        return openTimeSlots;
      }

      openTimeSlots.possibleTimeSlots = possibleTimeSlots;
      return openTimeSlots;
    },
    endHours() {
      if (this.beginHourToBook == null || this.openTimeSpots == null) {
        return;
      } else {
        let possibleHours = [];

        for (var i = 0; i <= this.openTimeSpots.hours.length - 1; i++) {
          if (this.openTimeSpots.hours[i] <= this.beginHourToBook) {
            continue;
          } else if (
            this.openTimeSpots.hours[i + 1] !=
              this.openTimeSpots.hours[i] + 1 &&
            i != this.openTimeSpots.hours.length - 1
          ) {
            possibleHours.push(this.openTimeSpots.hours[i]);

            break;
          } else {
            possibleHours.push(this.openTimeSpots.hours[i]);
          }
        }
        return possibleHours;
      }
    },
  },
};
</script>

<style scoped>
.searchBar {
  width: 300px;
  border: 2px solid #1c63af;
  color: #1c63af;
  outline: none;
  font-size: 1rem;
  margin-left: 15px;
}

.bookRessourceModal {
  min-height: 50vh;
}

/*Remove arrow from bulma-select*/
.select:not(.is-multiple):not(.is-loading)::after {
  display: none;
}

.free {
  color: #30b830;
  text-shadow: 0 0 2px black;
}

.occupied {
  color: #f06969;
  text-shadow: 0 0 2px black;
}

.rows {
  padding: 15px;
  border-radius: 30px;
  margin: 15px;
  box-shadow: -10px 10px 15px #777777;
  background: #1c63af;
}

.ressourceItem {
  cursor: default;
  color: white;
}

.ressourceHeader:after {
  content: "";
  border-bottom: 1px solid #ffffff;
  width: 95%;
  display: inline-block;
}

.ressourceTitle {
  text-align: center;
  font-weight: bolder;
  color: #e9a12d;
  font-size: 1.3rem;
}

.ressourceDescription {
  color: #ffffff;
  text-align: start;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

.container {
  padding: 15px;
  font-size: 1.2rem;
}

.filterText {
  margin: 0px 5px 0px 15px;
}
</style>
