<template>
<div class="component deviceComponent">

    <div class="deviceHeader">
        <div class="">
            <div class="consumptionHeader" v-if="currentTab=='overview'">
                <div class="devicesHeaderTitle">{{$t('enaq_translation_devices_consumption_header')}}</div>
                <div class="devicesHeaderSubtitle">{{currentDate}}</div>
            </div>

            <div class="statisticsHeader" v-if="currentTab=='statistics'">
                <div class="devicesHeaderTitle">{{$t('enaq_translation_devices_statistics_header')}}</div>
            </div>

            <div class="ressourceTypeIcons">
                <div class="ressourceTypeIcon" :class="{'activeRessourceTypeIcon':ressourceType=='electricity'}" @click="changeRessourceType('electricity')"><i class="fas fa-bolt"></i></div>
                <div class="ressourceTypeIcon" :class="{'activeRessourceTypeIcon':ressourceType=='heat'}" @click="changeRessourceType('heat')"><i class="fas fa-fire-alt"></i></div>

            </div>

            <div class="headerIcons">
                <div class="icons leftIcons">
                    <div class="deviceHeaderIcon" @click="currentTab='overview'">
                        <img alt="IconDashboard" src="@/assets/icons/Dashboard_Orange_Icon.png" v-if="currentTab=='overview'">
                        <img alt="IconDashboard" src="@/assets/icons/Dashboard_Grey_Icon.png" v-else>
                    </div>

                    <div class="deviceHeaderIcon" @click="currentTab='statistics'">
                        <img alt="IconGraph" src="@/assets/icons/Graph_Orange_Icon.png" v-if="currentTab=='statistics'">
                        <img alt="IconGraph" src="@/assets/icons/Graph_Grey_Icon.png" v-else>

                    </div>
                </div>
                <div class="icons rightIcons">
                    <div class="deviceHeaderIcon" @click="showHeaderMenu = !showHeaderMenu">
                        <img alt="IconElectricity" src="@/assets/icons/Electricity_orange_Button.png">

                    </div>
                    <img class="arrowButton" alt="IconElectricity" src="@/assets/icons/arrowdown.png">

                </div>
            </div>
        </div>
        <div class="consumptionMenu" v-if="showHeaderMenu">
            <div class="totalConsumptionNumber">
                <div class="tcTitle">
                    {{$t('enaq_translation_devices_consumption_total')}}
                </div>
                <div class="tcNumber">
                    <div v-if="true" class="tcSingleNumber" v-for="tcNumber in currentConsumption">{{tcNumber}}</div>
                </div>

            </div>

            <div class="totalConsumptionNumber">
                <div class="tcTitle">
                    {{$t('enaq_translation_devices_consumption_total')}}
                </div>
                <div class="tcNumber">
                    <div v-if="true" class="tcSingleNumber" v-for="tcNumber in currentConsumption">{{tcNumber}}</div>
                </div>

            </div>

            <div class="consMenuCurrentCosts">
                <div class="cmcTitle">
                    {{$t('enaq_translation_devices_costs_current')}}

                </div>

                <div class="cmcValue">{{mockCurrentCosts()}} Cent kW/h</div>
            </div>
        </div>
    </div>
    <consumptionCarousel v-if="currentTab=='overview'" />

    <div v-if="currentTab=='overview'" class="deviceGraphs columns is-mobile is-multiline">
        <div class="column is-12-tablet is-12-mobile">
            <div class="pricechart ">
                <div class="title">Kosten je kW/h</div>
                <linediagramm :graphData="priceData"></linediagramm>
            </div>
        </div>

    </div>

    <deviceStatistics v-if="currentTab=='statistics'" />

    <router-link class="  " :to="'/olddevices'" v-if="false">Alte Seite</router-link>

</div>
</template>

<script>
import linediagramm from '@/components/Devices/linediagramm.vue'
import piediagramm from '@/components/Devices/piediagramm.vue'
import consumptionCarousel from '@/components/Devices/DeviceCarousel/consumptionCarousel.vue'
import deviceStatistics from '@/components/Devices/Statistics/statistics.vue'
import idealConsumptionGraph from '@/components/Devices/Statistics/idealConsumptionGraph.vue'

export default {
    name: 'Devices',
    props: [],
    components: {
        linediagramm,
        piediagramm,
        consumptionCarousel,
        deviceStatistics,
        idealConsumptionGraph,

    },
    mounted: function () {
        this.consumptionToday = Math.floor(Math.random() * 150)
        this.mockConsumptionData();
        this.mockCurrentConsumption();
        // this.getDeviceData();
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            currentConsumption: [],
            consumptionToday: 30,
            consumptionData: {},
            showHeaderMenu: false,
            currentTab: "statistics",
            ressourceType:'electricity',
        currentSelectedRessource: "electricity"

        }
    },
    methods: {
        mockCurrentConsumption() {
            for (var i = 0; i <= 5; i++) {
                this.currentConsumption.push(Math.floor(Math.random() * 10))
            }
        },

        mockCurrentCosts() {
            let currentCosts = "";
            currentCosts += Math.floor(Math.random() * 8) + 10
            currentCosts += "." + Math.floor(Math.random() * 9)
            return currentCosts;
        },

        consumptionBatteryStyle() {
            console.log("cbstyle")
            if (this.consumptionToday <= 100) {
                return {
                    'top': 100 - this.consumptionToday + "%"
                };
            } else {
                return {
                    'top': "0%",
                    'background': 'red'
                };
            }

        },
        changeRessourceType(ressourceType){
this.ressourceType = ressourceType;
        },

        mockConsumptionData() {
            let consumptionData = {
                own: {},
                house: {},
                quartier: {},
            };

            //Own data
            let ownConsumption = Math.floor(Math.random() * 200)
            consumptionData.own.consumption = ownConsumption;

            let ownDevelopment = Math.floor(Math.random() * 3)
            consumptionData.own.development = ownDevelopment;

            let ownCosts = Math.floor(Math.random() * 80)
            consumptionData.own.costs = ownCosts;

            let ownCostSavings = Math.floor(Math.random() * 80)
            consumptionData.own.costSaving = ownCostSavings;

            let ownCO2 = Math.floor(Math.random() * 600) + 50
            consumptionData.own.co2 = ownCO2;

            let costsAVG = Math.floor(Math.random() * 160)
            consumptionData.own.costsavg = costsAVG;

            let consumptionAVG = Math.floor(Math.random() * 250)
            consumptionData.own.consumptionavg = consumptionAVG;

            //House data
            let houseConsumption = Math.floor(Math.random() * 600)
            houseConsumption += 700;
            consumptionData.house.consumption = houseConsumption;

            let houseCost = Math.floor(Math.random() * 280) + 432
            consumptionData.house.costs = houseCost;

            let houseDevelopment = Math.floor(Math.random() * 3)
            consumptionData.house.development = houseDevelopment;

            let houseProduction = Math.floor(Math.random() * 500) + 140
            consumptionData.house.production = houseProduction;

            let housePVConsumption = Math.floor(Math.random() * houseProduction)
            consumptionData.house.pvconsumption = housePVConsumption;

            let eigenverbrauchsquote = (housePVConsumption / houseProduction) * 100;
            consumptionData.house.evq = eigenverbrauchsquote.toFixed(1);

            let autarkie = (housePVConsumption / houseConsumption) * 100
            consumptionData.house.autarkie = autarkie.toFixed(1);

            let einspeisung = houseProduction - housePVConsumption;
            consumptionData.house.output = einspeisung;

            let houseCO2 = Math.floor(Math.random() * 10) + 3
            consumptionData.house.co2 = houseCO2;

            //Quartier data

            let quartierConsumption = Math.floor(Math.random() * 12000)
            quartierConsumption += 5000;
            consumptionData.quartier.consumption = quartierConsumption;

            let quartierCost = Math.floor(Math.random() * 2080) + 1432
            consumptionData.quartier.costs = quartierCost;

            let quartierDevelopment = Math.floor(Math.random() * 3)
            consumptionData.quartier.development = quartierDevelopment;

            let quartierProduction = Math.floor(Math.random() * 5000) + 1240
            consumptionData.quartier.production = quartierProduction;

            let quartierPVConsumption = Math.floor(Math.random() * (quartierProduction - 1240)) + 1240
            consumptionData.quartier.pvconsumption = quartierPVConsumption;

            let quartiereigenverbrauchsquote = (quartierPVConsumption / quartierProduction) * 100;
            consumptionData.quartier.evq = quartiereigenverbrauchsquote.toFixed(1);

            let quartierautarkie = (quartierPVConsumption / quartierConsumption) * 100
            consumptionData.quartier.autarkie = quartierautarkie.toFixed(1);

            let quartiereinspeisung = quartierProduction - quartierPVConsumption;
            consumptionData.quartier.output = quartiereinspeisung;

            let quartierCO2 = Math.floor(Math.random() * 150) + 80
            consumptionData.quartier.co2 = quartierCO2;

            this.consumptionData = consumptionData;
        },

       
    },
    computed: {
        currentDate() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '.' + mm + '.' + yyyy;
            return today;
        },

        currentMonth() {
            const date = new Date();
            const month = date.toLocaleString('default', {
                month: 'long'
            });
            return month;
        },
        ownPowerSourceData() {
            let sourcedata = [];
            sourcedata.push(Math.floor(Math.random() * 10) + 13)
            sourcedata.push(Math.floor(Math.random() * 10) + 4)
            sourcedata.push(Math.floor(Math.random() * 10) + 2)
            return sourcedata;
        },
        priceData() {

            let pricedata = [{
                data: [],
                name: "KEHAG",
                dates: []
            }, {
                data: [],
                name: "Durchschnittlicher Festpreis",
                dates: []
            }]
            for (var i = 0; i < 24; i++) {
                var costs = (Math.floor(Math.random() * 1000) + 200) / 1000;
                pricedata[0].data.push(costs)
                pricedata[0].dates.push(i + ":00")
                pricedata[1].data.push(0.9)
            }

            return pricedata;
        }
    },
}
</script>

<style scoped>
.pricechart {
    height: 35vh;
    margin-top: 2vh;
    width: 60%;
    margin-left: 20%;
}

.devicesHeaderTitle {
    font-size: 2em;
    color: #F7931D;
    font-weight: bolder;
    padding-top: .5em;
}

.devicesHeaderSubtitle {
    color: #757575;
    font-weight: bolder;
    font-size: 1.2em;
}

.deviceHeader {
    -webkit-box-shadow: 0px 5px 26px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 5px 26px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 5px 26px 1px rgba(0, 0, 0, 0.75);
    height: 110px;
    margin-bottom: calc(3vw + 30px);
    position: relative;
}

.deviceHeader .columns {
    margin-top: 0;
    height: 110px;

}

.deviceComponent {
    margin-left: -5vw;
    margin-right: 0;
    width: 100vw;
}

.deviceHeaderIcon {
    display: inline-block;

    margin: 0 0.6666%;

}

.deviceHeaderIcon img {
    max-height: calc(110px - 2.3rem);
    margin-top: calc(.4rem - 2px);
}

.icons {
    display: inline-block;
    border: 2px solid #757575;
    border-radius: 10px;
    height: calc(110px - 1.5rem);
    background: #CDCDCD;

}

.deviceHeaderIcon img {
    border-radius: 10px;
    height: 110px;
    background: #E4E4E4;
    padding: 5px;
    background: linear-gradient(#FAFAFA, #D6D6D6);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
}

.leftIcons {
    width: calc(220px - 2rem);
    margin-left: calc(5vw);
    margin-right: calc(6vw);

}

.leftIcons img {
    margin-left: 5px;
    margin-right: 5px;

}

.rightIcons {
    width: calc(110px - 1rem);

    /*margin-left:calc(33.333vw - (340px - 2rem) - 1rem - 36px)
*/
}

.consumptionMenu {
    background: #E4E4E4;
    width: 20vw;
    position: absolute;
    right: 26px;
    top: 122px;
    border: 3px solid #757575;
    border-radius: 10px;
    min-height: 110px;
    z-index: 5;
    -webkit-box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.75);
}

.headerIcons {
    position: absolute;
    right: 26px;
    top: .75rem;
}

.arrowButton {
    position: absolute;
    max-width: 5%;
    right: calc((110px - 1.5rem)/2 - 2.5%);
    bottom: -11px;
}

.totalConsumptionNumber {
    margin: 5px 10px;
    padding: 2px;

    border: 2px solid #FF611E;
    border-radius: 10px;
    height: 44px;
}

.tcTitle {
    display: inline-block;
    width: calc(30% - 4px);
    overflow: hidden;
    color: white;
    background: linear-gradient(#F7931E, #FF611E);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 12px;
    height: 36px;
    margin-right: 4px;
    font-weight: bolder;

}

.tcNumber {
    display: inline-block;
    width: 70%;
    vertical-align: top;
}

.tcSingleNumber:last-child {

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.tcSingleNumber {
    line-height: 36px;
    font-size: 24px;
    display: inline-block;
    width: calc((100% / 6) - 2px);
    height: 36px;
    color: white;
    font-weight: bolder;
    background: linear-gradient(#F7931E, #FF611E);
    margin-right: 2px;

}

.consMenuCurrentCosts {
    font-size: 1.1rem;
    font-weight: bolder;
}

.cmcTitle {
    text-decoration: underline;
}

.ressourceTypeIcons {
    position: absolute;
    width: 33%;
    text-align: left;
    top: 0;
    margin-top: 1.15rem;
}

.ressourceTypeIcon {
    display: inline-block;
    width: 20%;
    font-size: 2em;
    max-height: calc(110px - 2.3rem);
    height: calc(110px - 2.3rem);

    width:  calc(110px - 2.3rem);
    border: 1px solid #757575;
    background: linear-gradient(#FAFAFA, #D6D6D6);
    border-radius: 10px;
    line-height: calc(110px - 2.3rem);
    text-align:center;
    margin-left:1rem;
    cursor:pointer;
    opacity:0.5;
}

.activeRessourceTypeIcon{
        opacity:1;

}
</style>
