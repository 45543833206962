<template>
<div class="login">
    <div class="inputbox">
        <input class="input" type="text" :placeholder="$t('enaq_translation_login_mail')" v-model="email">
        <input @keydown.enter="onClickLogin" class="input" type="password" :placeholder="$t('enaq_translation_login_pass')" v-model="password">
     <!--   <a class="button is-info " @click="onClickLogin">Login</a>-->
        <button class="button is-info is-hidden-mobile" @click="onClickLogin">{{$t('enaq_translation_login_login')}}</button>
<button class="button is-large is-info is-hidden-tablet " @click="onClickLogin">{{$t('enaq_translation_login_login')}}</button>
        <router-link class="registerLink" :to="'/register'">{{$t('enaq_translation_login_register')}}</router-link>

    </div>
    <infoModal :message="infoMessage" :openModal="infoMessage!=''" :title="infoTitle" @closed="infoMessage=''"></infoModal>
{{msg}}

</div>
</template>

<script>
import infoModal from '@/components/UI/infoModal.vue';

export default {
    name: 'login',
    props: ['msg'],
    components: {
        infoModal
    },
    mounted: function () {},
    data() {
        return {
            email: "",
            password: "",
            errorMessage: "",
            infoMessage: "",
            infoColor: "",
            infoTitle: "Warnung",
        }
    },
    methods: {
        onClickLogin() {
            this.validateInput();
            if (this.infoMessage == "") {
                this.tryLogin();
            }
        },

        validateInput() {
            this.errorMessage = "";
            if (this.email == "") {
                this.infoMessage = this.$t('enaq_translation_login_error_mail')
            }
            if (this.password == "") {
                this.infoMessage = this.$t('enaq_translation_login_error_pass')
            }
        },

        tryLogin() {
            let loginData = {
                email: this.email,
                password: this.password
            }
            this.$axios.post(this.$backendURL + "/auth/login", loginData).then(function (result) {

                this.clearStorage();
                localStorage.setItem('bearertoken', result.data.access_token);
                this.$store.commit('setUsername', result.data.username)
                this.$router.push({name:'dashboard',params: { refresh: true }})
                
            }.bind(this)).catch(function (error) {
               
                if (error.response!=undefined&&error.response.data.message == "Unauthorized") {
                    this.infoMessage = this.$t('enaq_translation_login_error_wrong_data')
                } else {
                    this.infoMessage = this.$t('enaq_translation_login_error_server') + JSON.stringify(error.response.data);
                }

            }.bind(this))
        },
        clearStorage() {
            this.$store.commit('setUsername', '');
            this.$store.commit('setUser', '');

            localStorage.bearertoken = '';
            localStorage.clear();
        },
    },
    computed: {},
}
</script>

<style scoped>
.inputbox {
    width: 60%;
    margin: auto
}

.input {
    margin: 30px 0
}

.registerLink {
    justify-content: center;
    padding-bottom: calc(0.375em - 1px);
    padding-left: 0.75em;
    padding-right: 0.75em;
    padding-top: calc(0.375em - 1px);
    text-align: center;
    white-space: nowrap;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    line-height: 1.5;
    font-weight:bolder;
}

@media screen and (max-width: 900px) {
    .inputbox {
    width: 95%;
    margin: auto
}

.registerLink {
    font-size:1.5rem;
}
}
</style>
