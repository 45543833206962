<template>
  <div class="component">
    <div class="sliderImage"><img :src="image.path" /></div>
  </div>
</template>

<script>
export default {
  name: "slideshowitem",
  props: ["image"],
  mounted: function () {},
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
    };
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.sliderImage {
  height: 300px;
}

.sliderImage img {
  object-fit: contain;
  width: 100%;
  max-height: 100%;
  margin: auto;
}
</style>
