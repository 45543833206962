<template>
<div class="component">
    <div class="button" v-if="greenIndex==null" @click="getGreenIndex()">Grünstromindex</div>
    <div class="table-container" v-if="greenIndex!=null">

        <table class="greenIndexes table is-striped">
            <tr>
                <th>Stunde</th>
                <th v-for="greenIndexEntry in greenIndex">
                    <div class="greenIndex">{{unixToHour(greenIndexEntry.timeStamp)}}</div>
                </th>
            </tr>

            <tr>
                <th>Grünstromindex</th>

                <td v-for="greenIndexEntry in greenIndex" :style="{'background':backgroundColor(greenIndexEntry.gsi,'gsi')}">
                    <div class="greenIndex">{{greenIndexEntry.gsi}}</div>
                </td>
            </tr>

            <tr>
                <th>WIndenergie</th>

                <td v-for="greenIndexEntry in greenIndex">
                    <div class="greenIndex">{{greenIndexEntry.ewind}}</div>
                </td>
            </tr>

            <tr>
                <th>Solarenergie</th>

                <td v-for="greenIndexEntry in greenIndex" :style="{'background':backgroundColor(greenIndexEntry.esolar,'solar')}">
                    <div class="greenIndex">{{greenIndexEntry.esolar}}</div>
                </td>
            </tr>
        </table>
    </div>
</div>
</template>

<script>
export default {
    name: 'component',
    props: [],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            greenIndex: null,
        }
    },
    methods: {
        getGreenIndex() {
            this.sendInfoMessage({
                'loading': true
            })

            this.callApi("https://api.corrently.io/core/gsi?plz=26121", "get", null, null, true, true).then(function (response) {
                this.greenIndex = response.data.forecast;
                this.sendInfoMessage({
                    'type': 'close'
                })
            }.bind(this))
        },
        unixToHour(datestring) {
            var date = new Date(datestring);
            var hours = date.getHours();
            return hours;
        },
        backgroundColor(value, type) {
            if (type == "gsi") {
                if (value < 45) {
                    return "red"
                } else if (value <= 55) {
                    return "yellow"
                } else return "green"
            } else if (type == "solar") {
                if (value <= 0) {
                    return "red"
                } else return "green"
            }
        }
    },
    computed: {

    },
}
</script>

<style scoped>

</style>
