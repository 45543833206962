<template>
  <div class="component announcementContainer">
    <paginator :pages="maxPages" @changepage="changePage" />
    <div>
      <announcement
        v-for="announcement in announcements"
        :announcement="announcement"
        v-bind:key="announcement.id"
      >
      </announcement>
    </div>
  </div>
</template>

<script>
import announcement from "@/components/Announcements/announcement.vue";
import paginator from "@/components/UI/paginator.vue";

export default {
  name: "Announcements",
  props: ["refresh"],
  components: {
    announcement,
    paginator,
  },
  mounted: function () {
    if (this.refresh == true) {
      this.$router.push({
        name: "announcements",
        params: {
          refresh: false,
        },
      });
      this.$router.go();
    }
    this.getAnnouncements();
  },
  data() {
    return {
      announcements: null,
      currentPage: 1,
      amountPerPage: 4,
      maxPages: 0,
      announcementCache: {},
    };
  },
  methods: {
    getAnnouncements() {
      if (
        this.announcementCache[this.currentPage] != null &&
        this.announcementCache[this.currentPage] != []
      ) {
        this.announcements = this.announcementCache[this.currentPage];
        return;
      }
      this.HTTPRequest()
        .getAnnouncementPage(this.amountPerPage, this.currentPage)
        .then(
          function (response) {
            this.announcements = response.data.announcements.data;
            this.maxPages = response.data.announcements.last_page;
            this.announcementCache[this.currentPage] = this.announcements;
          }.bind(this)
        );
    },

    changePage(pageNumber) {
      this.currentPage = pageNumber;
      this.getAnnouncements();
    },
  },
  computed: {
    middlePageNumber() {
      return Math.round(this.maxPages / 2);
    },
  },
};
</script>
<style computed>
.announcementContainer {
  padding-top: 3rem;
}
</style>