<template>
  <div>
Test Test Test
  </div>
</template>

<script>
export default {
  name: "detail2",
};
</script>

<style scoped>

</style>
