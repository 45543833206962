<template>
<div class="compareDiagram">

    <apexchart :width="'100%'" :height="'90%'" type="bar" :options="graphOptions" :series="graphData.series"></apexchart>

</div>
</template>

<script>
export default {
    name: 'compareDiagram',
    props: ["graphData","labelY"],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: []
        }
    },
    methods: {},
    computed: {

        graphOptions() {
            return {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: this.graphData.labels,
                },
                yaxis: {
                    decimalsInFloat:2,
                    title: {
                        text: this.labelY
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return  val.toFixed(2) +" "+ this.labelY
                        }.bind(this)
                    },
                     x: {
                        formatter: function (val) {
                            return  val 
                        }.bind(this)
                    }
                }
            };
        },

    },
}
</script>

<style scoped>

</style>
