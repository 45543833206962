Umfrage-Seite 1:

<template>
  <div class="component questionBox" v-if="question != undefined">
    <div>
      <table class="column is-fullwidth">
        <div class="is-mobile is-multiline">
          <div class="is-12-desktop is-12-tablet is-12-mobile">
            <div class="questionItemHeader">
              <div class="questionTitle">
                <span
                  ><i
                    class="pointer fas fa-info-circle"
                    @click="showDescription(question.description)"
                  ></i>
                  {{ question.question }}</span
                >
              </div>
            </div>
          </div>
          <div class="is-12-desktop is-12-tablet is-12-mobile">
            <div class="questionItemBody">
              <div v-if="question.questionType == 0">
                <div>
                  <div
                    class="multipleChoiceAnswer"
                    v-for="answer in question.answers"
                    :key="answer.id"
                    :class="{ choosenAnswer: questionAnswer == answer.id }"
                  >
                    <span
                      @click="chooseAnswer(answer.id)"
                      v-if="questionAnswer == answer.id"
                      class="answerMark pointer"
                      ><i class="fas fa-check-square"></i
                    ></span>
                    <span
                      @click="chooseAnswer(answer.id)"
                      v-else
                      class="answerMark pointer"
                      ><i class="far fa-square"></i
                    ></span>
                    <span class="answerText">
                      {{ answer.answerText }}
                      <i
                        class="pointer fas fa-info-circle"
                        @click="showDescription(answer.answerDescription)"
                      ></i
                    ></span>
                  </div>
                </div>
              </div>

              <div v-if="question.questionType == 1">
                <div class="customTextAnswer">
                  <textarea
                    class="textarea"
                    v-model="questionCustomText"
                    placeholder="Trage hier deinen Antworttext ein..."
                    rows="4"
                  ></textarea>
                  <div>
                    <enaqbutton :width="'150px'" @click="addAnswerText"
                      >Antwort senden</enaqbutton
                    >
                  </div>
                </div>
              </div>

              <div v-if="question.questionType == 2">
                <div class="customNumberAnswer">
                  <input
                    type="number"
                    class="input"
                    placeholder="0"
                    v-model="questionCustomNumber"
                  />
                  <div>
                    <enaqbutton :width="'150px'" @click="addAnswerNumber"
                      >Antwort senden</enaqbutton
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </table>
    </div>
    <br />
  </div>
</template>

<script>
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "question",
  props: ["question"],
  components: { enaqbutton },
  mounted: function () {
    if (this.question.user_answer.length > 0) {
      this.questionAnswer = this.question.user_answer[0].survey_answer_id;
      this.questionCustomNumber =
        this.question.user_answer[0].customAnswerNumber;
      this.questionCustomText = this.question.user_answer[0].customAnswerText;
      this.answered = true;
    }
  },
  data() {
    return {
      data: [],
      openModal: false,
      questionAnswer: null,
      questionCustomNumber: null,
      questionCustomText: "",
      answered: false,
    };
  },
  methods: {
    showDescription(text) {
      if (text != "" && text != null && text != undefined) {
        this.sendInfoMessage({
          text: text,
          duration: "8",
        });
      } else {
        this.sendInfoMessage({
          text: "Keine Beschreibung vorhanden.",
          duration: "8",
        });
      }
    },
    chooseAnswer(id) {
      this.questionAnswer = id;
      let postData = {
        survey_question_id: this.question.id,
        survey_answer_id: id,
      };

      this.sendAnswer(postData);
    },
    addAnswerNumber() {
      let postData = {
        survey_question_id: this.question.id,
        customAnswerNumber: this.questionCustomNumber,
      };

      this.sendAnswer(postData);
    },
    addAnswerText() {
      let postData = {
        survey_question_id: this.question.id,
        customAnswerText: this.questionCustomText,
      };

      this.sendAnswer(postData);
    },

    sendAnswer(postData) {
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };
      this.sendInfoMessage({ loading: true });

      this.$axios
        .post(this.$backendURL + "/survey/answer", postData, {
          headers: headerData,
        })
        .then(
          function (result) {
            this.sendInfoMessage({
              text: this.$t("enaq_translation_surveys_answer_saved"),
              duration: "8",
            });

            this.$emit("onAnswerAdd");
            this.openModal = false;
            this.answered = true;
          }.bind(this)
        );
    },
  },
  computed: {},
};
</script>

<style scoped>
.component {
  cursor: default;
}

.choosenAnswer .answerMark {
  color: #1c63af;
}

.multipleChoiceAnswer {
  padding: 10px;
}

.textarea {
  margin: 10px 0px 20px 0px;
}

.input {
  margin: 10px 0px 20px 0px;
  width: 20%;
}

.column {
  padding: 15px;
  border-radius: 30px;
  border: 1px solid #1c63af;
  margin: 10px 0px 0px 0px;
  box-shadow: -10px 10px 15px #777777;
  background: #eeeeee;
  color: #1c63af;
}

.questionItemHeader:after {
  content: "";
  border-bottom: 1px solid #1c63af;
  width: 95%;
  display: inline-block;
}

.questionTitle {
  text-align: start;
  font-weight: bolder;
  font-size: 1.3rem;
  padding-left: 3.5%;
  padding-right: 3.5%;
}

.questionItemBody {
  font-size: 1rem;
  text-align: start;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

.pointer {
  cursor: pointer;
}
</style>
