<template>
<div class="component ttdatePicker">
    <div class="timePickerWrapper" v-if="selectedDate!=null">
        <div class="timePickerTitle" @click="isOpen = !isOpen">{{timePickerHeader}}
            <div class="timePickerTitleIcon"><i class="fas fa-caret-down"></i></div>
        </div>
        <div class="timePickerOpenMenu" v-if="isOpen">
            <div class="timePickerOpenHeader">
                <div class="prevTime" @click="changePeriode(-1)">
                    <span>
                        <i class="fas fa-chevron-left"></i>
                    </span>
                </div>

                <div class="timeHeader">
                    <span>{{currentTimeHeader}}</span>
                </div>

                <div class="nextTime" @click="changePeriode(1)">
                    <span> <i class="fas fa-chevron-right"></i>
                    </span>
                </div>
            </div>

            <div class="timePickerOpenMenuValues">
                <div class="timeValue" :style="timeValueStyle" v-for="timeValues in currentTimeValues" :key="timeValues" @click="selectDate(timeValues)">{{timeValues}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'datepicker',
    props: ["initialDate", "type", "localisation"],
    components: {
        moment,
    },
    mounted: function () {
        this.selectedDate = this.initialDate;
        moment.locale(this.localisation)
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            isOpen: false,
            selectedDate: this.initialDate,
            highlightedTimePeriode: '',
           
        }
    },
    methods: {
        changePeriode(value) {
            let currentDate = this.selectedDate;
            this.selectedDate = null;
            if (this.type == "month") {
                if (value < 0) {
                    value *= -1;
                    currentDate.subtract(value, 'years');

                } else {
                    currentDate.add('years', value);

                }
            } else if (this.type == "year") {
                if (value < 0) {
                    value *= -1;
                    currentDate.subtract(value * 5, 'years');

                } else {
                    currentDate.add('years', value * 5);

                }
            } else if (this.type == "day") {
                if (value < 0) {
                    value *= -1;
                    currentDate.subtract(value, 'months');

                } else {
                    currentDate.add('months', value);

                }
            }
            this.selectedDate = currentDate;
            this.isOpen = true;
        },
        selectDate(dateValue) {
            let currentDate = this.selectedDate;
            this.selectedDate = null;

            if (this.type == "month") {
                currentDate.month(dateValue)
            }
            if (this.type == "year") {
                currentDate.year(dateValue)
            }
            if (this.type == "day") {
                currentDate.date(dateValue)
            }
            this.selectedDate = currentDate;
            this.isOpen = false;
            this.$emit("onDateChange", this.selectedDate)
        }
    },
    computed: {
        timePickerHeader() {
            if (typeof this.selectedDate.format !== "function") {
                return "-"
            }
            if (this.type == "month") {
                return this.selectedDate.format("MMMM YYYY")
            } else if (this.type == "year") {
                return this.selectedDate.format("YYYY")

            } else if (this.type == "day") {
                return this.selectedDate.format("DD.MMMM YYYY")

            }
        },
        currentTimeHeader() {

            if (this.selectedDate == null || this.selectedDate == undefined || typeof this.selectedDate.format !== "function") {
                return "-";
            } else {
                if (this.selectedDate.format("YYYY") == "-") {
                    return ""
                }

                if (this.type == "month") {
                    return this.selectedDate.format("YYYY")
                } else if (this.type == "year") {
                    let currentYear = parseInt(this.selectedDate.format("YYYY"));
                    return (currentYear - 5 + " - " + (currentYear + 6))
                } else if (this.type == "day") {
                    let currentYear = parseInt(this.selectedDate.format("YYYY"));
                    return (currentYear + " - " + this.selectedDate.format("MMMM"))
                }
            }

        },
        currentTimeValues() {
            let timeValues = []
            if (this.type == "day") {
                let daysInMonth = this.selectedDate.daysInMonth()
                for (var i = 1; i <= daysInMonth; i++) {
                    timeValues.push(i)
                }
                return timeValues;
            }

            if (this.type == "month") {
                return moment.monthsShort();
            }

            if (this.type == "year") {
                let years = [];
                let currentYear = parseInt(this.selectedDate.format("YYYY"));
                for (var i = -5; i <= 6; i++) {
                    years.push(currentYear + i)
                }
                return years;
            }
        },

        timeValueStyle() {
            if (this.type == "month" || this.type == "year") {
                return {
                    display: "inline-block",
                    "width": "25%"
                }
            }

            if (this.type == "day") {
                return {
                    display: "inline-block",
                    "width": "12.5%"
                }
            }
        }

    },
    watch: {
        // whenever question changes, this function will run
        initialDate: function (newDate) {
            console.log("watch init date " + newDate)
            this.selectedDate = newDate
        },
        localisation: function (newLoc) {
            moment.locale(newLoc)
        }
    },
}
</script>

<style scoped>
.ttdatePicker {
    width: 100%;
    height: 2em;
}

.timePickerWrapper {
    height: 2em;
    position: relative;
}

.timePickerOpenMenu {

    border: 1px solid #c7c7c7;
    height: 8em;
    position: absolute;
    width: 100%;
    bottom: -8em;
    z-index: 999;
    background: white;
}

.prevTime,
.nextTime {
    display: inline-block;
    width: 10%;
}

.timeHeader {
    display: inline-block;
    width: 80%;
    font-weight: bolder;
}

.timePickerOpenMenuValues {
    margin: 0 5px;
    padding: -1px;
    text-align: left;

}

.timeValue {
    border: 1px solid #c7c7c7;

    text-align: center;
}

.timeValue:hover {
    background: #c5c5c5;
    cursor: pointer;
}

.timePickerTitle {
    line-height: 2em;
        min-height: 2em;

    font-weight: bolder;
    position: relative;
    background: #E6E6E6;
    border: 3px solid #919191;
    border-radius: 10px;
    color: #919191;
    font-weight: bolder;
    cursor: pointer;
}

.timePickerTitleIcon {
    position: absolute;
    margin: auto;
    top: -.05em;
    text-align: right;
    right: 5%;
    font-size: 1.8em;
}
</style>
