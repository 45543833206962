<template>
  <div class="component">
    <div class="surveyTitleContainer">
      <i
        class="pointer fas fa-info-circle"
        @click="showDescription(survey.description)"
      ></i>
      <span> {{ survey.title }}</span>
    </div>

    <div v-if="survey.isOpen == 1">
      <showQuestion
        v-for="question in survey.questions"
        :key="question.id"
        :question="question"
      ></showQuestion>
    </div>
    <div v-else>
      <showQuestionResult
        v-for="result in surveyResults.questions"
        :key="result.id"
        :question="result"
      ></showQuestionResult>
    </div>
    <enaqbutton :fontsize="'25px'" :width="'150px'" @click="$emit('close')">{{
      $t("enaq_translation_surveys_back")
    }}</enaqbutton>
  </div>
</template>

<script>
import showQuestion from "@/components/Meetingpoint/Surveys/showQuestion.vue";
import showQuestionResult from "@/components/Meetingpoint/Surveys/showQuestionResult.vue";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "survey",
  props: ["surveyId"],
  components: {
    showQuestion,
    showQuestionResult,
    enaqbutton,
  },
  mounted: function () {
    this.getSurvey();
    if (this.survey.isOpen != 1) {
      this.getSurveyResults();
    }
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      survey: {},
      surveyResults: {},
    };
  },
  methods: {
    showDescription(text) {
      if (text != "" && text != null && text != undefined) {
        this.sendInfoMessage({
          text: text,
          duration: "8",
        });
      } else {
        this.sendInfoMessage({
          text: "Keine Beschreibung vorhanden.",
          duration: "8",
        });
      }
    },
    getSurvey() {
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };

      this.$axios
        .get(this.$backendURL + "/survey/" + this.surveyId, {
          headers: headerData,
        })
        .then(
          function (response) {
            this.survey = response.data.survey[0];
          }.bind(this)
        );
    },
    getSurveyResults() {
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };

      this.$axios
        .get(this.$backendURL + "/survey/result/" + this.surveyId, {
          headers: headerData,
        })
        .then(
          function (response) {
            this.surveyResults = response.data.results;
          }.bind(this)
        );
    },
  },
  computed: {},
};
</script>

<style scoped>
.surveyTitleContainer {
  text-align: start;
  color: #1c63af;
  padding: 1%;
  font-size: 1.8em;
  font-weight: bolder;
}

.pointer {
  cursor: pointer;
}
</style>
