<template>
<div class="serviceList">
    <div class="title">Kategorien</div>
    <div class="columns is-mobile is-multiline">

        <div class="column is-4 "><span class="is-size-4">Name</span></div>
        <div class="column is-6"><span class="is-size-4">Beschreibung</span></div>
        <div class="column is-2 ">

        </div>


        <div class="column is-4 "><input v-model="newCategoryName" class="input" /></div>
        <div class="column is-6"><input v-model="newCategoryDescription" class="input" /></div>
        <div class="column is-2 ">
            <div class="button is-success" @click="createCategorie">Speichern</div>

        </div>
    </div>

    <div class=" ">
        <div v-for="category in allCategories" class=" columns is-mobile is-multiline" :key="category.id">
            <div class="column is-4">
                <div class="tag">{{category.name}}</div>
            </div>
            <div class="column is-6">
                <div class="">{{category.description}}</div>
            </div>
            <div class="column is-2">
                <div class="button is-danger" @click="deleteCategory(category.id)">Löschen</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'categorySettings',
    components: {},
    props: [],
    mounted: function () {
        this.getAllCategories()
    },
    data() {
        return {
            newCategorie: null,
            allCategories: [],
            newCategoryName: "",
            newCategoryDescription:""

        }
    },
    methods: {

        getAllCategories() {
            this.sendInfoMessage({
                loading: true,
            });
            this.HTTPRequest()
                .getAllCategories()
                .then(
                    function (result) {
                        this.sendInfoMessage({
                            type: "close",
                        });
                        this.allCategories = result.data.categories;
                    }.bind(this)
                );
        },
        createCategorie() {
            this.sendInfoMessage({
                loading: true,
            });
            if (this.newCategoryName == "") {
                return;
            }

            this.HTTPRequest()
                .createCategory(this.newCategoryName,this.newCategoryDescription)
                .then(
                    function (result) {
                        this.sendInfoMessage({
                            type: "close",
                        });
                        this.newCategoryName = "";
                        this.newCategoryDescription = "";
                        this.getAllCategories();
                    }.bind(this)
                );

        },
        deleteCategory(id){
            this.sendInfoMessage({
                loading: true,
            });
            this.HTTPRequest()
                .deleteCategory(id)
                .then(
                    function (result) {
                        this.sendInfoMessage({
                            type: "close",
                        });
                        this.getAllCategories();
                    }.bind(this)
                );
            
        }
    },
    computed: {},
}
</script>

<style scoped>
.lockedIcon {
    color: red;
}

.unlockedIcon {
    color: green;
}
</style>
