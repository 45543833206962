<template>
<div class="login">
    <div class="inputbox">
        <div class="personalInformation columns is-mobile is-multiline">
            <div class="column is-2">
                <div class="select salutationDropdown">
                    <select v-model="salutation">
                        <option disabled value="0">Anrede</option>
                        <option value="1">Herr</option>
                        <option value="2">Frau</option>
                        <option value="3">Andere</option>
                        <option value="0">Keine Angabe</option>
                    </select>
                </div>
            </div>
            <div class="column is-5"><input class="input" type="text" placeholder="Vorname" v-model="firstName">
            </div>
            <div class="column is-5"><input class="input" type="text" placeholder="Name" v-model="name">
            </div>

        </div>

        <div class="columns is-multiline is-mobile birthFields">
            <div class="  column is-4">
                <label class=" alignedLabel label">Geburtstag</label>
            </div>
            <datepicker class="column is-8" input-class="input" v-model="birthdate"></datepicker>
        </div>

        <div class="columns is-multiline is-mobile birthFields">
            <div class="  column is-4">
                <label class=" alignedLabel label">Einzugsdatum</label>
            </div>
            <datepicker class="column is-8" input-class="input" v-model="moveinDate"></datepicker>
        </div>

        <a class="button is-info" @click="onClickRegister">Registrieren</a>
    </div>
    {{errorMessage}}
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

export default {
    name: 'register',
    components: {
        Datepicker
    },
    props: [],
    mounted: function () {},
    data() {
        return {
            name: "",
            firstName: "",
            salutation: 0,
            birthdate: "",
            errorMessage: "",
            moveinDate: "",
        }
    },
    methods: {
        onClickRegister() {
            this.validateInput();
            if (this.errorMessage == "") {
                this.trySignup();
            }
        },

        validateInput() {
            this.errorMessage = "";
            if (this.name == "" || this.firstName == "") {
                this.errorMessage = "Bitte einen Vor- und Nachnamen angeben"
            }
            if (this.birthdate == "") {
                this.errorMessage = "Bitte das Geburtstdatum angeben"
            }
            if (this.moveinDate == "") {
                this.errorMessage = "Bitte das Einzugsdatum angeben"
            }

        },

        trySignup() {
            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }

            let signupData = {
                firstname: this.firstName,
                name: this.name,
                birthdate: this.customFormatter(this.birthdate),
                moveinDate: this.customFormatter(this.moveinDate),
                salutation: this.salutation
            }
            this.$axios.post(this.$backendURL + "/auth/admin/user/create", signupData, {
                headers: headerData
            }).then(function (result) {
                this.errorMessage = "Voucher erstellt: " + result.data.voucher;
            }.bind(this))
        },
        customFormatter(date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    computed: {},
}
</script>

<style scoped>
.inputbox {
    width: 100%;
    margin: auto
}

.input {
    margin-top: 30px
}

.salutationDropdown {
    margin-top: 30px
}

.datePicker input {
    width: 100%;
    margin-top: 30px;
}

.alignedLabel {
    font-size: 1rem;
    height: 2.25em;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    text-align: left;
}

.birthFields {
    margin-top: 20px;
}
</style>
