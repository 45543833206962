import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userName: "",
        user: "",
        newMessages: 0,
        currentLanguage: "de",
        services: []
    },
    mutations: {
        setUsername(state, userName) {
            state.userName = userName;
        },
        setUser(state, user) {
            state.user = user;
        },
        setNewMessage(state, newMessageCount) {
            state.newMessages = newMessageCount;
        },
        setCurrentLanguage(state, currentLanguage) {
            state.currentLanguage = currentLanguage;
        },
        addService(state, service) {
            state.services.push(service)
        }
    },
    actions: {

    },
    getters: {
        getUsername: state => state.userName,
        getUser: state => state.user,
        getNewMessageCount: state => state.newMessages,
        getCurrentLanguage: state => state.currentLanguage,
    }
})