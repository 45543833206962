<template>
<div class="component graphcomponent">

    <apexchart width="100%" :height="graphHeight||'100%'" type="bar" :options="graphOptions" :series="graphData"></apexchart>

</div>
</template>

<script>
export default {
    name: 'Graph',
    props: ["title", "graphData", "graphHeight"],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: []
        }
    },
    methods: {

        getGraphStyle() {
            return {
                "max-height": "30px"
            }
        }
    },
    computed: {
        graphOptions() {

            let graphOptions = {
                chart: {
                    id: this.title || "Graph " + Math.floor(Math.random() * 1000),
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    height: '100%',
                },
                xaxis: {
                    tickAmount: 1,
                    offsetX: '0'
                },

                yaxis: {
                    type: "integer",
                    labels: {
                        formatter: function (value) {
                            return parseInt(value);
                        }
                    }
                }
            };

            if (this.graphData[0] != undefined && this.graphData[0].dates != undefined) {
                graphOptions.xaxis.categories = this.graphData[0].dates
            }
            return graphOptions;
        },
    },
}
</script>

<style scoped>
.graphcomponent {
    height: 100%
}
</style>
