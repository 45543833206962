<template>
<div class="serviceList">
    <div class="serviceObject columns is-mobile is-multiline" v-for="service in serviceList" :key="service.id">

        <div class="column is-8"><p class="is-size-4">{{service.name}}</p></div>
        <div class="column is-4">
            <div class="button is-danger is-size-5" v-if="isServiceHidden(service.id)" @click="setServiceVisibility(service.id,true)">Unsichtbar</div>
            <div class="button is-success is-size-5" v-else  @click="setServiceVisibility(service.id,false)">Sichtbar</div>

        </div>

    </div>

</div>
</template>

<script>

export default {
    name: 'ServiceSettings',
    components: {
    },
    props: [],
    mounted: function () {
        this.serviceList = this.getServices();
        this.getHiddenServices();
    },
    data() {
        return {
            serviceList: [],
            hiddenServices: [],
            testdata:null

        }
    },
    methods: {
        getServices() {
            return this.$store.state.services;
        },

        getHiddenServices() {
            this.HTTPRequest()
                .getHiddenServices()
                .then(
                    function (result) {
                        this.testdata=result
                        this.hiddenServices = result.data.data;
                    }.bind(this)
                );
        },
        isServiceHidden(id) {
            console.log("checkservice")
            console.log( this.hiddenServices.filter(function(e) {console.log(e.serviceid); return e.serviceid === id; }).length > 0)
         return this.hiddenServices.filter(function(e) { return e.serviceid === id; }).length > 0

           
        },
        setServiceVisibility(id, visibility) {
            this.HTTPRequest()
                .setServiceVisibility(id, visibility)
                .then(
                    function (result) {
                        this.getHiddenServices()
                    }.bind(this)
                );
        }
    },
    computed: {},
}
</script>

<style scoped>
.lockedIcon {
    color: red;
}

.unlockedIcon {
    color: green;
}
</style>
