<!--
Items:
[{id:9,name:'titel',path:'http://www.quantumfrog.de/logo.png'}]
-->
<template>
  <div class="component slideshowImageContainer">
    <table class="table is-fullwidth">
      <div class="answerHeader columns is-mobile is-multiline">
        <div class="column is-2-desktop is-12-tablet is-12-mobile">
          <i
            class="fas fa-arrow-left navigationArrow arrowLeft"
            @click="clickPrev()"
          ></i>
        </div>
        <div class="column is-8-desktop is-12-tablet is-12-mobile">
          <transition :name="currentTransition">
            <slideitem
              :key="selectedImage.id"
              class="slideItem"
              :image="selectedImage"
            ></slideitem>
          </transition>
        </div>
        <div class="column is-2-desktop is-12-tablet is-12-mobile">
          <i
            class="fas fa-arrow-right navigationArrow arrowRight"
            @click="clickNext()"
          ></i>
        </div>
      </div>
    </table>
  </div>
</template>

<script>
import slideitem from "@/components/Slideshow/slideitem.vue";

export default {
  name: "Slideshow",
  props: ["items"],
  components: {
    slideitem,
  },
  mounted: function () {},
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      activeSlide: -1,
      currentTransition: "transitionRight",
    };
  },
  methods: {
    clickPrev() {
      this.activeSlide--;
      this.currentTransition = "transitionRight";
    },
    clickNext() {
      this.activeSlide++;
      this.currentTransition = "transitionLeft";
    },
  },
  computed: {
    selectedImage() {
      if (this.activeSlide >= this.items.length) {
        this.activeSlide = 0;
      }

      if (this.activeSlide < 0) {
        this.activeSlide = this.items.length - 1;
      }
      this.$emit("activeImageID", this.items[this.activeSlide].id);
      return this.items[this.activeSlide];
    },
  },
};
</script>

<style scoped>
.navigationArrow {
  font-size: 2em;
}

.arrowLeft {
  line-height: 300px;
  height: 300px;
}

.arrowRight {
  line-height: 350px;
  height: 350px;
}

.slideItem {
  margin: auto;
}

.slideshowImageContainer {
  height: 300px;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
  margin: auto;
  border: 1px solid #f0f0f0;
}

.transitionRight-enter-active,
.transitionRight-leave-active {
  transition: all 0.4s;
  display: inline-block;
  position: absolute;
  width: 90%;
}

.transitionRight-leave,
.transitionRight-enter-to {
  right: 5%;
}

.transitionRight-enter {
  right: 95%;
}

.transitionRight-leave-to {
  right: -100%;
}

.transitionLeft-enter-active,
.transitionLeft-leave-active {
  transition: all 0.4s;
  display: inline-block;
  position: absolute;
  width: 100%;
}

.transitionLeft-leave,
.transitionLeft-enter-to {
  left: 5%;
}

.transitionLeft-enter {
  left: 95%;
}

.transitionLeft-leave-to {
  left: -100%;
}
</style>
