<template>
  <div>
    <div class="filterHeader columns is-mobile is-multiline">
      <div class="column is-12-fullhd is-12-tablet is-12-mobile">
        <div class="container" id="status">
          <div>
            <label
              ><input type="radio" v-model="selectedStatus" value="All" /> Alle
              Umfragen</label
            >
            <label
              ><input type="radio" v-model="selectedStatus" value="Open" />
              Offene Umfragen</label
            >
            <label
              ><input type="radio" v-model="selectedStatus" value="Closed" />
              Geschlossene Umfragen</label
            >
          </div>
          <div class="columns is-mobile is-multiline">
            <span class="column is-6 has-text-right">Teilgenommen</span>
            <div class="column is-6 has-text-left">
              <slidebutton
                :default="onlyOwn"
                @toggle="onlyOwn = !onlyOwn"
              ></slidebutton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <table
      class="table is-fullwidth"
      v-for="(survey, index) in filteredSurveys"
      :key="survey.id"
    >
      <div class="columns is-mobile is-multiline">
        <div
          class="column is-10-desktop is-offset-1 surveyItem"
          :style="getSurveyItemClass(survey)"
        >
          <div class="column is-12-desktop is-12-tablet is-12-mobile">
            <div v-if="survey.isOpen" class="surveyTitle">
              {{ survey.title }}
            </div>
            <div v-else class="surveyTitle">
              <span class="closed">Beendet: </span>{{ survey.title }}
            </div>
          </div>

          <div class="column is-12-desktop is-12-tablet is-12-mobile">
            <div>
              <enaqbutton
                :width="'150px'"
                v-if="survey.isOpen"
                @click="chooseSurvey(survey.id, index)"
              >
                {{ $t("enaq_translation_surveys_vote") }}
              </enaqbutton>
              <enaqbutton
                :width="'220px'"
                v-else
                @click="chooseSurvey(survey.id, index)"
              >
                {{ $t("enaq_translation_surveys_closed") }}
              </enaqbutton>
            </div>
          </div>
        </div>
      </div>
    </table>
  </div>
</template>

<script>
import slidebutton from "@/components/helper/slidebutton.vue";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "surveyList",
  components: {
    slidebutton,
    enaqbutton,
  },
  props: [],
  mounted: function () {
    this.getSurveys();
    this.callApi("/survey/all");
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      surveys: [],
      selectedStatus: "All",
      onlyOwn: false,
    };
  },
  methods: {
    getSurveys() {
      this.callApi("/survey/all", "get").then(
        function (response) {
          /*console.log(JSON.stringify(response) + "x");*/
          this.surveys = response.data.surveys;
        }.bind(this)
      );
      return;
    },
    chooseSurvey(id) {
      this.$emit("choose", id);
    },
    getSurveyItemClass(survey, index = 0) {
      let bgcOpen = "#1c63af";
      let bgColor = "#b1b1b1";
      if (survey.isOpen == 1) {
        bgColor = bgcOpen;
      }
      let styleData = {
        background: bgColor,
      };
      return styleData;
    },
  },
  computed: {
    filteredSurveys: function () {
      var vm = this;
      var status = vm.selectedStatus;
      if (status === "All") {
        if (this.onlyOwn) {
          return vm.surveys.filter(function (survey) {
            return survey.answered > 0;
          });
        } else {
          return vm.surveys;
        }
      } else if (status === "Open") {
        if (this.onlyOwn) {
          return vm.surveys.filter(function (survey) {
            return survey.answered > 0 && survey.isOpen == 1;
          });
        } else {
          return vm.surveys.filter(function (survey) {
            return survey.isOpen == 1;
          });
        }
      } else if (status === "Closed") {
        if (this.onlyOwn) {
          return vm.surveys.filter(function (survey) {
            return survey.answered > 0 && survey.isOpen === 0;
          });
        } else {
          return vm.surveys.filter(function (survey) {
            return survey.isOpen === 0;
          });
        }
      }
    },
  },
};
</script>


<style scoped>
.columns {
  margin-top: 2px;
}

.closed {
  color: #ce2b2b;
}

.surveyItem {
  width: 50%;
  padding: 15px;
  border-radius: 30px;
  margin: 15px;
  box-shadow: -10px 10px 15px #777777;
}

.surveyTitle {
  text-align: center;
  font-weight: bolder;
  color: #ffffff;
  font-size: 1.6rem;
  text-shadow: 0 0 2px black;
}

.container {
  width: 90%;
  font-size: 1.2rem;
  font-weight: 500;
}

label {
  line-height: 1.5em;
  padding: 10px 10px 20px 10px;
}
</style>
