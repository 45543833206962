import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/login.vue'
import Register from './views/register.vue'
import Quartier from './views/quartier.vue'

import AdminBoard from './views/admin/adminboard.vue'
import AdminGroups from './views/admin/admingroups.vue'

import CreateUser from './views/admin/createUser.vue'
import Vouchers from './views/admin/vouchers.vue'
import CreateSurvey from './views/admin/createSurvey.vue'
import CreateRoom from './views/admin/createRoom.vue'
import AdminAnnouncements from './views/admin/announcements.vue'
import AdminUser from './views/admin/user.vue'
import CreateRessources from './views/admin/createRessource.vue'
import ServiceSettings from './views/admin/serviceSettings.vue'
import CategorySettings from './views/admin/categorySettings.vue'

import User from './views/users/profile.vue'
import MessageCreate from './components/Messages/messageCreate.vue'
import Inbox from './views/users/inbox.vue'
import Dashboard from './views/users/dashboard.vue'
import Devices from './views/users/devices.vue'

import Map from './views/users/map.vue'

import Feedback from './views/users/feedback.vue'
import OldDevices from './views/users/devicesold.vue'

import azureLogin from './views/azurelogin.vue'
import MainPage from './views/Mainpage/MainPage.vue'

import MeetingPoint from './views/Meetingpoint/Meetingpoint.vue'
import Market from './views/Meetingpoint/Market.vue'
import Groups from './views/Meetingpoint/Groups.vue'
import NBHelp from './views/Meetingpoint/NBHelp.vue'
import Ressources from './views/Meetingpoint/Ressources.vue'
import Highscore from './views/Meetingpoint/Highscore.vue'
import Surveys from './views/Meetingpoint/Surveys.vue'
import Calendar from './views/Meetingpoint/Calendar.vue'
import Events from './views/Meetingpoint/Events.vue'

import PluginOverview from './views/Plugins/pluginoverview.vue'

import Announcements from './views/Announcements.vue'
import gruenstrom from './components/Devices/gruenstrom.vue'


Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [{
            path: '/',
            name: 'home',
            component: Dashboard
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        }, {
            path: '/main',
            name: 'main',
            component: MainPage
        },
        {
            path: '/register',
            name: 'register',
            component: Register
        },
        {
            path: '/user/:id',
            name: 'user',
            component: User
        },
        {
            path: '/message/create/:toUser',
            name: 'messageCreate',
            component: MessageCreate
        }, {
            path: '/gruen',
            name: 'gruenstrom',
            component: gruenstrom
        },
        {
            path: '/message/create/',
            name: 'messageCreateWithTitle',
            component: MessageCreate,
            props: true,
        },
        {
            path: '/message/inbox',
            name: 'inbox',
            component: Inbox
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard
        },
        {
            path: '/feedback',
            name: 'feedback',
            component: Feedback
        },
        {
            path: '/map',
            name: 'map',
            component: Map
        },
        {
            path: '/quartier',
            name: 'quartier',
            component: Quartier
        },
        {
            path: '/devices',
            name: 'devices',
            component: Devices,
            props: true,
        },
        {
            path: '/ressources',
            name: 'ressources',
            component: Ressources
        },
        {
            path: '/highscore',
            name: 'highscore',
            component: Highscore
        },
        {
            path: '/olddevices',
            name: 'olddevices',
            component: OldDevices
        },
        {
            path: '/authcallback',
            name: 'azure',
            component: azureLogin
        },
        {
            path: '/plugins',
            name: 'PluginOverview',
            component: PluginOverview
        },
        {
            path: '/announcements',
            name: 'Announcements',
            component: Announcements
        },
        {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: function() {
                return import ( /* webpackChunkName: "about" */ './views/About.vue')
            }
        },
        {
            path: '/administration',
            component: AdminBoard,
            children: [{
                    path: "",
                    component: AdminGroups
                },
                {
                    path: "groups",
                    component: AdminGroups
                },
                {
                    path: "surveys",
                    component: CreateSurvey
                },
                {
                    path: 'user',
                    component: AdminUser
                },
                {
                    path: 'createuser',
                    component: CreateUser
                },
                {
                    path: 'createvouchers',
                    component: Vouchers
                },
                {
                    path: 'announcements',
                    component: AdminAnnouncements
                },
                {
                    path: 'createressources',
                    component: CreateRessources
                },
                {
                    path: 'rooms',
                    component: CreateRoom
                }, {
                    path: 'services',
                    component: ServiceSettings
                }, {
                    path: 'categories',
                    component: CategorySettings
                },
            ]
        },
        {
            path: '/meetingpoint',
            //  name: "meetingpoint",
            component: MeetingPoint,
            children: [{
                    path: "",
                    component: Ressources
                },
                {
                    name: "market",

                    path: "market",
                    component: Market
                },
                {
                    path: "groups",
                    component: Groups
                },
                {
                    path: 'nbhelp',
                    component: NBHelp
                },
                {
                    path: 'ressources',
                    component: Ressources
                },
                {
                    path: 'highscore',
                    component: Highscore
                },
                {
                    path: 'surveys',
                    component: Surveys
                },
                {
                    path: 'calendar',
                    component: Calendar
                },
                {
                    path: 'events',
                    props: true,
                    component: Events
                },
            ]
        },
    ]
})