<template>
  <div class="component">
    <div class="modal is-active">
      <div class="modal-background" @click="onClose"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Event erstellen</p>
          <button class="delete" aria-label="close" @click="onClose"></button>
        </header>
        <section class="modal-card-body">
          <div class="createEvent" v-if="createEvent">
            <div class="roomForm columns is-mobile is-multiline">
              <label class="label inputBox column is-3-desktop is-12-mobile"
                >Eventname</label
              >
              <input
                type="text"
                v-model="eventName"
                class="input column is-9-desktop is-12-mobile"
              />

              <label class="label inputBox column is-3-desktop is-12-mobile"
                >Beschreibung</label
              >
              <textarea
                v-model="eventDescription"
                class="
                  textarea
                  textareaPartial
                  column
                  is-9-desktop is-12-mobile
                "
              ></textarea>

              <br />

              <label class="label inputBox column is-6-desktop is-12-mobile"
                >Maximale Besucher</label
              >
              <input
                type="number"
                v-model="maxMember"
                class="input column is-6-desktop is-12-mobile"
              />

              <label class="label inputBox column is-6-desktop is-12-mobile"
                >Teilnahmegebühr</label
              >
              <input
                type="number"
                v-model="price"
                class="input column is-6-desktop is-12-mobile"
              />

              <label class="label column inputBox is-6"
                >Teilnehmer manuell bestätigen</label
              >
              <div class="column is-4">
                <toggleButton
                  :class="{ active: activeState }"
                  id="event_handle"
                  v-on:change="triggerEvent"
                  :defaultState="this.activeState"
                  labelEnableText="Ja"
                  labelDisableText="Nein"
                />
              </div>

              <label
                class="label inputBox column is-3-desktop is-12-mobile"
                v-if="place == '' || place == undefined"
                >Raum</label
              >
              <div
                class="
                  column
                  is-9-desktop is-12-mobile
                  roomSelect
                  has-text-centered-mobile
                "
                v-if="place == '' || place == undefined"
              >
                <div class="select">
                  <select v-model="room">
                    <option :value="-1" disabled>Raum auswählen</option>

                    <option
                      v-for="room in allRooms"
                      :value="room.id"
                      :key="room.id"
                    >
                      {{ room.name }}
                    </option>
                  </select>
                </div>
              </div>

              <label class="label inputBox column is-3-desktop is-12-mobile"
                >Individueller Ort</label
              >
              <input
                type="text"
                v-model="place"
                class="input column is-9-desktop is-12-mobile"
              />

              <label class="label inputBox column is-3-desktop is-12-mobile"
                >Tag</label
              >
              <datepicker
                class="column is-9-desktop is-12-mobile"
                input-class="input"
                v-model="eventDate"
              ></datepicker>

              <label class="label inputBox column is-2">Uhrzeit</label>

              <label class="label inputBox column is-1 is-offset-1">Von</label>
              <div class="column is-3">
                <div class="select">
                  <select v-model="startHour">
                    <option value="0">0 Uhr</option>
                    <option v-for="hour in 24" :value="hour" :key="hour.id">
                      {{ hour }} Uhr
                    </option>
                  </select>
                </div>
              </div>

              <label class="label inputBox column is-1 is-offset-1">Bis</label>
              <div class="column is-3">
                <div class="select">
                  <select v-model="endHour">
                    <option
                      v-for="hour in 24"
                      :key="hour.id"
                      :value="hour"
                      v-if="hour > startHour"
                    >
                      {{ hour }} Uhr
                    </option>
                  </select>
                </div>
              </div>

              <!--<label class="label inputBox column is-3-desktop is-12-mobile"
                >Kategorien</label
              >
              <div class="column is-9-desktop is-12-mobile has-text-left">
                <div class="select is-multiple">
                  <select multiple :size="5" v-model="selectedEventTypes">
                    <option
                      v-for="eventType in allEventTypes"
                      :key="eventType.id"
                      :value="eventType.id"
                    >
                      {{ eventType.name }}
                    </option>
                  </select>
                </div>
              </div>-->

              <!--<label class="label inputBox column is-3-desktop is-12-mobile"
                >Neue Kategorie</label
              >
              <div class="column is-9-desktop is-12-mobile">
                <input type="text" class="input" v-model="newEventType" />
                <br /><br />
                <span
                  v-if="newEventType != ''"
                  class="button is-info"
                  @click="createEventType"
                  >Kategorie Hinzufügen</span
                >
              </div>-->
            </div>
          </div>

          <infoModal
            :message="infoMessage"
            :openModal="infoMessage != ''"
            :title="'Information'"
            @closed="infoMessage = ''"
          ></infoModal>
        </section>
        <footer class="modal-card-foot">
          <enaqbutton :width="'200px'" :margin="'5px'" @click="sendEvent">{{
            eventButtonText
          }}</enaqbutton>
          <enaqbutton
            :margin="'5px'"
            :backgroundcolor="'#808080'"
            @click="onClose"
            >Abbrechen</enaqbutton
          >
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import slidebutton from "@/components/helper/slidebutton.vue";
import infoModal from "@/components/UI/infoModal.vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import toggleButton from "@/components/helper/toggleButton.vue";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "editEvent",
  components: {
    slidebutton,
    infoModal,
    Datepicker,
    toggleButton,
    enaqbutton,
  },
  props: ["event"],
  mounted: function () {
    this.getRooms();
    this.getEventTypes();
    if (this.event) {
      this.eventName = this.event.title;
      this.eventDescription = this.event.description;
      this.maxMember = this.event.maxMember;
      this.price = this.event.price;
      if (
        this.event.room != null &&
        this.event.room != undefined &&
        this.event.room.id != undefined
      ) {
        this.room = this.event.room.id;
      }
      this.place = this.event.place;
      this.needsManualParticipateConfirmation =
        this.event.needsManualParticipateConfirmation;
      if (this.needsManualParticipateConfirmation == 0) {
        this.activeState = false;
      }
      if (this.needsManualParticipateConfirmation == 1) {
        this.activeState = true;
      }
      this.eventDate = this.event.date;
      this.startHour = new Date(this.event.begin).getHours();
      this.endHour = new Date(this.event.end).getHours();
      this.selectedEventTypes = this.event.eventids;
      this.eventButtonText = "Änderungen speichern";
      this.updateEvent = true;
    } else {
      this.eventDate = new Date();
    }
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      infoMessage: "",
      allRooms: [],
      createEvent: true,
      eventName: "",
      eventDescription: "",
      maxMember: 0,
      price: 0,
      room: -1,
      place: "",
      needsManualParticipateConfirmation: "",
      eventDate: "",
      startHour: 13,
      endHour: 17,
      allEventTypes: [],
      selectedEventTypes: [],
      newEventType: "",
      eventButtonText: "Event erstellen",
      updateEvent: false,
      activeState: null,
    };
  },
  methods: {
    triggerEvent(value) {
      this.activeState = value;
      if (this.activeState == false) {
        this.needsManualParticipateConfirmation = 0;
      }
      if (this.activeState == true) {
        this.needsManualParticipateConfirmation = 1;
      }
    },
    onClose() {
      this.$emit("closeModal");
    },
    sendEvent() {
      if (!this.validateInput()) {
        return;
      } else {
        this.sendCreateEvent();
      }
      this.onClose();
    },
    validateInput() {
      if (this.eventName == "") {
        this.infoMessage += "Bitte einen Namen für das Event angeben";
      }
      if (
        (this.place == "" || this.place == null) &&
        (this.room == undefined || this.room <= 0 || this.room == null)
      ) {
        this.infoMessage =
          "Bitte wähle einen Raum aus oder trage selbst einen Ort ein";
      } else {
      }

      if (this.infoMessage != "") {
        return false;
      }
      return true;
    },
    getRooms() {
      this.HTTPRequest()
        .getRooms()
        .then(
          function (result) {
            this.allRooms = result.data.rooms;
          }.bind(this)
        )
        .catch(
          function (error) {
            this.infoMessage = "Raum konnte nicht erstellt werden.";
          }.bind(this)
        );
    },
    parseDate(date, hour) {
      hour = "" + hour;
      if (hour.length == 1) {
        hour = "0" + hour;
      }
      let dateString = moment(date).format("YYYY-MM-DD");
      dateString += " " + hour + ":00:00";
      return dateString;
    },
    checkRoom() {
      var begin = this.parseDate(this.eventDate, this.startHour);
      var end = this.parseDate(this.eventDate, this.endHour);
      var eventtoupdate = null;

      if (this.event) {
        eventtoupdate = this.event.id;
      }

      /*this.HTTPRequest()
        .checkRoom(begin, end, this.room, eventtoupdate)
        .then(
          function (result) {
            if (result.data.code == 1) {
              this.sendCreateEvent();
            } else {
              this.infoMessage =
                "Der Raum ist zu diesem Zeitpunkt nicht buchbar.";
            }
          }.bind(this)
        );*/
      this.sendInfoMessage({
        loading: true,
      });
      this.HTTPRequest()
        .checkRoom(begin, end, this.room, eventtoupdate)
        .then(
          function (result) {
            this.sendInfoMessage({
              type: "close",
            });
            if (result.data.code != 1) {
              console.log("Der Raum ist zu diesem Zeitpunkt nicht buchbar.");
            }
          }.bind(this)
        );
    },
    sendCreateEvent() {
      var begin = this.parseDate(this.eventDate, this.startHour);
      var end = this.parseDate(this.eventDate, this.endHour);

      var room = null;
      var place = null;

      if (this.place == "" || this.place == null) {
        if (this.room == undefined || this.room <= 0 || this.room == null) {
          /*this.infoMessage =
            "Bitte wähle einen Raum aus oder trage selbst einen Ort ein";*/
        } else {
          room = this.room;
        }
      } else {
        place = this.place;
      }

      if (this.event) {
        this.HTTPRequest()
          .updateEvent(
            this.event.id,
            this.eventName,
            this.eventDescription,
            this.maxMember,
            this.price,
            this.needsManualParticipateConfirmation,
            begin,
            end,
            this.selectedEventTypes,
            room,
            place
          )
          .then(
            function (result) {
              if (result.data.code == 1) {
                this.eventName = "";
                this.eventDescription = "";
                this.maxMember = 0;
                this.price = 0;
                this.room = null;
                this.place = "";
                this.needsManualParticipateConfirmation = "";
                this.eventDate = "";
                this.startHour = 13;
                this.endHour = 17;
                this.selectedEventTypes = [];
                this.newEventType = "";

                if (this.event) {
                  this.infoMessage = "Das Event wurde aktualisiert.";
                } else {
                  this.infoMessage = "Das Event wurde erstellt.";
                }
              } else if (result.data.code == 15) {
                this.infoMessage =
                  "Der Raum ist zu diesem Zeitpunkt nicht buchbar.";
              }
            }.bind(this)
          )
          .catch(function () {}.bind(this));
      } else {
        this.HTTPRequest()
          .createEvent(
            this.eventName,
            this.eventDescription,
            this.maxMember,
            this.price,
            this.needsManualParticipateConfirmation,
            begin,
            end,
            this.selectedEventTypes,
            room,
            place
          )
          .then(
            function (result) {
              if (result.data.code == 1) {
                this.eventName = "";
                this.eventDescription = "";
                this.maxMember = 0;
                this.price = 0;
                this.room = null;
                this.place = "";
                this.needsManualParticipateConfirmation = "";
                this.eventDate = "";
                this.startHour = 13;
                this.endHour = 17;
                this.selectedEventTypes = [];
                this.newEventType = "";

                if (this.event) {
                  this.infoMessage = "Das Event wurde aktualisiert.";
                } else {
                  this.infoMessage = "Das Event wurde erstellt.";
                }
              } else if (result.data.code == 15) {
                this.infoMessage =
                  "Der Raum ist zu diesem Zeitpunkt nicht buchbar.";
              }
            }.bind(this)
          )
          .catch(function () {}.bind(this));
      }
    },
    getEventTypes() {
      return;
      this.HTTPRequest()
        .getEventTypes()
        .then(
          function (result) {
            this.allEventTypes = result.data.eventTypes;
          }.bind(this)
        );
    },
    createEventType() {
      if (this.newEventType == "") {
        return;
      }
      this.HTTPRequest()
        .createEventType(this.newEventType)
        .then(
          function (result) {
            this.newEventType = "";
            this.getEventTypes();
          }.bind(this)
        );
    },
  },
  computed: {},
  watch: {
    room: function () {
      this.checkRoom();
    },
    eventDate: function () {
      if (this.room != undefined || this.room > 0 || this.room == null) {
      } else {
        this.checkRoom();
      }
    },
    startHour: function () {
      if (this.room == undefined || this.room <= 0 || this.room == null) {
      } else {
        this.checkRoom();
      }
    },
    endHour: function () {
      if (this.room == undefined || this.room <= 0 || this.room == null) {
      } else {
        this.checkRoom();
      }
    },
  },
};
</script>

<style scoped>
.viewTitle {
  font-weight: bolder;
  font-size: 3em;
}

.textareaPartial {
  min-width: 0px;
  margin-bottom: 0.5em;
}

.roomSelect {
  text-align: left;
}

.modal {
  z-index: 2000;
}

.inputBox {
  text-align: left;
}
</style>
