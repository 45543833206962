<!--
Klasse, um verschiedene Nachrichten zu senden.
Durch this.sendInfoMessage(data) aufgerufen.
Data:

{
    type: open|close -> erstelle eine neue nachricht oder schließes sie
    duration: 0-x -> Zeitraum für den die Nachricht offen bleibt bzw bis sie geschlossen wird
    text -> inhalt der nachricht
    loading: true|false -> zeige ladebalken statt text
    
}

-->
<template>
<div class="infoMessage" v-if="isOpen">
    <div class="background">
        <div class="infoMessageContainer">
            <div class="infoMessageContent">
                <transition name="loadingtransition" mode="out-in">

                    <div v-if="isLoading" :key="'loading'" class="button is-loading">
                        Loading
                    </div>

                    <div v-if="!isLoading" class="infoText" :key="'text'">{{infoMessageText}}</div>

                </transition>
            </div>

        </div>
        <transition name="closeButtonTransition">

            <div v-if="!isLoading" class="closeButton" @click="resetInfoMessage"><i class="far fa-times-circle"></i></div>
        </transition>
    </div>

</div>
</template>

<script>
export default {
    name: 'infoMessage',
    props: [""],
    mounted: function () {
        this.$root.$on('sendInfoMessage', function (data) {
            this.infoMessageData = data;
        }.bind(this));
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            isOpen: false,
            isLoading: false,
            infoMessageData: {},
            infoMessageText: '',
            isExpired: true,
            autoClose: null

        }
    },
    methods: {
        parseInfoMessage(infoMessage) {
            this.isExpired = false;

            if (infoMessage.type != undefined && infoMessage.type != null && infoMessage.type == "close") {
                this.resetInfoMessage();
                return;
            }

            this.isOpen = true;

            if (infoMessage.loading == true) {
                this.isLoading = true;
            } else {
                this.isLoading = false;
            }

            if (infoMessage.text != undefined && infoMessage.text != null && infoMessage.text != "") {
                this.infoMessageText = infoMessage.text;
            }
            if (this.autoClose != null) {
                clearTimeout(this.autoClose)
            }
            let timer = infoMessage.duration;
            if (timer != undefined && timer != null && timer > 0) {
                this.autoClose = setTimeout(() => {
                    this.resetInfoMessage()
                    this.autoClose = null;
                }, timer * 1000);
            }

        },
        resetInfoMessage() {
            this.isOpen = false;
            this.infoMessageText = "";
            this.isLoading = false;
            this.isExpired = true;
            if (this.autoClose != null) {
                clearTimeout(this.autoClose)
            }
            this.$emit("close");
        }
    },
    computed: {
        test() {
            return this.infoMessageData;
        }
    },
    watch: {
        infoMessageData: function (newInfo, oldInfo) {
            if (oldInfo == null || oldInfo == undefined || this.isExpired || this.isLoading) {
                this.parseInfoMessage(newInfo)

            } else {
                alert("Doppelte infonachricht")
                console.log("Alte info: " + JSON.stringify(oldInfo) + " --- neue info:" + JSON.stringify(newInfo))

            }

        }
    },
}
</script>

<style scoped>
.infoMessage .background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(10, 10, 10, .94);
    z-index: 99999;
}

.infoMessageContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.infoMessageContainer .is-loading {
    font-size: 6vh;
    border-radius: 40px;
    height: calc(1.5em + 6vh);
}

.infoMessageContent {
    position: relative
}

.infoMessageContainer .infoText {
    background-color: white;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    padding: 0.75em;
    border: 1px solid transparent;
    border-radius: 40px;
    font-size: 2em;
    min-height: 15vh;
    line-height: calc(15vh - 1.5em)
}

.closeButton {
    position: absolute;
    top: 4vh;
    right: 4vw;
    z-index: 99999;
    font-size: 5em;
    border-radius: 40px;
    width: 5vw;
    height: 5vw;
    line-height: 5vw;
    color: white;
    cursor: pointer;
}

.loadingtransition-leave-active,
.loadingtransition-enter-active {
    transition: all 0.5s linear;
    overflow: hidden;
    box-sizing: border-box;
    white-space: nowrap;

}

.loadingtransition-enter,
.loadingtransition-leave-to {
    max-width: 10px;
}

.loadingtransition-leave,
.loadingtransition-enter-to {
    max-width: 100vw;
}

.closeButtonTransition-leave-active,
.closeButtonTransition-enter-active {

    transition: all 0.2s linear;
    overflow: hidden;
    box-sizing: border-box;
}

.closeButtonTransition-enter,
.closeButtonTransition-leave-to {
    font-size: 0px;

}

.closeButtonTransition-leave,
.closeButtonTransition-enter-to {
    transform: rotate(360deg);
    font-size: 5rem;
}
</style>
