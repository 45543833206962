<template>
<div class="component">
    <div class="powerSource columns is-mobile is-multiline" v-if="showGraph">
        <div class="column is-5 ownPowerSource">
            <div class="title has-text-left">Eigener Strombezug</div>
            <piediagramm :graphData="ownPowerSourceData" :graphLabels="['Eigenerzeugung','Fremdbezug','Speicher']"></piediagramm>
        </div>

        <div class="column is-5 is-offset-2 quarterPowerSource">
            <div class="title has-text-left">Strombezug des Quartiers</div>
            <piediagramm :graphData="getRandomData()" :graphLabels="['Eigenerzeugung','Fremdbezug','Speicher']"></piediagramm>
        </div>

    </div>

    <div class="pricechart">
        <div class="title">Kosten je KW/H</div>
        <linediagramm :graphData="priceData"></linediagramm>

    </div>
</div>
</template>

<script>
import piediagramm from '@/components/Devices/piediagramm.vue'
import linediagramm from '@/components/Devices/linediagramm.vue'

export default {
    name: 'compareQuarter',
    props: ["selectedPeriode"],
    components: {
        piediagramm,
        linediagramm
    },
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            showGraph: true,
        }
    },
    methods: {
        getRandomData(amount = 3) {
            let randomData = []
            for (var i = 0; i < amount; i++) {
                randomData.push(Math.floor(Math.random() * 10) + ((2 - i) * 10))

            }
            return randomData;
        },
        
    },
    computed: {
        ownPowerSourceData() {
            this.showGraph = false;
            let sourcedata = [];
            var x = this.selectedPeriode;
            sourcedata.push(Math.floor(Math.random() * 10) + 13)
            sourcedata.push(Math.floor(Math.random() * 10) + 4)
            sourcedata.push(Math.floor(Math.random() * 10) + 2)
            this.showGraph = true;
            return sourcedata;
        },
        priceData() {
            var x = this.selectedPeriode;

            let pricedata = [{
                data: [],
                name: "KEHAG",
                dates: []
            }, {
                data: [],
                name: "Durchschnittlicher Festpreis",
                dates: []
            }]
            for (var i = 0; i < 24; i++) {
                var costs = (Math.floor(Math.random() * 1000) + 200) / 1000;
                pricedata[0].data.push(costs)
                pricedata[0].dates.push(i + ":00:00")
                pricedata[1].data.push(0.9)
            }

            return pricedata;
        }
    },
}
</script>

<style scoped>
.powerSource {
    height: 30vh;
    margin-top: 5vh;
}

.pricechart {
    height: 30vh;
    margin-top: 5vh;
}
</style>
