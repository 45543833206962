var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component"},[(_vm.loaded)?_c('div',{staticClass:"modal is-active"},[_c('div',{staticClass:"modal-background",on:{"click":_vm.closeModal}}),_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.message.title))]),_c('button',{staticClass:"delete",attrs:{"aria-label":"close"},on:{"click":_vm.closeModal}})]),_c('section',{staticClass:"modal-card-body"},[_vm._v(" "+_vm._s(_vm.message.message)+" ")]),_c('footer',{staticClass:"modal-card-foot"},[_c('enaqbutton',{attrs:{"backgroundcolor":'#808080',"margin":'5px'},on:{"click":_vm.closeModal}},[_vm._v("Schließen")]),_c('enaqbutton',{attrs:{"margin":'5px'}},[_c('router-link',{attrs:{"to":{
                name: 'messageCreateWithTitle',
                params: {
                  messagetitle: 'AW: ' + this.message.title,
                  messagereciever: this.message.author_id,
                },
              }}},[_vm._v(" Antworten ")])],1),_c('div',{staticClass:"authorInfo columns is-mobile is-multiline",on:{"click":function($event){return _vm.$router.push({ name: 'user', params: { id: _vm.message.author.id } })}}},[_c('span',{staticClass:"column is-2-desktop is-3-mobile is-offset-3-desktop is-offset-1-mobile has-text-left-mobile authorTitle"},[_vm._v("Von: ")]),_c('span',{staticClass:"column is-2-desktop is-hidden-mobile authorImageContainer"},[_c('img',{staticClass:"authorImage",attrs:{"src":_vm.$storageURL + _vm.message.author.avatar}})]),_c('span',{staticClass:"authorName column is-5-desktop is-8-mobile has-text-right-mobile"},[_vm._v(_vm._s(_vm.message.author.name)+", "+_vm._s(_vm.message.author.firstname))])])],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }