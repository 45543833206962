import { render, staticRenderFns } from "./addSurvey.vue?vue&type=template&id=ff5766e4&scoped=true&"
import script from "./addSurvey.vue?vue&type=script&lang=js&"
export * from "./addSurvey.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff5766e4",
  null
  
)

export default component.exports