<template>
<div class="component">
    <div class="consumptionCarousel" v-if="consumptionData.length=3">
        <transition-group name="circleListTransition" tag="div" class="carouseltransition">

            <consumptionCarouselItem :style="getCItemStyle" v-if="consumptionData != null && consumptionData[0]!=undefined" v-for="consData in consumptionData" :key="consData.Class" class="circle" @moredetailsClicked="rotate(circleData[consData.Class])" :class="[{'growingCircle':growingCircle==consData.Class,'shrinkingCircle':shrinkingCircle==consData.Class},circleData[consData.Class]]" :consumptionData="consData" :isActive="consData.IsActive" />

            <!--
        <consumptionCarouselItem :key="'own'" class=" circle" @moredetailsClicked="rotate(circleData['ownCircleClass'])" :class="[circleData['ownCircleClass']]" :consumptionData="this.consumptionData[0]" :isActive="circleData['ownCircleClass']=='circle_middle'" />
        <consumptionCarouselItem :key="'quarter'" class=" circle" @moredetailsClicked="rotate(circleData['quarterCircleClass'])" :class="[circleData['quarterCircleClass']]" :consumptionData="this.consumptionData[1]" :isActive="circleData['quarterCircleClass']=='circle_middle'" />
        <consumptionCarouselItem :key="'house'" class=" circle" @moredetailsClicked="rotate(circleData['houseCircleClass'])" :class="[circleData['houseCircleClass']]" :consumptionData="this.consumptionData[2]" :isActive="circleData['houseCircleClass']=='circle_middle'" />
    -->
        </transition-group>

    </div>
</div>
</template>

<script>
import consumptionCarouselItem from '@/components/Devices/DeviceCarousel/consumptionCarouselItem.vue'

export default {
    name: 'consumptioCarousel',
    props: [],
    components: {
        consumptionCarouselItem
    },
    mounted: function () {
        this.getDeviceConsumptionData();
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            consumptionData: [],
            activeCircle: 1,

            circleData: {
                'houseCircleClass': 'circle_left',
                'ownCircleClass': 'circle_middle',
                'quarterCircleClass': 'circle_right'
            },
            circlePosition: {
                left: 'houseCircleClass',
                middle: 'ownCircleClass',
                right: 'quarterCircleClass'
            },
            growingCircle: '',
            shrinkingCircle: '',
        }
    },
    methods: {

        rotate(circlePosition) {
            if (circlePosition == 'circle_left') {
                this.rotateLeft();
            } else if (circlePosition == 'circle_right') {
                this.rotateRight();
            }
        },
        rotateLeft() {

            let currentLeftItem = this.consumptionData[0];
            let currentMiddleItem = this.consumptionData[1];

            let currentRightItem = this.consumptionData[2];
            this.consumptionData = [];
            this.consumptionData[0] = currentRightItem;
            this.consumptionData[1] = currentLeftItem;
            this.consumptionData[2] = currentMiddleItem;

            this.growingCircle = currentLeftItem.Class
            this.shrinkingCircle = currentMiddleItem.Class

            this.circleData[currentLeftItem.Class] = 'circle_middle';
            this.circleData[currentMiddleItem.Class] = 'circle_right';
            this.circleData[currentRightItem.Class] = 'circle_left';
            this.consumptionData[0].IsActive = false;
            this.consumptionData[1].IsActive = true;
            this.consumptionData[2].IsActive = false;

            /*
                        let currentLeftItem = this.consumptionData[0];
                        let currentMiddleItem = this.consumptionData[1];

                        this.consumptionData[0] = currentMiddleItem;
                        this.consumptionData[1] = currentLeftItem;

                        this.circleData[currentLeftItem.Class] = 'circle_middle';
                        this.circleData[currentMiddleItem.Class] = 'circle_left';
                        this.consumptionData[0].IsActive = false;
                        this.consumptionData[1].IsActive = true;
                        this.consumptionData[2].IsActive = false;

                        return;
            */

        },
        rotateRight() {

            let currentLeftItem = this.consumptionData[0];
            let currentMiddleItem = this.consumptionData[1];
            let currentRightItem = this.consumptionData[2];

            this.consumptionData[0] = currentMiddleItem;
            this.consumptionData[1] = currentRightItem;
            this.consumptionData[2] = currentLeftItem;

            this.circleData[currentLeftItem.Class] = 'circle_right';
            this.circleData[currentMiddleItem.Class] = 'circle_left';
            this.circleData[currentRightItem.Class] = 'circle_middle';
            this.consumptionData[0].IsActive = false;
            this.consumptionData[1].IsActive = true;
            this.consumptionData[2].IsActive = false;

            /*
                        let currentRightItem = this.consumptionData[2];
                        let currentMiddleItem = this.consumptionData[1];
                        this.consumptionData[2] = currentMiddleItem;
                        this.consumptionData[1] = currentRightItem;

                        this.circleData[currentRightItem.Class] = 'circle_middle';
                        this.circleData[currentMiddleItem.Class] = 'circle_right';
                        this.consumptionData[2].IsActive = false;
                        this.consumptionData[1].IsActive = true;

                        return;
            */

        },

        getDeviceConsumptionData() {
            this.consumptionData = [];

            this.consumptionData.push({
                Class: 'houseCircleClass',
                IsActive: false,

                Title: this.$t('enaq_translation_devices_house'),
                Consumption: Math.floor(Math.random() * 200) + 500,
                Costs: Math.floor(Math.random() * 50) + 200,
                CostAverage: Math.floor(Math.random() * 30) + 80,
                CostSaving: Math.floor(Math.random() * 20) + 25,
                ConsumptionAverage: Math.floor(Math.random() * 100) + 250,
                CO2: Math.floor(Math.random() * 400) + 500,
                Icon: "icons/Home_Icon.png",
                Rating: 2,
                CircleColorStart: "#FF8A00",
                CircleColorEnd: "#DCB900",
                HeaderColor: "#CC5C00",
                RatingPercentage: Math.floor(Math.random() * 98) + 1,

            })

            this.consumptionData.push({
                Class: 'ownCircleClass',
                IsActive: true,
                Title: this.$t('enaq_translation_devices_own'),
                Consumption: Math.floor(Math.random() * 100) + 100,
                Costs: Math.floor(Math.random() * 20) + 20,
                CostAverage: Math.floor(Math.random() * 10) + 10,
                CostSaving: Math.floor(Math.random() * 7) + 7,
                ConsumptionAverage: Math.floor(Math.random() * 100) + 90,
                CO2: Math.floor(Math.random() * 300) + 200,
                Icon: "icons/Profile_Icon.png",
                Rating: 0,
                CircleColorStart: "#504596",
                CircleColorEnd: "#009FBB",
                HeaderColor: "#003E68",
                RatingPercentage: Math.floor(Math.random() * 98) + 1,

            });

            this.consumptionData.push({
                Class: 'quarterCircleClass',
                IsActive: false,

                Title: this.$t('enaq_translation_devices_quarter'),
                Consumption: Math.floor(Math.random() * 1000) + 1000,
                Costs: Math.floor(Math.random() * 150) + 800,
                CostAverage: Math.floor(Math.random() * 80) + 50,
                CostSaving: Math.floor(Math.random() * 40) + 130,
                ConsumptionAverage: Math.floor(Math.random() * 300) + 500,
                CO2: Math.floor(Math.random() * 1000) + 2000,
                Icon: "icons/Quarter_Icon.png",
                Rating: 1,
                CircleColorStart: "#F60363",
                CircleColorEnd: "#FF745C",
                HeaderColor: "#89003E",
                RatingPercentage: Math.floor(Math.random() * 98) + 1,

            })

        },
    },
    computed: {
        getCItemStyle() {
            return {}
        }
    },
}
</script>

<style scoped>
.circleListTransition-move {
    transition: all 4.5s;

}

/*
.inactiveCircle {
    width: 30%;
    display: inline-block;
}

.activeCircle {
    width: 40%;
    display: inline-block;
}

.leftCircle{
    padding-left:10%
}

.rightCircle{
    padding-right:30%;
}
*/
.consumptionCarousel {
    position: relative;
    min-height: 80vh;
    width: 100vw;

}

.circle {
    position: absolute;
    position: relative;
    vertical-align: top;
    display: inline-block;
    transition: all 0.5s;

}


@keyframes circleSmaller {
    0% {
    }

    100% {
        transform: scale(1.0);
    }
}

.circle_left,
.circle_right {
    width: 33%;
    transform: scale(.6);

}

.growingCircle {

}

.shrinkingCircle {


}

.circle_middle {
    width: 33%;
    transition: all 0.5s;

}
</style>
