<template>
  <div class="component">
    <div class="tabs" v-if="$showLocalisation">
      <ul>
        <li
          class=""
          @click="activeTab = 'de'"
          :class="{ 'is-active': activeTab == 'de' }"
        >
          <a>Deutsch</a>
        </li>
        <li
          @click="activeTab = 'en'"
          :class="{ 'is-active': activeTab == 'en' }"
        >
          <a>English</a>
        </li>
      </ul>
    </div>

    <div v-if="activeTab == 'de'">
      <div class="columns is-mobile is-multiline">
        <div class="column is-2">Titel (DE)</div>
        <div class="column is-10">
          <input type="text" class="input" v-model="title_de" />
        </div>

        <div class="column is-2">Nachricht (DE)</div>
        <div class="column is-10">
          <textarea class="textarea" v-model="message_de"></textarea>
        </div>
      </div>
    </div>

    <div v-if="activeTab == 'en'">
      <div class="columns is-mobile is-multiline">
        <div class="column is-2">Title (EN)</div>
        <div class="column is-10">
          <input type="text" class="input" v-model="title_en" />
        </div>

        <div class="column is-2">Message (EN)</div>
        <div class="column is-10">
          <textarea class="textarea" v-model="message_en"></textarea>
        </div>
      </div>
    </div>

    <div class="select is-multiple userSelect">
      <select multiple :size="userList.length" v-model="selectedUser">
        <option v-for="user in userList" :key="user.id" :value="user.id">
          {{ user.name }}, {{ user.firstname }}
        </option>
      </select>
    </div>
    <enaqbutton
      :fontsize="'20px'"
      :width="'200px'"
      :margin="'15px'"
      @click="sendMessage()"
      >Senden</enaqbutton
    >
    {{ errorMessage }}
    <infoModal
      :message="infoMessage"
      :openModal="infoMessage != ''"
      :title="infoTitle"
      @closed="infoMessage = ''"
    ></infoModal>
  </div>
</template>

<script>
import infoModal from "@/components/UI/infoModal.vue";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "createMessage",
  props: ["messagereciever", "messagetitle"],
  components: {
    infoModal,
    enaqbutton,
  },
  mounted: function () {
    this.getUserList();
    if (
      this.$route.params.toUser != undefined &&
      this.$route.params.toUser > 0
    ) {
      this.selectedUser = [this.$route.params.toUser];
    } else if (this.messagereciever) {
      this.selectedUser = [this.messagereciever];
    }
    if (
      this.messagetitle != "" &&
      this.messagetitle != undefined &&
      this.messagetitle != null
    ) {
      this.title_de = this.messagetitle;
    }
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      activeTab: "de",
      userList: [],
      selectedUser: [],
      title_de: "",
      message_de: "",
      title_en: "",
      message_en: "",
      errorMessage: "",
      infoMessage: "",
      infoColor: "",
      infoTitle: "",
    };
  },
  methods: {
    getUserList() {
      this.callApi("/user/list/all").then(
        function (response) {
          this.userList = response.data.user;
        }.bind(this)
      );
    },

    sendMessage() {
      this.validateData();
      if (this.infoMessage != "") {
        return;
      }
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };

      let messageData = {
        title: this.title_de,
        message: this.message_de,
        title_en: this.title_en,
        message_en: this.message_en,
        user: this.selectedUser,
      };

      this.callApi("/message/send", "post", messageData).then(
        function (response) {
          this.infoMessage = "Nachricht gesendet";
          (this.title_de = ""),
            (this.message_de = ""),
            (this.title_en = ""),
            (this.message_en = ""),
            (this.selectedUser = []);
        }.bind(this)
      );
    },
    validateData() {
      this.infoMessage = "";
      this.infoColor = "";
      this.infoTitle = "";
      if (this.message_de == "" || this.title_de == "") {
        this.infoMessage = "Bitte einen Titel und eine Nachricht angeben";
        this.infoTitle = "Warnung";
      }
      if (this.selectedUser.length <= 0) {
        this.infoMessage = "Bitte einen Empfänger auswählen";
        this.infoTitle = "Warnung";
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.component {
  padding-top: 20px;
}

.userSelect {
  padding-top: 20px;
}

.userSelect option {
  padding: 0.5em 3em;
}

.select,
select {
  width: 80%;
  margin: auto;
}
</style>
