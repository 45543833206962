//Vue components
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//Functions
import axios from 'axios'

//Design, UI
import './../node_modules/bulma/css/bulma.css';
import Clipboard from 'v-clipboard'
import VueApexCharts from 'vue-apexcharts'
import VueMonthlyPicker from 'vue-monthly-picker'
import msal from 'vue-msal'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStoreSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

//library.add(faStoreSlash)
//Vue.component('font-awesome-icon', FontAwesomeIcon)


//Localization
import VueI18n from 'vue-i18n'
import CountryFlag from 'vue-country-flag'
import { languages } from './lang/localisation.js'
import { defaultLocale } from './lang/localisation.js'
import { fallbackLocale } from './lang/localisation.js'

import * as VueGoogleMaps from 'vue2-google-maps'
import AuthService from './helper/msal.js'
import RouterHelper from './helper/routerHelper.js'


Vue.prototype.$AuthService = new AuthService()


import enaqbutton from "./components/UI/enaqbutton"

//Mixins
import HTTPRequestsMixin from './mixins/HTTPRequestsMixin'
Vue.mixin(HTTPRequestsMixin)

//Plugins




/*
import ('./Plugins/ExamplePlugin/main.js').then(
    (install) => {
        console.log(" importing: " + JSON.stringify(install))
        install.testexport();
        Vue.use(
            install, { "store": store })
    }
).catch(
    (error) => {
        console.log("error importing: " + JSON.stringify(error))
            // handle the case where module didn't load or something went wrong
    }
);

let { default: installd } =
import ('./Plugins/ExamplePlugin/main.js');

   */


import ExamplePlugin from './Plugins/ExamplePlugin/main.js'
Vue.use(ExamplePlugin, { "store": store })
import Neu_ExamplePlugin from './Plugins/Neu_ExamplePlugin/main.js'
Vue.use(Neu_ExamplePlugin, { "store": store })
import Neu3_ExamplePlugin from './Plugins/Neu3_ExamplePlugin/main.js'
Vue.use(Neu3_ExamplePlugin, { "store": store })
import Neu4_ExamplePlugin from './Plugins/Neu4_ExamplePlugin/main.js'
Vue.use(Neu4_ExamplePlugin, { "store": store })
import Neu5_ExamplePlugin from './Plugins/Neu5_ExamplePlugin/main.js'
Vue.use(Neu5_ExamplePlugin, { "store": store })


//Global components
Vue.component('ebutton', enaqbutton)

Vue.prototype.$plugins = [""]
const messages = Object.assign(languages)

Vue.use(VueI18n)
    // https://login.microsoftonline.com/4ce0085b-4817-400f-bce1-2cfc1a60f5bd/v2.0/.well-known/openid-configuration
    /*Vue.use(msal, {
        auth: {
            clientId: '490b390a-87dc-41ca-9e8c-fa382f9d9b83',
            tenantName: "login.microsoftonline.com",
            tenantId: "common",
        }
    });
    */
var supportedLanguages = [];
for (var lang in messages) supportedLanguages.push(lang);

Vue.prototype.$axios = axios

Vue.config.productionTip = false

Vue.prototype.$supportedLanguages = supportedLanguages;
Vue.prototype.$allowedUnauthenticatedRoutes = ['login', 'register', 'impressum', 'feedback', "azure"]
Vue.prototype.$adminRoutes = ['createUser', 'vouchers', 'administration']
Vue.prototype.$backendURL = process.env.VUE_APP_backendBaseUrl
Vue.prototype.$storageURL = process.env.VUE_APP_backendStorageUrl

Vue.prototype.$showLocalisation = false;




Vue.prototype.callServicePlatform = async function(endpoint, type = 'get', payload = {}, header = null, showError = true, customEndpoint = false) {
    var token = Vue.prototype.$AuthService.getUser();
    console.log(token)
    return;
    var promise = new Promise(function(resolve, reject) {
        let headerData = header;

        //Only add authorization-header when backend is called
        if (header == null && !customEndpoint) {
            headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }
        }
        let apiUrl = Vue.prototype.$backendURL + endpoint;
        if (customEndpoint) {
            apiUrl = endpoint
        }
        if (type == 'post') {
            Vue.prototype.$axios.post(apiUrl, payload, {
                headers: headerData
            }).then(function(result) {
                Vue.prototype.checkForError(result)
                resolve(result);

            }.bind(this)).catch(function(error) {

                Vue.prototype.checkForError(error, 'server')

                reject(error);
            }.bind(this))
        } else {
            Vue.prototype.$axios.get(apiUrl, {
                headers: headerData
            }).then(function(result) {
                Vue.prototype.checkForError(result)

                resolve(result);

            }.bind(this)).catch(function(error) {
                reject(error);
                Vue.prototype.checkForError(error, 'server')

            }.bind(this))
        }

    }.bind(this));
    return promise;
}



Vue.prototype.sendInfoMessage = function(data) {
    this.$root.$emit('sendInfoMessage', data)
}

Vue.component('apexchart', VueApexCharts)
Vue.component('country-flag', CountryFlag)

const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: fallbackLocale,
    messages
})

let routerhelper = new RouterHelper(Vue, store);
routerhelper.setRoutesWithoutAuth(Vue.prototype.$allowedUnauthenticatedRoutes)
routerhelper.setAdminRoutes(Vue.prototype.$adminRoutes)
    //Prüfe ob der Nutzer eingeloggt ist oder eine Seite besucht die dies nicht benötigt. Ansonsten weiterleitung zur Loginseite.
    //Setzt außerdem den Nutzernamen (VueX)
    //Prüfung bei jedem Seitenaufruf, sowohl direkt per URL als auch per Link
router.beforeEach((to, from, next) => {
    router.app.$emit('pageclick', null)
    router.app.$emit('navigationChange', to.matched[0].path)
    let parentRoute = to.matched[0].path

    //  console.log("Try to navigate to " + to.name + " from " + from.name)
    //  console.log("Parent" + parentRoute)
    if (!routerhelper.needsRouteAuthentication(to.name)) {
        //Always allow routes that dont need authentification
        //  console.log("route dont need auth")
        next();
    } else {
        //Check if user has valid login and accessrights
        routerhelper.userCanAccessRoute(to.name).then(function(response) {

            if (response.allowedToAccess) {
                next();
            } else {
                if (process.env.VUE_APP_useLocalAuth) {
                    next("/login");

                } else {
                    next("/authcallback");

                }
            }
        })
    }

})


Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyA5GjrZCUkiOJYF76A3yp86Qgg68yeL96c',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)


    },
    //installComponents: false

})



let vueInstance = new Vue({
    i18n,
    router,
    store,

    render: function(h) { return h(App) }
}).$mount('#app')

Vue.use(Clipboard);
Vue.use(VueMonthlyPicker);





//type: server(504,...) or data(wrong password,...) 
Vue.prototype.checkForError = function(error, type = "data") {
    /*console.log("in checkerror")*/
    if (type == 'server') {
        let errorcodes = {
            '401': 'Can\'t authorize on Server',
            '422': 'Invalid data: ' + JSON.stringify(error.response.data.errors),
            '500': 'Servererror'

        }
        let errormessage = errorcodes[error.response.status] || 'Servererror';
        // this.$root.$emit('sendInfoMessage', data)
        vueInstance.$emit('sendInfoMessage', { 'text': errormessage })

        //  Vue.prototype.sendInfoMessage({ 'text': errormessage });
    } else {
        /*console.log("errortype is not server")
        console.log("errorcode is " + JSON.stringify(error.data.code))*/
        if (error.data.code == 1 || error.data.code == "1" || error.data.code == undefined || error.data.code == null) {
            return true;
        }
        let errorcodes = {
            '2': 'Invalid Data given',
            '3': 'Unauthorized',
            '4': 'Duplicate entry',
            '5': 'You can only perform this as an Admin',
            '12': 'Some data is missing',
            '13': 'Error - ?',
            '14': 'User has not requestet to participate',
            '15': 'The room is not available',
            '16': 'The user does not participate and can not be removed',
            '17': 'Begin is after end',
            '18': 'User is already a member',
            '19': 'The event is full',
            '25': 'The ressouce is not available at this time',
            '31': 'Voucher',
            '32': 'Invalid voucher',
            '33': 'Voucher already used',
        }
        let errormessage = errorcodes[error.data.code] || 'Internal error';
        console.log("errormessage is " + errormessage)
        vueInstance.$emit('sendInfoMessage', { 'text': errormessage })


    }
    return false;
}