<template>
  <div class="component">
    <div class="meetingpointNavigationContainer">
      <div class="meetingpointNavigation">
        <div
          class="meetingpointNavLink"
          @click="activeLink = 'market'"
          :class="{ activeMeetingpointLink: activeLink == 'market' }"
        >
          <router-link :to="'/meetingpoint/market'">
            <i class="icon fas fa-shopping-cart"></i>
            {{ $t("enaq_translation_meetingpoint_market") }}</router-link
          >
        </div>
        <div
          class="meetingpointNavLink"
          @click="activeLink = 'groups'"
          :class="{ activeMeetingpointLink: activeLink == 'groups' }"
        >
          <router-link :to="'/meetingpoint/groups'">
            <i class="icon fas fa-comments"></i
            >{{ $t("enaq_translation_meetingpoint_groups") }}</router-link
          >
        </div>
        <div
          class="meetingpointNavLink"
          @click="activeLink = 'nbhelp'"
          :class="{ activeMeetingpointLink: activeLink == 'nbhelp' }"
        >
          <router-link :to="'/meetingpoint/nbhelp'">
            <i class="icon fas fa-handshake"></i>
            {{ $t("enaq_translation_meetingpoint_nbhelp") }}</router-link
          >
        </div>
        <div
          class="meetingpointNavLink"
          @click="activeLink = 'ressources'"
          :class="{ activeMeetingpointLink: activeLink == 'ressources' }"
        >
          <router-link :to="'/meetingpoint/ressources'">
            <i class="icon fas fa-bicycle"></i>
            {{ $t("enaq_translation_meetingpoint_ressources") }}</router-link
          >
        </div>
        <div
          class="meetingpointNavLink"
          @click="activeLink = 'highscore'"
          :class="{ activeMeetingpointLink: activeLink == 'highscore' }"
        >
          <router-link :to="'/meetingpoint/highscore'">
            <i class="icon fas fa-trophy"></i>
            {{ $t("enaq_translation_meetingpoint_highscore") }}</router-link
          >
        </div>
        <div
          class="meetingpointNavLink"
          @click="activeLink = 'surveys'"
          :class="{ activeMeetingpointLink: activeLink == 'surveys' }"
        >
          <router-link :to="'/meetingpoint/surveys'">
            <i class="icon fas fa-bullhorn"></i>
            Umfragen</router-link
          >
        </div>
        <div
          class="meetingpointNavLink"
          @click="activeLink = 'calendar'"
          :class="{ activeMeetingpointLink: activeLink == 'calendar' }"
        >
          <router-link :to="'/meetingpoint/calendar'">
            <i class="icon fas fa-calendar"></i>Kalender</router-link
          >
        </div>
        <div
          class="meetingpointNavLink"
          @click="activeLink = 'events'"
          :class="{ activeMeetingpointLink: activeLink == 'events' }"
        >
          <router-link :to="'/meetingpoint/events'">
            <i class="icon fas fa-sun"></i>
            Events</router-link
          >
        </div>
      </div>
    </div>

    <div class="meetingpointContent">
      <router-view />
    </div>
  </div>
</template>

<script>
import Market from "@/views/Meetingpoint/Market.vue";
import NBHelp from "@/views/Meetingpoint/NBHelp.vue";
import Highscore from "@/views/Meetingpoint/Highscore.vue";
import Ressources from "@/views/Meetingpoint/Ressources.vue";
import Groups from "@/views/Meetingpoint/Groups.vue";

export default {
  name: "Meetingpoint",
  props: ["viewToOpen"],
  components: {
    Market,
    NBHelp,
    Highscore,
    Ressources,
    Groups,
  },
  mounted: function () {
    if (this.$route.params.viewToOpen != undefined) {
      this.activeLink = this.$route.params.viewToOpen;
    }
    if (this.$route.params.additionalRouteValues != undefined) {
      this.additionalRouteValues = this.$route.params.additionalRouteValues;
      console.log(
        "add additional groupvalue: " + this.$route.params.additionalRouteValues
      );
    }
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      activeLink: "market",
      additionalRouteValues: undefined,
    };
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.icon {
  color: #164786;
}

.meetingpointNavigationContainer {
  display: inline-block;
  width: 16%;
  position: fixed;
  left: 0;
  top: 124px;
  bottom: 0;
  overflow-x: hidden;
}

.meetingpointNavigation {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #e6e6e6;
  padding-left: 15px;
  padding-right: 10px;
}

.meetingpointNavLink {
  text-align: start;
  text-decoration: none;
}

.meetingpointNavLink a {
  text-decoration: none !important;
  color: black !important;
  font-size: 1.2em;
  border-bottom: 1px solid grey;
  width: 100%;
  line-height: 3em;
  display: block;
}

.meetingpointContent {
  display: inline-block;
  width: 84%;
  margin-left: 16%;
}

.activeMeetingpointLink a {
  color: #e9a12d !important;
  font-size: 1.2rem;
  font-weight: bolder;
}
</style>