<template>
  <div class="component">
    <transition name="groupListTransition">
      <groupList
        v-if="selectedGroup == null"
        @selectGroup="selectGroup"
      ></groupList>
    </transition>
    <transition name="groupTransition">
      <group
        @closegroup="selectedGroup = null"
        v-if="selectedGroup != null"
        :group="selectedGroup"
      ></group>
    </transition>
  </div>
</template>

<script>
import groupList from "@/components/Meetingpoint/Groups/groupList.vue";
import group from "@/components/Meetingpoint/Groups/group.vue";

export default {
  name: "Groups",
  props: ["groupToOpen"],
  components: {
    groupList,
    group,
  },
  mounted: function () {
    if (this.$route.params.groupToOpen != undefined) {
      this.selectedGroup = this.$route.params.groupToOpen;
    }
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      selectedGroup: null,
    };
  },
  methods: {
    selectGroup(group) {
      this.selectedGroup = group;
    },
  },
  computed: {},
  watch: {
    groupToOpen: function (newVal, oldVal) {
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>

<style scoped>
.component {
  position: relative;
}

.groupTransition-enter-active {
  transition: all 0.8s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.groupTransition-leave-active {
  transition: all 0.8s;
}

.groupTransition-enter,
.groupTransition-leave-to {
  transform: translateX(100vw);
}

.groupTransition-enter-to {
  transform: translateX(0vw);
}

.groupListTransition-enter-active {
  transition: all 0.8s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.groupListTransition-leave-active {
  transition: all 0.8s;
}

.groupListTransition-enter,
.groupListTransition-leave-to {
  transform: translateX(-100vw);
}

.groupListTransition-enter-to {
  transform: translateX(0vw);
}

.groupListTransition-leave {
  transform: translateX(110vw);
}
</style>
