<template>
<div class="">
  <h1 class="title ">Benutzerverwaltung</h1>

<div class="tabs is-centered">
  <ul>
    <li :class="{'is-active':activeTab=='userEdit'}" @click="activeTab='userEdit'"><a>Benutzer</a></li>
    <li :class="{'is-active':activeTab=='vouchers'}" @click="activeTab='vouchers'"><a>Voucher</a></li>
  </ul>
</div>
    <div v-if="activeTab=='userEdit'">
        <div class="button" @click="newUserClick">Neuer Benutzer</div>
        <div class="userList">
            <table class="table is-fullwidth">
                <tr>
                    <th>Benutzername</th>

                    <th>Status</th>
                </tr>
                <tr v-for="user in userList">
                    <td @click="editUserCLick(user)">{{user.name}}, {{user.firstname}}</td>
                    <td v-if="user.active"><i class="fas fa-lock-open unlockedIcon" @click="lockUser(user.id)"></i></td>
                    <td v-if="!user.active"><i class="fas fa-lock lockedIcon" @click="unlockUser(user.id)"></i></td>
                </tr>
            </table>
        </div>
        <editUser :user="userEditData" :modalState="userEditModalState" v-if="userEditModalState!='closed'" @close="userEditModalState='closed'"></editUser>
    </div>

    <div v-if="activeTab=='vouchers'"><vouchers></vouchers></div>
</div>
</template>

<script>
import editUser from '@/views/admin/editUser.vue';
import vouchers from '@/views/admin/vouchers.vue';

export default {
    name: 'adminUser',
    components: {
        editUser,
        vouchers
    },
    props: [],
    mounted: function () {
        this.getAllUser();
    },
    data() {
        return {
            openUserList: false,
            userList: null,
            userEditData: null,
            userEditModalState: "closed",
            activeTab: "userEdit",

        }
    },
    methods: {
        getAllUser() {
            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }

            this.$axios.get(this.$backendURL + "/user/list/all/admin", {
                headers: headerData
            }).then(function (result) {
                this.userList = result.data.user
            }.bind(this))
        },
        lockUser(id) {
            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }

            this.$axios.post(this.$backendURL + "/user/admin/" + id + "/lock", null, {
                headers: headerData
            }).then(function (result) {
                this.getAllUser();
            }.bind(this))
        },
        unlockUser(id) {
            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }

            this.$axios.post(this.$backendURL + "/user/admin/" + id + "/unlock", null, {
                headers: headerData
            }).then(function (result) {
                this.getAllUser();
            }.bind(this))
        },
        newUserClick() {
            this.userEditData = {
                "firstName": "",
                "name": "",
                "moveinDate": "",
                "birthdate": "",
                "salutation": 0
            };
            this.userEditModalState = "newUser"
        },
        editUserCLick(user) {
            this.userEditData = user;
            this.userEditModalState = "editUser";
        }
    },
    computed: {},
}
</script>

<style scoped>
.lockedIcon {
    color: red;
}

.unlockedIcon {
    color: green;
}
</style>
