<template>
  <div>
    <br />
    <div class="component" @click="$emit('selectAnnouncement', announcement)">
      <div class="card">
        <div class="header">
          <p class="announcement_title">{{ announcement.title }}</p>
        </div>
        <div class="description">
          <p>Kurzbeschreibung: {{ announcement.description }}</p>
        </div>

        <div class="content">
          <p>{{ announcement.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "component",
  props: ["announcement"],
};
</script>

<style scoped>
.card {
  width: 100%;
  height: 100%;
  background-color: #1d64b2;
  border-radius: 20px;
  box-shadow: -10px 10px 15px #777777;
  margin: 15px;
  color: white;
  border: 1px solid #000000;
  padding: 5px;
}

.header {
  color: #E9A12D;
  font-size: 22px;
  font-weight: 500;
  text-decoration: underline;
}

.description {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
}
</style>
