<template>
  <div class="component">
    <div class="tabs" v-if="$showLocalisation">
      <ul>
        <li
          class=""
          @click="activeTab = 'de'"
          :class="{ 'is-active': activeTab == 'de' }"
        >
          <a>Deutsch</a>
        </li>
        <li
          @click="activeTab = 'en'"
          :class="{ 'is-active': activeTab == 'en' }"
        >
          <a>English</a>
        </li>
      </ul>
    </div>
    <br />

    <div class="columns is-mobile">
      <div class="column is-5 is-offset-1">
        <div><input class="input" v-model="messageToSearch"/></div>
      </div>
      <div class="column is-5">
        <div class="has-text-right">
          <enaqbutton :fontsize="'20px'" :width="'250px'">
            <router-link :to="'/message/create'">{{
              $t("enaq_translation_inbox_new_button")
            }}</router-link>
          </enaqbutton>
        </div>
      </div>
    </div>
    <br />
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th width="10%"></th>

          <th class="has-text-centered" width="50%">
            {{ $t("enaq_translation_inbox_title") }}
          </th>
          <th width="40%">{{ $t("enaq_translation_inbox_sender") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="messageRow"
          v-for="message in filteredMessages"
          :key="message.id"
          v-if="message.readedAt == null"
        >
          <td class="newMessageIcon has-text-right">
            <i class="fas fa-exclamation"></i>
          </td>
          <td
            class="has-text-centered newMessage"
            @click="openMessage(message, true)"
          >
            {{ shortTitle(message.title) }}
          </td>
          <td
            class="has-text-left tablePadding"
            @click="
              $router.push({ name: 'user', params: { id: message.author.id } })
            "
          >
            <span class="authorImageContainer">
              <img
                class="authorImage"
                :src="$storageURL + message.author.avatar"
              />
            </span>
            <span class="authorName"
              >{{ message.author.name }}, {{ message.author.firstname }}</span
            >
          </td>
        </tr>

        <tr
          class="messageRow"
          v-for="message in filteredMessages"
          :key="message.id"
          v-if="message.readedAt != null"
        >
          <td></td>
          <td class="has-text-centered" @click="openMessage(message, false)">
            {{ shortTitle(message.title) }}
          </td>
          <td
            class="tablePadding has-text-left"
            @click="
              $router.push({ name: 'user', params: { id: message.author.id } })
            "
          >
            <div class="authorImageContainer">
              <img
                class="authorImage"
                :src="$storageURL + message.author.avatar"
              />
            </div>
            <div class="authorName">
              {{ message.author.name }}, {{ message.author.firstname }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <message
      :messageId="selectedMessage"
      v-if="selectedMessage >= 0"
      @close="selectedMessage = -1"
    ></message>
  </div>
</template>

<script>
import message from "@/components/Messages/message.vue";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "inbox",
  props: [],
  components: {
    message,
    enaqbutton,
  },
  mounted: function () {
    this.getMessages();
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      activeTab: "de",
      messages: [],
      selectedMessage: -1,
      messageToSearch: "",
    };
  },
  methods: {
    getMessages() {
      this.HTTPRequest()
        .getUserMessages()
        .then(
          function (response) {
            this.messages = response.data.messages;
          }.bind(this)
        );
    },

    openMessage(message, newMessage) {
      if (newMessage) {
        this.$store.commit(
          "setNewMessage",
          this.$store.getters.getNewMessageCount - 1
        );
      }
      this.selectedMessage = message.id;
      message.readedAt = new Date();
    },
    shortTitle(title) {
      if (title.length <= 30) {
        return title;
      }
      return title.substring(0, 30) + "...";
    },
  },
  computed: {
    filteredMessages() {
      let filteredMessages = this.messages.filter(
        function (message) {
          console.log(JSON.stringify(message));
          return (
            this.messageToSearch == "" ||
            message.title
              .toUpperCase()
              .includes(this.messageToSearch.toUpperCase()) ||
            message.author.firstname
              .toUpperCase()
              .includes(this.messageToSearch.toUpperCase()) ||
            message.author.name
              .toUpperCase()
              .includes(this.messageToSearch.toUpperCase())
          );
        }.bind(this)
      );

      return filteredMessages;
    },
  },
};
</script>

<style scoped>
.messageRow {
  cursor: pointer;
}

.newMessage {
  color: #004684;
  font-weight: bolder;
}

.authorImage {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  object-fit: contain;
  vertical-align: text-bottom;
}

.newMessageIcon {
  color: green;
  font-size: 1.5em;
  font-weight: bolder;
}

.authorImageContainer {
  vertical-align: text-bottom;
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 45%;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 70, 132, 1);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 70, 132, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 70, 132, 1);
}

.authorName {
  padding-left: 10px;
  display: inline-block;
  height: 30px;
  vertical-align: text-bottom;
}
.tablePadding {
  padding-left: 15%;
}

@media screen and (max-width: 600px) {
  .authorImageContainer {
    display: none;
  }
}
</style>
