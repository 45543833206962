<template>
<div class="adminusers">

    <div v-if="user!=undefined" class="modal " :class="{'is-active':modalState!='closed'}">
        <div class="modal-background" @click="$emit('close')"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Nutzerverwaltung</p>
                <button class="delete" aria-label="close"></button>
            </header>
            <section class="modal-card-body">

                <div class="inputbox">
                    <div class="personalInformation columns is-mobile is-multiline">
                        <div class="column is-4">
                            <div class="select salutationDropdown">
                                <select v-model="user.salutation">
                                    <option disabled value="0">Anrede</option>
                                    <option value="1">Herr</option>
                                    <option value="2">Frau</option>
                                    <option value="3">Andere</option>
                                    <option value="0">Keine Angabe</option>
                                </select>
                            </div>
                        </div>
                        <div class="column is-4"><input class="input" type="text" placeholder="Vorname" v-model="user.firstName">
                        </div>
                        <div class="column is-4"><input class="input" type="text" placeholder="Name" v-model="user.name">
                        </div>

                    </div>

                    <div class="columns is-multiline is-mobile birthFields">
                        <div class="  column is-4">
                            <label class=" alignedLabel label">Geburtstag</label>
                        </div>
                        <datepicker class="column is-8" input-class="input" v-model="user.birthdate"></datepicker>
                    </div>

                    <div class="columns is-multiline is-mobile birthFields">
                        <div class="  column is-4">
                            <label class=" alignedLabel label">Einzugsdatum</label>
                        </div>
                        <datepicker class="column is-8" input-class="input" v-model="user.moveinDate"></datepicker>
                    </div>
                    <div v-if="modalState=='editUser'">

                        <div class="columns is-multiline is-mobile birthFields">
                            <div class="  column is-3">
                                <label class="  label">Beschreibung</label>
                            </div>
                            <div class="column is-6"><input class="input" type="text" placeholder="" v-model="user.description">
                            </div>
                            <div class=" column is-3">
                                <div class="button" @click="deleteDescription=!deleteDescription">
                                    <p v-if="!deleteDescription">Löschen</p>
                                    <p v-if="deleteDescription">Wird gelöscht</p>
                                </div>
                            </div>
                        </div>

                        <div class="columns is-multiline is-mobile birthFields">
                            <div class="  column is-4">
                                <label class="  label">Avatar</label>
                            </div>

                            <div class=" column is-8">
                                <div class="button" @click="deleteProfileImage=!deleteProfileImage">
                                    <p v-if="!deleteProfileImage">Löschen</p>
                                    <p v-if="deleteProfileImage">Wird gelöscht</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="onClickSave">Speichern</button>
                <button class="button">Cancel</button>
            </footer>
        </div>
    </div>
    {{errorMessage}}
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

export default {
    name: 'register',
    components: {
        Datepicker
    },
    props: ["user", "modalState"],
    mounted: function () {
        this.userData = this.user;
    },
    data() {
        return {
            name: "",
            firstName: "",
            salutation: 0,
            birthdate: "",
            errorMessage: "",
            moveinDate: "",
            userData: {
                "firstName": "",
                "name": "",
                "moveinDate": "",
                "birthdate": "",
                "salutation": 0
            },
            deleteDescription: false,
            deleteProfileImage: false,
        }
    },
    methods: {
        onClickRegister() {
            this.validateInput();
            if (this.errorMessage == "") {
                this.trySignup();
            }
        },

        validateInput() {
            this.errorMessage = "";
            if (this.name == "" || this.firstName == "") {
                this.errorMessage = "Bitte einen Vor- und Nachnamen angeben"
            }
            if (this.birthdate == "") {
                this.errorMessage = "Bitte das Geburtstdatum angeben"
            }
            if (this.moveinDate == "") {
                this.errorMessage = "Bitte das Einzugsdatum angeben"
            }

        },

        trySignup() {
            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }

            let signupData = {
                firstName: this.firstName,
                name: this.name,
                birthdate: this.customFormatter(this.birthdate),
                moveinDate: this.customFormatter(this.moveinDate),
                salutation: this.salutation
            }
            this.$axios.post(this.$backendURL + "/auth/admin/user/create", signupData, {
                headers: headerData
            }).then(function (result) {
                this.errorMessage = "Voucher erstellt: " + result.data.voucher;
            }.bind(this))
        },
        customFormatter(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        onClickSave() {
            this.user.birthdate = this.customFormatter(this.user.birthdate)
            this.user.moveinDate = this.customFormatter(this.user.moveinDate)

            if (this.modalState == 'newUser'){
                this.newUser();
            }else{
                this.editUser();
            }
        },
        newUser() {
            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }

            let postData = {
                user: this.user
            }
            console.log("post "+JSON.stringify(postData))
            this.$axios.post(this.$backendURL + "/auth/admin/user/new", postData, {
                headers: headerData
            }).then(function (result) {
                this.errorMessage = "Voucher erstellt: " + result.data.voucher;
            }.bind(this))
        },
        editUser() {
            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }
            this.user.deleteProfileImage = this.deleteProfileImage;
            this.user.deleteDescription = this.deleteDescription;
            let postData = {
                user: this.user
            }
            this.$axios.post(this.$backendURL + "/user/admin/"+this.user.id+"/edit", postData, {
                headers: headerData
            }).then(function (result) {
                this.errorMessage = "Voucher erstellt: " + result.data.voucher;
            }.bind(this))   
        }
    },
    computed: {

    },
}
</script>

<style scoped>
.inputbox {
    width: 100%;
    margin: auto
}

.personalInformation .input {
    margin-top: 30px
}

.salutationDropdown {
    margin-top: 30px
}

.datePicker input {
    width: 100%;
    margin-top: 30px;
}

.alignedLabel {
    font-size: 1rem;
    height: 2.25em;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    text-align: left;
}

.birthFields {
    margin-top: 20px;
}
</style>
