<template>
<div class="component" v-if="surveyData!=undefined">
    <h1 class="title">{{surveyData.title}}</h1>

    <div class="columns is-mobile is-multiline">
        <div class="has-text-left column is-3	">
            <span class="button is-danger addQuestion" @click="$emit('close')">Zurück</span>
        </div>
        <div class="has-text-left column is-3	">
            <span class="button is-info addQuestion" v-if="surveyData.isOpen" @click="closeSurvey">Umfrage schließen</span>
            <span class="button is-info addQuestion" v-else @click="openSurvey">Umfrage öffnen</span>
        </div>
        <div class="has-text-right column is-3	"> <span class="button is-danger addQuestion" @click="deleteSurvey">Umfrage löschen</span>

        </div>

        <div class="has-text-right column is-3	"> <span class="button is-info addQuestion" @click="addQuestion=true">Frage hinzufügen</span>

        </div>

    </div>

    <div class="card" v-for="question in surveyData.questions" :key="question">
        <header class="card-header">
            <div class="card-header-title columns is-mobile is-multiline">
                
                    <div class="column is-8 is-offset-2" @click="choosenQuestion = question">{{question.question}}</div>
                    <div class="column is-2">
                        <div class="button is-danger" @click="deleteQuestion(question.id)">Löschen</div>
                    </div>
           
            </div>
            <a href="#" class="card-header-icon" aria-label="more options">
                <span v-if="question.questionType==0">{{question.answers.length}} mögliche Antworten</span>
                <span v-else>Keine Antwortvorgaben</span>

            </a>
        </header>
        <div class="card-content" v-if="choosenQuestion.id == question.id && question.questionType==0">
            <div class="content">
                <div class="list is-hoverable columns is-multiline" v-for="answer in question.answers" :key="answer.id">
                    <a class="list-item column is-10" @click="chooseSurvey(survey.id)">
                        {{answer.answerText}}
                    </a>

                    <div class="column is-2">
                        <div class="button is-danger" @click="deleteAnswer(answer.id)">Löschen</div>
                    </div>
                </div>
                <span class="button" @click="addAnswerModal=true">Antwort hinzufügen</span>

            </div>
        </div>
        <footer class="card-footer">
        </footer>
    </div>

    <div v-if="addAnswerModal">
        <addAnswer @close="addAnswerModal=false" :question="choosenQuestion" @onAnswerAdd="getSurvey()"></addAnswer>
    </div>

    <div v-if="addQuestion">
        <addQuestion :surveyId="surveyId" @close="addQuestion=false" @onQuestionAdd="getSurvey()"></addQuestion>
    </div>

</div>
</template>

<script>
import addAnswer from '@/components/Admin/Surveys/addPossibleAnswer.vue'
import addQuestion from '@/components/Admin/Surveys/addQuestion.vue'

export default {
    name: 'showSurvey',
    props: ["surveyId"],
    components: {
        addAnswer,
        addQuestion
    },
    mounted: function () {
        this.getSurvey();
    },
    data() {
        return {
            data: [],
            surveyData: null,
            choosenQuestion: -1,
            addAnswerModal: false,
            addQuestion: false,
        }
    },
    methods: {
        getSurvey() {

            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }

            this.$axios.get(this.$backendURL + "/survey/" + this.surveyId, {
                headers: headerData
            }).then(function (response) {

                this.surveyData = response.data.survey[0]
            }.bind(this))

        },
        deleteSurvey() {
            this.HTTPRequest()
                .deleteSurvey(this.surveyId)
                .then(
                    function (result) {
                        this.$emit('close')
                    }.bind(this)
                );
        },

        closeSurvey() {
            this.HTTPRequest()
                .closeSurvey(this.surveyId)
                .then(
                    function (result) {
                        this.getSurvey();
                    }.bind(this)
                );
        },

        openSurvey() {
            this.HTTPRequest()
                .openSurvey(this.surveyId)
                .then(
                    function (result) {
                        this.getSurvey();
                    }.bind(this)
                );
        },

        deleteQuestion(id) {
            this.HTTPRequest()
                .deleteQuestion(id)
                .then(
                    function (result) {
                        this.getSurvey();
                    }.bind(this)
                );
        },
        deleteAnswer(id) {

  this.HTTPRequest()
                .deleteAnswer(id)
                .then(
                    function (result) {
                        this.getSurvey();
                    }.bind(this)
                );
        },
        addAnswer() {

        }
    },
    computed: {},
}
</script>

<style scoped>
.title {
    margin-top: 20px;
}

.addQuestion {
    font-size: 22px !important;
    margin: 20px 0%;
    font-weight: bolder;
}
</style>
