<template>
<div class="component">
    <!--
Der größte Teil dieser Seite sowie den unterkomponenten sollte später auf der Service-Plattform zur Verfügung gestellt werden.
-->
    <div class="statisticsHeader">
        <div class="statisticsType columns is-mobile is-multiline">
            <div class="column is-5 has-text-right">
                <i @click="changeCurrentDataTimePeriode(-1)" class="leftPeriodArrow fas fa-caret-left"></i>
            </div>
            <div class="column is-2 periodText">
                {{ currentTimePeriodetranslation }}
            </div>
            <div class="column is-5 has-text-left">
                <i @click="changeCurrentDataTimePeriode(1)" class="rightPeriodArrow fas fa-caret-right"></i>
            </div>
        </div>

        <div class="statisticsSettings columns is-mobile is-multiline">
            <div class="column is-2">
                <div class="compareButton">
                    <datePicker @onDateChange="changeSelectedDate('first', $event)" :initialDate="selectedDateOne" :type="possibleDataTimePeriods[selectedDataTimePeriod]" :localisation="$store.getters.getCurrentLanguage" />
                </div>
            </div>
            <div class="column is-2">
                <div class="compareButton">
                    <datePicker @onDateChange="changeSelectedDate('second', $event)" :initialDate="selectedDateTwo" :type="possibleDataTimePeriods[selectedDataTimePeriod]" :localisation="$store.getters.getCurrentLanguage" />
                </div>
            </div>

            <div class="column is-2 is-offset-1">
                <div class="areaSelection">
                    <div class="areaSelectionIcon" @click="selectedAreaType = 'own'">
                        <img alt="Icon" :src="require(`@/assets/icons/Profile_Icon.png`)" />
                    </div>
                    <div class="areaSelectionIcon" @click="selectedAreaType = 'house'">
                        <img alt="Icon" :src="require(`@/assets/icons/Home_Icon.png`)" />
                    </div>
                    <div class="areaSelectionIcon" @click="selectedAreaType = 'quarter'">
                        <img alt="Icon" :src="require(`@/assets/icons/Quarter_Icon.png`)" />
                    </div>
                </div>
            </div>

            <div class="column is-2 is-offset-1">
                <div class="ressourceToCompareButton">
                    <div class="ressourceToCompareTitle" @click="openRessourceToCompareMenu = !openRessourceToCompareMenu">
                        {{
                $t(
                  "enaq_translation_devices_statistics_type_" +
                    selectedRessorceType
                )
              }}
                    </div>
                    <div class="ressourceToCompareButtonTitleIcon">
                        <i class="fas fa-caret-down"></i>
                    </div>

                    <div class="ressourceToCompareValues" v-if="openRessourceToCompareMenu">
                        <div class="ressourceToCompareValue" @click="changeSelectedRessourceType('Verbrauch')">
                            {{ $t("enaq_translation_devices_statistics_type_Verbrauch") }}
                        </div>
                        <div class="ressourceToCompareValue" @click="changeSelectedRessourceType('Kosten')">
                            {{ $t("enaq_translation_devices_statistics_type_Kosten") }}
                        </div>
                        <div class="ressourceToCompareValue" @click="changeSelectedRessourceType('Emissionen')">
                            {{ $t("enaq_translation_devices_statistics_type_Emissionen") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="leftStatistics">
        <compareDiagram class="compareDiagramm" :labelY="diagramCurrency" v-if="compareDiagrammData != null" :graphData="compareDiagrammData" />
    </div>

    <div class="rightStatistics">
        <div class="quarterPowerSource">
            <piediagramm :graphData="pieDiagrammData" :graphLabels="[
            $t('enaq_translation_devices_statistics_source_own'),
            $t('enaq_translation_devices_statistics_source_external'),
            $t('enaq_translation_devices_statistics_source_storage'),
          ]"></piediagramm>
        </div>
        <battery :width="'6'" percentage="40" />
    </div>

    <div class="productionUsageCompare" v-if="this.selectedDataTimePeriod == 0">
        <productionUsageCompare :graphData="consumptionProductionData" />
    </div>

    <idealConsumptionGraph />
</div>
</template>

<script>
import piediagramm from "@/components/Devices/piediagramm.vue";
import compareDiagram from "@/components/Devices/Statistics/compareDiagram.vue";
import battery from "@/components/Devices/Statistics/battery.vue";
import datePicker from "@/components/datepicker.vue";
import productionUsageCompare from "@/components/Devices/Statistics/productionUsageCompare.vue";
import idealConsumptionGraph from "@/components/Devices/Statistics/idealConsumptionGraph.vue";

import VueMonthlyPicker from "vue-monthly-picker";
import moment from "moment";

export default {
    name: "statistics",
    props: [],
    components: {
        piediagramm,
        compareDiagram,
        battery,
        VueMonthlyPicker,
        moment,
        datePicker,
        productionUsageCompare,
        idealConsumptionGraph,
    },
    mounted: function () {
        moment.locale(this.$store.getters.getCurrentLanguage);

        this.initialiseDefaultSelectedDates();
        //this.getRandomData();
        // this.initialiseGeraphData();
        //this.getDeviceData();
        this.initMockConsumptiondata();
    },
    data() {
        return {
            msg: "Welcome to Your Vue.js App",
            data: [],
            pieDiagrammData: [],
            compareDiagrammData: [],
            possibleDataTimePeriods: ["day", "week", "month", "year"],
            selectedDataTimePeriod: 2,
            selectedDateOne: "",
            selectedDateTwo: "",
            selectedAreaType: "own",
            maxDateToPick: moment(),
            openRessourceToCompareMenu: false,
            selectedRessorceType: "Verbrauch",
            diagramCurrency: "kW/h",
            currentlyFetchingData: false,
        };
    },
    methods: {
        changeSelectedDate(dateObject, timeValue) {
            if (dateObject == "first") {
                let newMomentObject = this.selectedDateOne;
                this.selectedDateOne = null;
                newMomentObject.set(timeValue);
                this.selectedDateOne = newMomentObject;
            } else {
                let newMomentObject = this.selectedDateTwo;
                this.selectedDateTwo = null;
                newMomentObject.set(timeValue);
                this.selectedDateTwo = newMomentObject;
            }
            console.log("val1: " + dateObject);

            console.log("val2: " + timeValue);
            this.initMockConsumptiondata();
        },
        changeSelectedRessourceType(ressourceType) {
            this.selectedRessorceType = ressourceType;
            this.openRessourceToCompareMenu = false;
            this.initMockConsumptiondata();
        },
        changeCurrentDataTimePeriode(amount) {
            if (this.currentlyFetchingData) {
                return;
            }
            this.selectedDataTimePeriod += amount;
            if (this.selectedDataTimePeriod >= this.possibleDataTimePeriods.length) {
                this.selectedDataTimePeriod = 0;
            }

            if (this.selectedDataTimePeriod < 0) {
                this.selectedDataTimePeriod = this.possibleDataTimePeriods.length - 1;
            }

            this.initialiseDefaultSelectedDates();
            this.initMockConsumptiondata();
        },
        initialiseDefaultSelectedDates() {
            if (this.selectedDataTimePeriod == 2) {
                this.selectedDateOne = moment().subtract(1, "months");
                this.selectedDateTwo = moment();
            }
        },

        getRandomData(amount = 3) {
            let randomData = [];
            for (var i = 0; i < amount; i++) {
                randomData.push(Math.floor(Math.random() * 10) + (2 - i) * 10);
            }
            this.pieDiagrammData = randomData;
        },
        getRandomGraphData() {

            var dataSetLength = 12;

            var data = Array.from({
                length: dataSetLength
            }, () => Math.floor(((Math.random() * 12) + 5)));

            if (this.selectedDataTimePeriod == 2) {
                dataSetLength = 31

                data = Array.from({
                    length: dataSetLength
                }, () => Math.floor(((Math.random() * 12) + 5)));
            } else if (this.selectedDataTimePeriod == 0) {
                dataSetLength = 24

                data = Array.from({
                    length: dataSetLength
                }, () => Math.floor(((Math.random() * 12) + 5)));
            } else if (this.selectedDataTimePeriod == 1) {
                dataSetLength = 7

                data = Array.from({
                    length: dataSetLength
                }, () => Math.floor(((Math.random() * 12) + 5)));
            } else if (this.selectedDataTimePeriod == 3) {
                dataSetLength = 12

                data = Array.from({
                    length: dataSetLength
                }, () => Math.floor((((Math.random() * 12) + 5) * 30)));
            }
            return data;
        },
        initMockConsumptiondata() {
            this.compareDiagrammData = null;
            this.compareDiagrammData = {
                series: [],
                labels: [],
            };
            this.mockConsumptionDataNew(this.selectedDateOne);
            this.mockConsumptionDataNew(this.selectedDateTwo);
        },

        mockConsumptionDataNew(selectedDate) {

            var dataSetLength = 12;
            this.compareDiagrammData.labels = [];

            var data = this.getRandomGraphData();
            if (this.selectedDataTimePeriod == 2) {
                for (var i = 1; i <= 31; i++) {
                    this.compareDiagrammData.labels.push(i);
                }

            } else if (this.selectedDataTimePeriod == 0) {

                for (var i = 0; i <= 23; i++) {
                    this.compareDiagrammData.labels.push(i+":00");
                }

            } else if (this.selectedDataTimePeriod == 1) {

                this.compareDiagrammData.labels.push("Mo");
                this.compareDiagrammData.labels.push("Di");
                this.compareDiagrammData.labels.push("Mi");
                this.compareDiagrammData.labels.push("Do");
                this.compareDiagrammData.labels.push("Fr");
                this.compareDiagrammData.labels.push("Sa");
                this.compareDiagrammData.labels.push("So");

            } else if (this.selectedDataTimePeriod == 3) {

                for (var i = 1; i <= 12; i++) {
                this.compareDiagrammData.labels.push("Jan");
                this.compareDiagrammData.labels.push("Feb");
                this.compareDiagrammData.labels.push("Mar");
                this.compareDiagrammData.labels.push("Apr");
                this.compareDiagrammData.labels.push("Mai");
                this.compareDiagrammData.labels.push("Jun");
                this.compareDiagrammData.labels.push("Jul");
                this.compareDiagrammData.labels.push("Aug");
                this.compareDiagrammData.labels.push("Sep");
                this.compareDiagrammData.labels.push("Okt");
                this.compareDiagrammData.labels.push("Nov");
                this.compareDiagrammData.labels.push("Dez");
                }

            }

            if (this.selectedRessorceType == "Verbrauch") {
                this.diagramCurrency = "kW/h";
                data.forEach(function (value, index) {
                    data[index] = value * 1;
                });
            } else if (this.selectedRessorceType == "Kosten") {
                this.diagramCurrency = "Euro";
                //Slightly randomize cost per KWH to simulate variable costs
                data.forEach(function (value, index) {
                    data[index] = Math.floor((value * (Math.random() * 2) + 1)) / 10

                });
            } else if (this.selectedRessorceType == "Emissionen") {
                if (this.selectedDataTimePeriod == 3) {
                    this.diagramCurrency = "KG";
                    data.forEach(function (value, index) {
                        data[index] = value / 1000;
                    });
                } else {
                    this.diagramCurrency = "g";
                    data.forEach(function (value, index) {
                        data[index] = value / 100;
                    });
                }
            }

            let dataName = "";
            if (this.selectedDataTimePeriod == 2) {
                dataName = selectedDate.format("MMMM");
            } else if (this.selectedDataTimePeriod == 0) {
                dataName = selectedDate.format("DD/MM");
            } else if (this.selectedDataTimePeriod == 3) {
                dataName = selectedDate.format("YYYY");
            }
            let dataset = {
                name: dataName,
                data: data,
            };
            this.compareDiagrammData.series.push(dataset);
        },

        getDeviceData() {
            //
            return;
            if (this.currentlyFetchingData) {
                return;
            }
            this.currentlyFetchingData = true;
            this.compareDiagrammData = null;
            this.compareDiagrammData = {
                series: [],
                labels: [],
            };
            if (this.selectedDataTimePeriod == 1) {
                this.currentlyFetchingData = false;
                return;
            }
            let postData = {
                user: "1",
                type: this.selectedRessorceType,
                timeframe: this.possibleDataTimePeriods[this.selectedDataTimePeriod],
                month: this.selectedDateOne.format("M"),
                day: this.selectedDateOne.format("D"),
            };
            this.callApi("/devices/testdata", "post", postData)
                .then(
                    function (response) {
                        if (this.selectedDataTimePeriod == 2) {
                            for (var i = 1; i <= 31; i++) {
                                this.compareDiagrammData.labels.push(i);
                            }
                        } else if (this.selectedDataTimePeriod == 0) {
                            for (var i = 0; i <= 23; i++) {
                                this.compareDiagrammData.labels.push(i);
                            }
                        } else if (this.selectedDataTimePeriod == 3) {
                            for (var i = 1; i <= 12; i++) {
                                this.compareDiagrammData.labels.push(i);
                            }
                        }
                        this.parseConsumptionData(this.selectedDateOne, response.data.data);
                    }.bind(this)
                )
                .then(
                    function (resp) {
                        postData = {
                            user: "1",
                            type: this.selectedRessorceType,
                            timeframe: this.possibleDataTimePeriods[this.selectedDataTimePeriod],
                            month: this.selectedDateTwo.format("M"),
                            day: this.selectedDateTwo.format("D"),
                        };
                        this.callApi("/devices/testdata", "post", postData).then(
                            function (response) {
                                this.parseConsumptionData(
                                    this.selectedDateTwo,
                                    response.data.data
                                );
                                this.currentlyFetchingData = false;
                            }.bind(this)
                        );
                    }.bind(this)
                )
                .catch(
                    function (error) {
                        this.currentlyFetchingData = false;

                        this.compareDiagrammData = {
                            series: [],
                            labels: [],
                        };
                        console.log("Error fetching data: " + error);
                    }.bind(this)
                );

            //---
            /*
                              postData = {
                                  user: "1",
                                  type: "Verbrauch",
                                  timeframe: this.possibleDataTimePeriods[this.selectedDataTimePeriod],
                                  month: this.selectedDateTwo.format("M"),
                                  day: this.selectedDateTwo.format("D"),
                              }
                              this.callApi("/devices/testdata", 'post', postData).then(function (response) {
                                  this.parseConsumptionData(this.selectedDateTwo, response.data.data)

                              }.bind(this))
                  */
        },

        parseConsumptionData(selectedDate, data) {
            return [];
            if (this.selectedRessorceType == "Verbrauch") {
                this.diagramCurrency = "kW/h";
                data.forEach(function (value, index) {
                    data[index] = value * 1000;
                });
            } else if (this.selectedRessorceType == "Kosten") {
                this.diagramCurrency = "Euro";
                data.forEach(function (value, index) {
                    data[index] = value / 100;
                });
            } else if (this.selectedRessorceType == "Emissionen") {
                if (this.selectedDataTimePeriod == 3) {
                    this.diagramCurrency = "KG";
                    data.forEach(function (value, index) {
                        data[index] = value / 1000;
                    });
                } else {
                    this.diagramCurrency = "g";
                    data.forEach(function (value, index) {
                        data[index] = value / 100;
                    });
                }
            }
            let dataName = "";
            if (this.selectedDataTimePeriod == 2) {
                dataName = selectedDate.format("MMMM");
            } else if (this.selectedDataTimePeriod == 0) {
                dataName = selectedDate.format("DD/MM");
            } else if (this.selectedDataTimePeriod == 3) {
                dataName = selectedDate.format("YYYY");
            }
            let dataset = {
                name: dataName,
                data: data,
            };
            this.compareDiagrammData.series.push(dataset);
        },
    },
    computed: {
        currentTimePeriodetranslation() {
            return this.$t(
                "enaq_translation_devices_statistics_period_" +
                this.possibleDataTimePeriods[this.selectedDataTimePeriod]
            );
        },
        consumptionProductionData() {
            if (this.compareDiagrammData.series[0] == null) {
                return;
            }
            let diagramDates = [];
            let consumptionValues = this.compareDiagrammData.series[0].data;
            let productionValues = [];

            if (this.selectedDataTimePeriod == 2) {
                for (var i = 1; i <= 31; i++) {
                    diagramDates.push(i);
                    productionValues.push(
                        (Math.random() * (1.5 - 0.5) + 0.5) * consumptionValues[i]
                    );
                }
            } else if (this.selectedDataTimePeriod == 0) {
                for (var i = 0; i <= 23; i++) {
                    diagramDates.push(i);
                    let productionValue = consumptionValues[i];
                    let valueMultiplicator = Math.abs(1 - (12 - i - 1) * -1);

                    if (valueMultiplicator > 5) {
                        productionValue = 0;
                    } else {
                        productionValue =
                            productionValue * (2.5 - valueMultiplicator / 100);
                    }
                    productionValues.push(productionValue);
                }
            } else if (this.selectedDataTimePeriod == 3) {
                for (var i = 1; i <= 12; i++) {
                    diagramDates.push(i);
                    productionValues.push(
                        (Math.random() * (1.5 - 0.5) + 0.5) * consumptionValues[i]
                    );
                }
            }

            let consProdData = [{
                    data: productionValues,
                    name: "Erzeugung",
                    dates: diagramDates,
                },
                {
                    data: consumptionValues,
                    name: "Verbrauch",
                    dates: diagramDates,
                },
            ];
            return consProdData;
        },
    },
};
</script>

<style scoped>
.leftStatistics {
    display: inline-block;
    width: 70%;
    vertical-align: top;
    text-align: center;
}

.quarterPowerSource {
    text-align: -webkit-center;
}

.rightStatistics {
    display: inline-block;
    width: 30%;
    vertical-align: top;
    min-height: 60vh;
}

.leftPeriodArrow {
    color: #7f7f7f;
    font-size: 2em;
    line-height: 1em;
}

.rightPeriodArrow {
    color: #7f7f7f;
    font-size: 2em;
    line-height: 1em;
}

.periodText {
    color: #7f7f7f;
    font-size: 2em;
    font-weight: bolder;
    line-height: 1em;
}

.areaSelectionIcon {
    display: inline-block;
    width: calc(100% / 3);
}

.compareDiagramm {
    min-height: 60vh;
}

.statisticsSettings {
    width: 90%;
    margin: auto;
}

.areaSelection .areaSelectionIcon img {
    max-height: 4em;
    margin-top: -1em;
    overflow: hidden;
    cursor: pointer;
}

.ressourceToCompareButton {
    line-height: 2em;
    min-height: 2em;
    font-weight: bolder;
    position: relative;
    background: #e6e6e6;
    border: 3px solid #919191;
    border-radius: 10px;
    color: #919191;
    font-weight: bolder;
    cursor: pointer;
    position: relative;
}

.ressourceToCompareButtonTitleIcon {
    position: absolute;
    margin: auto;
    top: -0.05em;
    text-align: right;
    right: 5%;
    font-size: 1.8em;
}

.ressourceToCompareValues {
    position: absolute;
    z-index: 12;
    border: 3px solid #919191;
    width: calc(100% + 6px);
    background: #e6e6e6;
    top: calc(100% - 5px);
    left: -3px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.ressourceToCompareValue {
    text-align: center;
}
</style>
