<template>
  <div class="component">
    <div class="loginContainer">
      <div class="subtitle">
        Zur Anmeldung können Sie ihren bestehenden Google- oder Microsoftaccount
        verwenden oder einen neuen Account erstellen.
      </div>
      <div class="button is-info is-large" @click="getOauthToken()">
        Jetzt einloggen
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "component",
  props: [],
  mounted: function () {
    // this.getOauthToken()
    //   this.$AuthService.getUser();
    this.callServicePlatform();
  },
  data() {
    return {
      user: null,
      token: "",
    };
  },
  methods: {
    getOauthToken() {
      this.$AuthService.loginAsync().then(
        function (response) {
          this.user = this.$AuthService.getUser();
          this.token = response;

          this.loginToCPBackend(response);
        }.bind(this)
      );
    },
    loginToCPBackend(oauthToken) {
      localStorage.setItem("spAuthToken", oauthToken);

      console.log("Bearer in logintobackend " + oauthToken);
      let oauthData = {
        token: oauthToken,
      };
      this.$axios
        .post(this.$backendURL + "/auth/jwtlogin", oauthData)
        .then(
          function (result) {
            this.clearStorage();
            localStorage.setItem("spAuthToken", oauthToken);

            localStorage.setItem("bearertoken", result.data.access_token);
            this.$store.commit("setUsername", result.data.username);
            this.$router.push({
              name: "dashboard",
              params: {
                refresh: true,
              },
            });
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(
              "Error during authorization: login to backend not possible - bearer token may not be usable or backend is not reachable."
            );
            console.log(error);
            if (
              error.response != undefined &&
              error.response.data.message == "Unauthorized"
            ) {
              this.infoMessage = this.$t(
                "enaq_translation_login_error_wrong_data"
              );
            } else {
              this.infoMessage =
                this.$t("enaq_translation_login_error_server") +
                JSON.stringify(error.response.data);
            }
          }.bind(this)
        );
    },

    clearStorage() {
      this.$store.commit("setUsername", "");
      this.$store.commit("setUser", "");

      localStorage.bearertoken = "";
      localStorage.clear();
    },
  },
  computed: {},
};
</script>

<style scoped>
.loginContainer {
  width: 80vw;
  margin: auto;
  padding-top: 15vh;
}
</style>
