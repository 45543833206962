import overview from './overview.vue';
import detail from './detail.vue';

export default {
    install(Vue, options) {
        Vue.component('service2', overview);
        Vue.component('detail2', detail);

        options.store.commit('addService', { "overview": "service2", "page": "detail2", "name": "Energieampel", "id": "12345678444490" })
    }
}