import * as Msal from 'msal'
import axios from 'axios'


export default class AuthService {
    constructor() {
        this.app = new Msal.UserAgentApplication({
            auth: {
                clientId: "1f35e63a-b292-4491-b83c-2f0b78fe6152",
                authority: "https://enaqad.b2clogin.com/enaqad.onmicrosoft.com/B2C_1_SignUpSignIn",
                validateAuthority: false,
                // redirectUri: "https://vuejs-test2.azurewebsites.net/"
            },
            cache: {
                cacheLocation: "localStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: true // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
            }
        })
    }
    /*
// if the user is already logged in you can acquire a token
if (msalInstance.getAccount()) {
    var tokenRequest = {
        scopes: ["user.read", "mail.send"]
    };
    msalInstance.acquireTokenSilent(tokenRequest)
        .then(response => {
            // get access token from response
            // response.accessToken
        })
        .catch(err => {
            // could also check if err instance of InteractionRequiredAuthError if you can import the class.
            if (err.name === "InteractionRequiredAuthError") {
                return msalInstance.acquireTokenPopup(tokenRequest)
                    .then(response => {
                        // get access token from response
                        // response.accessToken
                    })
                    .catch(err => {
                        // handle error
                    });
            }
        });
} else {
    // user is not logged in, you will need to log them in to acquire a token
}
    */
    async loginAsync() {
        console.log("loginasync -")


        var promise = new Promise(function (resolve, reject) {



            this.app.loginPopup().then(
                token => {
                    console.log("JWT token " + JSON.stringify(token))
                    this.app.acquireTokenSilent({ scopes: ['https://enaqad.onmicrosoft.com/backend/user.read'] }).then(response => {
                        // Access Token
                        console.log("Response: " + JSON.stringify(response))
                        console.log("Set bearer token Bearer Token (Access Token: " + response.accessToken)
                        resolve(response.idToken.rawIdToken)

                    });
                },
                error => {
                    reject(error)
                    //TODO
                    console.log("Login error " + error)
                }
            );

            //   reject(Error(""));

        }.bind(this));
        return promise;
    }
    async getUserToken() {
        console.log("in getusertoken")
        var promise = new Promise(function (resolve, reject) {

            // if the user is already logged in you can acquire a token
            if (this.app.getAccount()) {
                var tokenRequest = {
                    scopes: ['https://enaqad.onmicrosoft.com/backend/user.read']
                };
                this.app.acquireTokenSilent(tokenRequest)
                    .then(response => {
                        // get access token from response
                        // response.accessToken
                        resolve(response.accessToken)

                    })
                    .catch(err => {
                        console.log("in error getusertoken" + err)
                        // could also check if err instance of InteractionRequiredAuthError if you can import the class.
                        if (err.name === "InteractionRequiredAuthError") {
                            return this.app.acquireTokenPopup(tokenRequest)
                                .then(response => {
                                    // get access token from response
                                    // response.accessToken
                                    console.log("getUserToken error " + err)

                                })
                                .catch(err => {
                                    console.log("getUserToken error 2 " + err)
                                });
                        }
                    });
            } else {
                console.log("not logged in in  SPBackend")

                // user is not logged in, you will need to log them in to acquire a token
            }

        }.bind(this));
        return promise;
    }
    logout() {
        this.app._user = null
        this.app.logout()
    }

    getUser() {
        console.log(this.app.getAccount())
        return this.app.getAccount();
    }



}