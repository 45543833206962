<template>
<div class="component">

    <apexchart width="100%" :height="'100%'" type="area" :options="graphOptions" :series="graphData"></apexchart>
</div>
</template>

<script>
export default {
    name: 'AreaChart',
    props: ["graphData", "graphLabels", "width"],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            chartData: []
        }
    },
    methods: {

    },
    computed: {

        graphOptions() {

            let graphOptions = {
                chart: {
                    stacked: true,
                    id: this.title || "Graph " + Math.floor(Math.random() * 1000),
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },

                },


                yaxis: {
                    type: "numeric",
                    tickAmount: 12,
                    labels: {
                        formatter: function (value) {
                            return value.toFixed() + " KW/H"
                        }
                    },
                    title: {
                        text: "KW/H",
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            cssClass: 'apexcharts-xaxis-title',
                        },
                    },
                },

                labels: this.graphData[0].dates,

            };

            return graphOptions;
        },

    },
    watch: {
        graphData: function (newData, oldData) {
            this.chartData = this.graphData
        }
    }
}
</script>

<style scoped>
.component {
    height: 100%;
    min-height: 100%;

}
</style>
