<template>
  <div>
    <br />
    <div class="cpTitle">{{ $t("enaq_translation_dashboard_title") }}</div>
    <br />
    <div class="tile is-ancestor mr-6">
      <div class="tile is-parent is-3 mr-2">
        <article class="tile is-child box tile_announcements">
          <div class="tileIcon">
            <img
              class="icon"
              alt="Icon"
              :src="require(`@/assets/Announcement.png`)"
            />
          </div>
          <p class="title_tile">
            {{ $t("enaq_translation_dashboard_cardtitle_messages") }}
          </p>
          <div class="tile is-parent is-vertical">
            <div
              class="flip-card"
              v-for="announcement in announcements"
              :key="announcement.id"
            >
              <div class="flip-card-inner is-area">
                <div class="flip-card-front">
                  <p class="dashboard_subtitle">
                    {{ announcement.title }}
                  </p>
                  {{ announcement.description.slice(0, 90) }}
                </div>
                <div class="flip-card-back">
                  {{ announcement.content.slice(0, 130) }}
                </div>
              </div>
            </div>
          </div>
          <div class="ml-20">
            <router-link :to="'/announcements'"
              ><enaqbutton>Mehr</enaqbutton></router-link
            >
          </div>
        </article>
      </div>
      <div class="tile is-vertical is-6 mx-4">
        <div>
          <article class="tile title_consumption is-child">
            {{ $t("enaq_translation_dashboard_consumption_title") }}
          </article>
        </div>
        <div class="tile is-parent">
          <div class="tile is-parent is-vertical mr-6">
            <article class="tile is-child">
              <img
                alt="Icon"
                :src="require(`@/assets/icons/Dashboard_Grey_Icon.png`)"
                width="200px"
              />
            </article>
          </div>
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <img
                alt="Icon"
                :src="require(`@/assets/icons/Dashboard_Grey_Icon.png`)"
                width="200px"
              />
            </article>
          </div>
        </div>
        <div class="tile">
          <div class="tile is-parent is-vertical mr-6">
            <article class="tile is-child box tile_devices">
              <div class="tileIcon">
                <img
                  class="icon"
                  alt="Icon"
                  :src="require(`@/assets/Devices.png`)"
                />
              </div>
              <p class="title_tile">
                {{ $t("enaq_translation_dashboard_cardtitle_devices") }}
              </p>
              <div class="tile is-parent is-vertical">
                <div class="flip-card">
                  <div class="flip-card-inner is-area">
                    <div class="flip-card-front">
                      <p class="dashboard_subtitle">
                        SmartMeter - Teschke,Timo
                      </p>
                      <p>1234 kWh</p>
                      <p class="dashboard_subtitle">Garage - Teschke,Timo</p>
                      <p>1234 kWh</p>
                    </div>
                    <div class="flip-card-back">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua.
                    </div>
                  </div>
                </div>
                <div class="dashboard_buttonContainer">
                  <router-link class="" :to="'/devices'"
                    ><enaqbutton>Mehr</enaqbutton></router-link
                  >
                </div>
              </div>
            </article>
          </div>
          <div class="tile is-parent is-vertical">
            <article class="tile is-child box tile_location">
              <div class="tileIcon">
                <img
                  class="icon"
                  alt="Icon"
                  :src="require(`@/assets/Location.png`)"
                />
              </div>
              <p class="title_tile">
                {{ $t("enaq_translation_dashboard_cardtitle_overview") }}
              </p>
              <div class="tile is-parent is-vertical is-area">
                <img
                  class="map"
                  alt="Icon"
                  :src="require(`@/assets/enaqmap.png`)"
                />
              </div>
              <br />
              <div class="dashboard_buttonContainer">
                <router-link class="" :to="'/map'"
                  ><enaqbutton>Suche</enaqbutton></router-link
                >
              </div>
            </article>
          </div>
        </div>
      </div>
      <div class="tile is-parent is-3 ml-4">
        <article class="tile is-child box tile_groupmessages">
          <div class="tileIcon">
            <img class="icon" alt="Icon" :src="require(`@/assets/Chat.png`)" />
          </div>
          <p class="title_tile">
            {{ $t("enaq_translation_dashboard_groupmessages_title") }}
          </p>
          <div class="tile is-parent is-vertical">
            <article
              class="tile is-child is-area box groupMessage"
              v-for="groupMessage in groupMessages"
              :key="groupMessage.id"
              @click="openGroupView(groupMessage.group)"
            >
              <p class="dashboard_subtitle">{{ groupMessage.group.title }}</p>
              <p>
                {{ groupMessage.message.slice(0, 180) }}
              </p>
            </article>
            <div class="dashboard_buttonContainer">
              <router-link class="" :to="'/meetingpoint/groups'"
                ><enaqbutton>Mehr</enaqbutton></router-link
              >
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import announcement from "@/components/Announcements/announcement.vue";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "Dashboard",
  props: ["refresh"],
  components: {
    announcement,
    enaqbutton,
  },
  mounted: function () {
    if (this.refresh == true) {
      this.$router.push({
        name: "dashboard",
        params: {
          refresh: false,
        },
      });

      this.$router.go();
    }
    this.getAnnouncements();
    this.getNewestGroupMessages();
    //this.getDevices();
    this.$AuthService.getUser();
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      announcementCount: 3,
      announcements: null,
      groupMessages: null,
      devices: null,
    };
  },
  methods: {
    getAnnouncements() {
      this.HTTPRequest()
        .getAnnouncements(this.announcementCount)
        .then(
          function (response) {
            this.announcements = response.data.announcements;
          }.bind(this)
        );
    },
    openGroupView(group) {
      this.$router.push({
        name: "meetingpointgroups",
        params: { viewToOpen: "groups", groupToOpen: group },
      });
    },
    getNewestGroupMessages() {
      this.HTTPRequest()
        .getNewGroupMessages()
        .then(
          function (result) {
            this.groupMessages = result.data.messages;
          }.bind(this)
        );
    },
    sliceString(content, numberOfChars) {
      return content.slice(0, numberOfChars);
    },
    getDevices() {
      let headerData = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.bearertoken,
      };

      this.$axios
        .get(this.$backendURL + "/devices", {
          headers: headerData,
        })
        .then(
          function (response) {
            if (response.data.devices.length > 0) {
              this.devices = response.data.devices;
            }
          }.bind(this)
        );
    },
  },
  computed: {},
};
</script>

<style scoped>
.groupMessage {
  cursor: pointer;
}

.tile_announcements,
.tile_devices,
.tile_location,
.tile_groupmessages {
  background-color: #1d64b2;
  border-radius: 50px;
  box-shadow: -10px 10px 15px #777777;
}

.title_tile {
  text-decoration: underline;
  color: #b8e6ff;
  font-size: 22px;
  font-weight: 400;
}

.title_consumption {
  text-decoration: underline;
  color: #014580;
  font-size: 30px;
  font-weight: 500;
}

.dashboard_subtitle {
  text-decoration: underline;
}

.is-area {
  background-color: #4590ce;
  color: white;
  text-align: start;
  border-radius: 20px;
}

.icon {
  width: 20%;
  height: 50%;
  text-align: center;
  z-index: 2;
  position: relative;
}

.map {
  width: 50%;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  padding: 10px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  padding: 5px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front {
  position: absolute;
  width: 95%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  overflow: hidden;
}
.flip-card-back {
  position: relative;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  transform: rotateY(180deg);
  overflow: hidden;
}
</style>
