<template>
<div class="HoverText">

    <div @click="onClick()" class="buttonBody" :style="buttonStyle" @mouseover="mouseEnter()" @mouseleave="mouseLeave">
        <slot></slot>
        <div v-show="hover&&tooltip" class="tooltip" ref="tooltipx" :style="tooltipStyle">
         <p>   {{tooltip}}</p>
        </div>
        <div style="clear:both"></div>
    </div>
</div>
</template>

<script>
export default {
    name: 'HoverText',
    //props: ["tooltip", "backgroundcolor", "fontcolor", "fontsize", "hovercolor", "forceHover",],
    props: {"tooltip":String, "backgroundcolor":String, "fontcolor":String, "fontsize":String, "hovercolor":String, "forceHover":Boolean,"maxBoxWidthCH":{default:"30"}},
    mounted: function () {
        this.tooltipdata = this.$refs.tooltipx.clientWidth + "_" + this.$refs.tooltipx.clientHeight
        if (this.forceHover == true) {
            this.hover = true;
            this.$nextTick().then(function () {
                this.showTooltip = true;
                this.tooltipWidth = this.$refs.tooltipx.clientWidth;
                this.tooltipX = this.$refs.tooltipx.getBoundingClientRect().left
            }.bind(this))
            this.$emit("hover")
        }
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            defaultFontsize: "20px",
            defaultColor: "#209cee",
            defaultHoverColor: "#1496ed",
            defaultFontColor: "black",
            hover: false,
            tooltipdata: "234",
            tooltipWidth: 0,
            tooltipX: 0,
            screenWidth: 0,
            showTooltip: false,
            YAxisPosition: -50
        }
    },
    methods: {
        mouseEnter() {

            this.hover = true;
            this.$nextTick().then(function () {
                this.showTooltip = true;
                this.tooltipWidth = this.$refs.tooltipx.clientWidth;
                this.tooltipX = this.$refs.tooltipx.getBoundingClientRect().left
            }.bind(this))
            this.$emit("hover")

        },
        mouseLeave() {
            if (this.forceHover == true) {
                return;
            }
            this.showTooltip = false;
            this.hover = false;
            this.$emit("hoverend")

        },
        onClick() {
            this.$emit("click")

        }
    },
    computed: {
        buttonStyle() {
            var backgroundcolor = this.defaultColor;

            if (this.hover) {
                backgroundcolor = this.defaultHoverColor
                if (this.hovercolor != null && this.hovercolor != undefined && this.hovercolor != "") {
                    backgroundcolor = this.hovercolor
                }
            } else {
                if (this.backgroundcolor != null && this.backgroundcolor != undefined && this.backgroundcolor != "") {
                    backgroundcolor = this.backgroundcolor
                }
            }
            var fontsize = this.defaultFontsize;
            if (this.fontsize != null && this.fontsize != undefined && this.fontsize != "") {
                fontsize = this.fontsize
            }

            var fontcolor = this.defaultFontColor;
            if (this.fontcolor != null && this.fontcolor != undefined && this.fontcolor != "") {
                fontcolor = this.fontcolor
            }

            return {
                "font-size": fontsize,
                //"background": backgroundcolor,
                "color": fontcolor
            }
        },
        tooltipStyle() {
            //showTooltip is changed 1 tick AFTER tooltip is displayed.
            //This ensures that the calculations ( tooltipwidth usw. ) are correct. Otherwise the width would be based on an empty box.
            if (!this.showTooltip) {
                return {
                    left: "50%",
                    transform: "translateX(-50%)",
                }
            }

            this.screenWidth = window.screen.width;

            var rightEndPixel = this.tooltipWidth + this.tooltipX;
            var leftEndPixel = this.tooltipX;

            var styleLeft = "50%";
            if (rightEndPixel > this.screenWidth) {
                styleLeft = "calc( 50% - " + (rightEndPixel - this.screenWidth) + "px - 1em)"
            } else if (leftEndPixel < 0) {
                styleLeft = "calc( 50% - " + leftEndPixel + "px )"

            }

            let minWidthVal = "15ch";
            let transformValue = -100;
            //Calc box width
            if (this.tooltip.length > 20) {
                minWidthVal = this.maxBoxWidthCH+"ch"
                transformValue = -50
            }
            return {
                   left: styleLeft,
                transform: "translate(-50%,"+transformValue+"%)",
                width: minWidthVal
            }

        }
    },
    watch: {
        forceHover(newValue, oldValue) {
            if (newValue == true) {

                this.hover = true;
                this.$nextTick().then(function () {
                    this.showTooltip = true;
                    this.tooltipWidth = this.$refs.tooltipx.clientWidth;
                    this.tooltipX = this.$refs.tooltipx.getBoundingClientRect().left
                }.bind(this))
                this.$emit("hover")
            }
            if (newValue == false && oldValue == true) {
                this.showTooltip = false;
                this.hover = false;
                this.$emit("hoverend")
            }
        }
    }
}
</script>

<style scoped>
.HoverText {
    display: inline-block;
    text-decoration: none !important;
    position:relative

}

.buttonBody {
    display: inline-block;
    padding: 0.25em 0.5em;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    text-decoration: none !important;

}

.buttonBody a {
    text-decoration: none !important;
    color: inherit
}

.buttonBody a:link {
    text-decoration: none !important;
    color: inherit
}

.buttonBody a:hover {
    text-decoration: none !important;
    color: inherit
}

.buttonBody a:active {
    text-decoration: none !important;
    color: inherit
}

.buttonBody a:visited {
    text-decoration: none !important;
    color: inherit
}

.tooltip {
    position: absolute;
    background: rgba(97, 97, 97, .9);
    top: 50%;
    padding: 0.25em .5em;
    border-radius: 5px;
    width: auto;

    line-break: none;
    font-size: 1em;
    color: white;
    white-space: normal;
    z-index: 999
}
</style>
