<template>
  <div class="newSaleModal">
    <div class="modal is-active">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-card">
        <!-- Sale geöffnet -->
        <div v-if="ownSale.isopen">
          <header class="modal-card-head">
            <p class="modal-card-title">Bearbeite dein Angebot/Gesuch:</p>
            <button
              class="delete"
              aria-label="close"
              @click="closeModal"
            ></button>
          </header>
          <section class="modal-card-body">
            <div class="columns is-mobile is-multiline">
              <div class="label column is-3">
                {{ $t("enaq_translation_market_your_offers_close_offer") }}:
              </div>
              <div class="column is-9">
                <div class="icon">
                  <img
                    @click="closeSale(ownSale.id)"
                    alt="Icon"
                    :src="require(`@/assets/icons/store-slash-solid.svg`)"
                    width="50px"
                  />
                </div>
              </div>
              <div class="label column is-3">Kategorie:</div>
              <div class="column is-9">
                <select v-model="ownSale.type">
                  <option value="0">Verkaufe</option>
                  <option value="1">Kaufe/Suche</option>
                  <option value="2">Tausche</option>
                  <option value="3">Verschenke</option>
                  <option value="4">Anderes</option>
                </select>
              </div>
              <div class="label column is-3">Titel:</div>
              <div class="column is-9">
                <textarea
                  type="text"
                  class="input saleTitle"
                  v-model="ownSale.title"
                />
              </div>
              <div class="label column is-3">Beschreibung:</div>
              <div class="column is-9">
                <textarea
                  type="text"
                  class="input"
                  v-model="ownSale.description"
                />
              </div>
              <div class="label column is-3">Preis:</div>
              <div class="column is-9">
                <input type="number" v-model="ownSale.price" />
              </div>
            </div>
            <slideshow
              :items="saleItems"
              @activeImageID="getActiveImageID"
            ></slideshow>
            <br />
            <div
              v-if="saleItems.length <= 0"
              class="has-text-centered deleteImage"
            >
              <img
                alt="Icon"
                :src="require(`@/assets/icons/image-regular.svg`)"
                width="200px"
              />
            </div>
            <div class="columns is-mobile is-multiline">
              <div class="label column is-5 has-text-right">
                {{ $t("enaq_translation_market_your_offers_add_image") }}
                <i
                  class="icon fas fa-images"
                  @click="selectedSaleForImageUpload = ownSale.id"
                ></i>
              </div>
              <div class="label column is-2"></div>
              <div class="label column is-5 has-text-left">
                <div v-if="saleItems.length > 0" class="deleteImage">
                  Bild löschen
                  <i
                    class="icon fas fa-trash"
                    @click="removeSaleImage(ownSale.id, activeImageID)"
                  ></i>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <enaqbutton :width="'200px'" @click="updateSale(ownSale)">
              Änderungen speichern
            </enaqbutton>
          </footer>
        </div>
        <!-- Sale geschlossen -->
        <div v-else>
          <header class="modal-card-head">
            <p class="modal-card-title">Bearbeite dein Angebot/Gesuch:</p>
            <button
              class="delete"
              aria-label="close"
              @click="closeModal"
            ></button>
          </header>
          <section class="modal-card-body">
            <div class="has-text-centered">
              <div>
                {{ $t("enaq_translation_market_your_offers_closed") }}
                <i class="fas fa-times"></i>
              </div>
              <div>
                Bearbeitung nicht mehr möglich.
                <i class="fas fa-times"></i>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="newSaleModal" v-if="selectedSaleForImageUpload >= 0">
      <addimage
        :saleId="selectedSaleForImageUpload"
        @close="(selectedSaleForImageUpload = -1), closeModal()"
        @refreshAddedImages="refreshAddedImages"
      ></addimage>
    </div>
  </div>
</template>

<script>
import slideshow from "@/components/Slideshow/slideshow.vue";
import addimage from "@/components/Meetingpoint/Market/addImage.vue";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "editSale",
  props: ["ownSale"],
  components: {
    slideshow,
    addimage,
    enaqbutton,
  },
  mounted: function () {
    this.getImages();
  },
  data() {
    return {
      data: [],
      selectedSaleForImageUpload: -1,
      activeImageID: -1,
    };
  },
  methods: {
    getImages() {
      let items = [];
      for (var x = 0; x < this.ownSale.images.length; x++) {
        let imageData = this.ownSale.images[x];
        items.push({
          id: imageData.id,
          title: "",
          path: this.$storageURL + "" + imageData.imagepath,
        });
      }
      return items;
    },
    updateSale(sale) {
      this.HTTPRequest()
        .updateSale(sale)
        .then(
          function (result) {
            this.ownSales = result.data.sales;
            this.$emit("refreshSale");
            this.closeModal();
          }.bind(this)
        );
    },
    refreshAddedImages() {
      this.$emit("refreshImages");
    },
    getActiveImageID(activeImageID) {
      this.activeImageID = activeImageID;
    },
    closeSale(saleId) {
      this.HTTPRequest()
        .closeSale(saleId)
        .then(function (result) {}.bind(this));
    },
    removeSaleImage(saleId, imageID) {
      this.HTTPRequest()
        .removeSaleImage(saleId, imageID)
        .then(
          function (result) {
            this.updateSale(result.data.sale);
            this.closeModal();
          }.bind(this)
        );
    },
    closeModal() {
      this.$emit("close");
    },
    getSaleType(ownSale) {
      switch (ownSale.type) {
        case 0:
          return this.$t("enaq_translation_market_new_offer_type_sell");
        case 1:
          return this.$t("enaq_translation_market_new_offer_type_buy");
        case 2:
          return this.$t("enaq_translation_market_new_offer_type_trade");
        case 3:
          return this.$t("enaq_translation_market_new_offer_type_gift");
        case 4:
          return this.$t("enaq_translation_market_new_offer_type_other");
        default:
      }
    },
    getSalePrice(ownSale) {
      if (
        ownSale.price != 0 &&
        ownSale.price != undefined &&
        ownSale.price != null
      ) {
        return ownSale.price + "€";
      } else if (
        ownSale.individual_reward != "" &&
        ownSale.individual_reward != 0 &&
        ownSale.individual_reward != undefined &&
        ownSale.individual_reward != null
      ) {
        return ownSale.individual_reward;
      } else {
        return "Kein Preis angegeben";
      }
    },
  },
  computed: {
    saleItems() {
      let items = [];
      for (var x = 0; x < this.ownSale.images.length; x++) {
        let imageData = this.ownSale.images[x];
        items.push({
          id: imageData.id,
          title: "",
          path: this.$storageURL + "" + imageData.imagepath,
        });
      }
      return items;
    },
  },
};
</script>

<style scoped>
.icon {
  cursor: pointer;
}
.saleImage {
  height: 100%;
}

.input {
  width: 60%;
  height: auto;
}
.modal-card-body {
  max-height: 500px;
}
</style>
