<template>
<div class="component">

    <div class="modal is-active ">
        <div class="modal-background" @click="closeModal()"></div>
        <div class="modal-card ">
            <header class="modal-card-head ">
                <p class="modal-card-title">Füge eine Frage hinzu</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body has-background-light	">

                <div> <label class="label">Frage</label>
                    <input type="text" class="input" v-model="question" />
                    <br />
                    <label class="label">Beschreibung</label>
                    <textarea class="textarea" v-model="questionDescription"></textarea>

                </div>
                <br />
                <label class="label">Art der Antworten</label>
                <div class="select">
                    <select v-model="questionType">
                        <option :value="0">Antworten vorgeben</option>
                        <option :value="1">Individuelle Antworten (Text)</option>
                        <option :value="2">Individuelle Antworten (Zahlen/Nummern)</option>

                    </select>
                </div>

            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="sendQuestion">Senden</button>
                <button class="button" @click="closeModal()">Abbrechen</button>
            </footer>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'addQuestion',
    props: ["surveyId"],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            questionType: 0,
            question: "",
            questionDescription: ""
        }
    },
    methods: {
        sendQuestion() {

            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }

            let postData = {
                question: this.question,
                questionType: this.questionType,
                description: this.questionDescription,
                survey_id: this.surveyId
            }

            this.callApi("/survey/question/add", 'post', postData).then(function (response) {
                this.$emit("onQuestionAdd");
                this.closeModal();
            }.bind(this))

        },
        closeModal() {
            this.$emit("close");

        },

    },
    computed: {},
}
</script>

<style scoped>

</style>
