<template>
    <div class="infoModal">
<div class="modal" :class="{'is-active':openModal}">

  <div class="modal-background" @click="closeModal()"></div>
  <div class="modal-card">
    <header class="modal-card-head" >
      <p class="modal-card-title">{{mTitle}}</p>
      <button class="delete" aria-label="close" @click="closeModal()"></button>
    </header>
    <section class="modal-card-body">
      <span :style="{ color: mColor}">{{message}}</span>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-info" @click="closeModal()">Ok</button>
    </footer>
  </div>
</div>

    </div>
</template>

<script>
    export default {
        name: 'infoModal',
        props: ["message", "title", "messageColor", "openModal"],
        mounted: function () {
        },
        data() {
            return {
                msg: 'Welcome to Your Vue.js App',
                data: [],
            }
        },
        methods: {
            closeModal(){
                this.$emit("closed");
                this.openModal=false;
            }
        },
        computed: {
            mColor(){
                if(this.messageColor==""||this.messageColor==undefined){
                    return "black"
                }else{
                    return this.messageColor
                }
            },
            mTitle(){
                if(this.title==""||this.title==undefined){
                    return "Information"
                }else{
                    return this.title;
                }
            }
        },
    }
</script>

<style scoped>
.modal-card-foot{text-align:center;}
.modal-card-foot .button{padding: 0 30px;margin:auto;}
.modal-card-head{font-size:32pt!important;font-weight:bolder;}
.modal-card-body{ font-size:18pt}
@media screen and (max-width: 600px) {
    .modal-card-title{
        font-size:1.35rem;
    }
}
</style>
