<template>
    <div class="component">

    <apexchart width="90%" :height="'90%'" type="line" :options="graphOptions" :series="graphData"></apexchart>

    </div>
</template>

<script>
    export default {
        name: 'idealConsumptionGraph',
        props: [],
        mounted: function () {
        },
        data() {
            return {
                data: [],
                day:1313564400000,
            }
        },
        methods: {
            getData(){

            }
        },
        computed: {
                graphOptions() {
                    return {
                stroke: {
                    curve: 'smooth',
                },
                chart: {

                    id: this.title || "Graph " + Math.floor(Math.random() * 1000),
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                xaxis: {
                    categories: this.graphData[0].dates,

                },
                yaxis: {
                    type: "numeric",
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(2)
                        }
                    },
                    title: {
                        text: "Euro",
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            cssClass: 'apexcharts-xaxis-title',
                        },
                    },
                }}
            }
        },
    }
</script>

<style scoped>

</style>
