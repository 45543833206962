<template>
<div class="component">
    <div class="battery" :style="batteryStyle">
        <div class="batteryTop"></div>
        <div class="batteryWrapper">
            <div class="batteryInside" :style="batteryFillStyle"></div>
            <div class="batteryIcon" :style="batteryIconStyle"><i class="fas fa-bolt"></i></div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: 'battery',
    props: ["percentage", "width"],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: []
        }
    },
    methods: {

    },
    computed: {
        batteryStyle() {
            return {
                "width": this.width + "vw",
                "height": this.width * 1.6 + "vw",
                "margin-bottom": this.width / 9 + "vw",
                "margin-top": this.width / 9 + "vw",

            }
        },
        batteryFillStyle() {
            return {
                "height": this.percentage + "%"
            }
        },
        batteryIconStyle() {
            return {
                "font-size": this.width / 2 + "vw"
            }
        }
    },
}
</script>

<style scoped>
.battery {
    position: relative;
    margin: auto;
    margin-bottom: 6px;
    padding: 5px;
    background: linear-gradient(#7C7C7C, #B6B6B6, #7C7C7C);

    box-sizing: content-box;

}

.batteryWrapper {
    height: 100%;
    width: 100%;
    background: radial-gradient(#A5A5A5, #959595);
    position: relative;

    box-sizing: content-box;
}

.batteryInside {
    width: calc(100%);
    position: absolute;
    bottom: 0px;
    overflow: hidden;
    background: linear-gradient(#B1FF07, #00BC3F)
}

.batteryIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.batteryTop {
    position: absolute;
    width: 33%;
    height: 8%;
    background: #7C7C7C;
    z-index: 4;
    left: 50%;

    transform: translateX(-50%);
    top: -8%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

}
</style>
