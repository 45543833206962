<template>
<div class="component">
    <div class="select is-multiple" :class="{'is-multiple':multiselect}">
        <select @change="onChange($event)" :multiple="true" :size="userList.length" size="8" class="userSelect">
            <option v-for="user in userList" :value="user.id" :key="user">{{user.name}}, {{user.firstname}}</option>
        </select>
    </div>

</div>
</template>

<script>
export default {
    name: 'component',
    props: ["multiselect"],
    mounted: function () {
        this.getUserList();
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            userList: [],
            selectedUser:[],
        }
    },
    methods: {
        getUserList() {
            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }
            this.$axios.get(this.$backendURL + "/user/list/all", {
                headers: headerData
            }).then(function (response) {
                this.userList = response.data.user;
            }.bind(this))
        },

         onChange(event) {
             this.$emit("selected",event.target.value)
        },
    },
    computed: {

    },
}
</script>

<style scoped>
.userSelect {
    height: auto;
}
</style>
