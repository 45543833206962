<template>
<div class="component">
    <div class="columns is-mobile is-multiline enaqFooter cpPrimaryBackground">

        <div class="column is-6-tablet is-hidden-mobile footerLeft">
            <div class=" footerImage">
                <img alt="Vue logo" src="../../assets/foerderlogo.jpg">

            </div>
        </div>

        <div class="column is-6-tablet is-12-mobile footerRight">
            <div class="footerLinks">
                <div class="footerLinks column is-12-desktop is-12-mobile">
                    <a href="https://helleheide.de/beitraege/"> {{$t('enaq_translation_footer_press')}}</a>
                    <a href="https://www.quantumfrog.de/impressum/"> {{$t('enaq_translation_footer_impressum')}}</a>
                    <a href="https://www.quantumfrog.de/datenschutzerklaerung/"> {{$t('enaq_translation_footer_privacy')}}</a>
                    <router-link class="  " :to="'/feedback'"> {{$t('enaq_translation_footer_feedback')}}</router-link>

                </div>
            </div>
        </div>

        <div class="column is-12 is-hidden-tablet footerLeft">
            <div class=" mobileFooterImage">
                <img alt="Vue logo" src="../../assets/foerderlogo.jpg">

            </div>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'Footer',
    props: [],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: []
        }
    },
    methods: {},
    computed: {},
}
</script>

<style scoped>
.enaqFooter {
    width: 100vw;
    padding: 0;
    position: relative;
    font-weight: lighter;
    margin-top: 50px;
    padding-top: 1px;
}

.footerLeft {
    text-align: right;
}

.footerImage {
    max-width: 60%;
    margin-left: 20%;
}

.mobileFooterImage {
    max-width: 95%;
    margin: auto;
}

.footerLinks a {
    width: 80%;
    padding-left: 20%;
    display: inline-block;
    color: white;
    text-align: left;
    font-size: 1em;
    white-space: nowrap;
    font-weight: normal;
    margin: .4em 0;

}

.footerLinks a:after {
    border-bottom: 1px solid rgba(255, 255, 255, .08);
    margin-left: -5%;
    width: 10%;
    height: 1px;
    content: "";
    display: block;

}

.footerLinks {
    width: 100%;
    margin-top: 1em;
}

@media (max-width: 1484px) {
    .footerImage {
        max-width: 80%;
        margin: auto;
    }
}
</style>
