<template>
<div class="component">

    <div class="modal is-active ">
        <div class="modal-background" @click="closeModal()"></div>
        <div class="modal-card ">
            <header class="modal-card-head ">
                <p class="modal-card-title">Neue Umfrage</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body has-background-light	">

                <div> <label class="label">Titel der Umfrage</label>
                    <input type="text" class="input" v-model="surveyTitle" />
                    <br />
                  
                </div>
                <br />
              

            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="sendQuestion">Senden</button>
                <button class="button" @click="closeModal()">Abbrechen</button>
            </footer>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'addSurveyModal',
    props: ["surveyId"],
    mounted: function () {},
    data() {
        return {
            data: [],
            surveyTitle:"",
        }
    },
    methods: {
        sendQuestion() {

            let headerData = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.bearertoken
            }

            let postData = {
                title: this.surveyTitle
            }

            this.$axios.post(this.$backendURL + "/survey/create", postData, {
                headers: headerData
            }).then(function (result) {
                this.$emit("onQuestionAdd");
                this.closeModal();
            }.bind(this))
        },
        closeModal() {
            this.$emit("close");

        },

    },
    computed: {},
}
</script>

<style scoped>

</style>
