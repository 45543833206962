import { render, staticRenderFns } from "./createRessource.vue?vue&type=template&id=6c6d4778&scoped=true&"
import script from "./createRessource.vue?vue&type=script&lang=js&"
export * from "./createRessource.vue?vue&type=script&lang=js&"
import style0 from "./createRessource.vue?vue&type=style&index=0&id=6c6d4778&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c6d4778",
  null
  
)

export default component.exports