<template>
  <div class="component">
    <div>
      <br />
      <table class="table is-fullwidth">
        <div class="columns is-mobile is-multiline">
          <div class="column is-2-desktop is-12-tablet is-12-mobile">
            <div>
              <datepicker
                v-model="filteredMonthYear"
                :minimumView="'month'"
                :maximumView="'month'"
                :format="format"
                placeholder="Wähle Monat und Jahr"
                :language="languages[language]"
              ></datepicker>
            </div>
          </div>
          <div class="column is-2-desktop is-12-tablet is-12-mobile">
            <enaqbutton :width="'180px'" @click="getEvents(year, monthNum)">
              {{ month }} / {{ year }}
            </enaqbutton>
          </div>
          <div class="column is-2-desktop is-12-tablet is-12-mobile">
            <enaqbutton
              :width="'180px'"
              @click="getEvents(yearPlusOne, monthNumPlusOne)"
            >
              {{ monthPlusOne }} / {{ yearPlusOne }}
            </enaqbutton>
          </div>
          <div class="column is-2-desktop is-12-tablet is-12-mobile">
            <enaqbutton
              :width="'180px'"
              @click="getEvents(yearPlusTwo, monthNumPlusTwo)"
            >
              {{ monthPlusTwo }} / {{ yearPlusTwo }}
            </enaqbutton>
          </div>
        </div>
        <div class="columns is-mobile is-multiline">
          <div class="column is-2-desktop is-12-tablet is-12-mobile">
            <div class="columns is-mobile is-multiline">
              <span class="column is-7 has-text-right filterTitle">{{
                $t("enaq_translation_calendar_only_open")
              }}</span>
              <div class="column is-5 has-text-left">
                <slidebutton
                  :height="'31px'"
                  :default="onlyOpen"
                  @toggle="onlyOpen = !onlyOpen"
                ></slidebutton>
              </div>
            </div>
          </div>
          <div class="column is-3-desktop is-12-tablet is-12-mobile">
            <div class="columns is-mobile is-multiline">
              <span class="column is-7 has-text-right filterTitle">{{
                $t("enaq_translation_calendar_only_free")
              }}</span>
              <div class="column is-5 has-text-left">
                <slidebutton
                  :height="'31px'"
                  :default="onlyFree"
                  @toggle="onlyFree = !onlyFree"
                  v-model="onlyFree"
                ></slidebutton>
              </div>
            </div>
          </div>
          <div class="column is-7-desktop is-12-tablet is-12-mobile">
            <div class="columns is-mobile is-multiline">
              <span class="column is-3 has-text-right filterTitle"
                >Teilnehmend</span
              >
              <div class="column is-9 has-text-left">
                <slidebutton
                  :height="'31px'"
                  :default="onlyParticipated"
                  @toggle="onlyParticipated = !onlyParticipated"
                  v-model="onlyParticipated"
                ></slidebutton>
              </div>
            </div>
          </div>
          <div
            class="column is-4-desktop is-12-tablet is-12-mobile has-text-left"
          >
            <span>Preis: </span>
            <input
              class="textField"
              type="number"
              min="0"
              v-model="priceFrom"
              id="priceFrom"
            />
            <span> - </span>
            <input
              class="textField"
              type="number"
              min="1"
              v-model="priceTo"
              id="priceTo"
            />
            <span> €</span>
          </div>
          <div
            class="column is-8-desktop is-12-tablet is-12-mobile has-text-left"
          >
            <enaqbutton :width="'200px'" @click="resetPriceFilter()"
              >Preis zurücksetzen</enaqbutton
            >
          </div>
        </div>
      </table>
    </div>
    <br />
    <div class="columns is-mobile is-multiline">
      <div class="column is-2-desktop is-12-tablet is-12-mobile">
        <div class="cpTextSmall filterResult">
          {{ filteredEvents.length }} Suchergebnisse
        </div>
      </div>
    </div>

    <!-- Zeitverlauf angepasst (blaue Kästchen) -->
    <header class="modal-card-head" @click="openTabByName('test2')">
      <p class="modal-card-title">Alle Events</p>
      <i class="fas fa-chevron-down"></i>
    </header>
    <transition name="eventPartTransition">
      <transition-group
        name="eventListTransition"
        tag=""
        v-if="openTab == 'test2'"
      >
        <div
          class="eventItem columns is-mobile is-multiline"
          v-for="event in filteredEvents"
          :key="event.id"
          @click="onEventTitleClick(event.id)"
        >
          <!-- Datum-->
          <div class="column is-1 eventListLeft">
            <div class="eventListDate">
              {{ formatDate(event.begin)[0] }}<br />{{
                formatDate(event.begin)[1]
              }}
            </div>
          </div>

          <!-- Punkte-->
          <div class="column is-1 eventListMiddle">
            <div
              class="eventListDot"
              :class="{ eventListDotPast: !event.isOpen }"
            ></div>
            <div
              class="eventListLine"
              :class="{ activeEventListLine: openEvent == event.id }"
            ></div>
          </div>

          <!-- Block -->
          <div class="block columns is-mobile is-multiline column is-10">
            <!-- Inhalt-->
            <div class="column is-10 eventListMiddle">
              <div class="eventTitle">
                {{ event.title }}
              </div>
              <transition name="eventPartTransition">
                <div v-if="openEvent == event.id" class="innerBlock">
                  <div>
                    <div class="date">
                      <span class="labelEvents">Zeitraum: </span>
                      {{ formatDate(event.begin)[0] }} ({{
                        formatDate(event.begin)[1]
                      }}) - {{ formatDate(event.end)[0] }} ({{
                        formatDate(event.end)[1]
                      }})
                    </div>
                    <br />
                    <span class="labelEvents">
                      {{ $t("enaq_translation_calendar_event_organisator") }}:
                    </span>
                    <div>
                      <router-link
                        class="messageAuthor"
                        :to="{
                          name: 'messageCreateWithTitle',
                          params: {
                            messagetitle: 'Eventanfrage zu ' + event.title,
                            messagereciever: event.author.id,
                          },
                        }"
                      >
                        {{ event.author.firstname }}
                        {{ event.author.name }} (Nachricht)
                      </router-link>
                    </div>
                    <br />
                    <div>
                      <div class="labelEvents">Beschreibung:</div>
                      {{ event.description }}
                    </div>
                    <br />
                    <div v-if="event.room == null">
                      <span class="labelEvents"
                        >{{ $t("enaq_translation_events_place") }}:</span
                      >
                      {{ event.place }}
                    </div>
                    <div v-else>
                      <span class="labelEvents"
                        >{{ $t("enaq_translation_events_place") }}:</span
                      >
                      {{ event.room.name }}
                    </div>
                    <br />
                    <div>
                      <span class="labelEvents">Preis: </span
                      >{{ event.price }} €
                    </div>
                    <br />
                  </div>
                  <div v-if="event.isOpen">
                    <div v-if="participate == -1">
                      <enaqbutton
                        :backgroundcolor="'#30b830'"
                        :width="'150px'"
                        class="has-text-centered"
                        @click="joinEvent(event.id)"
                      >
                        Teilnehmen
                        <i class="fas fa-check"></i>
                      </enaqbutton>
                    </div>
                    <div v-if="participate == 0" class="has-text-centered">
                      <enaqbutton
                        :width="'150px'"
                        :backgroundcolor="'#f06969'"
                        @click="leave(event.id)"
                      >
                        {{ $t("enaq_translation_events_leave") }}
                        <i class="fas fa-sign-out-alt"></i>
                      </enaqbutton>
                      (Sie müssen aktuell leider noch warten, bis ihre Anfrage
                      angenommen wird.)
                    </div>
                    <div v-if="participate == 1">
                      <enaqbutton
                        :width="'150px'"
                        :backgroundcolor="'#f06969'"
                        class="has-text-centered"
                        @click="leave(event.id)"
                      >
                        {{ $t("enaq_translation_events_leave") }}
                        <i class="fas fa-sign-out-alt"></i>
                      </enaqbutton>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <!-- offen/geschlossen-->
            <div class="column is-2 eventListMiddle">
              <div v-if="event.isOpen" class="open">offen</div>
              <div v-else class="closed">geschlossen</div>
            </div>
          </div>
        </div>
      </transition-group>
    </transition>

    <br />

    <!-- eigene Events -->
    <header class="modal-card-head" @click="openTabByName('own')">
      <p class="modal-card-title">{{ $t("enaq_translation_events_own") }}</p>
      <i class="fas fa-chevron-down"></i>
    </header>
    <br />
    <div class="has-text-right">
      <enaqbutton
        :fontsize="'20px'"
        :width="'180px'"
        @click="openModal = true"
        >{{ $t("enaq_translation_events_new_button") }}</enaqbutton
      >
    </div>
    <br />
    <transition name="eventPartTransition">
      <div class="ownEvents" v-if="openTab == 'own'">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th class="has-text-centered" width="40%">
                {{ $t("enaq_translation_events_title") }}
              </th>
              <th class="has-text-centered" width="10%">
                {{ $t("enaq_translation_events_member") }}
              </th>
              <th class="has-text-centered" width="15%">
                {{ $t("enaq_translation_events_update") }}
              </th>
              <th class="has-text-centered" width="15%">
                {{ $t("enaq_translation_events_delete") }}
              </th>
            </tr>
          </thead>

          <tbody v-for="event in ownEvents" :key="event.id">
            <tr class="messageRow">
              <td class="has-text-centered">{{ event.title }}</td>
              <td class="has-text-centered" @click="showMembers(event)">
                <i class="fas fa-users"></i>
              </td>
              <td v-if="event.isOpen" class="has-text-centered">
                <i class="fas fa-edit" @click="selectEditEvent(event)"></i>
              </td>
              <td v-if="event.isOpen" class="has-text-centered">
                <i class="fas fa-trash" @click="deleteEvent(event.id)"></i>
              </td>
              <td v-if="!event.isOpen" class="has-text-centered" colspan="2">
                {{ $t("enaq_translation_events_closed") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </transition>
    <div v-if="openModal">
      <editEvent
        :event="selectedEventToEdit"
        @closeModal="closeEventModal"
      ></editEvent>
    </div>

    <div class="memberModal" v-if="selectedMemberEvent != ''">
      <div class="modal is-active">
        <div
          class="modal-background"
          @click="
            (selectedMemberEvent = ''), (activeTab = 1), emptyMemberLists()
          "
        ></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              {{ $t("enaq_translation_events_member") }}
            </p>
            <button
              class="delete"
              aria-label="close"
              @click="
                (selectedMemberEvent = ''), (activeTab = 1), emptyMemberLists()
              "
            ></button>
          </header>
          <section class="modal-card-body">
            <div v-if="selectedMemberEvent.isOpen">
              <table class="table is-fullwidth">
                <div class="columns is-mobile is-multiline">
                  <div
                    class="column is-6-desktop is-12-tablet is-12-mobile tab"
                  >
                    <input
                      type="radio"
                      name="css-tabs"
                      id="tab-1"
                      checked
                      class="tab-switch"
                    />
                    <label
                      for="tab-1"
                      class="tab-label"
                      @click="setActiveTab(1)"
                      >Teilnehmer</label
                    >
                  </div>
                  <div
                    class="column is-6-desktop is-12-tablet is-12-mobile tab"
                  >
                    <input
                      type="radio"
                      name="css-tabs"
                      id="tab-2"
                      class="tab-switch"
                    />
                    <label
                      for="tab-2"
                      class="tab-label"
                      @click="setActiveTab(2)"
                      >Anfragen</label
                    >
                  </div>
                </div>
              </table>

              <div v-if="activeTab == 1" class="tab-content">
                <div
                  class=""
                  v-if="
                    acceptedMembers == null ||
                    acceptedMembers.length == 0 ||
                    acceptedMembers == undefined
                  "
                >
                  Bisher noch keine Teilnehmer.
                </div>
                <div v-else>
                  <div v-for="member in acceptedMembers" :key="member.id">
                    <div class="columns">
                      <div class="column is-9">
                        {{ member.user.firstname }}
                        {{ member.user.name }}
                      </div>
                      <div class="column is-3">
                        <enaqbutton
                          :backgroundcolor="'#f06969'"
                          @click="removeMember(member)"
                        >
                          {{ $t("enaq_translation_events_member_remove") }}
                        </enaqbutton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="activeTab == 2" class="tab-content">
                <div
                  class=""
                  v-if="
                    membershipRequests == null ||
                    membershipRequests.length == 0 ||
                    membershipRequests == undefined
                  "
                >
                  Keine Anfragen vorhanden.
                </div>
                <div
                  v-else
                  v-for="member in membershipRequests"
                  :key="member.id"
                >
                  <div class="columns">
                    <div class="column is-9">
                      {{ member.user.firstname }} {{ member.user.name }}
                    </div>
                    <div class="column is-3">
                      <enaqbutton
                        :width="'120px'"
                        :backgroundcolor="'#30b830'"
                        v-if="!member.accepted"
                        @click="acceptMember(member)"
                      >
                        {{ $t("enaq_translation_events_member_accept") }}
                      </enaqbutton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>Event ist geschlossen</div>
          </section>
          <footer class="modal-card-foot has-text-centered">
            <enaqbutton
              :backgroundcolor="'#808080'"
              @click="
                (selectedMemberEvent = ''), (activeTab = 1), emptyMemberLists()
              "
            >
              {{ $t("enaq_translation_events_new_cancel") }}
            </enaqbutton>
          </footer>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import slidebutton from "@/components/helper/slidebutton.vue";
import infoModal from "@/components/UI/infoModal.vue";
import Datepicker from "vuejs-datepicker";
import editEvent from "@/components/Meetingpoint/Events/editEvent.vue";
import * as lang from "vuejs-datepicker/src/locale";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "Events",
  components: {
    slidebutton,
    infoModal,
    Datepicker,
    editEvent,
    enaqbutton,
  },
  props: ["eventtoedit"],
  mounted: function () {
    this.userID = this.$store.getters.getUser.id;
    this.setMonthsAndYears();
    var d = new Date();
    this.selectedMonth = d.getMonth() + 1;
    this.selectedYear = d.getFullYear();
    this.getEvents(d.getFullYear(), d.getMonth() + 1);
    this.getParticipatedEvents();
    this.getOwnEvents();
    if (this.eventtoedit) {
      this.selectedEventToEdit = this.eventtoedit;
      this.openModal = true;
    }
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      openEvent: null,
      selectedEvent: null,
      events: [],
      ownEvents: [],
      participatedEvents: [],
      selectedEventToEdit: "",
      openModal: false,
      selectedMemberEvent: "",
      openTab: "test2",
      format: "MMM yyyy",
      filteredMonthYear: null,
      language: "de",
      languages: lang,
      selectedMonth: null,
      selectedYear: null,
      filter: -1,
      year: null,
      yearPlusOne: null,
      yearPlusTwo: null,
      monthNum: null,
      month: "",
      monthNumPlusOne: null,
      monthPlusOne: "",
      monthNumPlusTwo: null,
      monthPlusTwo: "",
      onlyFree: false,
      onlyOpen: false,
      priceFrom: "",
      priceTo: "",
      userID: null,
      acceptedMembers: [],
      membershipRequests: [],
      activeTab: 1,
      participate: -1,
      onlyParticipated: false,
    };
  },
  methods: {
    emptyMemberLists() {
      this.acceptedMembers = null;
      this.membershipRequests = null;
    },
    setActiveTab(num) {
      this.activeTab = num;
    },
    disable() {
      document.getElementById("priceFrom").disabled = true;
      document.getElementById("priceTo").disabled = true;
    },
    enable() {
      document.getElementById("priceFrom").disabled = false;
      document.getElementById("priceTo").disabled = false;
    },
    resetPriceFilter() {
      this.priceFrom = "";
      this.priceTo = "";
    },
    setMonthsAndYears() {
      /* neues (aktuelles) Datum */
      var d = new Date();

      /* aktuelles Jahr */
      this.year = d.getFullYear();

      /* aktueller Monat */
      var mNum = d.getMonth() + 1;
      this.monthNum = mNum;
      this.month = this.getNewMonth(mNum);

      /* aktueller Monat plus 1 */
      var mNumPlusOne = mNum + 1;

      this.yearPlusOne = this.year;
      if (mNumPlusOne > 12) {
        if (mNumPlusOne == 13) {
          mNumPlusOne = 1;
        }
        if (mNumPlusOne == 14) {
          mNumPlusOne = 2;
        }
        this.yearPlusOne = d.getFullYear() + 1;
      }
      this.monthNumPlusOne = mNumPlusOne;
      this.monthPlusOne = this.getNewMonth(mNumPlusOne);

      /* aktueller Monat plus 2 */
      var mNumPlusTwo = mNum + 2;
      this.yearPlusTwo = this.year;
      if (mNumPlusTwo > 12) {
        if (mNumPlusTwo == 13) {
          mNumPlusTwo = 1;
        }
        if (mNumPlusTwo == 14) {
          mNumPlusTwo = 2;
        }
        this.yearPlusTwo = d.getFullYear() + 1;
      }
      this.monthNumPlusTwo = mNumPlusTwo;
      this.monthPlusTwo = this.getNewMonth(mNumPlusTwo);
    },
    getNewMonth(number) {
      if (number == 13) {
        number = 1;
      }
      if (number == 14) {
        number = 2;
      }
      switch (number) {
        case 1:
          return "Januar";
        case 2:
          return "Februar";
        case 3:
          return "März";
        case 4:
          return "April";
        case 5:
          return "Mai";
        case 6:
          return "Juni";
        case 7:
          return "Juli";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "Oktober";
        case 11:
          return "November";
        case 12:
          return "Dezember";
        default:
      }
    },
    getMonthYear(monthYear) {
      var date = [];
      var str = new String(monthYear);
      date = str.split(" ");
      switch (date[1]) {
        case "Jan":
          this.selectedMonth = 1;
          break;
        case "Feb":
          this.selectedMonth = 2;
          break;
        case "Mar":
          this.selectedMonth = 3;
          break;
        case "Apr":
          this.selectedMonth = 4;
          break;
        case "May":
          this.selectedMonth = 5;
          break;
        case "Jun":
          this.selectedMonth = 6;
          break;
        case "Jul":
          this.selectedMonth = 7;
          break;
        case "Aug":
          this.selectedMonth = 8;
          break;
        case "Sep":
          this.selectedMonth = 9;
          break;
        case "Oct":
          this.selectedMonth = 10;
          break;
        case "Nov":
          this.selectedMonth = 11;
          break;
        case "Dec":
          this.selectedMonth = 12;
          break;
        default:
      }
      this.selectedYear = date[3];
    },
    changeSelectedDate(dateObject, timeValue) {
      let newMomentObject = this.selectedDateOne;
      this.selectedDateOne = null;
      newMomentObject.set(timeValue);
      this.selectedDateOne = newMomentObject;
      this.getDeviceData();
    },
    openTabByName(name) {
      if (this.openTab == name) {
        this.openTab = "";
      } else {
        this.openTab = name;
      }
    },
    getsortedEvents(list) {
      return list.sort(function (a, b) {
        return b.begin > a.begin ? -1 : 1;
      });
    },
    getEvents(year, month) {
      this.HTTPRequest()
        .getEvents(year, month)
        .then(
          function (result) {
            this.events = this.getsortedEvents(result.data.events);
            this.getParticipatedEventsForFilter();
          }.bind(this)
        );
    },
    getOwnEvents() {
      this.HTTPRequest()
        .getOwnEvents()
        .then(
          function (result) {
            this.ownEvents = result.data.events;
          }.bind(this)
        );
    },
    getParticipatedEventsForFilter() {
      for (var eventIndex = 0; eventIndex < this.events.length; eventIndex++) {
        let eventData = this.events[eventIndex];
        if (eventData.participated) {
          this.events[eventIndex].color = "#f0ad12";
          this.events[eventIndex].participated = true;
        }
      }
    },
    getParticipatedEvents() {
      this.HTTPRequest()
        .getParticipatedEvents()
        .then(
          function (result) {
            this.participatedEvents = result.data.events;
          }.bind(this)
        );
    },
    selectEditEvent(event) {
      this.selectedEventToEdit = event;
      this.openModal = true;
    },
    joinEvent(id) {
      this.HTTPRequest()
        .joinEvent(id)
        .then(
          function (result) {
            this.getParticipatedEvents();
          }.bind(this)
        );
    },
    leave(eventID) {
      this.HTTPRequest()
        .leaveEvent(eventID)
        .then(
          function (result) {
            this.getParticipatedEvents();
          }.bind(this)
        );
    },
    deleteEvent(eventID) {
      this.HTTPRequest()
        .deleteEvent(eventID)
        .then(
          function (result) {
            this.getOwnEvents();
          }.bind(this)
        );
    },
    getAcceptedMembers(id) {
      this.HTTPRequest()
        .getAcceptedMembers(id)
        .then(
          function (result) {
            this.acceptedMembers = result.data.members;
          }.bind(this)
        );
    },
    getMembershipRequests(id) {
      this.HTTPRequest()
        .getMembershipRequests(id)
        .then(
          function (result) {
            this.membershipRequests = result.data.requests;
          }.bind(this)
        );
    },
    acceptMember(member) {
      this.HTTPRequest()
        .acceptMember(member.event_id, member.user_id)
        .then(
          function (result) {
            this.getAcceptedMembers(member.event_id);
            this.getMembershipRequests(member.event_id);
          }.bind(this)
        );
    },
    removeMember(member) {
      this.HTTPRequest()
        .removeMember(member.event_id, member.user_id)
        .then(
          function (result) {
            this.getAcceptedMembers(member.event_id);
            this.getMembershipRequests(member.event_id);
          }.bind(this)
        );
    },
    showMembers(event) {
      this.selectedMemberEvent = event;
      this.getAcceptedMembers(event.id);
      if (event.needsManualParticipateConfirmation == 1) {
        this.getMembershipRequests(event.id);
      }
    },
    closeEventModal() {
      this.openModal = false;
      this.selectedEventToEdit = "";
      this.getOwnEvents();
    },
    onEventTitleClick(eventid) {
      if (this.openEvent == eventid) {
        this.openEvent = -1;
      } else {
        this.openEvent = eventid;
        this.checkParticipatedEvent(eventid);
      }
    },
    checkParticipatedEvent(eventid) {
      var participate = -1;

      this.participatedEvents.forEach(function (item) {
        if (item.id == eventid) {
          if (item.accepted == true) {
            participate = 1;
          }
          if (item.accepted == false) {
            participate = 0;
          }
        }
      });
      this.participate = participate;
    },
    formatDate(date) {
      var formattedDate = new Date(date);
      var dateValues = [];
      var options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      dateValues.push(formattedDate.toLocaleDateString("de-DE", options));
      dateValues.push(
        formattedDate.getHours() + " " + this.$t("enaq_translation_events_hour")
      );

      return dateValues;
    },
  },
  computed: {
    filteredEvents: function () {
      var priceFrom = this.priceFrom;
      var priceTo = this.priceTo;

      var newEvents = this.events;

      if (this.onlyOpen) {
        newEvents = newEvents.filter(function (event) {
          return event.isOpen == true;
        });
      }

      if (this.onlyFree) {
        newEvents = newEvents.filter(function (event) {
          return event.price == 0;
        });
      }

      if (this.onlyParticipated) {
        newEvents = newEvents.filter(function (event) {
          return event.participated == true;
        });
      }

      if (
        priceFrom != "" &&
        priceFrom >= 0 &&
        priceFrom != undefined &&
        priceFrom != null &&
        priceTo != "" &&
        priceTo >= 0 &&
        priceTo != undefined &&
        priceTo != null
      ) {
        newEvents = newEvents.filter(function (event) {
          return event.price >= priceFrom && event.price <= priceTo;
        });
      }

      return newEvents;
    },
  },
  watch: {
    filteredMonthYear: function (val, oldVal) {
      if (val != oldVal) {
        this.getMonthYear(val);
        this.getEvents(this.selectedYear, this.selectedMonth);
      }
    },
    onlyFree: function (val) {
      if (val == true) {
        this.disable();
        this.resetPriceFilter();
      }
      if (val == false) {
        this.enable();
      }
    },
  },
};
</script>

<style scoped>
.textField {
  width: 140px;
}
.filterResult {
  font-weight: bolder;
}
.eventItem {
  padding: 0px 5px 0px 5px;
}
.date {
  color: #f0ad12;
}
.block {
  color: white;
  text-align: start;
  border-radius: 30px;
  box-shadow: -10px 10px 15px #777777;
  background: #1c63af;
  margin-top: 30px;
}
.test {
  background: blue;
  width: 1500px;
}
.labelEvents {
  font-weight: bold;
}

.viewTitle {
  margin-top: 15px;
  font-weight: bolder;
  font-size: 3em;
}

.createEvent {
  margin-top: 25px;
}

.textareaPartial {
  min-width: 0px;
  margin-bottom: 0.5em;
}

.roomSelect {
  text-align: left;
}

.eventPartTransition-enter-active {
  transition: all 0.7s;
  overflow: hidden;
  max-height: 100vh;
}

.eventPartTransition-leave-active {
  transition: all 0.3s;
  overflow: hidden;
  max-height: 100vh;
}

.eventPartTransition-enter,
.eventPartTransition-leave-to {
  opacity: 0;
  max-height: 0px;
}

.eventListLine {
  display: block;
  height: calc(100%);
  border-left: 10px solid #f5f5f5;
  margin-top: 2%;
  margin-left: -5px;
  max-width: 10px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  z-index: 3;
  position: absolute;
}

.activeEventListLine {
  border-left: 10px solid #cccccc;
  border-radius: 0px;
  z-index: 3;
}

.eventListDot {
  border: 3px solid #f0ad12;
  height: 14px;
  width: 14px;
  border-radius: 40px;
  margin-left: -7px;
  z-index: 30;
  position: absolute;
  background: #ffffff;
}

.eventListDotPast {
  border: 3px solid #f0ad12;
  z-index: 30;
}

.eventListLeft {
  font-size: 0.8em;
  display: inline-block;
}

.eventTitle {
  color: #f0ad12;
  font-size: 1.4em;
  font-weight: bolder;
}

.eventListDate {
  font-size: 1em;
  background: #f5f5f5;
  padding: 5% 10%;
  display: inline-block;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 10px;
}

.eventListMiddle {
  position: relative;
}

.eventListTransition-enter-active,
.eventListTransition-leave-active {
  transition: all 0.5s;
}

/*Ressources*/
.open {
  color: #30b830;
  text-shadow: 0 0 2px black;
}
.closed {
  color: #f06969;
  text-shadow: 0 0 2px black;
}

.tab-switch {
  display: none;
}
.tab-label {
  display: block;
  line-height: 2em;
  border: 1px #b1aaaa solid;
  border-radius: 50px;
  cursor: pointer;
}
.tab-switch:checked + .tab-label {
  transition: all 0.35s;
  z-index: 1;
  background: #f0ad12;
}
.tab-content {
  border-top: 1px #dfd9d9 solid;
  padding: 15px;
}

.messageAuthor {
  color: white !important;
  text-decoration: underline;
}
</style>
