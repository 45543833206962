<template>
<div class="component">
    <div class="">
        <div class="columns is-mobile">
            <div class="title column is-8 is-offset-2">Aktueller Verbrauch</div>
            <div class="completeConsumption column is-2">
                <span class="consumptionNumber" v-for="cnum in completeConsumption">{{cnum}}</span></div>
        </div>
        <div class="compareGraph">
            <consumptionGraph :graphData="getRandomGraphData()" />
        </div>
        <div class="title">Vergleich letzter Zeitraum</div>
        <div v-if="false" class="columns is-mobile is-centered">
            <datepicker class="column is-3" :initial-view="datePickerMode" @changedYear="setRandomCompareData()" @changedMonth="setRandomCompareData()" @selected="" input-class="input"></datepicker>
        </div>
        <div class="compareGraph">
            <consumptionGraph :graphData="getRandomGraphData()" />
        </div>

    </div>
    <div class="title">Zusammensetzung des bezogenen Stroms</div>
    <div class="compositionChart">
        <areachart :graphData="randomCompositionData"></areachart>
    </div>

    <div class="compareAverageToday">
        <div class="title">Tagesverbrauch Durchschnitt: {{consumptionToday}}%</div>
        <div class="caWrapper">
            <div class="caContent" :style="consumptionBatteryStyle()"></div>
        </div>
    </div>
</div>
</template>

<script>
import consumptionGraph from '@/components/Devices/consumptionGraph.vue'
import areachart from '@/components/Devices/areachart.vue'

import Datepicker from 'vuejs-datepicker';

export default {
    name: 'ConsumptionCompare',
    props: ["selectedPeriode"],
    components: {
        consumptionGraph,
        Datepicker,
        areachart
    },
    mounted: function () {
        this.setRandomCompareData();
        this.mockCompleteConsumption();
        console.log("mount")
        this.consumptionToday = Math.floor(Math.random() * 150)
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            graphDatax: [],
            graphOptions: {},
            consumptionData: {},
            compareData: [],
            completeConsumption: [],
            consumptionToday: 40,
        }
    },
    methods: {
        consumptionBatteryStyle() {
            console.log("cbstyle")
            // this.consumptionToday = Math.floor(Math.random() * 100)
            if (this.consumptionToday <= 100) {
                return {
                    'top': 100 - this.consumptionToday + "%"
                };
            } else {
                return {
                    'top':  "0%",
                    'background':'red'
                };
            }

        },
        mockCompleteConsumption() {
            for (var i = 0; i <= 5; i++) {
                this.completeConsumption.push(Math.floor(Math.random() * 10))

            }
        },
        getMockConsumptionData(amount = 30, min = 0, max = 10) {
            let consumptionData = {
                dates: [],
                consumptionvalue: []
            }

            for (var i = 1; i <= amount; i++) {
                var consumption = min + Math.floor(Math.random() * (max - min))

                consumptionData.consumptionvalue.push(consumption)

            }
            return consumptionData;
        },
        getConsumptionDates(amount, type = 'daily') {
            var dates = []
            for (var i = 1; i <= amount; i++) {

                if (type == "daily") {

                    if (i < 10) {

                        dates.push("0" + i + ".01.19")

                    } else {
                        dates.push(i + ".01.19")

                    }

                } else if (type == "hourly") {
                    if (i < 10) {
                        dates.push("0" + i + ":00")

                    } else {
                        dates.push(i + ":00")

                    }

                } else if (type == "quarter") {

                    dates.push("14:" + ((i * 15) % 60))
                } else if (type == "yearly") {

                    dates.push("01." + i + ".2019")
                } else if (type == "all") {

                    dates.push("01." + i + ".2019")
                } else {
                    dates.push(i)
                }
            }
            return dates;
        },

        setRandomCompareData() {
            console.log("set new comparedata")
            this.compareData = this.getRandomGraphData();
        },

        getRandomGraphData() {

            let mockdata = {}
            if (this.selectedPeriode == 'periode') {
                mockdata = this.getMockConsumptionData(12);
                mockdata.dates = this.getConsumptionDates(12, 'daily')

            } else if (this.selectedPeriode == 'daily') {
                mockdata = this.getMockConsumptionData();
                mockdata.dates = this.getConsumptionDates(30, 'daily')

            } else if (this.selectedPeriode == 'hourly') {

                mockdata = this.getMockConsumptionData(24);
                mockdata.dates = this.getConsumptionDates(24, 'hourly')

            } else if (this.selectedPeriode == 'yearly') {
                mockdata = this.getMockConsumptionData(12, 50, 300);
                mockdata.dates = this.getConsumptionDates(12, 'yearly')

            } else if (this.selectedPeriode == 'all') {
                mockdata = this.getMockConsumptionData(17, 50, 300);
                mockdata.dates = this.getConsumptionDates(17, 'all')

            } else if (this.selectedPeriode == 'quarter') {
                mockdata = this.getMockConsumptionData(4, 1, 5);
                mockdata.dates = this.getConsumptionDates(4, 'quarter')
            }

            return [{
                name: "Kw/H",
                data: mockdata.consumptionvalue,
                dates: mockdata.dates
            }, ]

        },

    },
    computed: {
        datePickerMode() {
            if (this.selectedPeriode == 'daily') {
                return "month"
            } else if (this.selectedPeriode == 'yearly' || this.selectedPeriode == 'all') {
                return "year"
            } else if (this.selectedPeriode == 'daily') {
                return "month"
            } else return ""
        },

        randomCompositionData() {
            let compData = [{
                name: "Eigenerzeugnis",
                data: [],
                dates: []
            }, {
                name: "Speicher",
                data: [],
                dates: []
            }, {
                name: "Fremdbezug",
                data: [],
                dates: []
            }, ];

            for (var i = 0; i < 30; i++) {
                var randVal = Math.floor(Math.random() * 50) + 20;
                compData[0].data.push(randVal);
                randVal = Math.floor((Math.random() * 7) + 1)
                compData[1].data.push(randVal);
                randVal = Math.floor((Math.random() * 12) + 1)
                compData[2].data.push(randVal);
                compData[0].dates.push((i + 1) + ".01.2020");

            }
            return compData;

        }
    }
}
</script>

<style scoped>
.compareGraph {
    max-height: 25vh;
    height: 25vh;
}

.consumptionNumber {
    font-weight: bolder;
    padding: 4px;
    border: 3px solid #f5f5f5;
    font-size: 1.5em;
    min-width: 1em;
    display: inline-block;
}

.compositionChart {
    height: 40vh;
}

.compareAverageToday {
    text-align: center;
}

.caWrapper {
    display: inline-block;
    height: 10vh;
    width: 7vh;
    border: 2px solid #a1a1a1;
    overflow: hidden;
    border-radius: 2px;
}

.caContent {
    background: #47C655;
    width: 100%;
    height: 100%;
    position:relative;
}
</style>
