<template>
  <div class="profileContainer">
    <div class="fixedSVGWave">
      <svg viewBox="0 0 500 20" preserveAspectRatio="xMinYMin meet">
        <path
          d="M0,15 C150,30 250,0 500,15 L500,00 L0,0 Z"
          style="stroke: none; fill: #164786"
        ></path>
      </svg>
    </div>

    <table class="table is-fullwidth">
      <div class="columns is-mobile is-multiline">
        <div class="column is-1-desktop is-12-tablet is-12-mobile"></div>
        <div
          class="column is-4-desktop is-12-tablet is-12-mobile has-text-left"
        >
          <div class="column profileLabel">
            Aktive Foren
            <i class="icon fas fa-comments"></i>
          </div>
          <div class="column is-8">
            <div class="forenBox">Forum 1</div>
          </div>
          <div class="column is-8">
            <div class="forenBox">Forum 2</div>
          </div>
          <div class="column is-8">
            <div class="forenBox">Forum 3</div>
          </div>
          <div class="column is-8">
            <div class="forenBox">Forum 4</div>
          </div>
        </div>
        <div
          class="column is-2-desktop is-12-tablet is-12-mobile has-text-left"
        >
          <!-- profileName -->
          <div class="column profileName">
            {{ profile.firstname }} {{ profile.name }}
          </div>
          <!-- nickname -->
          <div v-if="ownProfile">
            <span class="column"
              ><i class="icon fas fa-asterisk"></i
              >{{ $t("enaq_translation_profile_nickname") }}:
            </span>
            <input
              class="input column is-6 ml-2"
              v-model="profile.nickname"
              placeholder="/"
            />
          </div>

          <div v-else>
            <div
              v-if="
                profile.nickname != undefined &&
                profile.nickname != '' &&
                profile.nickname != null
              "
            >
              <span class="column"
                ><i class="icon fas fa-asterisk"></i
                >{{ $t("enaq_translation_profile_nickname") }}:
                {{ profile.nickname }}</span
              >
            </div>
            <div v-else>
              <span class="column"
                ><i class="icon fas fa-asterisk"></i
                >{{ $t("enaq_translation_profile_nickname") }}: /</span
              >
            </div>
          </div>

          <!-- birthday -->
          <span class="column"
            ><i class="icon fas fa-asterisk"></i
            >{{ $t("enaq_translation_profile_birthday") }}:
            {{ profile.birthdate }}</span
          >

          <!-- move-in date -->
          <span class="column"
            ><i class="icon fas fa-asterisk"></i
            >{{ $t("enaq_translation_profile_resident_since") }}:
            {{ profile.moveinDate }}</span
          >

          <!-- score -->
          <div
            class="column"
            v-if="profile.score != undefined && profile.score != null"
          >
            <span>
              <i class="icon fas fa-asterisk"></i>Score:
              {{ profile.score }}
              {{ $t("enaq_translation_profile_score") }}
              <router-link :to="'/meetingpoint/highscore'">
                (Highscore)
              </router-link>
            </span>
          </div>
        </div>
        <div class="column is-2-desktop is-12-tablet is-12-mobile">
          <div
            class="
              column
              is-12-tablet is-12-fullhd
              profileImageContainerContainer
            "
            v-if="ownProfile"
          >
            <!-- profileImage -->
            <div class="column">
              <div
                class="profileImageContainer pointer"
                v-if="
                  this.$store.getters.getUser.avatar != undefined &&
                  this.$store.getters.getUser.avatar != '' &&
                  (this.imageData == null ||
                    this.imageData == undefined ||
                    this.imageData == '')
                "
              >
                <img
                  @click="chooseFile"
                  class="profileImage"
                  :src="this.$storageURL + this.$store.getters.getUser.avatar"
                />
              </div>

              <div
                class="profileImageContainer pointer"
                v-if="
                  this.imageData != null &&
                  this.imageData != undefined &&
                  this.imageData != ''
                "
              >
                <img
                  @click="chooseFile"
                  class="profileImage"
                  :src="this.imageData"
                />
              </div>
            </div>

            <!-- change profileImage -->
            <input
              type="file"
              style="display: none"
              id="changeFile"
              @change="onFileChanged"
            />

            <!-- delete profileImage -->
            <div class="column">
              <enaqbutton :width="'150px'" @click="delete_own_avatar">
                Bild löschen <i class="fas fa-trash"></i>
              </enaqbutton>
            </div>
          </div>

          <!-- profileImage !ownProfile -->
          <div
            class="
              column
              is-12-fullhd is-12-tablet
              profileImageContainerContainer
            "
            v-else
          >
            <div class="column">
              <div class="profileImageContainer">
                <img
                  class="profileImage"
                  :src="$storageURL + this.profile.avatar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- friends/biography -->
      <div class="columns is-mobile is-multiline">
        <div class="column is-1"></div>
        <div class="column is-4">
          <!-- friends -->
          <div class="column profileLabel has-text-left">
            Freunde <i class="icon fas fa-users"></i>
          </div>
          <div class="columns">
            <div class="column is-3">
              <div class="friendImageContainer">
                <img
                  class="friendImage"
                  src="@/assets/woman1.png"
                  alt="Group"
                />
              </div>
            </div>
            <div class="column is-3">
              <div class="friendImageContainer">
                <img class="friendImage" src="@/assets/man1.jpg" alt="Group" />
              </div>
            </div>
            <div class="column is-3">
              <div class="friendImageContainer">
                <img
                  class="friendImage"
                  src="@/assets/woman2.jpg"
                  alt="Group"
                />
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-3">
              <div class="friendImageContainer">
                <img class="friendImage" src="@/assets/man2.png" alt="Group" />
              </div>
            </div>
            <div class="column is-3">
              <div class="friendImageContainer">
                <img
                  class="friendImage"
                  src="@/assets/woman3.jpg"
                  alt="Group"
                />
              </div>
            </div>
            <div class="column is-3">
              <div class="friendImageContainer">
                <img class="friendImage" src="@/assets/man3.png" alt="Group" />
              </div>
            </div>
          </div>
          <div class="column has-text-left profileLabel pointer">
            Nachbarn finden <i class="icon_bold">>></i>
          </div>
        </div>

        <!-- biography -->
        <div class="column is-5">
          <div class="has-text-left profileLabel">
            Biografie <i class="icon fas fa-book"></i>
          </div>
          <div v-if="ownProfile" class="is-11">
            <textarea
              style="border: solid 1px #164786"
              class="textarea"
              rows="10"
              placeholder="/"
              v-model="profile.description"
            ></textarea>
            <div class="column has-text-left">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="profile.acceptHighscoreListing"
                />
                {{ $t("enaq_translation_profile_highscore_acceptance") }}
              </label>
            </div>

            <!-- save changes -->
            <enaqbutton
              :fontsize="'16px'"
              :padding="'3px'"
              :margin="'10px'"
              :width="'250px'"
              @click="saveProfile"
              >Änderungen speichern <i class="fas fa-save"></i
            ></enaqbutton>
          </div>

          <!-- biography !ownProfile -->
          <div v-else class="is-11">
            <textarea
              style="border: solid 1px #164786"
              class="textarea"
              rows="10"
              placeholder="/"
              v-model="profile.description"
              disabled="true"
            ></textarea>
          </div>
        </div>
      </div>
    </table>
  </div>
</template>

<script>
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "profile",
  components: {
    enaqbutton,
  },
  props: ["profileID"],
  mounted: function () {
    this.userId = this.$route.params.id;
    this.getProfile();
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      userId: 0,
      profile: "",
      ownProfile: "",
      nickName: "",
      selectedFile: null,
      imageData: null,
    };
  },
  methods: {
    chooseFile() {
      document.getElementById("changeFile").click();
    },
    getProfile() {
      this.HTTPRequest()
        .getProfile(this.userId)
        .then(
          function (result) {
            this.profile = result.data.user;
            this.ownProfile = result.data.ownProfile;
            this.nickName = result.data.nickname;
          }.bind(this)
        );
    },
    onFileChanged(event) {
      this.previewImage(event);
      this.selectedFile = event.target.files[0];
    },
    onUpload() {
      if (this.selectedFile != null) {
        let headerData = {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.bearertoken,
        };
        const formData = new FormData();
        formData.append("avatar", this.selectedFile, this.selectedFile.name);
        let uploadData = {
          avatar: this.selectedFile,
        };
        this.$axios
          .post(this.$backendURL + "/user/edit/avatar", formData, {
            headers: headerData,
          })
          .then(function (response) {}.bind(this));
      }
    },
    delete_own_avatar() {
      this.HTTPRequest()
        .delete_own_avatar()
        .then(
          function (result) {
            this.$router.go();
          }.bind(this)
        );
    },
    saveProfile() {
      this.onUpload();
return;
      this.profile.id += "";
      let userdata = {
        user: this.profile,
      };
      this.sendInfoMessage({
        loading: true,
      });

      this.callApi("/user/" + this.profile.id + "/edit", "post", userdata).then(
        function (response) {
          this.$router.go();
        }.bind(this)
      );
    },

    previewImage: function (event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.profileImage {
  height: 100%;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  background: rgb(245, 245, 245);
}

.profileImageContainer {
  margin: 0 auto;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 100%;
  box-shadow: 10px 10px #536b8b;
}

.profileImageContainerContainer {
  margin-left: 25%;
}

.profileName {
  font-size: 1.5em;
  font-weight: bolder;
}

.profileContainer {
  margin-left: -5vw;
  width: 100vw;
  overflow: hidden;
  background: #ffffff;
}
.profileLabel {
  font-size: 1em;
  font-weight: bolder;
}

.fixedSVGWave {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.table {
  margin-top: 5%;
}

.forenBox {
  color: white;
  background: #164786;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
}

.friendImage {
  height: 100%;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  background: rgb(245, 245, 245);
  cursor: pointer;
}

.friendImageContainer {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 100%;
}

.icon {
  color: #164786;
}
.icon_bold {
  color: #164786;
  font-weight: bolder;
}
</style>
