<template>
  <div class="pluginContainer">
    <div
      class=""
      v-if="
        currentService == '' ||
        currentService == null ||
        currentService == undefined
      "
    >
      <br />

      <header
        class="modal-card-head"
        @click="openTabAddedServices('abboniert')"
      >
        <p class="modal-card-title">Abbonierte Services</p>
        <i class="fas fa-chevron-down"></i>
      </header>

      <transition name="servicePartTransition">
        <div v-if="tabAddedServices == 'abboniert'">
          <br />
          <table class="table is-fullwidth">
            <div class="columns is-multiline is-mobile">
              <div
                class="column is-3"
                v-for="service in getServices()"
                v-bind:key="service.id"
                v-show="allSPServices.find((e) => e.id == service.id)"
                v-if="isServiceVisible(service.id)"
              >
                <!--Später ebenfalls prüfen ob consent_given o.ä true ist, ansonsten unten anzeigen-->
                <component
                  class="test"
                  @open="selectService(service)"
                  :is="service.overview"
                  @edit="openServicePermissionWIndow(service)"
                />
              </div>
            </div>
          </table>
        </div>
      </transition>

      <br />

      <header class="modal-card-head" @click="openTabAllServices('andere')">
        <p class="modal-card-title">Alle anderen Services</p>
        <i class="fas fa-chevron-down"></i>
      </header>

      <transition name="servicePartTransition">
        <div v-if="tabAllServices == 'andere'">
          <br />
          <table class="table is-fullwidth">
            <div class="columns is-multiline is-mobile">
              <div
                class="column is-3"
                v-for="service in getServices()"
                v-bind:key="service.id"
                v-if="isServiceVisible(service.id)"
              >
                <component
                  @open="selectService(service)"
                  :is="service.overview"
                  @edit="openServicePermissionWIndow(service)"
                />
              </div>
            </div>
          </table>
        </div>
      </transition>

      <permissionPopup
        :pluginID="selectedServiceForPermissions"
        @close="closePermissionPopup"
      />
    </div>
    <div v-else>
      <component @closeService="closeService()" :is="currentService" />
    </div>
  </div>
</template>

<script>
import Enaqbutton from "../../components/UI/enaqbutton.vue";
import permissionPopup from "../../components/Plugins/pluginPermissionPopup.vue";
export default {
  name: "PluginOverview",
  created: function () {
    this.services = this.getServices();
    this.getHiddenServices();
  },
  data() {
    return {
      currentService: "",
      services: [],
      tabAddedServices: "abboniert",
      tabAllServices: "",
      selectedServiceForPermissions: null,
      allSPServices: [],
      hiddenServices: [],
    };
  },
  components: {
    Enaqbutton,
    permissionPopup,
  },
  methods: {
    getServices() {
      return this.$store.state.services;
    },
    selectService(service) {
      this.currentService = service.page;
    },
    closeService() {
      this.currentService = "";
    },
    openServicePermissionWIndow() {
      this.selectedServiceForPermissions = 123;
    },
    openTabAddedServices(name) {
      if (this.tabAddedServices == name) {
        this.tabAddedServices = "";
      } else {
        this.tabAddedServices = name;
      }
    },
    closePermissionPopup() {
      this.selectedServiceForPermissions = null;
    },
    openTabAllServices(name) {
      if (this.tabAllServices == name) {
        this.tabAllServices = "";
      } else {
        this.tabAllServices = name;
      }
    },
    getPluginDetails() {
      this.SPRequest()
        .getAppDetails()
        .then(
          function (result) {
            this.appDetails = result;
          }.bind(this)
        );
    },
    getAllUserApps() {
      //Sobald BTC neue Schnittstellenbeschreibung bereitstellt, prüfen welchen Apps schon zugestimmt wurde
      this.SPRequest()
        .getAllUserApps()
        .then(
          function (result) {
            this.allSPServices = result;
          }.bind(this)
        );
    },

    getHiddenServices() {
      this.HTTPRequest()
        .getHiddenServices()
        .then(
          function (result) {
            this.testdata = result;
            this.hiddenServices = result.data.data;
          }.bind(this)
        );
    },
    isServiceVisible(id) {
      return (
        this.hiddenServices.filter(function (e) {
          return e.serviceid === id;
        }).length <= 0
      );
    },
  },
  computed: {
    visiblePlugins() {},
  },
};
</script>

<style>
/* servicePartTransition */
.servicePartTransition-enter-active {
  transition: all 0.7s;
  overflow: hidden;
  max-height: 100vh;
}

.servicePartTransition-leave-active {
  transition: all 0.3s;
  overflow: hidden;
  max-height: 100vh;
}

.servicePartTransition-enter,
.servicePartTransition-leave-to {
  opacity: 0;
  max-height: 0px;
}

/* cards in services */
/* all cards */
.yellowcard *,
.orangecard *,
.bluecard *,
.darkbluecard * {
  color: #ffffff !important;
}

/* all card-headers */
.yellowcard > .card-header,
.orangecard > .card-header,
.bluecard > .card-header,
.darkbluecard > .card-header {
  border-color: #00000029;
  border-width: 1px;
  border-style: solid;
}

/* all card-contents */
.yellowcard > .card-content,
.orangecard > .card-content,
.bluecard > .card-content,
.darkbluecard > .card-content {
  height: 120px;
}

/* all contents */
.yellowcard > .card-content > .content,
.orangecard > .card-content > .content,
.bluecard > .card-content > .content,
.darkbluecard > .card-content > .content {
  overflow: hidden;
  height: 100%;
}

/* all card-footer-items */
.yellowcard > .card-footer > .card-footer-item,
.orangecard > .card-footer > .card-footer-item,
.bluecard > .card-footer > .card-footer-item,
.darkbluecard > .card-footer > .card-footer-item {
  width: 1;
  overflow: hidden;
}

/* yellowcard */
.yellowcard > .card-header,
.yellowcard > .card-content,
.yellowcard > .card-footer :hover {
  background-color: #ffaa2c;
}

.yellowcard > .card-footer {
  background-color: #ffbc5a;
}

/* orangecard */
.orangecard > .card-header,
.orangecard > .card-content,
.orangecard > .card-footer :hover {
  background-color: #e26900;
}

.orangecard > .card-footer {
  background-color: #f37b12;
}

/* bluecard */
.bluecard > .card-header,
.bluecard > .card-content,
.bluecard > .card-footer :hover {
  background-color: #4988d9;
}

.bluecard > .card-footer {
  background-color: #6ba5f1;
}

/* darkbluecard */
.darkbluecard > .card-header,
.darkbluecard > .card-content,
.darkbluecard > .card-footer :hover {
  background-color: #2665b7;
}

.darkbluecard > .card-footer {
  background-color: #397bd1;
}

/* serviceCardTooltips */
[serviceCardTooltip] {
  position: relative;
}

[serviceCardTooltip]::before,
[serviceCardTooltip]::after {
  text-transform: none;
  font-size: 0.9em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[serviceCardTooltip]::before {
  content: "";
  border: 5px solid transparent;
  z-index: 1001;
}

[serviceCardTooltip]::after {
  content: attr(serviceCardTooltip);
  font-family: Helvetica, sans-serif;
  text-align: center;
  min-width: 90% !important;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background-color: #b9b9b9;
  color: rgb(255, 255, 255);
  z-index: 1000;
  /* absurdity 2 */
}

/* Make the serviceCardTooltips respond to hover */
[serviceCardTooltip]:hover::before,
[serviceCardTooltip]:hover::after {
  display: block;
}

/* don't show empty serviceCardTooltips */
[serviceCardTooltip=""]::before,
[serviceCardTooltip=""]::after {
  display: none !important;
}

/* FLOW: DOWN */
[serviceCardTooltip][flow^="down"]::after {
  top: calc(0% + 10px);
}

[serviceCardTooltip][flow^="down"]::before,
[serviceCardTooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}

/* KEYFRAMES */
@keyframes serviceCardTooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes serviceCardTooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
[serviceCardTooltip]:not([flow]):hover::before,
[serviceCardTooltip]:not([flow]):hover::after,
[serviceCardTooltip][flow^="up"]:hover::before,
[serviceCardTooltip][flow^="up"]:hover::after,
[serviceCardTooltip][flow^="down"]:hover::before,
[serviceCardTooltip][flow^="down"]:hover::after {
  animation: serviceCardTooltips-vert 300ms ease-out forwards;
}

[serviceCardTooltip][flow^="left"]:hover::before,
[serviceCardTooltip][flow^="left"]:hover::after,
[serviceCardTooltip][flow^="right"]:hover::before,
[serviceCardTooltip][flow^="right"]:hover::after {
  animation: serviceCardTooltips-horz 300ms ease-out forwards;
}
</style>
