<template>
  <div class="ENaQButton">
    <div
      @click="onClick()"
      class="buttonBody"
      :style="buttonStyle"
      @mouseover="mouseEnter()"
      @mouseleave="mouseLeave"
    >
      <slot></slot>
      <div
        v-show="hover && tooltip"
        class="tooltip"
        ref="tooltipx"
        :style="tooltipStyle"
      >
        {{ tooltip }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ENaQButton",
  props: [
    "tooltip",
    "backgroundcolor",
    "fontcolor",
    "fontsize",
    "hovercolor",
    "width",
    "padding",
    "margin",
  ],
  mounted: function () {
    this.tooltipdata =
      this.$refs.tooltipx.clientWidth + "_" + this.$refs.tooltipx.clientHeight;
  },
  data() {
    return {
      data: [],
      defaultFontsize: "15px",
      defaultColor: "#e9a12d",
      defaultHoverColor: "#cdcdcd",
      defaultFontColor: "white",
      defaultWidth: "100px",
      defaultPadding: "0px",
      defaultMargin: "0px",
      hover: false,
      tooltipdata: "234",
      tooltipWidth: 0,
      tooltipX: 0,
      screenWidth: 0,
      showTooltip: false,
    };
  },
  methods: {
    mouseEnter() {
      this.hover = true;
      this.$nextTick().then(
        function () {
          this.showTooltip = true;
          this.tooltipWidth = this.$refs.tooltipx.clientWidth;
          this.tooltipX = this.$refs.tooltipx.getBoundingClientRect().left;
        }.bind(this)
      );
      this.$emit("hover");
    },
    mouseLeave() {
      this.showTooltip = false;
      this.hover = false;
      this.$emit("hoverend");
    },
    onClick() {
      this.$emit("click");
    },
  },
  computed: {
    buttonStyle() {
      var backgroundcolor = this.defaultColor;

      if (this.hover) {
        backgroundcolor = this.defaultHoverColor;
        if (
          this.hovercolor != null &&
          this.hovercolor != undefined &&
          this.hovercolor != ""
        ) {
          backgroundcolor = this.hovercolor;
        }
      } else {
        if (
          this.backgroundcolor != null &&
          this.backgroundcolor != undefined &&
          this.backgroundcolor != ""
        ) {
          backgroundcolor = this.backgroundcolor;
        }
      }
      var fontsize = this.defaultFontsize;
      if (
        this.fontsize != null &&
        this.fontsize != undefined &&
        this.fontsize != ""
      ) {
        fontsize = this.fontsize;
      }

      var fontcolor = this.defaultFontColor;
      if (
        this.fontcolor != null &&
        this.fontcolor != undefined &&
        this.fontcolor != ""
      ) {
        fontcolor = this.fontcolor;
      }

      var width = this.defaultWidth;
      if (this.width != null && this.width != undefined && this.width != "") {
        width = this.width;
      }

      var padding = this.defaultPadding;
      if (
        this.padding != null &&
        this.padding != undefined &&
        this.padding != ""
      ) {
        padding = this.padding;
      }

      var margin = this.defaultMargin;
      if (
        this.margin != null &&
        this.margin != undefined &&
        this.margin != ""
      ) {
        margin = this.margin;
      }

      return {
        "font-size": fontsize,
        background: backgroundcolor,
        color: fontcolor,
        width: width,
        padding: padding,
        display: "inline-block",
        margin: margin,
      };
    },
    tooltipStyle() {
      //showTooltip is changed 1 tick AFTER tooltip is displayed.
      //This ensures that the calculations ( tooltipwidth usw. ) are correct. Otherwise the width would be based on an empty box.
      if (!this.showTooltip) {
        return {
          left: "50%",
          transform: "translateX(-50%)",
        };
      }

      this.screenWidth = window.screen.width;

      var rightEndPixel = this.tooltipWidth + this.tooltipX;
      var leftEndPixel = this.tooltipX;

      var styleLeft = "50%";
      if (rightEndPixel > this.screenWidth) {
        styleLeft =
          "calc( 50% - " + (rightEndPixel - this.screenWidth) + "px - 1em)";
      } else if (leftEndPixel < 0) {
        styleLeft = "calc( 50% - " + leftEndPixel + "px )";
      }

      return {
        left: styleLeft,
        transform: "translateX(-50%)",
      };
    },
  },
};
</script>

<style scoped>
.buttonBody {
  text-align: center;
  color: #ffffff;
  border: 2px solid #ffffff80;
  border-radius: 50px;
  box-shadow: -2px 2px 5px #777777;
  cursor: pointer;
}
.buttonBody a {
  text-decoration: none !important;
  color: inherit;
}
.buttonBody a:link {
  text-decoration: none !important;
  color: inherit;
}
.buttonBody a:hover {
  text-decoration: none !important;
  color: inherit;
}
.buttonBody a:active {
  text-decoration: none !important;
  color: inherit;
}
.buttonBody a:visited {
  text-decoration: none !important;
  color: inherit;
}

.tooltip {
  position: absolute;
  background: #616161b3;
  top: 105%;
  padding: 0.25em 0.5em;
  border-radius: 5px;
  width: auto;
  min-width: 100%;
  max-width: 1500%;
  line-break: none;
  font-size: 1em;
  white-space: nowrap;
  color: white;
}
</style>
