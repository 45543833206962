<template>
  <div class="component">
    <div class="gridcontainer" :style="gridContainerClass">
      <div class="dayNumber">
        {{ day }}
      </div>

      <div class="eventContainer" :style="eventContainerClass">
        <div
          class="eventNameContainer"
          v-if="events != null && events.length > 0"
        >
          <div v-for="dayevent in events" v-bind:key="dayevent.id">
            <span
              :style="eventTitleClass(dayevent)"
              class="eventTitle"
              @click="$emit('eventclicked', dayevent)"
              >{{ dayevent.title }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "calendarGrid",
  props: ["day", "events", "width", "height", "highlight"],
  mounted: function () {},
  data() {
    return {
      data: [],
    };
  },
  methods: {
    eventTitleClass(event) {
      if (event != undefined) {
      }
      if (event != undefined && event.color != undefined) {
        return {
          background: event.color,
        };
      } else {
        return {
          background: "#4590ce",
        };
      }
    },
  },
  computed: {
    eventCustomColors() {
      let partEv = [];
      this.events.forEach(
        function (eve) {
          if (eve != undefined && eve.color != undefined) {
            partEv.push(eve.id);
          }
        }.bind(this)
      );
      return partEv;
    },

    eventContainerClass() {
      var style = {
        height: this.height,
      };
      if (this.highlight) {
        style.background = "#CACACA";
      }

      return style;
    },
    gridContainerClass() {
      var style = {
        width: this.width,
        height: this.height,
      };

      return style;
    },
    onEventClick(arg) {
      this.$emit("onEventClickGrid", arg);
    },
  },
};
</script>

<style scoped>
.gridcontainer {
  min-height: 122px;
  max-width: 98%;
  margin: 2px auto;
  transition: 0.3s;
}

.eventContainer {
  background: #eaeaea;
  width: 100%;
  height: 100%;
  min-height: 120px;
}

.component {
  position: relative;
}

.dayNumber {
  position: absolute;
  top: 0;
  left: 5%;
  font-size: 1.3em;

  font-weight: bolder;
  border-radius: 30px;
  line-height: 1.5em;
  width: 1.5em;
}

.eventTitle {
  display: block;
  color: white;
  margin: 2px 0 2px 20%;
  border-radius: 20px;
  width: 75%;
  font-weight: 900;
  padding: 3px 0;
  max-height: 2em;
  overflow: hidden;
}

.eventNameContainer {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

@media (max-width: 1406px) {
  .dayNumber {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2%;
    font-size: 4.3em;
    font-weight: bolder;
  }
}

@media (min-width: 1406px) {
  .dayNumber {
    margin-left: -0.3em;
    margin-top: 0.25em;

    -webkit-box-shadow: 0px 0px 3px 0px rgba(165, 165, 165, 1);
    -moz-box-shadow: 0px 0px 3px 0px rgba(165, 165, 165, 1);
    box-shadow: 0px 0px 3px 0px rgba(165, 165, 165, 1);
  }
}
</style>
