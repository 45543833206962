<template>
  <div class="component">
    <div class="filterHeader columns is-mobile is-multiline">
      <div class="column is-4-fullhd is-12-tablet is-12-mobile">
        <div class="columns is-mobile is-multiline">
          <span class="column is-4 has-text-right filterTitle">{{
            $t("enaq_translation_calendar_categorie")
          }}</span>
          <div class="column is-8 has-text-left">
            <div class="select">
              <select v-model="selectedEventType">
                <option value="-1">
                  {{ $t("enaq_translation_calendar_categorie_none") }}
                </option>

                <option
                  v-for="eventType in allEventTypes"
                  :value="eventType.id"
                  :key="eventType.id"
                >
                  {{ eventType.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-8-fullhd is-12-tablet is-12-mobile has-text-left">
        <div class="columns is-mobile is-multiline">
          <div class="column is-8 has-text-left">
            <span>Preis: </span>
            <input
              class="textField"
              type="number"
              min="0"
              v-model="priceFrom"
              id="priceFrom"
            />
            <span> - </span>
            <input
              class="textField"
              type="number"
              min="1"
              v-model="priceTo"
              id="priceTo"
            />
            <span>€</span>
          </div>
          <div class="column is-4 has-text-centered">
            <enaqbutton :width="'200px'" @click="resetPriceFilter()"
              >Preis zurücksetzen</enaqbutton
            >
          </div>
        </div>
      </div>
      <div class="column is-3-desktop is-12-tablet is-12-mobile">
        <div class="filterResult">
          {{ filteredEvents.length }}
          <span v-if="filteredEvents.length > 1">Suchergebnisse</span>
          <span v-else>Suchergebnis</span>
        </div>
      </div>
      <div class="column is-3-fullhd is-12-tablet is-12-mobile">
        <div class="columns is-mobile is-multiline">
          <span class="column is-8 has-text-right filterTitle">{{
            $t("enaq_translation_calendar_only_free")
          }}</span>
          <div class="column is-4 has-text-left">
            <slidebutton
              :height="'31px'"
              :default="onlyFree"
              @toggle="onlyFree = !onlyFree"
            ></slidebutton>
          </div>
        </div>
      </div>
      <div class="column is-3-fullhd is-12-tablet is-12-mobile">
        <div class="columns is-mobile is-multiline">
          <span class="column is-8 has-text-right filterTitle">{{
            $t("enaq_translation_calendar_only_open")
          }}</span>
          <div class="column is-4 has-text-left">
            <slidebutton
              :height="'31px'"
              :default="onlyOpen"
              @toggle="onlyOpen = !onlyOpen"
            ></slidebutton>
          </div>
        </div>
      </div>
      <div class="column is-3-fullhd is-12-tablet is-12-mobile">
        <div class="columns is-mobile is-multiline">
          <span class="column is-8 has-text-right filterTitle"
            >Teilnehmend</span
          >
          <div class="column is-4 has-text-left">
            <slidebutton
              :height="'31px'"
              :default="onlyParticipated"
              @toggle="onlyParticipated = !onlyParticipated"
            ></slidebutton>
          </div>
        </div>
      </div>
    </div>
    <Calendar
      @onChangeDate="onChangeDate"
      @onEventClick="onEventClick"
      :events="filteredEvents"
      :currentyear="currentYear"
      :currentmonth="currentMonth"
    ></Calendar>

    <div
      v-if="selectedEvent != null"
      class="modal"
      :class="{ 'is-active': selectedEvent != null }"
    >
      <div class="modal-background" @click="selectedEvent = null"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ selectedEvent.title }}</p>
          <button
            @click="selectedEvent = null"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-mobile is-multiline">
            <div class="column is-2 hasBottomLine">
              {{ $t("enaq_translation_calendar_event_organisator") }}
            </div>
            <div class="column is-10 hasBottomLine">
              <router-link
                class=""
                :to="{
                  name: 'messageCreateWithTitle',
                  params: {
                    messagetitle: 'Eventanfrage zu ' + selectedEvent.title,
                    messagereciever: selectedEvent.author.id,
                  },
                }"
              >
                {{ selectedEvent.author.firstname }}
                {{ selectedEvent.author.name }} (Nachricht)
              </router-link>
            </div>
            <div class="column is-2 hasBottomLine">
              {{ $t("enaq_translation_calendar_event_place") }}
            </div>
            <div
              v-if="
                selectedEvent.place == null ||
                selectedEvent.place == undefined ||
                selectedEvent.place == ''
              "
              class="column is-10 hasBottomLine"
            >
              {{ selectedEvent.room.name }}
            </div>
            <div v-else class="column is-10 hasBottomLine">
              {{ selectedEvent.place }}
            </div>

            <div class="column is-12 hasBottomLine">
              <div class="columns is-mobile is-multiline">
                <div class="column is-2">
                  {{ $t("enaq_translation_calendar_event_date") }}
                </div>
                <div class="column is-2 is-offset-3">
                  {{ getReadableDate(selectedEvent.begin) }}
                </div>
                <div class="column is-2">
                  {{ getHour(selectedEvent.begin) }}
                  {{ $t("enaq_translation_calendar_event_date_hour") }}
                </div>
                <div class="column is-1">
                  {{ $t("enaq_translation_calendar_event_date_until") }}
                </div>
                <div class="column is-2">
                  {{ getHour(selectedEvent.end) }}
                  {{ $t("enaq_translation_calendar_event_date_hour") }}
                </div>
              </div>
            </div>

            <div class="column is-2 hasBottomLine">
              {{ $t("enaq_translation_calendar_event_member") }}
            </div>
            <div class="column is-10 hasBottomLine">
              <span
                v-if="
                  selectedEvent.maxMember == 0 ||
                  selectedEvent.maxMember == null
                "
                >{{ $t("enaq_translation_calendar_event_member_max") }}</span
              >
              <span v-else
                >{{ selectedEvent.membercount }}
                {{ $t("enaq_translation_calendar_event_member_to") }}
                {{ selectedEvent.maxMember }}</span
              >
            </div>

            <div class="column is-2 hasBottomLine">
              {{ $t("enaq_translation_calendar_event_price") }}
            </div>
            <div class="column is-10 hasBottomLine">
              <span
                v-if="selectedEvent.price == 0 || selectedEvent.price == null"
                >{{ $t("enaq_translation_calendar_event_price_free") }}</span
              >
              <span v-else>{{ selectedEvent.price }} Euro</span>
            </div>

            <div class="column is-2">
              {{ $t("enaq_translation_calendar_event_description") }}
            </div>
            <div class="column is-10">
              <span v-for="n in 1" :key="n.id"
                >{{ selectedEvent.description }}
              </span>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <enaqbutton
            :margin="'5px'"
            v-if="selectedEvent.organisator_id == $store.getters.getUser.id"
            @click="onEditEventClick()"
          >
            {{ $t("enaq_translation_calendar_event_change") }}
          </enaqbutton>
          <enaqbutton
            :margin="'5px'"
            :backgroundcolor="'#30b830'"
            v-if="!selectedEvent.participated"
            @click="participate()"
            :class="{
              'is-success': selectedEvent.membercount < selectedEvent.maxMember,
              'is-light': selectedEvent.membercount >= selectedEvent.maxMember,
            }"
          >
            {{ $t("enaq_translation_calendar_event_participate") }}
          </enaqbutton>
          <enaqbutton
            :margin="'5px'"
            :backgroundcolor="'#f06969'"
            v-else
            @click="leave()"
            :class="{
              'is-success': selectedEvent.membercount < selectedEvent.maxMember,
              'is-light': selectedEvent.membercount >= selectedEvent.maxMember,
            }"
          >
            {{ $t("enaq_translation_calendar_event_leave") }}
          </enaqbutton>
        </footer>
      </div>
    </div>

    <infoModal
      :message="infoMessage"
      :openModal="infoMessage != ''"
      :title="'Information'"
      @closed="infoMessage = ''"
    ></infoModal>
  </div>
</template>

<script>
import Calendar from "@/components/Meetingpoint/Calendar/calendarMain";
import infoModal from "@/components/UI/infoModal.vue";
import slidebutton from "@/components/helper/slidebutton.vue";
import enaqbutton from "@/components/UI/enaqbutton.vue";

export default {
  name: "Calendarview",
  components: {
    Calendar,
    infoModal,
    slidebutton,
    enaqbutton,
  },
  props: [],
  mounted: function () {
    var d = new Date();
    this.currentMonth = d.getMonth() + 1;
    this.currentYear = d.getFullYear();
    this.getEventTypes();
    this.getEvents();
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      data: [],
      currentMonth: null,
      currentYear: null,
      events: [],
      participatedEvents: [],
      loaded: false,
      selectedEvent: null,
      infoMessage: "",
      allEventTypes: [],
      selectedEventType: -1,
      onlyFree: false,
      onlyOpen: false,
      onlyParticipated: false,
      priceFrom: "",
      priceTo: "",
    };
  },
  methods: {
    resetPriceFilter() {
      this.priceFrom = "";
      this.priceTo = "";
    },
    disable() {
      document.getElementById("priceFrom").disabled = true;
      document.getElementById("priceTo").disabled = true;
    },
    enable() {
      document.getElementById("priceFrom").disabled = false;
      document.getElementById("priceTo").disabled = false;
    },
    onEventClick(event) {
      this.selectedEvent = event;
    },
    onEditEventClick() {
      this.$router.push({
        name: "events",
        params: {
          eventtoedit: this.selectedEvent,
        },
      });
    },
    onChangeDate(date) {
      this.currentMonth = new Date(date).getMonth() + 1;
      this.currentYear = new Date(date).getFullYear();
      this.getEvents();
    },
    getEvents() {
      this.events = [];

      this.HTTPRequest()
        .getEvents(this.currentYear, this.currentMonth)
        .then(
          function (result) {
            this.events = result.data.events;
            this.getParticipatedEvents();
          }.bind(this)
        );
    },
    getReadableDate(date) {
      let text = "";
      let dateObject = new Date(date);
      text +=
        dateObject.getDate() +
        "." +
        (dateObject.getMonth() + 1) +
        "." +
        dateObject.getFullYear();

      return text;
    },
    getHour(date) {
      return new Date(date).getHours() + "";
    },
    getParticipatedEvents() {
      for (var eventIndex = 0; eventIndex < this.events.length; eventIndex++) {
        let eventData = this.events[eventIndex];
        if (eventData.participated) {
          this.events[eventIndex].color = "#f0ad12";
          this.events[eventIndex].participated = true;
        }
      }
    },
    checkIfParticipate(event) {
      for (
        var participatedIndex = 0;
        participatedIndex < this.participatedEvents.length;
        participatedIndex++
      ) {
        let partEvent = this.participatedEvents[participatedIndex];
        if (event.id == partEvent.id) {
          return true;
        }
      }
      return false;
    },
    participate() {
      if (this.selectedEvent == null) {
        return;
      }
      if (
        this.selectedEvent.membercount >= this.selectedEvent.maxMember &&
        this.selectedEvent.maxMember != 0 &&
        this.selectedEvent.maxMember != null &&
        this.selectedEvent.maxMember != undefined
      ) {
        return;
      }

      this.HTTPRequest()
        .joinEvent(this.selectedEvent.id)
        .then(
          function (result) {
            this.selectedEvent = null;
            this.getEvents();
          }.bind(this)
        );
    },
    leave() {
      if (this.selectedEvent == null) {
        return;
      }
      if (
        this.selectedEvent.membercount >= this.selectedEvent.maxMember &&
        this.selectedEvent.maxMember != 0 &&
        this.selectedEvent.maxMember != null &&
        this.selectedEvent.maxMember != undefined
      ) {
        return;
      }
      this.HTTPRequest()
        .leaveEvent(this.selectedEvent.id)
        .then(
          function (result) {
            this.selectedEvent = null;
            this.getEvents();
          }.bind(this)
        );
    },
    getEventTypes() {
      return;
      this.HTTPRequest()
        .getEventTypes()
        .then(
          function (result) {
            this.allEventTypes = result.data.eventTypes;
          }.bind(this)
        );
    },
  },
  computed: {
    filteredEvents() {
      var priceFrom = this.priceFrom;
      var priceTo = this.priceTo;

      var newEvents = this.events;

      /*if (
        this.selectedEventType != "" &&
        this.selectedEventType != -1 &&
        this.selectedEventType != undefined &&
        this.selectedEventType != null
      ) {
        if (
          this.event.eventids == null ||
          this.event.eventids.length <= 0 ||
          !this.event.eventids.includes(this.selectedEventType)
        ) {
          newEvents = newEvents.filter(function (event) {
            return event.type == this.selectedEventType;
          });
        }
      }*/

      if (this.onlyOpen) {
        newEvents = newEvents.filter(function (event) {
          return event.isOpen == true;
        });
      }

      if (this.onlyFree) {
        newEvents = newEvents.filter(function (event) {
          return event.price == 0;
        });
      }

      if (this.onlyParticipated) {
        newEvents = newEvents.filter(function (event) {
          return event.participated == true;
        });
      }

      if (
        priceFrom != "" &&
        priceFrom >= 0 &&
        priceFrom != undefined &&
        priceFrom != null &&
        priceTo != "" &&
        priceTo >= 0 &&
        priceTo != undefined &&
        priceTo != null
      ) {
        newEvents = newEvents.filter(function (event) {
          return event.price >= priceFrom && event.price <= priceTo;
        });
      }

      return newEvents;
    },
  },
  watch: {
    onlyFree: function (val) {
      if (val == true) {
        this.disable();
        this.resetPriceFilter();
      }
      if (val == false) {
        this.enable();
      }
    },
  },
};
</script>

<style scoped>
.filterHeader {
  margin-top: 20px;
}

.filterTitle {
  font-size: 1.2rem;
  font-weight: bolder;
}

.hasBottomLine {
  border-bottom: 1px solid #164786;
}

.filterResult {
  font-weight: 600;
  font-size: 1.3rem;
  color: #e9a12d;
}
.filterHeader::after {
  content: "";
  border-bottom: 1px solid #1c63af;
  width: 100%;
  margin-top: 15px;
  display: inline-block;
}
</style>
